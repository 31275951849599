<div [ngClass]="{'star-blue':(color=='blue'), 'star-blue-dark':(color=='blue-dark'), 'star-orange':(color=='orange'), 'star-bronze':(color=='bronze'), 'star':true, 'star-small':true}" *ngIf="num>=1"></div>
<div [ngClass]="{'star-blue':(color=='blue'), 'star-blue-dark':(color=='blue-dark'), 'star-orange':(color=='orange'), 'star-bronze':(color=='bronze'), 'star':true, 'star-small':true}" *ngIf="num>=2"></div>
<div [ngClass]="{'star-blue':(color=='blue'), 'star-blue-dark':(color=='blue-dark'), 'star-orange':(color=='orange'), 'star-bronze':(color=='bronze'), 'star':true, 'star-small':true}" *ngIf="num>=3"></div>
<div [ngClass]="{'star-blue':(color=='blue'), 'star-blue-dark':(color=='blue-dark'), 'star-orange':(color=='orange'), 'star-bronze':(color=='bronze'), 'star':true, 'star-small':true}" *ngIf="num>=4"></div>
<div [ngClass]="{'star-blue':(color=='blue'), 'star-blue-dark':(color=='blue-dark'), 'star-orange':(color=='orange'), 'star-bronze':(color=='bronze'), 'star':true, 'star-small':true}" *ngIf="num>=5"></div>
<div [ngClass]="{'star-blue':(color=='blue'), 'star-blue-dark':(color=='blue-dark'), 'star-orange':(color=='orange'), 'star-bronze':(color=='bronze'), 'star':true, 'star-small':true, 'star-blue-transparent':true}" *ngIf="showEmptyStars && num<5"></div>
<div [ngClass]="{'star-blue':(color=='blue'), 'star-blue-dark':(color=='blue-dark'), 'star-orange':(color=='orange'), 'star-bronze':(color=='bronze'), 'star':true, 'star-small':true, 'star-blue-transparent':true}" *ngIf="showEmptyStars && num<4"></div>
<div [ngClass]="{'star-blue':(color=='blue'), 'star-blue-dark':(color=='blue-dark'), 'star-orange':(color=='orange'), 'star-bronze':(color=='bronze'), 'star':true, 'star-small':true, 'star-blue-transparent':true}" *ngIf="showEmptyStars && num<3"></div>
<div [ngClass]="{'star-blue':(color=='blue'), 'star-blue-dark':(color=='blue-dark'), 'star-orange':(color=='orange'), 'star-bronze':(color=='bronze'), 'star':true, 'star-small':true, 'star-blue-transparent':true}" *ngIf="showEmptyStars && num<2"></div>
<div [ngClass]="{'star-blue':(color=='blue'), 'star-blue-dark':(color=='blue-dark'), 'star-orange':(color=='orange'), 'star-bronze':(color=='bronze'), 'star':true, 'star-small':true, 'star-blue-transparent':true}" *ngIf="showEmptyStars && num<1"></div>
                                    