import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { ServiceUseDto } from './ServiceUseDto';
import { DataService } from '../network/data.service';

@Injectable()
export class ServiceUseResolve implements Resolve<ServiceUseDto> {
    constructor(private dataService: DataService) {
    }

    resolve(route: ActivatedRouteSnapshot): Promise<ServiceUseDto> {
        return this.dataService.get<ServiceUseDto>('ServiceUse').then(dto => {
            return dto;
        });
    }
}
