import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { FeedbackService } from './feedback.service';
import { LeadFeedbackUserStatusDto } from './LeadFeedbackUserStatusDto';
import { DisplayService } from '../display.service';

@Injectable()
export class FeedbackResolve implements Resolve<LeadFeedbackUserStatusDto> {
    constructor(private feedbackService: FeedbackService, private displayService: DisplayService) {
    }

    resolve(route: ActivatedRouteSnapshot): Promise<LeadFeedbackUserStatusDto> {
        if (!this.displayService.isBrowser()) {
            return Promise.resolve({
                date: '',
                items: [],
                providerHints : [],
            });    //won't work in the SSR, cause there are no cookies to auth the user
        }
        return this.feedbackService.getLatest();
    }
}
