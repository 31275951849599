import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ProjectService } from '../../shared/projects/project.service';
import { ProvidersSorting } from '../../shared/projects/ProvidersSorting';
import { Market } from '../../shared/locations/Market';
import { CountryMarket } from '../../shared/locations/CountryMarket';
import { LocationService } from '../../shared/locations/location.service';
import { LocationSource } from '../../shared/locations/LocationSource';
import { Location } from '../../shared/locations/Location';
import { WorkAreasService } from '../../shared/workareas/work-areas.service';
import { MasterWorkAreaSelectDto } from '../../shared/workareas/MasterWorkAreaSelectDto';

@Component({
    selector: 'bottom-links',
    templateUrl: './bottom-links.component.html'
})
export class BottomLinksComponent {
    ProvidersSorting: typeof ProvidersSorting = ProvidersSorting;   //hack for enum to be available in the template
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private loading: boolean = false;
    public markets: Market[] = [];
    private countryMarkets: CountryMarket[] = [];
    private currentLocation: Location | undefined;
    public masterWorkAreas1: MasterWorkAreaSelectDto[] = [];
    public masterWorkAreas2: MasterWorkAreaSelectDto[] = [];
    private initMwaLoadingDone = false;

    constructor(translate: TranslateService,
        private projectService: ProjectService, private router: Router, private locationService: LocationService,
        private workAreasService: WorkAreasService) {
    }

    ngOnInit(): void {
        this.subscribeToMarkets();
        this.subscribeToCurrentLocation();
        this.subscribeToMasterWorkAreas();
    }

    async searchWithSortBy(sorting: ProvidersSorting) {
        if (!this.loading) {
            this.loading = true;
            try {
                await this.doSearch(sorting);
            } finally {
                this.loading = false;
            };
            return;
        }
    }

    private subscribeToMarkets() {
        this.locationService.countryMarkets.pipe(takeUntil(this.ngUnsubscribe)).subscribe(newCountryMarkets => {
            this.countryMarkets = newCountryMarkets;
            if (this.currentLocation) {
                this.loadMarkets();
            }
        });
    }

    private subscribeToCurrentLocation(): void {
        this.locationService.currentLocation.pipe(takeUntil(this.ngUnsubscribe)).subscribe((newLocation : Location) => {
            this.currentLocation = newLocation;
            if (this.countryMarkets) {
                this.loadMarkets();
            }
        });
    }

    private subscribeToMasterWorkAreas(): void {
        this.workAreasService.workAreas.subscribe(workAreas => {
            this.masterWorkAreas1 = [];
            this.masterWorkAreas2 = [];
            let i = 0;
            for (; i < workAreas.length && i < 6; ++i) {
                this.masterWorkAreas1.push(workAreas[i]);
            }
            for (; i < workAreas.length && i < 12; ++i) {
                this.masterWorkAreas2.push(workAreas[i]);
            }

            this.initMwaLoadingDone = true;
            if (!this.masterWorkAreas1.length) {
                this.workAreasService.updateWorkAreasToSelect();
            }
        });
    }

    public async selectMwaAndSearch(mwa: MasterWorkAreaSelectDto) {
        mwa.selected = true;
        await this.doSearch();
    }

    private loadMarkets(): void {
        let countryMarkets = this.countryMarkets.find(cm => cm.markets && cm.markets.length && cm.markets[0].countryId == this.currentLocation?.country);
        if (countryMarkets && countryMarkets.markets && countryMarkets.markets.length) {
            this.markets = countryMarkets.markets;
        }
    }

    public async setMarketAndSearch(market: Market) {
        this.loading = true;
        try {
            await this.locationService.setCurrentLocation({
                locationName: market.localName,
                latitude: market.latitude,
                longitude: market.longitude,
                source: LocationSource.MarketSelector,
                country: market.countryId,
                address: market.localName,
                serviceAvailable: true,
            });
            await this.doSearch();
        } finally {
            this.loading = false;
        }
    }

    private async doSearch(sorting: ProvidersSorting | undefined = undefined) {
        let projectCanBeUpdated = await this.projectService.updateCurrentProjectAndPrices()
        if (projectCanBeUpdated) {
            if (sorting) {
                this.projectService.setProvidersSorting(sorting);
            }
            this.router.navigateByUrl('/search-results');
        } else {
            this.router.navigateByUrl('/comparison');
        }
    }
}
