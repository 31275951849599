import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ArticleSearchResultDto } from '../../shared/content/ArticleSearchResultDto';

@Component({
    selector: 'article-search-result',
    templateUrl: './article-search-result.page.html'
})
export class ArticleSearchResultComponent implements OnInit {
    @Input() article!: ArticleSearchResultDto;

    constructor(public translate: TranslateService) {
        
    }

    ngOnInit(): void {
        
    }

}
