import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { ProjectService } from '../../shared/projects/project.service';

@Component({
    selector: 'rename-project-modal',
    templateUrl: './rename-project-modal.component.html'
})
export class RenameProjectModalComponent implements OnInit {
    ngUnsubscribe: Subject<void> = new Subject<void>();
    loading: boolean = false;

    public projectName: string | undefined;
    public projectId: number | undefined;

    constructor(public bsModalRef: BsModalRef, private projectService: ProjectService) {
    }

    ngOnInit() {
    }

    public async rename() {
        if (this.loading || !this.isBtnSaveEnabled()) {
            return;
        }
        await this.projectService.renameProject(this.projectId as number, this.projectName as string);
        this.close();
    }

    close() {
        this.bsModalRef.hide();
    }

    isBtnSaveEnabled(): boolean {
        return !!this.projectName;
    }

}
