<div class="modal-header">
    <h4 class="modal-title">{{ mwaName }}</h4>
    <p>
        {{ 'WORKAREAS_SELECTOR.edit_ref_area_modal.desc' | translate }}
    </p>
</div>
<div class="modal-body">
    <form>
        <div class="quantity-block">
            <div class="row">
                <div class="col-sm-6">
                    <label for="quantity">
                        <b>
                            {{ 'edit_projectworkitemoption_modal.quantity' | translate }}
                        </b>
                    </label>
                </div>
                <div class="col-sm-6">
                    <input type="number" min="1" [(ngModel)]="area" (change)="areaChanged()" name="fakename">
                    <span>
                        sqm
                    </span>
                </div>
            </div>
        </div>
    </form>
    <div class="actions" [ngClass]="{ 'loading':loading }">
        <button class="btn btn-transparent-blue btn-middle"
                (click)="close()">{{ 'edit_projectworkitemoption_modal.cancel' | translate }}</button>
        <button class="btn btn-blue btn-middle"
                (click)="save()">{{ 'edit_projectworkitemoption_modal.save' | translate }}</button>
        </div>
</div>
<div class="modal-footer" *ngIf="!areaIsLocked" (click)="areaIsLocked=true">
    <div class="locked-field unlocked">
        <b>
            {{ 'edit_projectworkitemoption_modal.unlocked' | translate }}
        </b>
        <div class="wrap-switch">
            <img src="assets/img/unlock-orange.svg" width="12" alt="">
            <div class="switch">
                <div class="slider"></div>
            </div>
            <img src="assets/img/lock-blue.svg" width="12" alt="">
        </div>
    </div>
    <p>
        {{ 'WORKAREAS_SELECTOR.edit_ref_area_modal.unlocked_desc' | translate }}
    </p>
</div>
<div class="modal-footer" *ngIf="areaIsLocked" (click)="areaIsLocked=false">
    <div class="locked-field">
        <b>
            {{ 'edit_projectworkitemoption_modal.locked' | translate }}
        </b>
        <div class="wrap-switch">
            <img src="assets/img/unlock-blue.svg" width="12" alt="">
            <div class="switch green">
                <div class="slider pull-right"></div>
            </div>
            <img src="assets/img/lock-green.svg" width="12" alt="">
        </div>
    </div>
    <p>
        {{ 'WORKAREAS_SELECTOR.edit_ref_area_modal.locked_desc' | translate }}
    </p>
</div>
