<div class="blocks">
    <div class="block block-first"><img alt="" src="assets/img/icon-heart-house.svg">
        <b> {{ 'search_results.slogan1' | translate }}</b>
    </div>
    <div class="block block-second">
        <img alt="" src="assets/img/icon-envelope.svg">
        <b> {{ 'search_results.slogan2' | translate }} </b>
    </div>
    <div class="block block-third">
    <img alt="" src="assets/img/icon-reno.svg">
        <b> {{ 'search_results.slogan3' | translate }} </b>
    </div>
</div>