import { Component, OnInit, ElementRef, ViewChild, Inject  } from '@angular/core';
import { Language } from '../../shared/languages/Language'
import { InitDataService } from '../../shared/init-data.service';
import { L10nService } from '../../shared/l10n.service';

@Component({
    selector: 'lang-selector',
    templateUrl: './lang-selector.component.html',
})
export class LangSelectorComponent implements OnInit {
    public langSelectorVisible: boolean;
    public languagesCol1: Language[] = [];

    private selectedLang: string;

    @ViewChild("langSelectDropdown")
    public langSelectDropdownRef: ElementRef | undefined;

    constructor(private l10nService: L10nService) {
        this.langSelectorVisible = false;
        this.selectedLang = l10nService.getCurrentLang();
        this.loadLanguages();
    }

    ngOnInit(): void {
    }

    loadLanguages(): void {
        let languages = this.l10nService.getLanguages();
        this.languagesCol1 = [];
        for (let i = 0; i < languages.length; ++i) {
            languages[i].flag = this.getFlag(languages[i].id);
            this.languagesCol1.push(languages[i]);
        }
    }

    getFlagClasses(lang: Language) {
        let res: any = {
            'flag': true,
        };
        res[lang.flag] = true;
        return res;
    }

    onSelect(lang: Language) {
        let oldLangPathPart = `/${this.selectedLang}/`;
        let newLangPathPart = `/${lang.id}/`;
        if (window && window.location && window.location.pathname.startsWith(oldLangPathPart)) {
            window.location.pathname = window.location.pathname.replace(oldLangPathPart, newLangPathPart);
        }
       /* this.langService
            .selectLang(lang.id).then(() => {
                //window && window.location && window.location.reload();
                if (window && window.location) {
                    window.location.pathname = window.location.pathname.replace('/'+'/';
                }
            });*/
    }
    
    public getFlagClassesForSelectedLang() {
        let res: any = {
            'flag-symbol-selected': true,
            'flag': true,
        };
        res[this.getFlag(this.selectedLang)] = true;
        return res;
    }

    private getFlag(lang: string): string {
        return 'lang-' + lang;
    }

    public getSelectedLangname() {
        switch (this.selectedLang) {
            case 'en':
                return 'English';
            case 'fr':
                return 'français';
            default:
                return '-';
        }
    }
}
