import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'search-results',
    template: '<div></div>'
})
export class SearchResultsRedirectComponent implements OnInit {
    constructor(private router: Router) {
    }
    
    ngOnInit() {
        this.router.navigateByUrl('/search-results');
    }
}
