import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';

import { ProjectService } from '../../shared/projects/project.service';
import { SeoService } from '../../shared/seo.service';
import { DisplayService } from '../../shared/display.service';
import { UserProjectDto } from '../../shared/projects/UserProjectDto';
import { WorkAreasService } from '../../shared/workareas/work-areas.service';
import { LocationSource } from '../../shared/locations/LocationSource';
import { LocationService } from '../../shared/locations/location.service';
import { SaveProjectModalComponent } from '../register/save-project-modal.component';
import { RenameProjectModalComponent } from './rename-project-modal.component';
import { ActionTracker } from '../../shared/projects/ActionTracker';
import { AccountService } from '../../shared/account/account.service';
import { RenoRouter } from '../../shared/RenoRouter';

@Component({
    selector: 'my-projects',
    templateUrl: './my-projects.component.html'
})
export class MyProjectsComponent implements OnInit {
    ngUnsubscribe: Subject<void> = new Subject<void>();
    loading: boolean = true;
    leavingPage: boolean = false;
    projects: UserProjectDto[] = [];
    unsavedChangesCount: number = 0;

    public deletePopoverTitle: string = 'Confirm';
    public deletePopoverMessage: string = 'Are you sure?';
    public deletePopoverCancelText: string = 'No';
    public deletePopoverConfirmText: string = 'Yes';
    public unsavedChangesPopoverTitle: string = 'Unsaved changes';
    public unsavedChangesPopoverMessage: string = 'Do you want to save your current project first?';
    public unsavedChangesPopoverCancelText: string = 'No';
    public unsavedChangesPopoverConfirmText: string = 'Yes';
    saveProjectModalRef: BsModalRef | undefined;
    private isLoggedIn: boolean = false;

    constructor(public translate: TranslateService, private displayService: DisplayService,
        private projectService: ProjectService, private renoRouter: RenoRouter, private seoService: SeoService,
        private workAreasService: WorkAreasService, private locationService: LocationService,
        private modalService: BsModalService, private actionTracker: ActionTracker, private accountService: AccountService) {
    }

    ngOnInit() {
        this.translate.get(['my_projects.title', 'my_projects.desc', 'my_projects.delete_confirm_title', 'my_projects.delete_confirm_message', 'my_projects.delete_confirm_yes', 'my_projects.delete_confirm_no', 'my_projects.unsaved_changes_confirm_title', 'my_projects.unsaved_changes_confirm_message', 'my_projects.unsaved_changes_confirm_yes', 'my_projects.unsaved_changes_confirm_no']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['my_projects.title'], res['my_projects.desc']);
            this.deletePopoverTitle = res['my_projects.delete_confirm_title'];
            this.deletePopoverMessage = res['my_projects.delete_confirm_message'];
            this.deletePopoverConfirmText = res['my_projects.delete_confirm_yes'];
            this.deletePopoverCancelText = res['my_projects.delete_confirm_no'];
            this.unsavedChangesPopoverTitle = res['my_projects.unsaved_changes_confirm_title'];
            this.unsavedChangesPopoverMessage = res['my_projects.unsaved_changes_confirm_message'];
            this.unsavedChangesPopoverConfirmText = res['my_projects.unsaved_changes_confirm_yes'];
            this.unsavedChangesPopoverCancelText = res['my_projects.unsaved_changes_confirm_no'];
        });
        if (this.displayService.isBrowser()) {
            this.loadProjectsList();
        }
        this.subscribeToIsLoggedIn();
        this.subscribeToHideSaveRegisterDialog();
    }

    private subscribeToIsLoggedIn() {
        this.accountService.isLoggedIn.pipe(takeUntil(this.ngUnsubscribe)).subscribe(isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
        });
    }

    private subscribeToHideSaveRegisterDialog() {
        this.actionTracker.showSaveModal.pipe(takeUntil(this.ngUnsubscribe)).subscribe(show => {
            if (!show) {
                this.loadProjectsList();
            }
        });
    }

    private loadProjectsList() {
        this.projectService.getUserProjects().then(projects => {
            this.projects = projects;
            this.updateUnsavedChangesCount();
            if (!this.leavingPage) {
                this.loading = false;
            }
        });
    }

    public async loadProject(proj: UserProjectDto) {
        this.actionTracker.resetUnsavedChangesCountSinceProjectSave();
        if (proj.leadsCount) {
            this.renoRouter.navigateByUrl('/comparison');
        } else if (proj.isCurrent) {
            this.renoRouter.navigateByUrl('/search-results');
        } else {
            this.leavingPage = this.loading = true;
            let projectMeta = await this.projectService.setCurrentProject(proj.id);
            let location = projectMeta.location;
            location.source = LocationSource.Server;
            this.locationService.setCurrentLocation(location);
            await this.workAreasService.updateWorkAreasToSelect();
            await this.projectService.updatePricesForCurrentProject();
            this.renoRouter.navigateByUrl('/search-results');
        }
    }

    public saveCurrentProject() {
        if (this.isLoggedIn) {
            this.saveProject();
        }
        else {
            this.actionTracker.doShowSaveModal();
        }
    }

    public async saveProject(project: UserProjectDto | undefined = undefined) {
        this.saveProjectModalRef = this.modalService.show(SaveProjectModalComponent, { class: 'modal-green' });
        if (project) {
            this.saveProjectModalRef.content.projectId = project.id;
            this.saveProjectModalRef.content.projectName = project.name;
        } else if (this.projects && this.projects.length) {
            this.saveProjectModalRef.content.projectName = this.projects[0].name;
        }
        this.modalService.onHidden.pipe(takeUntil(this.ngUnsubscribe)).subscribe(nxt => {
            this.loadProjectsList();
        });
    }

    public async renameProject(project: UserProjectDto) {
        this.saveProjectModalRef = this.modalService.show(RenameProjectModalComponent, { class: 'modal-green' });
        this.saveProjectModalRef.content.projectId = project.id;
        this.saveProjectModalRef.content.projectName = project.name;
        this.modalService.onHidden.pipe(takeUntil(this.ngUnsubscribe)).subscribe(nxt => {
            this.loadProjectsList();
        });
    }

    public async deleteProject(project: UserProjectDto) {
        await this.projectService.deleteProject(project.id);
        this.projects = this.projects.filter(p => p.id != project.id);
    }

    private updateUnsavedChangesCount() {
        this.unsavedChangesCount = this.actionTracker.getUnsavedChangesCountSinceProjectSave();
    }
}
