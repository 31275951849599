import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs'

import { MetaTagsSet } from './MetaTagsSet';

@Injectable({
    providedIn: 'root'
})
export class SeoService {
    private metaTagsSource = new BehaviorSubject<MetaTagsSet>(new MetaTagsSet());
    public metaTags = this.metaTagsSource.asObservable();

    constructor(@Inject(DOCUMENT) private dom: Document) {
    }

    public setMetaTags(title: string, description: string): void {
        this.metaTagsSource.next({
            title: title,
            description: description
        });
    }

    public createCanonicalURL() {
      let link: HTMLLinkElement = this.dom.createElement('link');
      link.setAttribute('rel', 'canonical');
      this.dom.head.appendChild(link);
      link.setAttribute('href', this.dom.URL);
   }
}
