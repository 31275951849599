<div class="join-as-prof">
    <div class="title-green top">
        <div class="container">
            <a class="back-link d-none d-md-inline" *ngIf="showForm" (click)="hideForm()">
                {{ 'join.back1' | translate }}
            </a>
            <h1>
                {{ 'join.header1' | translate }}
            </h1>
        </div>
    </div>
    <div class="container">
        <h3>
            <span class="d-none d-sm-inline">
                {{ 'join.header2' | translate }}
            </span>
            <span class="d-sm-none">
                {{ 'join.header2_mobile' | translate }}
            </span>
        </h3>
    </div>
    <div class="partner-with-us">
        <div class="action action-top d-sm-none" *ngIf="isMobile">
            <div class="container">
                <a class="btn btn-green btn-large-extra" *ngIf="!showForm">
                    {{ 'join.slogan1' | translate }}
                </a>
            </div>
        </div>
        <div class="green-block mobile-transparent">
            <div class="container">
                <div class="wrap-content step-first" *ngIf="!showForm">
                    <div class="image d-none d-sm-block">
                        <img src="assets/img/search-result-example.png" alt="">
                    </div>
                    <div class="content">
                        <h5 class="d-none d-sm-block">
                            {{ 'join.slogan2' | translate }}
                        </h5>
                        <div class="search-result-list green-block d-sm-none">
                            <div class="item">
                                <div class="project-listing paddings">
                                    <div class="item-envelope">
                                        <div class="part-top">
                                            <div class="landing-text"><span> Bathroom renovation offer </span></div>
                                            <div class="personalize-text"><a href="">{{ 'search_results.personalize_project_mobile_p1' | translate }}</a></div>
                                            <div class="standard-text"> Offer</div><b class="price"> €&nbsp;2,609 </b><br>
                                            </div>
                                        <div class="part-middle"><a class="btn btn-white-green">{{ 'search_results.select_provider' | translate }}</a></div>
                                        <div class="part-bottom"><a> {{ 'search_results.provider_details_btn' | translate }} </a></div>
                                    </div>
                                    <div class="item-stars">
                                        <div class="item-prices"><h6> {{ 'search_results.prices_stars_header' | translate }} </h6>
                                            <div class="stars">
                                                <div class="star-blue star star-small"></div>
                                                <div class="star-blue star star-small"></div>
                                                <div class="star-blue star star-small"></div>
                                                <div class="star-blue star star-small"></div>
                                                <div class="star-blue star star-small"></div>
                                            </div>
                                        </div>
                                        <div class="item-services"><h6> {{ 'search_results.services_stars_header' | translate }} (<span>24</span>) </h6>
                                            <div class="stars">
                                                <div class="star-blue star star-small"></div>
                                                <div class="star-blue star star-small"></div>
                                                <div class="star-blue star star-small"></div>
                                                <div class="star-blue star star-small"></div>
                                                <div class="star-blue star star-small"></div>
                                            </div>
                                        </div>
                                        <div class="item-reviews"><h6> {{ 'search_results.reviews_stars_header' | translate }} (<span>18</span>) </h6>
                                            <div class="stars">
                                                <div class="star-blue star star-small"></div>
                                                <div class="star-blue star star-small"></div>
                                                <div class="star-blue star star-small"></div>
                                                <div class="star-blue star star-small"></div>
                                                <div class="star-blue star star-small"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="item-provider">
                                        <div class="provider">
                                            <div class="provider-verified">
                                                <img alt="" src="assets/img/icon-shield.svg">
                                                <span> {{ 'search_results.verified_provider' | translate }} </span>
                                            </div>
                                            <div class="provider-info"><a href="/en/provider/7102">{{ 'how_it_works.provider2_name' | translate }}</a>
                                                <div class="stars">
                                                    <div class="star-orange star star-small"></div>
                                                    <div class="star-orange star star-small"></div>
                                                    <div class="star-orange star star-small"></div>
                                                    <div class="star-orange star star-small"></div>
                                                </div>
                                            </div>
                                            <div class="provider-verifications">
                                                <img alt="" src="assets/img/appart-example.jpg" width="40">
                                                <ul class="advantages-check">
                                                    <li><span class="check">✓</span><a>{{ 'search_results.offices' | translate }} {{ 'search_results.verified' | translate }}</a></li>
                                                    <li><span class="check">✓</span><a>{{ 'how_it_works.step2_key_doc_name' | translate }} {{ 'search_results.verified' | translate }}</a>
                                                    </li>
                                                    <li><span class="check">✓</span><a>{{ 'offer_details.on_time_guarantee' | translate }}</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ul class="check-list">
                            <li>
                                <span class="icon green">✔</span>
                                {{ 'join.bullet_points.p1' | translate }}
                            </li>
                            <li>
                                <span class="icon green">✔</span>
                                {{ 'join.bullet_points.p2' | translate }}
                            </li>
                            <li>
                                <span class="icon green">✔</span>
                                {{ 'join.bullet_points.p3' | translate }}
                            </li>
                            <li>
                                <span class="icon green">✔</span>
                                {{ 'join.bullet_points.p4' | translate }}
                            </li>
                            <li>
                                <span class="icon green">✔</span>
                                {{ 'join.bullet_points.p5' | translate }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="wrap-content step-second" *ngIf="showForm">
                    <div class="wrap-back-link d-md-none">
                        <a class="back-link" (click)="hideForm()">
                            {{ 'join.back2' | translate }}
                        </a>
                    </div>
                    <form action="">
                        <div class="row">
                            <div class="form-group row col-md-6">
                                <label class="col-lg-3 col-form-label">{{ 'join.firstName' | translate }}</label>
                                <div class="col-lg-8 col-xl-7">
                                    <input [(ngModel)]="firstName" [ngModelOptions]="{standalone: true}" type="text"
                                           class="form-control"
                                           placeholder="{{ 'join.firstName_placeholder' | translate }}">
                                </div>
                            </div>
                            <div class="form-group row col-md-6">
                                <label class="col-lg-3 col-form-label">{{ 'join.company_name' | translate }}</label>
                                <div class="col-lg-8 col-xl-7">
                                    <input [(ngModel)]="companyName" [ngModelOptions]="{standalone: true}" type="text"
                                           class="form-control"
                                           placeholder="{{ 'join.company_name_placeholder' | translate }}">
                                </div>
                            </div>
                            <div class="form-group row col-md-6">
                                <label class="col-lg-3 col-form-label">{{ 'join.surname' | translate }}</label>
                                <div class="col-lg-8 col-xl-7">
                                    <input [(ngModel)]="lastName" [ngModelOptions]="{standalone: true}" type="text"
                                           class="form-control"
                                           placeholder="{{ 'join.surname_placeholder' | translate }}">
                                </div>
                            </div>
                            <div class="form-group row col-md-6">
                                <label class="col-lg-3 col-form-label">{{ 'join.company_address' | translate }}</label>
                                <div class="col-lg-8 col-xl-7">
                                    <input [(ngModel)]="companyAddress" [ngModelOptions]="{standalone: true}"
                                           type="text" class="form-control"
                                           placeholder="{{ 'join.company_address_placeholder' | translate }}">
                                </div>
                            </div>
                            <div class="form-group row col-md-6">
                                <label class="col-lg-3 col-form-label">{{ 'join.mobile' | translate }}</label>
                                <div class="col-lg-8 col-xl-7">
                                    <input [(ngModel)]="phoneNumber" [ngModelOptions]="{standalone: true}" type="text"
                                           class="form-control"
                                           placeholder="{{ 'join.mobile_placeholder' | translate }}">
                                </div>
                            </div>
                            <div class="form-group row col-md-6">
                                <label class="col-lg-3 col-form-label">{{ 'join.email' | translate }}</label>
                                <div class="col-lg-8 col-xl-7">
                                    <input [(ngModel)]="email" [ngModelOptions]="{standalone: true}" type="email"
                                           class="form-control"
                                           placeholder="{{ 'join.email_placeholder' | translate }}">
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="action">
            <div class="container">
                <div class="step-first" *ngIf="!showForm">
                    <a class="btn btn-green btn-large-extra" (click)="openForm()">
                        {{ 'join.btn_open_form' | translate }}
                    </a>
                </div>
                <div class="step-second" *ngIf="showForm">
                    <div class="form-check">
                        <label class="term-cond">
                            <input class="form-check-input" type="checkbox" [checked]="termsAccepted"
                                   (change)="termsAccepted = !termsAccepted" required>
                            <i class="checkbox-icon"></i>
                            {{ 'registration.I_read' | translate }}<a routerLink="/service-use"
                                                                      target="_blank">{{ 'registration.terms_conditions' | translate }}</a>
                        </label>
                    </div>
                    <button (click)="submit()"
                            [ngClass]="{'btn':true, 'btn-green':true, 'btn-large-extra':true, 'disabled':!isFormValid(), 'loading-small':loading }">
                        {{ 'join.btn_submit' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="green-block">
        <div class="container">
            <div class="wrap_icon-links">
                <a class="icon-link blue" target="_blank"
                   href="/other/Ristrutturazioni Milano Societa Anonima Offer Preview.pdf">
                    <img src="assets/img/offer-preview-blue.svg" height="40">
                    <span>{{ 'join.offer_example' | translate }}</span>
                </a>
                <a class="icon-link brown" routerLink="/search-results">
                    <img src="assets/img/design-tools.svg" height="40">
                    <span>{{ 'join.design_tool_link' | translate }}</span>
                </a>
                <a class="icon-link">
                    <img src="assets/img/offer-preview.svg" height="40">
                    <span>{{ 'join.provider_brochure' | translate }}</span>
                </a>
            </div>
        </div>
    </div>
    <div class="features">
        <div class="container">
            <h3>
                {{ 'join.features_header' | translate }}
            </h3>
            <features-set></features-set>
        </div>
    </div>
</div>
