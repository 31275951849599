<div class="authorization">
    <div class="container">
        <div class="top-part">
            <img src="assets/img/Excel_logo.png"
                 width="40" alt="xlsx">
            <span>
                {{ 'registration.compare_prices' | translate }}
            </span>
        </div>
        <h3>
            {{ 'registration_confirm.almost_there' | translate }}
        </h3>
        <p>
            {{ 'registration_confirm.check_email' | translate }}
        </p>
        <form>
            <div class="actions">
                <a class="btn btn-blue btn-middle" role="button" routerLink="/">{{ 'registration_confirm.btn_back' | translate }}</a>
            </div>
        </form>
    </div>
</div>
