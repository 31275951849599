import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutingHelper } from './app-routing.module';
import { L10nService } from './shared/l10n/l10n.service';
import { RenoRouter } from './shared/RenoRouter';

@Component({
    selector: 'route-fallback-component',
    template: '',
})
export class RouteFallbackComponent implements OnInit {

    constructor(private renoRouter: RenoRouter, private activatedRoute: ActivatedRoute, private l10nService: L10nService) {
    }

    ngOnInit(): void {
        let baseRoutes = RoutingHelper.baseRoutes;
        let curLang = this.l10nService.getCurrentLang();
        const queryParams = this.activatedRoute.snapshot.queryParams;
        const currentRoute = this.activatedRoute.snapshot.url.map(segment => segment.path).join('/');
        //console.log(`RouteFallbackComponent.ngOnInit. url = ${this.activatedRoute.snapshot.url} curLang = ${curLang}. curRoute = ${currentRoute}`);
        if (currentRoute && baseRoutes.find(r => r.path === currentRoute)) {
            this.renoRouter.navigate([currentRoute], { queryParams });
        } else {
            this.renoRouter.navigate(['/search']);
        }
      }

}