import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: '[gallery-stars]',
    templateUrl: './gallery-stars.component.html'
})
export class GalleryStarsComponent implements OnInit {
    @Input() num: number = 5;
    public starClasses: any[] = ['star', 'star', 'star', 'star', 'star'];

    ngOnInit() {
        for (let i = 0; i < 5; ++i) {
            this.starClasses[i] = this.getStarClasses(i);
        }
    }

    private getStarClasses(curNum: number) {
        let res: any = {
            'star': true,
        };
        let rounded = this.round(this.num);
        if (curNum < rounded) {
            res['star-blue'] = true;
        } else {
            res['star-blue-transparent'] = true;
        }
        return res;
    }

    public createRange(number: number) {
        var items: number[] = [];
        for (var i = 1; i <= number; i++) {
            items.push(i);
        }
        return items;
    }

    private round(num: number) {
        return Math.round(num * 2) / 2;
    }
}
