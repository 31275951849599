import { NgZone } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
//import { MapsAPILoader } from '@agm/core';
//import { } from 'googlemaps';

import { WorkAreasService } from '../../shared/workareas/work-areas.service';
import { MasterWorkAreaSelectDto } from '../../shared/workareas/MasterWorkAreaSelectDto';
import { WorkAreaSelectDto } from '../../shared/workareas/WorkAreaSelectDto';
import { LocationService } from '../../shared/locations/location.service';
import { LocationSource } from '../../shared/locations/LocationSource';
import { ProjectService } from '../../shared/projects/project.service';
import { GalleryService } from '../../shared/galleries/gallery.service';
import { Location } from '../../shared/locations/Location';
import { DisplayService } from '../../shared/display.service';
import { InitData } from '../../shared/initData';
import { ActionTracker } from '../../shared/projects/ActionTracker';

export abstract class WorkAreasSelectComponentBase {
    doneText!: string;
    editText!: string;
    selectWorkAreasText!: string;
    areaSqm!: number;
    serviceAvailable: boolean;
    
    latitude!: number;
    longitude!: number;

    constructor(protected workAreasService: WorkAreasService, protected projectService: ProjectService,
        protected galleryService: GalleryService, protected translate: TranslateService, protected locationService: LocationService,
        private actionTracker: ActionTracker) {
        this.serviceAvailable = true;
        this.saveSelection = this.debounce(() => this.saveSelectionInternal());
    }

    private saveSelection: () => void;
    
    protected subscribeToRenovationArea(): void {
        this.workAreasService.renovationArea.subscribe(area => {
            this.areaSqm = area;
        });
    }

    debounce(func: () => void, wait = 300) {
        let h: any;
        return () => {
            clearTimeout(h);
            h = setTimeout(() => func(), wait);
        };
    }

    mwaSelectedChanged(mwa: MasterWorkAreaSelectDto, event: any): void {
        mwa.collapsed = mwa.workAreas.length <= 1 || !event.target.checked;
        mwa.selected = event.target.checked;
        mwa.collapseBtnText = mwa.collapsed ? this.editText : this.doneText;
        this.workAreasService.setWorkAreasSelectionChangedByUser();
        this.actionTracker.incUnsavedChangesCount();
        //this.saveSelection();
    }

    waSelectedChanged(wa: WorkAreaSelectDto, event: any): void {
        wa.selected = event.target.checked;
        this.workAreasService.setWorkAreasSelectionChangedByUser();
        this.actionTracker.incUnsavedChangesCount();
        //this.saveSelection();
    }

    private saveSelectionInternal() {
        var self = this;
        this.projectService.updateCurrentProject().then(x => {
            self.galleryService.updatePictures();
        });
    }

    getCollapseClasses(mwa: MasterWorkAreaSelectDto) {
        let res: any = {
            'collapse': true,
            'show': !mwa.collapsed
        };
        return res;
    }

    getMwaFormCheckClasses(mwa: MasterWorkAreaSelectDto) {
        let res: any = {
            'form-check': true,
            'main': true,
            'active': mwa.selected
        };
        return res;
    }

    getWaFormCheckClasses(wa: WorkAreaSelectDto) {
        let res: any = {
            'form-check': true,
            'active': wa.selected
        };
        return res;
    }

    onBtnCollapseClick(mwa: MasterWorkAreaSelectDto) {
        mwa.collapsed = !mwa.collapsed;
        mwa.collapseBtnText = mwa.collapsed ? this.editText : this.doneText;
    }

    protected loadTranslations(): void {
        this.translate.get('DONE').subscribe((res: string) => {
            this.doneText = res;
        });
        this.translate.get('EDIT').subscribe((res: string) => {
            this.editText = res;
        });
        this.translate.get('WORKAREAS_SELECTOR.SELECT_WORKAREAS').subscribe((res: string) => {
            this.selectWorkAreasText = res;
        });
    }

    public setAreaSqm(newArea: number) {
        if (newArea) {
            this.workAreasService.setRenovationArea(newArea);
        } else {
            this.workAreasService.setRenovationArea(newArea);
        }
    }

    abstract isContinueButtonEnabled(): void;

    hasSmthSelected(mwas: MasterWorkAreaSelectDto[]) {
        return mwas.some(mwa => mwa.selected && mwa.workAreas.some(wa => wa.selected));
    }

    subscribeToCurrentLocation(): void {
        this.locationService.currentLocation.subscribe(newLocation => {
            this.latitude = newLocation.latitude;
            this.longitude = newLocation.longitude;
            this.serviceAvailable = newLocation.serviceAvailable;
            this.onLocationChanged(newLocation);
        });
    }

    protected onLocationChanged(newLocation: Location) {
    }
    
}
