import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { GoogleMapsModule } from '@angular/google-maps'
import { NgxGalleryModule } from '@kolkov/ngx-gallery';

import { DataService } from './shared/network/data.service';
import { L10nService } from './shared/l10n/l10n.service';
import { NotificationService } from './shared/notificaton.service';
import { InitDataService, initializeApp } from './shared/init-data.service';
import { SignInComponent } from './components/register/signin.component';
import { AccountService } from './shared/account/account.service';
import { TestComponent } from './test/test.component';
import { GdprPopupComponent } from './components/gdpr/gdpr-popup.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { SaveProjectModalComponent } from './components/register/save-project-modal.component';
import { GalleryService } from './shared/galleries/gallery.service';
import { BottomMenuComponent } from './components/bottom-menu/bottom-menu.component';
import { LocationService } from './shared/locations/location.service';
import { BottomLinksComponent } from './components/bottom-links/bottom-links.component';
import { LangSelectorComponent } from './components/lang-selector/lang-selector.component';
import { LinkService } from './shared/link.service';
import { WorkAreasService } from './shared/workareas/work-areas.service';
import { ActionTracker } from './shared/projects/ActionTracker';
import { DisplayService } from './shared/display.service';
import { ProjectService } from './shared/projects/project.service';
import { AnalyticsService } from './shared/analytics.service';
import { AbTestsService } from './shared/abTesting/abTestsService';
import { RenameProjectModalComponent } from './components/projects/rename-project-modal.component';
import { MyProjectsComponent } from './components/projects/my-projects.component';
import { JoinComponent } from './components/join/join.component';
import { FeatureAdComponent } from './components/feature-ad/feature-ad.component';
import { FeaturesSetComponent } from './components/feature-ad/features-set.component';
import { FaqComponent } from './components/faq/faq.page';
import { FaqResolve } from './shared/faq/FaqResolve';
import { FaqService } from './shared/faq/faq.service';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.page';
import { ServiceUseComponent } from './components/service-use/service-use.component';
import { ServiceUseResolve } from './shared/service-use/ServiceUseResolve';
import { ArticlesComponent } from './components/articles/articles-search.component';
import { ArticleBodyComponent } from './components/articles/article-body.component';
import { ArticleSearchResultComponent } from './components/articles/article-search-result.page';
import { ArticleService } from './shared/content/article.service';
import { ArticleResolve } from './shared/content/ArticleResolve';
import { GalleryComponent } from './components/gallery/gallery.component';
import { SearchService } from './shared/search/search.service';
import { FeedbackProviderInitiatedDoneComponent } from './components/feedback/feedback-provider-initiated-done.component';
import { FeedbackProjectStatusComponent } from './components/feedback/feedback-project-status.component';
import { FeedbackProviderScoreComponent } from './components/feedback/feedback-provider-score.component';
import { FeedbackUnsubscribeComponent } from './components/feedback/feedback-unsubscribe.component';
import { FeedbackProviderInitiatedComponent } from './components/feedback/feedback-provider-initiated.component';
import { OfferPreviewPageComponent } from './components/comparison/offer-preview.page';
import { ComparisonComponent } from './components/comparison/comparison.component';
import { ComparisonTableComponent } from './components/comparison/comparison-table.component';
import { RegisterComponent } from './components/register/register.component';
import { RegisterConfirmationComponent } from './components/register/register-confirmation.component';
import { ResetPasswordComponent } from './components/register/reset-password.component';
import { ForgotPasswordComponent } from './components/register/forgot-password.component';
import { ProjectEditComponent } from './components/project-edit/project-edit.component';
import { ProjectEditTopBannerComponent } from './components/project-edit/project-edit-top-banner.component';
import { ProjectTabsComponent } from './components/project-edit/tabs.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { SearchResultsRedirectComponent } from './components/search-results/searchResults-redirect.component';
import { SearchResultsTopBannerComponent } from './components/search-results/top-banner.component';
import { SearchResultsViewComponent } from './components/search-results/search-results-view.component';
import { SelectedOffersSearchResultsComponent } from './components/selected-offers/selected-offers-search-results.component';
import { SmallStarsComponent } from './components/search-results/small-stars.component';
import { StarsComponent } from './components/search-results/stars.component';
import { GalleryLightComponent } from './components/gallery/gallery-light.component';
import { WorkItemsViewComponent } from './components/workitems/workItems-view.component';
import { EditProjectWorkItemOptionModalComponent } from './components/workitems/edit-projectworkitemoption-modal.component';
import { CommentModalComponent } from './components/workitems/comment-modal.component';
import { AddProjectWorkItemOptionModalComponent } from './components/workitems/add-projectworkitemoption-modal.component';
import { WorkAreasSelectSideComponent } from './components/workareas-select/workareas-select-side.component';
import { OfferDetailsModalComponent } from './components/search-results/offer-details-modal.component';
import { ProviderDetailsPageComponent } from './components/providers/provider-details.page';
import { ProjectUploadsComponent } from './components/project-edit/project-uploads.component';
import { SearchComponent } from './components/search/search.component';
import { VerticalGalleryComponent } from './components/gallery/vertical-gallery.component';
import { OfferDetailsComponent } from './components/search-results/offer-details.component';
import { InitialWorkAresSelectComponent } from './components/workareas-select/initial-workareas-select.component';
import { WorkAreasSelectComponentBase } from './components/workareas-select/workareas-select-base';
import { ProvidersMapComponent } from './components/providers-map/providers-map.component';
import { GalleryStarsComponent } from './components/search/gallery-stars.component';
import { ProviderService } from './shared/providers/ProviderService';
import { SeoService } from './shared/seo.service';
import { LandingParamsService } from './shared/landingParams/landing-params.service';
import { RenoRouter } from './shared/RenoRouter';
import { FeedbackService } from './shared/feedback/feedback.service';
import { FeedbackResolve } from './shared/feedback/FeedbackResolve';
import { FeedbackProviderInitiatedResolve } from './shared/feedback/FeedbackProviderInitiatedResolve';
import { ProviderDetailsResolve } from './shared/providers/ProviderDetailsResolve';
import { LangDetector } from './shared/l10n/lang-detector';
import { LangRouterLinkDirective } from './shared/l10n/lang-router-link.directive';
import { LangRoutePipe } from './shared/l10n/lang-route.pipe';
import { EditRefAreaModalComponent } from './components/workareas-select/edit-refarea-modal.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, undefined, `.json?v=${new Date().getTime()}`);
}

export class UncaughtExceptionHandler implements ErrorHandler {
    handleError(error: any) {
        console && console.error && console.error(error);
    }
}

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent, ResetPasswordComponent, ForgotPasswordComponent,
    TestComponent,
    SaveProjectModalComponent,
    TopMenuComponent, BottomMenuComponent, BottomLinksComponent, GdprPopupComponent, LangSelectorComponent,
    MyProjectsComponent, RenameProjectModalComponent,
    JoinComponent,
    FeatureAdComponent, FeaturesSetComponent,
    FaqComponent, HowItWorksComponent, ServiceUseComponent,
    ArticlesComponent, ArticleBodyComponent, ArticleSearchResultComponent,
    GalleryComponent, GalleryLightComponent, VerticalGalleryComponent, GalleryStarsComponent,
    FeedbackProviderInitiatedDoneComponent, FeedbackProjectStatusComponent, FeedbackProviderScoreComponent,
    FeedbackUnsubscribeComponent, FeedbackProviderInitiatedComponent,
    OfferPreviewPageComponent, ComparisonComponent, ComparisonTableComponent, OfferDetailsComponent, OfferDetailsModalComponent,
    RegisterComponent, RegisterConfirmationComponent,
    ProjectEditComponent, ProjectEditTopBannerComponent, ProjectTabsComponent, ProjectUploadsComponent,
    SearchComponent, SearchResultsComponent,  SearchResultsViewComponent, SearchResultsRedirectComponent, SearchResultsTopBannerComponent, SmallStarsComponent, StarsComponent, WorkAreasSelectSideComponent, EditRefAreaModalComponent,
    SelectedOffersSearchResultsComponent,
    WorkItemsViewComponent, EditProjectWorkItemOptionModalComponent, CommentModalComponent, AddProjectWorkItemOptionModalComponent,
    ProviderDetailsPageComponent, ProvidersMapComponent,
    InitialWorkAresSelectComponent, WorkAreasSelectSideComponent, LangRouterLinkDirective, LangRoutePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ]
      }
    }),
    ToastrModule.forRoot(),
    TooltipModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
        confirmButtonType: 'danger',
    }),
    //ModalModule,  somehow modals work without it?
    BsDropdownModule.forRoot(),
    MatGoogleMapsAutocompleteModule.forRoot('AIzaSyBS5TuQwUB6Z4XvRuvpk_2NY_ydPHF4M4U'),
    GoogleMapsModule,
    NgxGalleryModule,
  ],
  providers: [
    /*provideClientHydration(
        //withEventReplay(), // in preview for now
    ),*/
    provideHttpClient(/*, withFetch()*/),   //TODO: enable fetch
    provideAnimations(),
    InitDataService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [ InitDataService ],
      multi: true
    },
    LinkService,
        TranslateModule,
        { provide: ErrorHandler, useClass: UncaughtExceptionHandler },
        WorkAreasService, LocationService, DataService, ProviderService, ProjectService,
        DisplayService, ArticleService, SeoService, ArticleResolve, GalleryService, AccountService, NotificationService,
        SearchService, FeedbackService, FeedbackResolve, ActionTracker, FaqService, FaqResolve, LandingParamsService,
        ServiceUseResolve, FeedbackProviderInitiatedResolve, BsModalService, AnalyticsService, AbTestsService, RenoRouter,
        ProviderDetailsResolve, LangDetector
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
