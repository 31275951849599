import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgxGalleryAnimation, NgxGalleryOptions, NgxGalleryImage } from '@kolkov/ngx-gallery';

import { GalleryPic, NgxGalleryPic } from '../../shared/galleries/GalleryPic';
import { GalleryService } from '../../shared/galleries/gallery.service';
import { ProviderStats } from '../../shared/providers/ProviderStats';

@Component({
    selector: 'gallery',
    templateUrl: './gallery.component.html'
})
export class GalleryComponent implements OnInit {
    id: string;
    galleryOptions: NgxGalleryOptions[] = [];
    galleryImages: NgxGalleryImage[] = [];
    providerId!: string;
    providerName!: string;
    providerLink!: string;
    price!: number;
    currencySign!: string
    providerStats!: ProviderStats;

    @Input() galleryId!: string;
    @Input() pictureId!: number;
    @Input() articleId!: number;
    @Input() projectId!: number;

    constructor(private galleryService: GalleryService, private router: Router,
        private activatedRoute: ActivatedRoute) {
        this.id = this.generateGuid();
    }

    ngOnInit() {
        if (this.pictureId) {
            this.galleryService.getGalleryPicsByPictureId(this.pictureId).then(pics => {
                this.setPics(pics);
            });
        }
        if (this.pictureId) {
            this.galleryOptions = [
                {
                    width: '100%',
                    height: '300px',
                    thumbnails: false,
                    imageAnimation: NgxGalleryAnimation.Slide,
                    imageSwipe: true,
                    previewSwipe: true,
                    imageInfinityMove: true,
                    imageAutoPlay: false,
                    imageAutoPlayPauseOnHover: true,
                    preview: false,
                    previewFullscreen: true,
                    previewCloseOnEsc: true,
                    previewInfinityMove: true,
                    previewAnimation: true,
                    thumbnailsMargin: 0,
                    thumbnailMargin: 0,
                    thumbnailsColumns: 3,
                    imageArrows: false,
                    arrowPrevIcon: "fa fa-angle-left",
                    arrowNextIcon: "fa fa-angle-right",
                    thumbnailsRemainingCount: true
                }
            ];
        } else {
            this.galleryOptions = [
                {
                    width: '100%',
                    height: '300px',
                    thumbnails: true,
                    imageAnimation: NgxGalleryAnimation.Slide,
                    imageSwipe: true,
                    previewSwipe: true,
                    imageInfinityMove: true,
                    imageAutoPlay: false,
                    imageAutoPlayPauseOnHover: true,
                    preview: true,
                    previewFullscreen: true,
                    previewCloseOnEsc: true,
                    previewInfinityMove: true,
                    previewAnimation: true,
                    thumbnailsMargin: 0,
                    thumbnailMargin: 0,
                    thumbnailsColumns: 3,
                    arrowPrevIcon: "fa fa-angle-left",
                    arrowNextIcon: "fa fa-angle-right",
                    thumbnailsRemainingCount: true
                }
            ];
        }
    }

    public setPics(pics: GalleryPic[]): void {
        pics = pics || [];
        this.galleryImages = pics.map(this.Map);
        if (!pics.length) {
            const placeholderPicUrl = 'assets/img/provider_pic_placeholder.png';
            this.galleryImages = [{ small: placeholderPicUrl, medium: placeholderPicUrl, big: placeholderPicUrl }];
            this.setGalleryOptionsToPlaceholderMode();
        } else {
            this.loadOverlayFromPic(pics[0]);
        }
    }

    private Map(pic: GalleryPic): NgxGalleryPic {
        function formatCurrency(val: number, n: any, x: any, s: any, c: any) {
            var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
                num = val.toFixed(Math.max(0, ~~n));

            return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
        }

        let formattedPrice = formatCurrency(pic.price, 0, 3, ',', '.');
        return {
            providerId: pic.providerId,
            providerName: pic.providerName,
            providerStats: pic.providerStats,
            price: pic.price,
            currencySign: pic.currencySign,
            small: pic.loResUrl,
            medium: pic.medResUrl,
            big: pic.hiResUrl,
            description: `${pic.providerName} - ${formattedPrice}${pic.currencySign}`
        };
    }

    private setGalleryOptionsToPlaceholderMode() {
        if (this.galleryOptions && this.galleryOptions.length) {
            this.galleryOptions[0].thumbnails = false;
            this.galleryOptions[0].preview = false;
            this.galleryOptions[0].imageArrows = false;
        }
    }

    public galleryClick(): void {
        if (this.articleId) {
            const queryParams: Params = Object.assign({}, this.activatedRoute.snapshot.queryParams);
            queryParams['id'] = this.articleId;
            this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: queryParams });
        }
    }

    public onPicChange($event: any) {
        let pic: NgxGalleryPic = $event.image;
        this.loadOverlayFromPic(pic);
    }

    private loadOverlayFromPic(pic: NgxGalleryPic) {
        this.providerName = pic.providerName;
        this.providerId = pic.providerId;
        this.price = pic.price;
        this.currencySign = pic.currencySign;
        this.providerStats = pic.providerStats;
        this.providerLink = '/offer/' + pic.providerId; //TODO: should be link to the new provider detail page here
    }

    public hasPics(): boolean {
        return this.galleryImages.length > 0;
    }

    private generateGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

}
