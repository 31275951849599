<a dropdownToggle class="dropdown-toggle" role="button" id="dropdownMenuLink"
    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <span class="d-lg-none">
        {{ getSelectedLangname() }}
    </span>
    <span [ngClass]="getFlagClassesForSelectedLang()"></span>
</a>
<div *dropdownMenu class="dropdown-menu languages-menu" aria-labelledby="dropdownLanguages">
    <div>
        <a *ngFor="let lang of languagesCol1" (click)="onSelect(lang)">
            <span class="d-lg-none">
                {{lang.name}}
            </span>
            <span [ngClass]="getFlagClasses(lang)"></span>
        </a>
    </div>
</div>
