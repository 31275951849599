<div class="article">
    <div class="container">
        <h1 *ngIf="title">{{title}}</h1>
        <ng-container *ngFor="let piece of pieces">
            <p *ngIf="piece.text" [innerHTML]="piece.text.html">
            </p>
            <gallery *ngIf="piece.standalonePic" [pictureId]="piece.standalonePic.id" [articleId]="piece.standalonePic.articleId" [ngClass]="{'left':piece.standalonePic.position == 'left', 'center':piece.standalonePic.position == 'center', 'right':piece.standalonePic.position == 'right', 'general-slider':true}"></gallery>
            <gallery *ngIf="piece.gallery" [galleryId]="piece.gallery.id" [projectId]="piece.gallery.projectId" [ngClass]="{'left':piece.gallery.position == 'left', 'center':piece.gallery.position == 'center', 'right':piece.gallery.position == 'right', 'general-slider':true, 'image-arrow':true}"></gallery>
        </ng-container>
    </div>
</div>
