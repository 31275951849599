import { Injectable, Inject } from '@angular/core';

import { ArticleViewDto } from './ArticleViewDto';
import { DataService } from '../network/data.service';
import { ArticleSearchResultDto } from './ArticleSearchResultDto';
import { SearchService } from '../search/search.service';
import { retry } from 'rxjs/operators';

@Injectable()
export class ArticleService {
    allArticles: ArticleSearchResultDto[] = [];
    allArticlesPromise: Promise<any> | undefined;
    preAllocatedArticleIds: number[][] = [];

    constructor(private dataService: DataService, private searchService: SearchService) {
    }

    public getArticle(id: string): Promise<ArticleViewDto>{
        return this.dataService.get<ArticleViewDto>('articles?id=' + id);
    }

    public parseArticleId(url: string): string | null {
        if (!url) {
            return null;
        }
        let queryString = url.substring(url.indexOf('?') + 1);
        let obj = this.parseQueryString(queryString);
        return obj['id'];
    }

    public async loadAllArticles() {
        if (this.allArticles && this.allArticles.length) {
            return;
        }
        let dto = await this.dataService.get<any>('articles/all');
        this.preAllocatedArticleIds = dto.preAllocatedArticles;
        this.loadPlaceHolderPic(dto.allArticles);
        this.allArticles = dto.allArticles;
    }

    private loadPlaceHolderPic(results: ArticleSearchResultDto[]) {
        for (let i = 0; i < results.length; ++i) {
            if (!results[i].pic1Url) {
                results[i].pic1Url = 'assets/img/provider_pic_placeholder.png';
            }
        }
    }

    private randomizeArticles()
    {
        for (let i = this.allArticles.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [this.allArticles[i], this.allArticles[j]] = [this.allArticles[j], this.allArticles[i]];
        }
    }

    public async search(searchQuery: string, num: number | null = null): Promise<ArticleSearchResultDto[]> {
        await this.loadAllArticles();
        this.randomizeArticles();

        let goodResults: ArticleSearchResultDto[] = [];
        let sorting: any[] | undefined = [];
        if (num != null) {
            sorting = this.preAllocatedArticleIds[num];
        }
        let googleResults = await this.getArticleIdsFromGoogleSearch(searchQuery);
        sorting = sorting.concat(googleResults);

        let self = this;
        sorting.forEach(function (key) {
            var found = false;
            self.allArticles.filter(function (article) {
                if (!found && article.articleId == key) {
                    goodResults.push(article);
                    found = true;
                    return false;
                } else
                    return true;
            })
        });


        let rest = this.allArticles.filter(function (article) {
            return sorting.indexOf(article.articleId) === -1;
        });
        return goodResults.concat(rest);
    }

    private async getArticleIdsFromGoogleSearch(searchQuery: string) {
        let googleResults = await this.searchService.search(searchQuery);
        let articleIds = [];
        for (let i = 0; i < googleResults.length; ++i) {
            let articleId = this.parseArticleId(googleResults[i]);
            if (articleId) {
                articleIds.push(articleId);
            }
        }
        return articleIds;
    }

    private parseQueryString(query: string): Record<string, any> {
        var result: Record<string, any> = {};
        query.split("&").forEach(function (part) {
            var item = part.split("=");
            result[item[0]] = decodeURIComponent(item[1]);
        });
        return result;
    }
}
