import { Injectable, Inject } from '@angular/core';


import { DataService } from '../network/data.service';
import { InitData } from '../initData';
import { DisplayService } from '../display.service';
import { InitDataService } from '../init-data.service';

export enum AbTests {
    envelopeSearchResults = 1,
    workAreasSelectorWithIcons = 2,
    projectOfferWithTabs = 4,
}

@Injectable()
export class AbTestsService {
    private abTestsState: number = 0;
    constructor(initDataService: InitDataService, private displayService: DisplayService,
        private dataService: DataService) {
        this.initAbTestsState(initDataService.getInitialData());
    }

    public getAbTestsState(): number {
        return this.abTestsState;
    }

    private initAbTestsState(initData: InitData): void {

        if (this.displayService.isBrowser()) {
            let fromCookie = this.dataService.getCookieValue('AbTestsState');
            if (fromCookie && this.isNumber(fromCookie)) {
                this.abTestsState = +fromCookie;
                return;
            }
        }
        this.abTestsState = initData.abTestsState;
    }

    private isNumber(value: string | number): boolean {
        return ((value != null) && (value !== '') && !isNaN(Number(value.toString())));
    }

    private dec2bin(dec: number) {
        return (dec >>> 0).toString(2);
    }

    public getGtmValsForAbTests() {
        //should work dynamically, controlled from the server
        return {
            ABTestsState: this.dec2bin(this.getAbTestsState())
        };
    }

}