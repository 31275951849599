export class SelectedMasterWorkAreaDto {
    MasterWorkAreaId!: string;
    WorkAreaIds: string[];
    refArea!: number | null;
    refAreaIsLocked: boolean = false;

    public constructor() {
        this.WorkAreaIds = new Array<string>();
    }
}
