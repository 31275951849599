import { Component } from '@angular/core';

@Component({
    selector: 'search-results-top-banner',
    templateUrl: './top-banner.component.html'
})
export class SearchResultsTopBannerComponent {
    constructor() {
    }
}
