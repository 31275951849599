<div class="images-block">
    <h4>
        {{article.title}}
    </h4>
    <p>
        {{article.desc}}
    </p>
    <div class="wrap-images">
        <div class="bigger-img">
            <img src="{{article.pic1Url}}" alt="">
        </div>
        <div class="smaller-img" *ngIf="article.pic2Url">
            <div class="wrap">
                <img src="{{article.pic2Url}}" alt="">
            </div>
            <div class="wrap" *ngIf="article.pic3Url">
                <img src="{{article.pic3Url}}" alt="">
            </div>
        </div>
        <div class="action">
            <a class="btn btn-transparent-white btn-small" [routerLink]="['/article']" [queryParams]="{id: article.articleId}">
                {{ 'articles.continue' | translate}}
            </a>
        </div>
    </div>
</div>
