import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
//import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';

import { ProviderDocsDto } from '../../shared/providers/ProviderDocsDto';
import { ProviderAttr, FeaturedServiceDto } from '../../shared/providers/ProviderAttr';
import { DisplayService } from '../../shared/display.service';
import { ProviderDetailsDto } from '../../shared/providers/ProviderDetailsDto';
import { ProjectPriceItem, ProjectGuaranteeDatesInContract } from '../../shared/projects/ProjectPriceItem';
import { LeadFeedbackViewDto } from '../../shared/providers/LeadFeedbackViewDto';
import { ProviderService } from '../../shared/providers/ProviderService';
import { GalleryService } from '../../shared/galleries/gallery.service';
import { OfferDetailsModalComponent } from '../search-results/offer-details-modal.component';

@Component({
    template: '<div>turn off temp<div>'
    //templateUrl: './provider-details.page.html'
})
export class ProviderDetailsPageComponent {
    ngUnsubscribe: Subject<void> = new Subject<void>();
    ProjectGuaranteeDatesInContract: typeof ProjectGuaranteeDatesInContract = ProjectGuaranteeDatesInContract;   //hack for enum to be available in the template
    public headerAttrs: ProviderAttr[] = [];
    public mainRatingStars: any[] = [];
    public docs: ProviderDocsDto;
    provider!: ProviderDetailsDto;
    reviews: LeadFeedbackViewDto[] = [];
    public numOf12StarReviewsBarClasses: any = { indicator_bar: true, 'length-5': true };
    public numOf3StarReviewsBarClasses: any = { indicator_bar: true, 'length-5': true };
    public numOf45StarReviewsBarClasses: any = { indicator_bar: true, 'length-5': true };
    public hasServices: FeaturedServiceDto[] = [];
    public hasNotServices: FeaturedServiceDto[] = [];
    public providerDescription: string = '';
    public providerDecsriptionShortened: boolean = false;
    public displayOfficeDetailsButton: boolean = false;
    public numOfDocs: number = 0;

    providerDetailsModalRef!: BsModalRef;
    private loadingModal: boolean = false;

    //galleryOptions: NgxGalleryOptions[];

    isBrowser: boolean = false;

    constructor(private activatedRoute: ActivatedRoute, private displayService: DisplayService,
        private providerService: ProviderService, private galleryService: GalleryService, private modalService: BsModalService) {
        this.docs = {
            offices: [],
            keyDocs: [],
            additionalServices: [],
            certs: [],
            awards: [],
            mediaMentions: []
        };
        this.isBrowser = displayService.isBrowser();

        /*this.galleryOptions = [
            {
                image: false,
                imageArrowsAutoHide: true,
                width: '100%',
                height: displayService.isMobile() ? '120px' : '240px',
                thumbnailsColumns: 3,
                thumbnailsRows: 1,
                thumbnailsPercent: 100,
                imagePercent: 0,
                thumbnailMargin: 2,
                thumbnailsMargin: 2,
                imageAnimation: NgxGalleryAnimation.Slide,
                previewSwipe: true,
                imageInfinityMove: true,
                preview: true,
                previewFullscreen: true,
                previewCloseOnEsc: true,
                previewAnimation: true,
                arrowPrevIcon: "fa fa-chevron-left",
                arrowNextIcon: "fa fa-chevron-right",
            }
        ];*/
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((dat : any) => {
            let data: { dto: ProviderDetailsDto } = dat;
            if (false /*check if smth is wrong*/) {
                //TODO: return 404
            }
            this.loadProvider(data.dto);
            this.setDocs(data.dto.docs);
            this.loadReviews(data.dto.providerId);
        });
    }

    public setDocs(dto: ProviderDocsDto) {
        this.loadGalleryImages(dto);
        this.docs = dto;
    }

    private loadProvider(provider: ProviderDetailsDto): void {
        this.provider = provider;
        this.galleryService.loadGalleryPicsByProviderIds([provider.providerId]);

        this.mainRatingStars = [];
        if (this.provider) {
            for (var i = 0; i < provider.stats.mainRating.starsNum; i++) {
                this.mainRatingStars.push(i);
            }
        }

        let totalReviews = provider.stats.numOf12Reviews + provider.stats.numOf3Reviews + provider.stats.numOf45Reviews;
        this.numOf12StarReviewsBarClasses = { indicator_bar: true };
        let num12OfReviews = Math.max(0, Math.min(3, Math.round(3 * provider.stats.numOf12Reviews / totalReviews)));
        this.numOf12StarReviewsBarClasses['length-' + num12OfReviews] = true;

        this.numOf3StarReviewsBarClasses = { indicator_bar: true };
        let numOf3Reviews = Math.max(0, Math.min(3, Math.round(3 * provider.stats.numOf3Reviews / totalReviews)));
        this.numOf3StarReviewsBarClasses['length-' + numOf3Reviews] = true;

        this.numOf45StarReviewsBarClasses = { indicator_bar: true };
        let numOf45Reviews = Math.max(0, Math.min(3, Math.round(3 * provider.stats.numOf45Reviews / totalReviews)));
        this.numOf45StarReviewsBarClasses['length-' + numOf45Reviews] = true;


        this.hasServices = provider.featuredServices.filter(s => s.has);
        this.hasNotServices = provider.featuredServices.filter(s => !s.has);

        this.loadReviews(provider.providerId);

        let descLength = this.displayService.isMobile() ? 220 : 380;
        if (provider.providerDescription && provider.providerDescription.length > descLength) {
            this.providerDecsriptionShortened = true;
            this.providerDescription = provider.providerDescription.substring(0, descLength - 3) + '...';
        } else {
            this.providerDescription = provider.providerDescription;
        }
        this.displayOfficeDetailsButton = provider.hasOfficeInfo;
        if (provider.docs) {
            this.numOfDocs = provider.docs.keyDocs.length + provider.docs.awards.length + provider.docs.certs.length + provider.docs.mediaMentions.length;
        }
    }

    private loadGalleryImages(dto: ProviderDocsDto) {
       /* if (!dto.offices) {
            return;
        }
        for (let i = 0; i < dto.offices.length; ++i) {
            dto.offices[i].galleryImages = [];
            for (let j = 0; j < dto.offices[i].picUrls.length; ++j) {
                dto.offices[i].galleryImages.push({
                    small: dto.offices[i].picUrls[j],
                    medium: dto.offices[i].picUrls[j],
                    big: dto.offices[i].picUrls[j]
                });
            }
        }*/ //TODO: fix
    }

    private async loadReviews(providerId: string) {
        this.reviews = await this.providerService.getReviews(providerId);
    }

    public showAllDesc() {
        this.providerDecsriptionShortened = false;
        this.providerDescription = this.provider.providerDescription;
    }

    async showDocsModal(providerId: string) {
        if (this.loadingModal) {
            return;
        }
        this.loadingModal = true;

        let docsDto = await this.providerService.getProviderDocs(providerId);
        this.providerDetailsModalRef = this.modalService.show(OfferDetailsModalComponent, { class: 'provider-docs' });
        this.providerDetailsModalRef.content.setDocs(docsDto);
        this.providerDetailsModalRef.content.headerAttrs = [];
        this.providerDetailsModalRef.content.providerName = this.provider.providerName;

        this.loadingModal = false;
    }
}
