import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { WorkAreasService } from '../../shared/workareas/work-areas.service';

@Component({
    selector: 'edit-refarea-modal',
    templateUrl: './edit-refarea-modal.component.html'
})
export class EditRefAreaModalComponent {
    mwaId!: string;
    mwaName!: string;
    area!: number;
    areaIsLocked!: boolean;
    loading: boolean = false;

    constructor(public bsModalRef: BsModalRef, private workAreasService: WorkAreasService) { }

    public areaChanged() {
        this.areaIsLocked = true;
    }
    
    close() {
        this.bsModalRef.hide();
    }

    async save() {
        this.loading = true;
        await this.workAreasService.setMwaRefArea(this.mwaId, this.area, this.areaIsLocked);
        this.bsModalRef.hide();
        this.loading = false;
    }

}