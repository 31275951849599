<div [ngClass]="{'search':true, 'condensed-mobile':condensedMobile}">
    <div class="container">
        <div class="scroll-block">
            <button class="btn btn-like-dropdown btn-transparent-bronze" href="block-sqm">
                <span class="label">
                    {{ 'search_results.refine_project' | translate }}
                </span>
            </button>
        </div>
        <div class="search-block">
            <div *ngIf="!selectedOffers.length && condensedMobile" class="search-result-filter d-md-none">
                <div class="top-banner">
                    <h4 *ngIf="projectPendingText1">
                        {{ projectPendingText1 }}
                    </h4>
                    <h4 *ngIf="!projectPendingText1">
                        {{ 'search_results.title' | translate }}
                    </h4>
                    <search-results-top-banner></search-results-top-banner>
                </div>
            </div>
            <workareas-select-side class="search-filter"></workareas-select-side>
            <search-results-view class="search-result"></search-results-view>
        </div>
    </div>
</div>
