import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { AccountService } from '../../shared/account/account.service';
import { NotificationService } from '../../shared/notificaton.service';
import { SeoService } from '../../shared/seo.service';
import { RenoRouter } from '../../shared/RenoRouter';

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
    public loading: boolean = false;
    public email: string | undefined;
    public error: string | null = null;

    constructor(public translate: TranslateService, private accountService: AccountService,
        private renoRouter: RenoRouter, private notifier: NotificationService, private seoService: SeoService) {
    }

    ngOnInit() {
        this.translate.get(['forgot_password.title', 'forgot_password.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['forgot_password.title'], res['forgot_password.desc']);
        });
    }

    sendEmailBtnEnabled(): boolean {
        return !!this.email;
    }

    async btnSendEmailClick() {
        this.loading = true;
        try {
            let error = await this.accountService.sendPasswordRecoveryEmail(this.email as string);
            if (error) {
                this.showError(error);
            } else {
                this.translate.get('forgot_password.email_sent').subscribe((res: string) => {
                    this.notifier.showSuccess(res);
                });
            }
        } finally {
            this.loading = false;
        }
    }
    
    showError(text: string) {
        console.error(text);
        this.error = text;
    }

    removeError() {
        this.error = null;
    }
}
