import { afterNextRender, AfterRenderPhase, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { DataService } from './network/data.service';
import { ProjectMeta } from './projects/ProjectMeta';
import { AbTestsService } from './abTesting/abTestsService';


export enum AnalyticsProjectFlags {
    none = 0,
    shownRegisterPage = 1,
    visited2Pdp = 2,
    made5ChangesToProject = 4,
    selected3Providers = 8,
    savedProject = 16,
}

@Injectable()
export class AnalyticsService {
    private analyticsFlags: number = 0;
    private meta: ProjectMeta | undefined;
    constructor(private router: Router, private dataService: DataService, private abTestsService: AbTestsService) {
    }

    public init() {
        /*afterNextRender(() => {
            this.ensureGTMDataLayerInitialized();
            this.ensureBingDataLayerInitialized();
          }, {phase: AfterRenderPhase.Write});*/    //TODO: fix
    }

    private getAllEvents() {
        return [
            'checkoutStarted',
            'checkoutFinished',
            'made5changes',
            'visited2Pdp',
            'selected3providers',
            'savedProject'
        ]
    }

    public pageView(additionalPath?: string) {
        let path = this.router.url;
        if (additionalPath) {
            path += additionalPath;
        }

        //GTM
        /*(<any>window).dataLayer.push({
            event: 'virtualPageview',
            pagePath: path
        });

        //Bing
        (<any>window).uetq.push('event', 'page_view', { page_path: path });*/
    }

    public fireEvent(eventName: string, flags: AnalyticsProjectFlags = 0): void {
        if (!eventName) {
            return;
        }
        if (flags) {
            if (flags & this.analyticsFlags) {
                return;
            }
            if (!this.meta || this.meta && flags & this.meta.analyticsFlags) {
                return;
            }
            this.analyticsFlags |= flags;
            this.markAnalyticsFlags(flags);
        }
        (<any>window).dataLayer.push({
            event: eventName
        });

        let bingEvent: any = this.convertEventNameToBingEvent(eventName);
        (<any>window).uetq.push({ 'ec': bingEvent.other.event_category, 'ea': bingEvent.action }); 
    }

    public setProjectMeta(meta: ProjectMeta): void {
        if (!meta) {
            return;
        }
        this.meta = meta;
        if (meta.editsSinceCreation >= 5) {
            this.fireEvent('made5changes', AnalyticsProjectFlags.made5ChangesToProject);
        }
    }

    private ensureGTMDataLayerInitialized() {
        let dataLayer = (<any>window).dataLayer;
        if (!dataLayer) {
            dataLayer = (<any>window).dataLayer = [];
        }

        let obj: any = undefined;
        if(!dataLayer.campaignName){
            let campaignName = this.readQueryParam('utm_campaign');
            if (campaignName) {
                obj = obj || {};
                obj.campaignName = campaignName;
            }
        }
        if(!dataLayer.campaignSource){
            let campaignSource = this.readQueryParam('utm_source');
            if (campaignSource) {
                obj = obj || {};
                obj.campaignSource = campaignSource;
            }
        }
        if(!dataLayer.campaignMedium){
            let campaignMedium = this.readQueryParam('utm_medium');
            if (campaignMedium) {
                obj = obj || {};
                obj.campaignMedium = campaignMedium;
            }
        }
        if (!dataLayer.gclid) {
            let gclid = this.readQueryParam('gclid');
            if (gclid) {
                obj = obj || {};
                obj.gclid = gclid;
            }
        }
        if (obj) {
            dataLayer.push(obj);
        }

        let gtmValsForAbTests: any = this.abTestsService.getGtmValsForAbTests();
        for (let key in gtmValsForAbTests) {
            let a: any = {};
            a[key] = gtmValsForAbTests[key];
            dataLayer.push(a);
        }
    }

    private ensureBingDataLayerInitialized() {
        (<any>window).uetq = (<any>window).uetq || [];

        /*for (let eventName of this.getAllEvents()) {
            let bingEvent = this.convertEventNameToBingEvent(eventName);
            if (bingEvent) {
                (<any>window).uetq.push('event', bingEvent.action, bingEvent.other);
            }
        }*/
    }
    
    private convertEventNameToBingEvent(eventName: string) {
        switch (eventName) {
            case 'checkoutStarted':
                return {
                    action: 'start',
                    other: { 'event_category': 'checkout' }
                };
            case 'checkoutFinished':
                return {
                    action: 'finished',
                    other: { 'event_category': 'checkout' }
                };
            case 'made5changes':
                return {
                    action: 'made5changes',
                    other: { 'event_category': 'setupProject' }
                };
            case 'visited2Pdp':
                return {
                    action: 'visit2pdp',
                    other: { 'event_category': 'setupProject' }
                };
            case 'selected3providers':
                return {
                    action: 'selected3providers',
                    other: { 'event_category': 'setupProject' }
                };
            case 'savedProject':
                return {
                    action: 'saveProject',
                    other: { 'event_category': 'saveProject' }
                };
            default:
                return undefined;
        }
    }

    private readQueryParam(paramName: string){
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(paramName);
    }

    private markAnalyticsFlags(flags: AnalyticsProjectFlags) {
        this.dataService.post('Project/MarkAnalyticsFlags', { flags });
    }

}