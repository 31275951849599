<div class="modal-header">
    <p>
        {{ 'save_project.header_p1' | translate }}
    </p>
    <h4 class="modal-title" id="registrationModalLabel">
        {{ 'save_project.header_p2' | translate }}
    </h4>
</div>
<div class="modal-body">
    <form>
        <div class="form-group row">
            <label class="col-form-label">{{ 'save_project.project_name' | translate }}</label>
            <div class="col-form-input">
                <input [(ngModel)]="projectName"[ngModelOptions]="{standalone: true}" type="text" class="form-control" placeholder="{{ 'save_project.project_name_placeholder' | translate }}">
            </div>
        </div>
        <ng-container *ngIf="!isRegistered">
            <div class="divider"></div>
            <div class="form-group row">
                <label class="col-form-label">{{ 'save_project.email' | translate }}</label>
                <div class="col-form-input">
                    <input [(ngModel)]="email" [ngModelOptions]="{standalone: true}" type="text" class="form-control" placeholder="{{ 'save_project.email_placeholder' | translate }}">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-form-label">{{ 'save_project.password' | translate }}</label>
                <div class="col-form-input">
                    <input [(ngModel)]="password" [ngModelOptions]="{standalone: true}" type="password" class="form-control" placeholder="{{ 'save_project.password_placeholder' | translate }}">
                </div>
            </div>
            <div class="form-group row">
                <label class="col-form-label">{{ 'save_project.confirm_password' | translate }}</label>
                <div class="col-form-input">
                    <input [(ngModel)]="passwordConfirm" [ngModelOptions]="{standalone: true}" type="password" class="form-control" placeholder="{{ 'save_project.password_placeholder' | translate }}">
                </div>
            </div>
            <div class="form-group input-padding row">
                <div class="col-none col-form-label"></div>
                <div class="col-form-input">
                    <div class="form-check">
                        <label class="term-cond">
                            <input class="form-check-input" type="checkbox" required="" [checked]="termsAccepted" (change)="termsAccepted = !termsAccepted">
                            <i class="checkbox-icon green"></i>
                            {{ 'save_project.read_terms_p1' | translate }}<a langRouterLink routerLink="/search">{{ 'save_project.read_terms_p2' | translate }}</a>
                        </label>
                    </div>
                </div>
            </div>
        </ng-container>
        <div class="form-group input-padding row">
            <div class="col-none col-form-label"></div>
            <div class="col-form-input">
                <button [ngClass]="{ 'disabled': !isBtnSaveEnabled(), 'loading-small':loading, 'btn':true, 'btn-green':true, 'btn-large':true, 'w-100':true }" (click)="save()">
                    {{ 'save_project.save' | translate }}
                </button>
            </div>
        </div>
        <div class="form-group input-padding row d-sm-none">
            <div class="col-none col-form-label"></div>
            <div class="col-form-input">
                <div class="actions">
                    <div class="wrap-link">
                        <a langRouterLink routerLink="/projects">
                            {{ 'save_project.my_projects_link' | translate }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <div class="form-group input-padding row">
        <div class="col-none col-form-label"></div>
        <div class="col-form-input">
            <div class="actions">
                <div class="wrap-link">
                    <a (click)="close()">
                        {{ 'save_project.cancel' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
