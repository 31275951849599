import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { ProviderDetailsDto } from './ProviderDetailsDto';
import { ProviderService } from './ProviderService';
import { RenoRouter } from '../RenoRouter';

@Injectable()
export class ProviderDetailsResolve implements Resolve<ProviderDetailsDto> {
    constructor(private renoRouter: RenoRouter, private providerService: ProviderService) {
    }

    resolve(route: ActivatedRouteSnapshot): Promise<ProviderDetailsDto> {
        let id = route.params['id'];

        return this.providerService.getProviderDetails(id);
        //TODO: check data loaded properly-
        /*return this.articleService.getArticle(id).then(article => {
            if (article) {
                return article;
            } else {
                //this.renorouter.navigate(['/']);    //redirect to 404
                return null;
            }
        });*/
    }
}