import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core'
import { Subject, takeUntil } from 'rxjs';

import { ProjectService } from '../../shared/projects/project.service';
import { NotificationService } from '../../shared/notificaton.service';
import { DisplayService } from '../../shared/display.service';
import { GroupedMwaWithWorkItems } from '../../shared/projects/GroupedMwaWithWorkItems';
import { ProviderWorkItemPriceDto } from '../../shared/projects/WorkItemPricesDto';
import { ProjectPriceItem } from '../../shared/projects/ProjectPriceItem';
import { AccountService } from '../../shared/account/account.service';
import { RenoRouter } from '../../shared/RenoRouter';

@Component({
    selector: 'comparison-table',
    templateUrl: './comparison-table.component.html'
})
export class ComparisonTableComponent implements OnInit {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public groupedMwasWithWorkItems: GroupedMwaWithWorkItems[] = [];
    public projectPriceItems: ProjectPriceItem[] = [];
    public dataWasLoaded: boolean = false;

    constructor(public translate: TranslateService, private displayService: DisplayService,
        private renoRouter: RenoRouter, private projectService: ProjectService, private accountService: AccountService) {
    }

    ngOnInit() {
        let user = this.accountService.getUserInfo();
        if (!user.emailConfirmed || !user.phoneNumberConfirmed) {
            this.renoRouter.navigateByUrl('/register');
            return;
        }

        if (this.displayService.isBrowser()) {
            this.subscribeToPrices();
            if (!this.projectPriceItems || !this.projectPriceItems.length) {
                this.projectService.updatePricesForFinishedProject();
            }
        }
    }

    private subscribeToPrices(): void {
        this.projectService.finishedPricesDto.pipe(takeUntil(this.ngUnsubscribe)).subscribe(dto => {
            if (dto.prices.length) {
                this.dataWasLoaded = true;
            }
            let selectedProjectPriceItems = dto.prices.filter(p => p.selected);
            if (selectedProjectPriceItems.length > 0 && selectedProjectPriceItems.length != 3) {
                this.renoRouter.navigateByUrl('/search');
            }
            this.projectPriceItems = selectedProjectPriceItems;

            let groupedMwasWithWorkItems = dto.groupedMwasWithWorkItems.filter(gmwa => gmwa.workItems.length);

            for (let i = 0; i < groupedMwasWithWorkItems.length; ++i) {
                for (let j = 0; j < groupedMwasWithWorkItems[i].workItems.length; ++j) {
                    let curWorkItem = groupedMwasWithWorkItems[i].workItems[j];
                    curWorkItem.prices = {};
                    for (let k = 0; k < this.projectPriceItems.length; ++k) {
                        let provider = this.projectPriceItems[k];
                        let price = provider.workItems.find(wi => wi.workItemId == curWorkItem.workItemId);
                        if (price) {
                            curWorkItem.prices[provider.providerId] = {
                                providerId: provider.providerId,
                                providerName: provider.providerName,
                                price: price.price,
                                pricePerQty: price.pricePerQty,
                            };
                        }
                    }
                }
            }

            this.groupedMwasWithWorkItems = groupedMwasWithWorkItems;
        });
    }
}
