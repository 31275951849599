import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { ProjectService } from '../../shared/projects/project.service';

@Component({
    selector: 'project-edit-top-banner',
    templateUrl: './project-edit-top-banner.component.html'
})
export class ProjectEditTopBannerComponent implements OnInit {
    ngUnsubscribe: Subject<void> = new Subject<void>();
    offersNum: number = 0;

    constructor(private projectService: ProjectService) { }

    ngOnInit(): void {
        this.projectService.pricesDto.pipe(takeUntil(this.ngUnsubscribe)).subscribe(dto => {
            this.offersNum = dto.prices.length;
        });
    }
}
