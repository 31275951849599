import { afterNextRender, Component, inject, Inject, Injector, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import { LocationService } from '../../shared/locations/location.service';
import { WorkAreasService } from '../../shared/workareas/work-areas.service';
import { GalleryService } from '../../shared/galleries/gallery.service';
import { ProjectPlug } from '../../shared/workareas/ProjectPlug';
import { Market } from '../../shared/locations/Market';
import { CountryMarket } from '../../shared/locations/CountryMarket';
import { LocationSource } from '../../shared/locations/LocationSource';
import { ProjectService } from '../../shared/projects/project.service';
import { DisplayService } from '../../shared/display.service';
import { RenoRouter } from '../../shared/RenoRouter';

@Component({
    selector: 'initial-workareas-select',
    templateUrl: './initial-workareas-select.component.html'
})
export class InitialWorkAresSelectComponent implements OnInit {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    projectPlugs: ProjectPlug[] = [];
    hasExistingProject: boolean = false;
    existingProjectSelected: boolean = false;
    areaSqm: number = 20;
    serviceAvailable: boolean = false;
    initialLoadingDone: boolean = false;
    latitude!: number;
    longitude!: number;
    countryMarkets: CountryMarket[] = [];
    currentLocationName: string = '';
    loading: boolean = false;
    continueButtonLoading: boolean = false;
    public activeTabId: number = 0;
    private areaTabShown: boolean;
    private locationTabShown: boolean;
    private swipeCoord?: [number, number];
    private swipeTime?: number;
    wobbleProjectPlugSelector: any = null;
    wobbleMarketSelector: any = null;
    isMobile: boolean = false;
    private readonly injector = inject(Injector);

    constructor(public translate: TranslateService, private locationService: LocationService,
        private workAreasService: WorkAreasService, private renoRouter: RenoRouter,
        private galleryService: GalleryService, private projectService: ProjectService,
        private displayService: DisplayService) {
        if (this.displayService.isMobile()) {
            this.areaTabShown = false;
            this.locationTabShown = false;
        } else {
            this.areaTabShown = true;
            this.locationTabShown = true;
        }
    }

    ngOnInit(): void {
        this.isMobile = this.displayService.isMobile();
        this.workAreasService.updateWorkAreasToSelect();
        this.subscribeToRenovationArea();
        this.subscribeToCurrentLocation();
        this.subscribeToMarkets();
        this.subscribeToProjectPlugs();
    }

    private subscribeToProjectPlugs() {
        this.workAreasService.projectPlugs.pipe(takeUntil(this.ngUnsubscribe)).subscribe(newProjectPlugs => {
            this.projectPlugs = newProjectPlugs.filter(pp => pp.id > 0);
            this.existingProjectSelected = this.hasExistingProject = newProjectPlugs.filter(pp => pp.id == -1).length > 0;
        });
    }

    private subscribeToMarkets() {
        this.locationService.countryMarkets.pipe(takeUntil(this.ngUnsubscribe)).subscribe(newCountryMarkets => {
            let marketsEmpty = !(this.countryMarkets && this.countryMarkets.length);
            this.countryMarkets = newCountryMarkets;
            if (marketsEmpty) {
                this.locationService.markActiveCountryMarket(this.countryMarkets);
            }
        });
    }

    private deselectAllProjectPlugs() {
        this.projectPlugs.forEach(pp => pp.isActive = false);
        this.existingProjectSelected = false;
    }

    public selectProjectPlug(projectPlug: ProjectPlug) {
        var self = this;
        this.deselectAllProjectPlugs();
        projectPlug.isActive = true;
        if (projectPlug.id != -1) {
            this.workAreasService.updateWorkAreasToSelect(projectPlug).then(() => {
                self.galleryService.updatePictures();
            });
        }
    }

    public existingProjectClick() {
        if (this.existingProjectSelected) {
            this.continueBtnClick();
        }
        this.deselectAllProjectPlugs();
        this.existingProjectSelected = true;
    }

    isAreaValid() {
        return this.areaSqm > 0;
    }

    private subscribeToRenovationArea(): void {
        this.workAreasService.renovationArea.pipe(takeUntil(this.ngUnsubscribe)).subscribe(area => {
            this.areaSqm = area;
        });
    }

    private subscribeToCurrentLocation(): void {
        this.locationService.currentLocation.pipe(takeUntil(this.ngUnsubscribe)).subscribe(newLocation => {
            this.currentLocationName = newLocation.locationName || this.currentLocationName;
            this.latitude = newLocation.latitude;
            this.longitude = newLocation.longitude;
            this.serviceAvailable = newLocation.serviceAvailable;
            this.initialLoadingDone = true;
        });
    }

    public async setLocationFromMarket(market: Market) {
        this.currentLocationName = market.localName;
        this.continueButtonLoading = true;
        try {
            await this.locationService.setCurrentLocation({
                locationName: market.localName,
                latitude: market.latitude,
                longitude: market.longitude,
                source: LocationSource.MarketSelector,
                country: market.countryId,
                address: market.localName,
                serviceAvailable: true,
            });
        } finally {
            this.continueButtonLoading = false;
        }
    }

    public setAreaSqm(newArea: number) {
        this.areaSqm = newArea;
        this.workAreasService.setRenovationArea(newArea);
    }

    isContinueButtonEnabled() {
        if (this.existingProjectSelected && this.serviceAvailable) {
            return true;
        }
        return this.getTabCompletedVal(0) && this.getTabCompletedVal(1) && this.getTabCompletedVal(2);
    }

    private getTabCompletedVal(tabId: number) {
        switch (tabId) {
            case 0:
                return this.serviceAvailable && this.projectPlugs.some(pp => pp.isActive);
            case 1:
                return this.areaTabShown && this.isAreaValid();
            case 2:
                return this.locationTabShown && !!this.currentLocationName;
            default:
                return false;
        }
    }

    selectTab(tabId: number) {
        this.activeTabId = tabId;
        let self = this;
        switch (tabId) {
            case 1:
                this.areaTabShown = true;
                break;
            case 2:
                this.locationTabShown = true;
                break;
        }
    }

    private selectNextTab(activeTabId: number, skipFilledTabs = true) {
        let nextTabId = (activeTabId + 1) % 3;
        let nextNextTabId = (activeTabId + 2) % 3;
        if (!this.getTabCompletedVal(nextTabId) || !skipFilledTabs) {
            this.selectTab(nextTabId);
        } else {
            this.selectTab(nextNextTabId);
        }
    }

    private selectPrevTab(activeTabId: number, skipFilledTabs = true) {
        let prevTabId = (3 + activeTabId - 1) % 3;
        let prevPrevTabId = (3 + activeTabId - 2) % 3;
        if (!this.getTabCompletedVal(prevTabId) || !skipFilledTabs) {
            this.selectTab(prevTabId);
        } else {
            this.selectTab(prevPrevTabId);
            console.log(`from ${activeTabId} to ${prevPrevTabId}`);
        }
    }

    async continueBtnClick() {
        if (this.continueButtonLoading) {
            return;
        }
        if (!this.loading && this.isContinueButtonEnabled()) {
            this.loading = true;
            try {
                if (this.existingProjectSelected) {
                    await this.projectService.updatePricesForCurrentProject();
                    this.renoRouter.navigateByUrl('/search-results');
                } else {
                    let projectCanBeUpdated = await this.projectService.updateCurrentProjectAndPrices();
                    if (projectCanBeUpdated) {
                        this.renoRouter.navigateByUrl('/search-results');
                    } else {
                        this.renoRouter.navigateByUrl('/comparison');
                    }
                }
            } finally {
                this.loading = false;
            };
            return;
        } else {
            if (!this.serviceAvailable) {
                this.wobbleMarketSelector = {};
            } else if (!this.projectPlugs.some(pp => pp.isActive)) {
                this.wobbleProjectPlugSelector = {};
            }
        }
        this.selectNextTab(this.activeTabId);
    }

    public getContinueButtonText() {
        if (this.displayService.isMobile()) {
            return this.getTabCompletedVal(0) && this.getTabCompletedVal(1) && this.getTabCompletedVal(2)
                ? 'WORKAREAS_SELECTOR.continue_to_prices'
                : 'WORKAREAS_SELECTOR.continue_to_next_step';
        }
        return 'WORKAREAS_SELECTOR.continue_to_prices';
    }

    public async openCustomProject() {
        if (!this.loading) {
            this.loading = true;
            try {
                this.workAreasService.createCustomProject();
                if (await this.projectService.updateCurrentProjectAndPrices()) {
                    this.renoRouter.navigateByUrl('/search-results');
                } else {
                    this.renoRouter.navigateByUrl('/comparison');
                }
            } finally {
                this.loading = false;
            };
            return;
        }
    }

    swipe(e: TouchEvent, when: string): void {
        if (!this.displayService.isMobile()) {
            return;
        }

        const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
        const time = new Date().getTime();

        if (when === 'start') {
            this.swipeCoord = coord;
            this.swipeTime = time;
        }

        else if (when === 'end') {
            const direction = [coord[0] - this.swipeCoord![0], coord[1] - this.swipeCoord![1]];
            const duration = time - this.swipeTime!;

            if (duration < 1000 && Math.abs(direction[0]) > 30 //Long enough
                && Math.abs(direction[0]) > Math.abs(direction[1] * 3)) { //Horizontal enough
                const swipe = direction[0] < 0 ? 'next' : 'previous';

                if (direction[0] > 0) {
                    this.selectPrevTab(this.activeTabId, false);
                } else {
                    this.selectNextTab(this.activeTabId, false);
                }
            }
        }
    }
}
