<div class="wrap-slider">
    <div class="top-part">
        <h4>
            <a [routerLink]="providerLink">
                {{providerName}}
            </a>
        </h4>
        <div class="stars" *ngIf="providerStats">
            <div class="star star-orange star-big"  *ngFor="let i of createRange(providerStats.mainRating.starsNum)"></div>
        </div>
    </div>
    <div class="slider">
        <div [ngClass]="{'wrap': true, 'pointable':!!articleId}" (click)="galleryClick()">
            <div class="top-notification">
                {{ 'gallery.from_previous_projects' | translate }}
            </div>
            <ngx-gallery [options]="galleryOptions" [images]="galleryImages" (change)="onPicChange($event)"></ngx-gallery>
        </div>
    </div>
    <div class="bottom-part">
        <div class="rating">
            <div class="rating-row" *ngIf="providerStats && providerStats.prices">
                <b class="title">
                    {{ 'provider_details.prices_header' | translate }}
                </b>
                <div gallery-stars [num]="providerStats.prices.starsNum" class="stars">
                </div>
            </div>
            <div class="rating-row" *ngIf="providerStats && providerStats.services">
                <b class="title">
                    {{ 'provider_details.services' | translate }}
                </b>
                <div gallery-stars [num]="providerStats.services.starsNum" class="stars">
                </div>
            </div>
            <div class="rating-row" *ngIf="false && providerStats && providerStats.reviews">
                <b class="title">
                    {{ 'provider_details.reviews' | translate }}
                </b>
                <div gallery-stars [num]="providerStats.reviews.starsNum" class="stars">
                </div>
            </div>
        </div>
    </div>
</div>
