import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { ProjectService } from '../../shared/projects/project.service';

@Component({
    selector: 'comment-modal',
    templateUrl: './comment-modal.component.html'
})
export class CommentModalComponent {
    comment: string = '';
    loading: boolean = false;

    constructor(public bsModalRef: BsModalRef, private projectService: ProjectService) { }

    close() {
        this.bsModalRef.hide();
    }

    async save() {
        if (this.loading) {
            return;
        }
        this.loading = true;
        await this.projectService.updateComment(this.comment);
        this.close();
        this.loading = false;
    }

}
