import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { WorkItemOption } from '../../shared/projects/WorkItemOption'
import { ProjectService } from '../../shared/projects/project.service';
import { AnalyticsService } from '../../shared/analytics.service';

@Component({
    selector: 'add-projectworkitemoption-modal',
    templateUrl: './add-projectworkitemoption-modal.component.html'
})
export class AddProjectWorkItemOptionModalComponent {
    quantity!: number;
    workItemId!: string;
    workItemName!: string;
    workItemDescription!: string;
    uom!: string;
    workItemOptions!: WorkItemOption[];
    selectedWorkItemOptionId!: string;
    loading: boolean = false;

    constructor(public bsModalRef: BsModalRef, private projectService: ProjectService,
        private analyticsService: AnalyticsService) { }

    close() {
        this.bsModalRef.hide();
    }

    async save() {
        this.loading = true;
        await this.projectService.addProjectWorkItemOptionAndUpdatePrices(this.selectedWorkItemOptionId, this.workItemId, this.quantity);
        this.bsModalRef.hide();
        this.loading = false;
        this.analyticsService.pageView();
    }

}
