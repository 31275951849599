import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: '[stars]',
    templateUrl: './stars.component.html'
})
export class StarsComponent implements OnInit {
    @Input() num!: number;
    public starClasses: any[] = ['star star-small', 'star star-small', 'star star-small', 'star star-small', 'star star-small'];

    ngOnInit() {
        for (let i = 0; i < 5; ++i) {
            this.starClasses[i] = this.getStarClasses(i);
        }
    }

    private getStarClasses(curNum: number) {
        let res: any = {
            'star': true,
            'star-small': true,
        };
        let rounded = this.round(this.num);
        if (curNum < rounded) {
            let fraction = rounded - curNum;
            if (fraction == 0.5) {
                res['star-blue-dark'] = true;
            } else {
                res['star-blue-dark'] = true;
            }
        } else {
            res['star-blue-transparent'] = true;
        }
        return res;
    }

    public createRange(number: number) {
        var items: number[] = [];
        for (var i = 1; i <= number; i++) {
            items.push(i);
        }
        return items;
    }

    private round(num: number) {
        return Math.round(num * 2) / 2;
    }
}
