<div class="authorization">
    <div class="container">
        <h3>
            {{ 'forgot_password.header' | translate }}
        </h3>
        <form>
            <div class="form-group">
                <label for="email6">{{ 'registration.email' | translate }}</label>
                <div class="input-padding">
                    <input [(ngModel)]="email" [ngModelOptions]="{standalone: true}" type="email" class="form-control" id="email6"
                           placeholder="{{ 'registration.email_placeholder' | translate }}" (input)="removeError()">
                </div>
            </div>
            <div class="actions">
                <div class="alert alert-danger" role="alert" *ngIf="error">
                    {{ error }}
                </div>
                <button type="submit" [ngClass]="{'btn':true, 'btn-blue':true, 'btn-middle':true, 'w-100':true, 'disabled':!sendEmailBtnEnabled(), 'loading-small':loading }" (click)="btnSendEmailClick()">{{ 'forgot_password.sendEmail' | translate }}</button>
            </div>
        </form>
    </div>
</div>
