<div [ngClass]="{'registration':true, 'wrap-bg':true, 'validation':showConfirmationsUI, 'validation-result':showConfirmationsUI }">
    <div class="container">
        <nav class="go-back d-none d-lg-block">
            <a langRouterLink routerLink="/search-results" *ngIf="selectedProviders && selectedProviders.length">
                {{ 'registration.back_to_search_results_link' | translate }}
            </a>
        </nav>
        <div class="registration-block">
            <div class="block-green">
                <h4>
                    {{ 'registration.top_banner' | translate }}
                </h4>
                <div class="two-column">
                    <div class="shopping-cart">
                        <selected-offers-search-results [showMissing]="true">
                        </selected-offers-search-results>
                    </div>
                    <ul class="advantages-check d-none d-lg-block">
                        <li>
                            <span class="check">✓</span>
                            <span><b>{{ 'registration.slogan_1_1' | translate }}</b>{{ 'registration.slogan_1_2' | translate }}</span>
                        </li>
                        <li>
                            <span class="check">✓</span>
                            <span><b>{{ 'registration.slogan_2_1' | translate }}</b>{{ 'registration.slogan_2_2' | translate }}</span>
                        </li>
                        <li>
                            <span class="check">✓</span>
                            <span><b>{{ 'registration.slogan_3_1' | translate }}</b>{{ 'registration.slogan_3_2' | translate }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="info-row red" *ngIf="selectedProviders && selectedProviders.length == 4">
                {{ 'registration.almost_there' | translate }} {{ 'registration.remove' | translate }}
                <span>1</span> {{ 'registration.offer_singular' | translate }} {{ 'registration.select_3_total_before_proceed' | translate }}
            </div>
            <div class="info-row red" *ngIf="selectedProviders && selectedProviders.length > 4">
                {{ 'registration.almost_there' | translate }} {{ 'registration.remove' | translate }}
                <span>{{ selectedProviders.length - 3 }}</span> {{ 'registration.select_3_total_before_proceed' | translate }}
            </div>
            <div class="info-row red" *ngIf="!selectedProviders || !selectedProviders.length">
                {{ 'registration.almost_there' | translate }} <a
                    langRouterLink routerLink="/search-results">{{ 'registration.select_3_offers' | translate }}</a> {{ 'registration.before_proceed' | translate }}
            </div>
            <div class="info-row red" *ngIf="selectedProviders && selectedProviders.length == 1">
                {{ 'registration.almost_there' | translate }} <a
                    langRouterLink routerLink="/search-results">{{ 'registration.select_2_offers' | translate }}</a> {{ 'registration.before_proceed' | translate }}
            </div>
            <div class="info-row red" *ngIf="selectedProviders && selectedProviders.length == 2">
                {{ 'registration.almost_there' | translate }} <a
                    langRouterLink routerLink="/search-results">{{ 'registration.select_1_offer' | translate }}</a> {{ 'registration.before_proceed' | translate }}
            </div>
            <div class="info-row" *ngIf="selectedProviders && selectedProviders.length == 3">
                {{ 'registration.already_registered' | translate }} <a
                    langRouterLink routerLink="/signin">{{ 'registration.login_link' | translate }}</a>
            </div>
            <form>
                <div class="form-group row name-field edit-active">
                    <label for="firstName12"
                           class="col-lg-2 col-form-label">{{ 'registration.firstname_header' | translate }} <span
                            class="mobile-name-extra-title">{{ 'registration.surname_header1' | translate }}</span></label>
                    <div class="col-lg-4">
                        <input [(ngModel)]="firstName" [ngModelOptions]="{standalone: true}"
                               [attr.disabled]="(showConfirmationsUI || fullyRegistered) ? '' : null" type="text"
                               [ngClass]="{'form-control':true, 'disabled':showConfirmationsUI}" id="firstName12"
                               placeholder="{{ 'registration.firstname_placeholder' | translate }}">
                        <div class="mobile-preview">
                            <span>{{firstName}}</span>
                        </div>
                    </div>
                    <label for="userSurname" class="col-form-label ml-lg-3">
                        <span class="d-none d-lg-inline">{{ 'registration.surname_header1' | translate }}</span>
                        <span class="d-lg-none">{{ 'registration.surname_header2' | translate }}</span>
                    </label>
                    <div class="col-lg-4">
                        <input [(ngModel)]="lastName" [ngModelOptions]="{standalone: true}"
                               [attr.disabled]="(showConfirmationsUI || fullyRegistered) ? '' : null" type="text"
                               [ngClass]="{'form-control':true, 'disabled':showConfirmationsUI}" id="userSurname"
                               placeholder="{{ 'registration.lastname_placeholder' | translate }}">
                        <div class="mobile-preview">
                            <span>{{lastName}}</span>
                        </div>
                    </div>
                </div>
                <div [ngClass]="{'form-group':true, 'row':true, 'edit-active':expandEmail}">
                    <label for="email6" class="col-lg-2 col-form-label">{{ 'registration.email' | translate }}</label>
                    <div class="col-lg-5">
                        <input [(ngModel)]="email" [ngModelOptions]="{standalone: true}" type="email"
                               [ngClass]="{'form-control':true}" id="email6"
                               [attr.disabled]="isLoggedIn ? '' : null"
                               placeholder="{{ 'registration.email_placeholder' | translate }}" (input)="removeError()">
                        <div class="mobile-preview" *ngIf="!expandEmail">
                            <span>{{email}}</span>
                            <a class="edit-input" (click)="expandEmail=true"><i class="fa fa-pencil"
                                                                                aria-hidden="true"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-5 validation-block" *ngIf="!emailInitiallyConfirmed">
                        <div class="validation-image">
                            <img class="validation-image-active" src="assets/img/circle-gold.png" alt=""
                                 *ngIf="!emailConfirmed && !emailCodeIsWrong">
                            <img class="validation-image-active" src="assets/img/circle-check-green.png" alt=""
                                 *ngIf="emailConfirmed">
                            <img class="validation-image-active" src="assets/img/circle-x-red.png" alt=""
                                 *ngIf="emailCodeIsWrong">
                        </div>
                        <input [(ngModel)]="emailCode" [ngModelOptions]="{standalone: true}" type="text"
                               class="form-control validation-input" id="userEmailVal"
                               placeholder="{{ 'registration.emailCode_placeholder' | translate }}">
                        <label for="userEmailVal" *ngIf="!emailConfirmed">
                            {{ 'registration.email_confirmation_instructions' | translate }} &nbsp; <a
                                (click)="resendEmailCode()">{{ 'registration.resend_email' | translate }}</a>
                        </label>
                    </div>
                </div>
                <div [ngClass]="{'form-group':true, 'row':true, 'edit-active':expandPhoneNumber}">
                    <label for="userMobile12" class="col-lg-2 col-form-label">
                        {{ 'registration.mobile' | translate }}
                    </label>
                    <div class="col-lg-5">
                        <input [(ngModel)]="phoneNumber" [ngModelOptions]="{standalone: true}" type="text"
                               [ngClass]="{'form-control':true}" id="userMobile12"
                               [attr.disabled]="phoneNumberInitiallyConfirmed ? '' : null"
                               placeholder="{{ 'registration.mobile_placeholder' | translate }}">
                        <div class="mobile-preview" *ngIf="!expandPhoneNumber">
                            <span>{{phoneNumber}}</span>
                            <a class="edit-input" (click)="expandPhoneNumber=true"><i class="fa fa-pencil"
                                                                                      aria-hidden="true"></i></a>
                        </div>
                    </div>
                    <div class="col-lg-5 validation-block" *ngIf="!phoneNumberInitiallyConfirmed">
                        <div class="validation-image">
                            <img class="validation-image-active" src="assets/img/circle-gold.png" alt=""
                                 *ngIf="!phoneNumberConfirmed && !phoneNumberCodeIsWrong">
                            <img class="validation-image-active" src="assets/img/circle-check-green.png" alt=""
                                 *ngIf="phoneNumberConfirmed">
                            <img class="validation-image-active" src="assets/img/circle-x-red.png" alt=""
                                 *ngIf="phoneNumberCodeIsWrong">
                        </div>
                        <input [(ngModel)]="phoneNumberCode" [ngModelOptions]="{standalone: true}" type="text"
                               class="form-control validation-input" id="userMobileVal"
                               placeholder="{{ 'registration.phoneCode_placeholder' | translate }}">
                        <label for="userMobileVal" *ngIf="!phoneNumberConfirmed">
                            {{ 'registration.mobile_confirmation_instructions' | translate }} &nbsp; <a
                                (click)="resendSms()">{{ 'registration.resend_sms' | translate }}</a>
                        </label>
                    </div>
                </div>
                <div class="form-group row edit-active">
                    <label for="password"
                           class="col-lg-2 col-form-label">{{ 'registration.password_header' | translate }}</label>
                    <div class="col-lg-4">
                        <input [(ngModel)]="password" [ngModelOptions]="{standalone: true}"
                               [attr.disabled]="(showConfirmationsUI || isLoggedIn) ? '' : null" type="password"
                               class="form-control" id="password"
                               placeholder="{{ 'registration.password_placeholder' | translate }}">
                    </div>
                    <label for="passwordConfirm" class="col-form-label ml-lg-3">
                        <span class="d-none d-lg-inline">{{ 'registration.confirm_password_header' | translate }}</span>
                        <span class="d-lg-none">{{ 'registration.confirm_password_header' | translate }}</span>
                    </label>
                    <div class="col-lg-4">
                        <input [(ngModel)]="passwordConfirm" [ngModelOptions]="{standalone: true}"
                               [attr.disabled]="(showConfirmationsUI || isLoggedIn) ? '' : null" type="password"
                               type="password" class="form-control" id="passwordConfirm"
                               placeholder="{{ 'registration.confirm_password_placeholder' | translate }}">
                    </div>
                </div>
                <div class="form-group input-padding row edit-active">
                    <div class="col-none col-lg-2 col-form-label"></div>
                    <div class="col-lg-10">
                        <div class="form-check">
                            <label for="termsCheck1">
                                <input [ngClass]="{ 'form-check-input':true, 'disabled': showConfirmationsUI }"
                                       [attr.disabled]="showConfirmationsUI ? '' : null" type="checkbox"
                                       [checked]="termsAccepted" (change)="termsAccepted = !termsAccepted;removeError()"
                                       id="termsCheck1" required>
                                <i class="checkbox-icon"></i>
                                {{ 'registration.I_read' | translate }}<a langRouterLink routerLink="/service-use"
                                                                          target="_blank">{{ 'registration.terms_conditions' | translate }}</a>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="actions row">
                    <div class="d-none d-lg-block col-lg-2 col-form-label"></div>
                    <div class="col-lg-8">
                        <div class="actions-step first-step" *ngIf="!selectedProviders || !selectedProviders.length">
                            <button [ngClass]="{'btn':true, 'btn-green':true, 'btn-middle':true, 'w-100':true, 'disabled':!isBtnGoToConfirmationsEnabled(), 'loading-small':loading }"
                                    (click)="btnGoToConfirmationsClick()">
                                {{ 'registration.register_button_0' | translate }}
                            </button>
                        </div>
                        <div class="actions-step first-step" *ngIf="selectedProviders && selectedProviders.length">
                            <button [ngClass]="{'btn':true, 'btn-green':true, 'btn-middle':true, 'w-100':true, 'disabled':!isBtnGoToConfirmationsEnabled(), 'loading-small':loading }"
                                    (click)="btnGoToConfirmationsClick()">
                                {{ 'registration.register_button_1_p1' | translate }}
                                <span>3</span> {{ 'registration.register_button_1_p2' | translate }}
                            </button>
                        </div>
                        <div class="actions-step second-step row align-items-center">
                            <b class="col-lg-6 validation-message">
                                {{ 'registration.codes_sent' | translate }}
                            </b>
                            <b class="col-lg-6 validation-message validation-message-error"
                               *ngIf="phoneNumberCodeIsWrong || emailCodeIsWrong">
                                <span>{{ 'registration.codes_incorrect_p1' | translate }}</span> {{ 'registration.codes_incorrect_p2' | translate }}
                                <br> {{ 'registration.codes_incorrect_p3' | translate }}
                            </b>
                            <div class="col-lg-6">
                                <button [ngClass]="{'btn':true, 'btn-green':true, 'btn-middle':true, 'w-100':true, 'disabled':!isBtnValidateCodesEnabled(), 'loading-small':loading }"
                                        (click)="btnValidateCodesClick()">
                                    {{ 'registration.register_button_2_p1' | translate }}<br> <span
                                        *ngIf="selectedProviders && selectedProviders.length">{{ 'registration.register_button_2_p2' | translate }}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <ul class="advantages-check d-block d-lg-none">
                <li>
                    <span class="check">✓</span>
                    <span><b>{{ 'registration.slogan_1_1' | translate }}</b>{{ 'registration.slogan_1_2' | translate }}</span>
                </li>
                <li>
                    <span class="check">✓</span>
                    <span><b>{{ 'registration.slogan_2_1' | translate }}</b>{{ 'registration.slogan_2_2' | translate }}</span>
                </li>
                <li>
                    <span class="check">✓</span>
                    <span><b>{{ 'registration.slogan_3_1' | translate }}</b>{{ 'registration.slogan_3_2' | translate }}</span>
                </li>
            </ul>
        </div>
        <div class="edit-block" *ngIf="workItemsCount">
            <h4>
                {{ 'registration.workItemsHeader' | translate:{workItemsCount: workItemsCount} }}
            </h4>
            <workitems-view></workitems-view>
        </div>
    </div>
</div>
