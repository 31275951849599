<div class="prov-details pep">
    <div class="top-block">
        <project-edit-top-banner></project-edit-top-banner>
    </div>
    <project-tabs activeTab="comments">
    </project-tabs>
    <div>
        <div class="info info-new">
            <div class="comments-uploads-part">
                <div class="container">
                    <div *ngIf="false" class="block block-pictures">
                        <div class="title-with-subtitle">
                            <h4>
                                {{ 'project_edit.uploads_header' | translate }}
                            </h4>
                            <span>
                                {{ 'project_edit.uploads_header_desc' | translate }}
                            </span>
                        </div>
                        <div class="wrap-pictures">
                            <div>
                                image uploader here
                                <!-- TODO: fix  <image-upload url="/ProjectPic/Upload" [uploadedFiles]="pics" [class]="'project-uploads'" (removed)="onPicRemoved($event)" (uploadFinished)="onUploadFinished($event)" max="15" maxFileSize="15728640"
                                              [buttonCaption]="uploadBtnCaption" dropBoxMessage=" " [fileTooLargeMessage]="fileTooLargeMsg"></image-upload> -->
                            </div>
                        </div>
                    </div>
                    <div class="block block-comments">
                        <div class="title-with-subtitle">
                            <h4>
                                {{ 'comments_modal.header' | translate }}
                            </h4>
                            <span>
                                {{ 'comments_modal.header_desc' | translate }}
                            </span>
                        </div>
                        <div class="wrap-textarea">
                            <textarea [(ngModel)]="comment" [ngModelOptions]="{standalone: true}"></textarea>
                        </div>
                        <div class="action">
                            <button (click)="cancel()" [ngClass]="{'disabled': (comment === savedComment), 'loading-small':loading, 'btn':true, 'btn-transparent-blue':true}">
                                {{ 'comments_modal.cancel' | translate }}
                            </button>
                            <button (click)="save()" [ngClass]="{'disabled': (comment === savedComment), 'loading-small':loading, 'btn':true, 'btn-blue':true}">
                                {{ 'comments_modal.save' | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>