<div class="wrap">
    <div class="row">
        <div class="col-6 wrap-img">
            <img src="{{imgPath}}" class="w-100" alt="{{title}}">
        </div>
        <div class="col-6 wrap-text">
            <h5>
                {{title}}
            </h5>
            <a class="btn btn-green btn-small-extra" [routerLink]="link">Go</a>
        </div>
    </div>
</div>
