import { Component, OnInit, NgZone } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

import { DisplayService } from '../../shared/display.service';
import { SeoService } from '../../shared/seo.service';

@Component({
    selector: 'how-it-works',
    templateUrl: './how-it-works.page.html',
})
export class HowItWorksComponent implements OnInit {
    private ngUnsubscribe: Subject<void> = new Subject<void>();

    public showExample1: boolean = false;
    public showExample2: boolean = false;
    public showExample3: boolean = false;

    constructor(public translate: TranslateService, private displayService: DisplayService,
        private seoService: SeoService, private activatedRoute: ActivatedRoute, private ngZone: NgZone) { }

    ngOnInit(): void {
        this.translate.get(['how_it_works.title', 'how_it_works.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['how_it_works.title'], res['how_it_works.desc']);
        });
        this.activatedRoute.fragment.subscribe((fragment: any) => {
            this.scrollToFragment(fragment);
        })
        this.displayService.isModalMode.pipe(takeUntil(this.ngUnsubscribe)).subscribe((mode: any) => {
            if (!mode) {
                this.showExample1 = false;
                this.showExample2 = false;
                this.showExample3 = false;
            }
        });
    }

    public showExample(num: number): void {
        switch (num) {
            case 1:
                this.showExample1 = true;
                this.displayService.setModalMode(true);
                break;
            case 2:
                this.showExample2 = true;
                this.displayService.setModalMode(true);
                break;
            case 3:
                this.showExample3 = true;
                this.displayService.setModalMode(true);
                break;
        }
        window && window.scrollTo(0, 0);
    }

    scrollToFragment(fragment: string): void {
        this.displayService.runOutsideAngular(this.ngZone, () => {
            let elem = document.getElementById(fragment);
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }, 200);
    }
}
