import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

import { ProjectService } from '../../shared/projects/project.service';
import { LocationService } from '../../shared/locations/location.service';
import { SeoService } from '../../shared/seo.service';
import { LandingParamsService } from '../../shared/landingParams/landing-params.service';
import { WorkAreasService } from '../../shared/workareas/work-areas.service';
import { DisplayService } from '../../shared/display.service';
import { AbTestsService } from '../../shared/abTesting/abTestsService';
import { ProjectPriceItem } from '../../shared/projects/ProjectPriceItem';

@Component({
    selector: 'search-results',
    templateUrl: './search-results.component.html'
})
export class SearchResultsComponent implements OnInit, OnDestroy {
    public providersCount: number = 0;
    ngUnsubscribe: Subject<void> = new Subject<void>();
    currentLocationName: string = '';
    landingText: string | null = null;
    showTopFilter: boolean = false;
    private initPricesLoadDone: boolean = false;
    loading: boolean = true;

    projectPendingText1: string | null = null;
    projectPendingText2: string | null = null;
    projectPendingText3: string | null = null;
    projectPendingText4: string | null = null;
    projectPending: boolean = false;
    hidePrices: boolean = false;
    isMobile: boolean = false;
    condensedMobile: boolean = true;
    selectedOffers: ProjectPriceItem[] =[];
    
    areaSqm: number = 0;
    workItemsCount: number = 0;

    constructor(public translate: TranslateService, private projectService: ProjectService,
        private locationService: LocationService, private seoService: SeoService, private landingParamsService: LandingParamsService,
        private modalService: BsModalService, private workAreasService: WorkAreasService,
        private displayService: DisplayService, private router: Router, private ngZone: NgZone,
        private abTestsService: AbTestsService) {
        this.isMobile = displayService.isMobile();
    }
    
    ngOnInit() {
        this.translate.get(['search_results.title', 'search_results.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['search_results.title'], res['search_results.desc']);
        });
        this.subscribeToPrices();
        this.subscribeToCurrentLocation();
        this.subscribeToRenovationArea();
        this.subscribeToSelectedOffers();
        this.loadLandingParams();
        if (this.displayService.isMobile()) {
            this.displayService.setCondensed(true);
        }
        this.subscribeToCondensed();
        this.projectService.loadPricesIfNotLoaded();
        let landingParams = this.landingParamsService.getLandingParams();
        if (!!landingParams.text1) {
            this.projectPendingText1 = landingParams.text1;
        }
    }

    private loadLandingParams() {
        this.landingParamsService.markShowingLandingText();
        let landingParams = this.landingParamsService.getLandingParams();
        this.hidePrices = landingParams.hidePrices;
        this.landingText = landingParams.text1;
        this.projectPendingText1 = landingParams.text2;
        this.projectPendingText2 = landingParams.text3;
        this.projectPendingText3 = landingParams.text4;
        this.projectPendingText4 = landingParams.text5;
    }

    private subscribeToPrices(): void {
        this.projectService.pricesDto.pipe(takeUntil(this.ngUnsubscribe)).subscribe(dto => {
            this.providersCount = dto.prices.length;
            if (this.initPricesLoadDone || dto.prices.length) {
                this.loading = false;
            }
            
            if (dto.prices.length) {
                this.workItemsCount = dto.prices[0].workItems.length;
                let edits = dto.projectMeta ? dto.projectMeta.editsSinceCreation : 0;
                this.projectPending = this.isProjectPending(edits);
                this.showTopFilter = !this.displayService.isMobile() && this.projectPending;
            }
            this.initPricesLoadDone = true;
        });
    }

    private subscribeToCurrentLocation(): void {
        this.locationService.currentLocation.subscribe(newLocation => {
            this.currentLocationName = newLocation.locationName || this.currentLocationName;
        });
    }

    protected subscribeToRenovationArea(): void {
        this.workAreasService.renovationArea.subscribe(area => {
            this.areaSqm = area;
        });
    }

    private subscribeToCondensed(): void {
        this.displayService.isCondensed.pipe(takeUntil(this.ngUnsubscribe)).subscribe(condensed => {
            this.condensedMobile = condensed;
        });
    }

    private subscribeToSelectedOffers(): void {
        this.projectService.selectedOffers.pipe(takeUntil(this.ngUnsubscribe)).subscribe(offers => {
            this.selectedOffers = offers;
        });
    }

    public getMasterWorkAreasDisplayList(): string {
        return this.workAreasService.getMasterWorkAreasDisplayList(12);
    }

    public scrollToProjectEdit(): void {
        this.displayService.scrollToClass('edit-block');
    }

    public openLocationInput() {
        this.router.navigate([], { queryParams: { tab: 'location' } });
        this.scrollToWorkAreasSelectSide();
    }

    public openWorkAreasInput() {
        this.router.navigate([], { queryParams: { tab: 'workareas' } });
        this.scrollToWorkAreasSelectSide();
    }

    public openAreaInput() {
        this.router.navigate([], { queryParams: { tab: 'area' } });
        this.scrollToWorkAreasSelectSide();
    }

    private scrollToWorkAreasSelectSide(): void {
        let self = this;
        if (this.displayService.isBrowser()) {
            this.ngZone.runOutsideAngular(function () {
                setTimeout(function () {
                    self.ngZone.run(function () {
                        self.displayService.scrollToClass('title-top');
                    });
                }, 200);
            });
        }
    }

    private isProjectPending(editsSinceCreation: number): boolean {
        return editsSinceCreation < 5;
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

}
