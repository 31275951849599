<div class="authorization">
    <div class="container">
        <h3>
            {{ 'registration.welcome_back' | translate }}
        </h3>
        <form>
            <div class="form-group">
                <label for="email1">{{ 'registration.email' | translate }}</label>
                <div class="input-padding">
                    <input [(ngModel)]="email" [ngModelOptions]="{standalone: true}" type="email" class="form-control" id="email1"
                           placeholder="{{ 'registration.email_placeholder' | translate }}" (input)="removeError()">
                </div>
            </div>
            <div class="form-group">
                <label for="pass1">{{ 'registration.password_header' | translate }}</label>
                <div class="input-padding">
                    <input [(ngModel)]="password" [ngModelOptions]="{standalone: true}" type="password" class="form-control" id="pass1" placeholder="******" (input)="removeError()">
                </div>
            </div>
            <div class="actions">
                <div>
                    <a routerLink="/forgotPassword">{{ 'registration.forgot_password' | translate }}</a>
                </div>
                <div class="alert alert-danger" role="alert" *ngIf="error">
                    {{ error }}
                </div>
                <button type="submit" [ngClass]="{'btn':true, 'btn-blue':true, 'btn-middle':true, 'w-100':true, 'disabled':!signInButtonEnabled(), 'loading-small':loading }" (click)="btnSignInClick()">{{ 'registration.signin' | translate }}</button>
            </div>
        </form>
    </div>
</div>
