import { Component, OnInit } from '@angular/core';

import { GalleryComponent } from './gallery.component';

@Component({
    selector: 'vertical-gallery',
    templateUrl: './vertical-gallery.component.html'
})
export class VerticalGalleryComponent extends GalleryComponent implements OnInit {
   public createRange(number: number) {
        var items: number[] = [];
        for (var i = 1; i <= number; i++) {
            items.push(i);
        }
        return items;
    }
}
