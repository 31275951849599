import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { ProviderService } from '../providers/ProviderService';
import { DisplayService } from '../display.service';

@Injectable()
export class FeedbackProviderInitiatedResolve implements Resolve<string> {
    constructor(private providerService: ProviderService, private displayService: DisplayService) {
    }

    resolve(route: ActivatedRouteSnapshot): Promise<string> {
        let providerId = route.params['id'];
        return this.providerService.getProviderName(providerId);
    }
}
