import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { SeoService } from '../../shared/seo.service';
import { FeedbackService } from '../../shared/feedback/feedback.service';
import { NotificationService } from '../../shared/notificaton.service';
import { Router } from '@angular/router';

@Component({
    selector: 'feedback-unsubscribe.component',
    templateUrl: './feedback-unsubscribe.component.html'
})
export class FeedbackUnsubscribeComponent implements OnInit {
    constructor(public translate: TranslateService, private seoService: SeoService, private feedbackService: FeedbackService,
        private notifier: NotificationService, private router: Router) {
    }

    ngOnInit() {
        this.translate.get(['feedback.title', 'feedback.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['feedback.title'], res['feedback.desc']);
        });
    }

    async unsubscribe() {
        await this.feedbackService.unsubscribe();
        this.translate.get('feedback.unsubscribed_successfully').subscribe((res: any) => {
            this.notifier.showSuccess(res);
            this.router.navigateByUrl('/search');
        });
    }
}
