import { Component, Input } from '@angular/core';

@Component({
    selector: 'small-stars',
    templateUrl: './small-stars.component.html'
})
export class SmallStarsComponent {
    @Input() num!: number;
    @Input() color!: string;
    @Input() showEmptyStars: boolean = true;

}
