import { Directive, Input, OnInit } from '@angular/core';
import { Router, RouterLink, NavigationExtras } from '@angular/router';
import { L10nService } from './l10n.service';
@Directive({
  selector: '[routerLink][langRouterLink]'
})
export class LangRouterLinkDirective implements OnInit {

  @Input('routerLink') linkParams: any;
  @Input('queryParams') queryParams: any;

  constructor(private l10nService: L10nService,  private routerLink: RouterLink) {
  }

  ngOnInit(): void {
    const lang = this.l10nService.getCurrentLang()

    const linkArray = Array.isArray(this.linkParams) ? this.linkParams : [this.linkParams];
    if (linkArray.length > 0) {
        linkArray[0] = `/${lang}${linkArray[0].startsWith('/') ? '' : '/'}${linkArray[0]}`;
    }

    if (this.queryParams) {
        const extras: NavigationExtras = { queryParams: this.queryParams };
        this.routerLink.routerLink = [[linkArray], extras];
    } else {
        this.routerLink.routerLink = linkArray;
    }
  }
}
