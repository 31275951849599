import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class RenoRouter {
    constructor(private router: Router) {
    }

    public gotoOfferDetails(providerId: string): void {
        this.router.navigateByUrl(`/offer/${providerId}`);
    }
}