<div class="search-result-filter" *ngIf="!loading">
    <ng-container *ngIf="!selectedOffers.length">
        <div class="top-banner d-none d-md-block">
            <h4 *ngIf="projectPendingText1">
                {{ projectPendingText1 }}
            </h4>
            <h4 *ngIf="!projectPendingText1">
                {{ 'search_results.title' | translate }}
            </h4>
            <search-results-top-banner></search-results-top-banner>
        </div>
    </ng-container>
    <div *ngIf="selectedOffers.length" class="shopping-cart block-green d-none d-md-block">
        <h4>
            {{ 'search_results.selected_offers_header' | translate }}
        </h4>
        <selected-offers-search-results>
        </selected-offers-search-results>
        <div>
            <a routerLink="/register" class="btn btn-green">
                <span>{{ 'search_results.register_link2' | translate }}</span>
            </a>
        </div>
    </div>
    <ul class="nav nav-pills nav-fill">
        <li class="nav-item">
            <a [ngClass]="{'nav-link': true, 'active':sorting==ProvidersSorting.Overall}"
               (click)="sortProviders(ProvidersSorting.Overall)">{{ 'search_results.sort_by_overall' | translate}}</a>
        </li>
        <li class="nav-item">
            <a [ngClass]="{'nav-link': true, 'active':sorting==ProvidersSorting.Price}"
               (click)="sortProviders(ProvidersSorting.Price)">{{ 'search_results.sort_by_price' | translate }}</a>
        </li>
        <li *ngIf="showReviewsSort" class="nav-item">
            <a [ngClass]="{'nav-link': true, 'active':sorting==ProvidersSorting.Reviews}"
               (click)="sortProviders(ProvidersSorting.Reviews)">{{ 'search_results.sort_by_reviews' | translate }}</a>
        </li>
    </ul>
</div>
<div [ngClass]="{'search-result-list': true, 'min-height':loading, 'envelope-version':true }">
    <ng-container *ngIf="loading">
        <div class="skeleton-card-envelope-version"></div>
        <br/>
        <div class="skeleton-card-envelope-version"></div>
        <br/>
        <div class="skeleton-card-envelope-version"></div>
        <br/>
    </ng-container>
    <ng-container *ngFor="let priceItem of priceItems">
        <div class="item">
            <div [ngClass]="{'landing':itemLandingHeader && !priceItem.selected, 'standard-listing':editsSinceCreation>=5, 'project-listing':editsSinceCreation<5 && !itemLandingHeader, 'paddings':true}">
                <div [ngClass]="{ 'green-part-top':priceItem.selected, 'price-less':hidePrices, 'item-envelope':true}">
                    <div class="part-top" *ngIf="!priceItem.selected">
                        <div *ngIf="itemLandingHeader" class="landing-text">
                            <span>
                                {{itemLandingHeader}}
                            </span>
                        </div>
                        <div *ngIf="!projectPendingText2" class="personalize-text">
                            <a routerLink="/search-results" [queryParams]="{tab: 'area'}">{{ 'search_results.personalize_project_mobile_p1' | translate }}</a>
                        </div>
                        <div class="standard-text">
                            {{ 'search_results.offerCap' | translate }}
                        </div>
                        <ng-container *ngIf="!hidePrices">
                            <b class="price"> {{priceItem.currencySign}}&nbsp;{{priceItem.totalPrice | number:'1.0-0'}} </b>
                            <br>
                        </ng-container>
                        <ng-container *ngIf="hidePrices">
                            <b class="price-less-text"> {{ 'search_results.offerCap' | translate }} </b>
                            <br>
                        </ng-container>
                    </div>
                    <div class="part-top" *ngIf="priceItem.selected">
                        <b class="price"> {{priceItem.currencySign}}&nbsp;{{priceItem.totalPrice | number:'1.0-0'}} </b>
                        <br>
                        <b>{{ 'search_results.provider_selected' | translate }}</b>
                    </div>
                    <div class="part-middle" *ngIf="!priceItem.selected">
                        <a class="btn btn-white-green"
                           (click)="selectProvider(priceItem.providerId)">{{ 'search_results.select_provider' | translate }}</a>
                    </div>
                    <div class="part-middle" *ngIf="priceItem.selected">
                        <a class="btn btn-white-red_orange" (click)="deselectProvider(priceItem.providerId)">{{ 'search_results.unselect_provider' | translate }}</a>
                    </div>
                    <div class="part-bottom">
                        <a (click)="openOffer(priceItem.providerId)"> {{ 'search_results.provider_details_btn' | translate }} </a>
                    </div>
                </div>
                <div class="item-stars">
                    <div [ngClass]="{'item-prices':true}">
                        <h6> {{ 'search_results.prices_stars_header' | translate }} </h6>
                        <ng-container>
                            <small-stars class="stars" [num]="priceItem.stats.prices.starsNum" color="blue">
                            </small-stars>
                        </ng-container>
                    </div>
                    <div class="item-services" *ngIf="priceItem.stats.services.num">
                        <h6> {{ 'search_results.services_stars_header' | translate }}
                            (<span>{{priceItem.stats.services.num}}</span>) </h6>
                        <small-stars class="stars" [num]="priceItem.stats.services.starsNum" color="blue">
                        </small-stars>
                    </div>

                    <div class="item-reviews" *ngIf="priceItem.stats.reviews.num">
                        <h6>
                            {{ 'search_results.reviews_stars_header' | translate }}
                            (<span>{{priceItem.stats.reviews.num}}</span>)
                        </h6>
                        <small-stars class="stars" [num]="priceItem.stats.reviews.starsNum" color="blue">
                        </small-stars>
                    </div>
                </div>
                <div class="item-provider">
                    <div class="provider">
                        <div class="provider-verified">
                            <img src="assets/img/icon-shield.svg" width="14px" alt="">
                            <span>
                                {{ 'search_results.verified_provider' | translate }}
                            </span>
                        </div>
                        <div class="provider-info">
                            <a [routerLink]="'/provider/' + priceItem.providerId">{{priceItem.providerName}}</a>
                            <small-stars class="stars" [num]="priceItem.stats.mainRating.starsNum" color="orange"
                                         [showEmptyStars]="false">
                            </small-stars>
                        </div>
                        <div class="provider-verifications">
                            <img *ngIf="priceItem.leadingPictureUrl" src="{{priceItem.leadingPictureUrl}}" width="40" height="40" alt="">
                            <ul *ngIf="priceItem.verifiedThings && priceItem.verifiedThings.length"
                                 (click)="showDocsModal(priceItem.providerId)"
                                 class="advantages-check">
                                <li *ngFor="let verifiedThing of priceItem.verifiedThings">
                                    <span class="check">✓</span>
                                    <a>{{ verifiedThing }}</a>
                                </li>
                                <li class="advantage advantage-blue"
                                     *ngIf="!priceItem.verifiedThings || !priceItem.verifiedThings.length">
                                    <a style="text-decoration: none!important;">&nbsp;</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="item-gallery">
                    <div class="gallery-wrap" style="width: 100%; height: 100%;">
                        <gallery-light class="item-photo" height="100%" [showImage]="true"
                                       [providerId]="priceItem.providerId">
                        </gallery-light>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>

