import { Injectable } from '@angular/core';

import { LeadFeedbackUserStatusDto } from './LeadFeedbackUserStatusDto';
import { DataService } from '../network/data.service';
import { ProviderScoreModel } from './ProviderScoreModel';

@Injectable()
export class FeedbackService {
    constructor(private dataService: DataService) { }

    public getLatest(): Promise<LeadFeedbackUserStatusDto> {
        return this.dataService.get<LeadFeedbackUserStatusDto>('Feedback/Latest');
    }

    public updateStatus(providerId: string, statusId: number) {
        return this.dataService.post<string>('Feedback/UpdateStatus', {
            providerId,
            statusId
        });
    }

    public getProviderScores(): Promise<any> {
        return this.dataService.get<any>('Feedback/Scores');
    }

    public saveScore(providerId: string, type: string, value: number) {
        return this.dataService.post('Feedback/SaveScore', { providerId, type, value });
    }

    public saveComment(providerId: string, isPositive: boolean, text: string) {
        return this.dataService.post('Feedback/SaveComment', { providerId, isPositive, text });
    }

    public saveScoreProviderInitiated(providerId: string, firstName: string, lastName: string,
        email: string, password: string, price: number, score: ProviderScoreModel) {
        let dto: any;
        dto = Object.assign({}, score);
        dto.providerId = providerId;
        dto.firstName = firstName;
        dto.lastName = lastName;
        dto.email = email;
        dto.price = price;
        dto.password = password;
        return this.dataService.post('Feedback/ProviderInitiated', dto);
    }

    public unsubscribe() {
        return this.dataService.post('Feedback/Unsubscribe', { });
    }
}
