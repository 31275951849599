<div class="unsubscribe">
    <div class="container">
        <h3>
            {{ 'feedback.header' | translate }}
        </h3>
        <div class="actions">
            <a (click)="unsubscribe()" class="btn btn-transparent-blue">
                {{ 'feedback.do_unsubscribe' | translate }}
            </a>
            <a routerLink="/search" class="btn btn-green">
                {{ 'feedback.home_link' | translate }}
            </a>
        </div>
    </div>
</div>
