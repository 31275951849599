import { Inject, PLATFORM_ID, NgZone, Injectable } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';


import { InitDataService } from './init-data.service';
import { RenoRouter } from './RenoRouter';

@Injectable()
export class DisplayService {
    private isModalModeSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isModalMode: Observable<boolean> = this.isModalModeSource.asObservable();
    private isCondensedSource: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public isCondensed: Observable<boolean> = this.isCondensedSource.asObservable();
    private initRedirectDone: boolean = false;
    private initRedirectToComparison: boolean;

    constructor(@Inject(PLATFORM_ID) private platformId: Object, initDataService: InitDataService,
        private renoRouter: RenoRouter) {
        this.initRedirectToComparison = initDataService.getInitialData().initRedirectToComparison;
    }

    public isMobile(): boolean {
        if (!isPlatformBrowser(this.platformId)) {
            return false;
        }
        if (window && window.screen && window.screen.width < 768) {
            return true;
        }
        return false;
    }

    public isBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    public setModalMode(val: boolean) {
        this.isModalModeSource.next(val && this.isMobile());
    }

    public isInitRedirectNeeded(): boolean {
        if (!this.isBrowser()) {
            return false;
        }
        if (!this.initRedirectDone && this.initRedirectToComparison) {
            this.initRedirectDone = true;
            return true;
        }
        return false;
    }

    public scrollToClass(className: string): void {
        let tags = document.getElementsByClassName(className);
        if (!tags) {
            return;
        }
        let elem: any;
        elem = tags[0];
        if (!elem) {
            return;
        }
        elem.style.display = "block";
        elem.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    public runOutsideAngular(ngZone: NgZone, callback: () => any, timeout: number = 200) {
        if (this.isBrowser()) {
            ngZone.runOutsideAngular(function () {
                setTimeout(function () {
                    ngZone.run(function () {
                        callback();
                    });
                }, timeout);
            });
        }
    }

    public setCondensed(val: boolean): void{
        this.isCondensedSource.next(val);
    }

    public navigateBack(defaultRoute: string): void {
        this.renoRouter.navigateByUrl(defaultRoute);
    }
}
