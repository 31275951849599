<div class="tabs">
    <div class="container">
        <div class="tabs-menu">
            <a langRouterLink routerLink="/search-results" [queryParams]="{ tab: 'area' }"
               [ngClass]="{ 'tab-active':activeTab == 'area', 'tab':true, 'tab-sqm':true }">
                <div class="wrap-img">
                    <img src="assets/img/icon-area.svg">
                </div>
                <div class="wrap-content">
                    <b class="tab-title">
                        <span>{{ areaSqm }}</span> {{ 'sqm' | translate }}
                    </b>
                    <span class="tab-action">
                        {{ 'provider_details.refine' | translate }} {{ 'provider_details.area' | translate }}
                    </span>
                </div>
            </a>
            <a langRouterLink routerLink="/search-results" [queryParams]="{ tab: 'workareas' }"
               [ngClass]="{ 'tab-active':activeTab == 'workareas', 'tab':true, 'tab-areas':true }">
                <div class="wrap-img">
                    <img [src]="mwaIcon" *ngFor="let mwaIcon of selectedMwaIcons">
                </div>
                <div class="wrap-content">
                    <b class="tab-title">
                        <span>{{ masterWorkAreasNum }}</span> {{ 'WORKAREAS_SELECTOR.workareas_header5' | translate }}
                    </b>
                    <span class="tab-action">
                        {{ 'provider_details.refine' | translate }} {{ 'provider_details.workareas' | translate }}
                    </span>
                </div>
            </a>
            <a langRouterLink routerLink="/edit-project"
               [ngClass]="{ 'tab-active':activeTab == 'details', 'tab':true, 'tab-details':true }">
                <div class="wrap-img">
                    <img src="assets/img/icon-file-search.svg" alt="">
                </div>
                <div class="wrap-content">
                    <b class="tab-title" *ngIf="workItemsCount">
                        {{ 'provider_details.edit_project_details_tab' | translate:{workItemsCount: workItemsCount} }}
                    </b>
                    <span class="tab-action">
                {{ 'provider_details.edit_workitems' | translate }}
            </span>
                </div>
            </a>
            <a langRouterLink routerLink="/project-uploads"
               [ngClass]="{ 'tab-active':activeTab == 'comments', 'tab':true, 'tab-comments':true }">
                <div class="wrap-img">
                    <img src="assets/img/icon-area-empty.svg" alt="">
                    <img src="assets/img/icon-comment.svg" alt="">
                    <img src="assets/img/icon-picture.svg" alt="">
                </div>
                <div class="wrap-content">
                    <b class="tab-title">
                        <span *ngIf="numOfCommentsAndUploads >= 0">{{numOfCommentsAndUploads}}</span> {{ 'provider_details.add_comments_and_uploads' | translate }}
                    </b>
                    <span class="tab-action">
                        {{ 'provider_details.add_comments_p1' | translate }} {{ 'provider_details.add_comments_p2' | translate }}
                    </span>
                </div>
            </a>
        </div>
    </div>
</div>