<div class="gpdr-fixed" *ngIf="show">
    <div class="container">
        <div class="text">
            <p>
                {{ 'gdpr.disclaimer_p1' | translate}}
                <a routerLink="/service-use" fragment="privacy">{{ 'gdpr.disclaimer_p2' | translate}}</a> {{ 'gdpr.disclaimer_p3' | translate}} <a routerLink="/service-use" [queryParams]="{chapter: 'cookies'}">{{ 'gdpr.disclaimer_p4' | translate}}</a>.
            </p>
        </div>
        <div class="actions">
            <a class="btn btn-white btn-small" (click)="accept()">
                {{ 'gdpr.btn_accept' | translate}}
            </a>
            <a class="close" (click)="close()">
                &#10005;
            </a>
        </div>
    </div>
</div>
