import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { ArticleService } from '../../shared/content/article.service';
import { SeoService } from '../../shared/seo.service';
import { ArticleSearchResultDto } from '../../shared/content/ArticleSearchResultDto';
import { SearchService } from '../../shared/search/search.service';
import { DisplayService } from '../../shared/display.service';

@Component({
    selector: 'articles-search',
    templateUrl: './articles-search.component.html'
})
export class ArticlesComponent implements OnInit {
    public itemsInRow = 2;
    public searchQuery: string = '';
    public searchResults: ArticleSearchResultDto[][] = [];
    public loading: boolean = false;

    constructor(private articleService: ArticleService, private seoService: SeoService,
        public translate: TranslateService, private searchService: SearchService, private displayService: DisplayService) {
        
    }

    ngOnInit(): void {
        this.translate.get(['articles.title', 'articles.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['articles.title'], res['articles.desc']);
        });
        this.searchPredefined(1);
    }

    public async doSearch() {
        if (this.loading || !this.displayService.isBrowser()) {
            return;
        }

        this.loading = true;

        let results = await this.articleService.search(this.searchQuery);
        this.displaySearchResults(results);
        this.loading = false;
    }

    private displaySearchResults(results: ArticleSearchResultDto[]) {
        let rowsNum = Math.ceil(results.length / this.itemsInRow + (this.searchResults.length % this.itemsInRow > 0 ? 1 : 0));
        let searchResults = rowsNum > 0 ? new Array(rowsNum) : [];
        for (let i = 0; i < rowsNum; ++i) {
            if (this.itemsInRow <= 0) {
                continue;
            }
            let num = Math.min(this.itemsInRow, results.length - i * this.itemsInRow);
            num = num < 0 ? 0 : num;
            searchResults[i] = new Array(num);
            for (let j = 0; j < num; ++j) {
                searchResults[i][j] = results[i * this.itemsInRow + j];
            }
        }
        this.searchResults = searchResults;

    }

    public searchPredefined(num: number) {
        if (this.loading) {
            return;
        }
        this.loading = true;
        this.translate.get('articles.predef_search_query' + num).subscribe((query: any) => {
            this.searchQuery = query;
            this.articleService.search(query, num - 1).then(results => {
                this.displaySearchResults(results);
                this.loading = false;
            });
        });
    }
}
