<div class="modal-header">
    <p>
        {{ 'rename_project.header' | translate }}
    </p>
</div>
<div class="modal-body">
    <form>
        <div class="form-group row">
            <label class="col-form-label">{{ 'rename_project.project_name' | translate }}</label>
            <div class="col-form-input">
                <input [(ngModel)]="projectName"[ngModelOptions]="{standalone: true}" type="text" class="form-control" placeholder="{{ 'save_project.project_name_placeholder' | translate }}">
            </div>
        </div>
        <div class="form-group input-padding row">
            <div class="col-none col-form-label"></div>
            <div class="col-form-input">
                <button [ngClass]="{ 'disabled': !isBtnSaveEnabled(), 'loading-small':loading, 'btn':true, 'btn-green':true, 'btn-large':true, 'w-100':true }" (click)="rename()">
                    {{ 'rename_project.save' | translate }}
                </button>
            </div>
        </div>
    </form>
</div>
