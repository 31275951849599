<div class="condensed-mobile-block">
    <div *ngIf="showCart && !tabShown" class="shopping-cart block-green d-md-none avoidingContainerPaddings">
        <div>
            <a routerLink="/register" class="btn btn-green">
                <span>{{ 'search_results.register_link1' | translate }}</span>
            </a>
        </div>
        <selected-offers-search-results>
        </selected-offers-search-results>
    </div>
    <a class="btn btn-like-dropdown btn-yellow-bronze" (click)="uncondense()">
        {{ 'WORKAREAS_SELECTOR.header_condensed_mobile' | translate }}
    </a>
</div>
<div class="step-first" *ngIf="showWholeWidget && !showSqmSelector && !showWorkAreasSelector && !showAddressSelector">
    <div class="icon-compact d-md-none">
        <a (click)="condense()">
            <img src="assets/img/icon-compact.svg" width="18" alt="">
        </a>
    </div>
    <h6 class="title title-top">
        <i class="fa fa-heart" aria-hidden="true"></i> {{ 'search_results.personalize_project_mobile_p1' | translate }}
    </h6>
    <div class="step-first-block bottom-line">
        <div class="number">
            <img src="assets/img/icons-number/first-green.svg" alt="1" *ngIf="step1Completed">
            <img src="assets/img/icons-number/first.svg" alt="1" *ngIf="!step1Completed">
        </div>
        <a [routerLink]="[]" [queryParams]="{ tab: 'location' }" class="btn btn-like-dropdown btn-transparent-bronze">
                <span>
                    {{ 'WORKAREAS_SELECTOR.location_header' | translate }}&nbsp;
                </span>
            <span class="value">
                    {{currentLocationName}}
                </span>
        </a>
    </div>
    <div class="step-first-block">
        <b class="number">
            <img src="assets/img/icons-number/second-green.svg" alt="2" *ngIf="step2Completed">
            <img src="assets/img/icons-number/second.svg" alt="2" *ngIf="!step2Completed">
        </b>
        <div class="btn btn-like-dropdown btn-transparent-bronze" (click)="openSqmSelector()">
            <div class="icons">
                <img src="assets/img/icon-area.svg" width="30" alt="">
            </div>
            <span class="text">
                    {{ 'WORKAREAS_SELECTOR.area_header_mobile' | translate }} <span
                    class="value">{{areaSqm}} {{ 'sqm' | translate }}</span>
                </span>
        </div>
    </div>
    <div class="step-first-block">
        <b class="number">
            <img src="assets/img/icons-number/third-green.svg" alt="3" *ngIf="step3Completed">
            <img src="assets/img/icons-number/third.svg" alt="3" *ngIf="!step3Completed">
        </b>
        <div class="btn btn-like-dropdown btn-transparent-bronze" (click)="openWorkAreasSelector()">
            <div class="icons icons-opacity">
                <img [src]="mwaIcon" *ngFor="let mwaIcon of selectedMwaIcons">
            </div>
            <span class="text">
                    <span class="value"
                          *ngIf="masterWorkAreasNum">{{masterWorkAreasNum}}</span> {{ 'WORKAREAS_SELECTOR.workareas_header5' | translate }}
                </span>
        </div>
    </div>
    <div class="step-first-block with-preview">
        <b class="number">
            <img src="assets/img/icons-number/fourth-green.svg" alt="4" *ngIf="step4Completed">
            <img src="assets/img/icons-number/fourth.svg" alt="4" *ngIf="!step4Completed">
        </b>
        <div class="wrap-preview">
            <div class="btn btn-like-dropdown btn-transparent-bronze" (click)="scrollToProjectEdit(); condense();">
                <div class="icons">
                    <img src="assets/img/icon-file-search.svg" width="22" alt="">
                </div>
                <span class="text">
                        <span class="value">{{ workItemsCount }}</span> {{ 'WORKAREAS_SELECTOR.workitems_header6' | translate }}
                    </span>
            </div>
            <div class="preview-block" *ngIf="editsSinceCreation < 5 && workItemsCount">
                <h6>
                    {{ 'WORKAREAS_SELECTOR.workitems_preview_header' | translate }}
                </h6>
                <ul>
                    <li>
                        <b>
                            {{ workItem1Name }}
                        </b>
                        <br>
                        <span>
                                {{ workItem1Desc }}
                            </span>
                    </li>
                    <li *ngIf="workItemsCount > 1">
                        <b>
                            {{ workItem2Name }}
                        </b>
                        <span>
                                {{ workItem1Desc }}
                            </span>
                    </li>
                    <li *ngIf="workItemsCount > 2">
                        <b>
                            <a (click)="scrollToProjectEdit()">
                                {{ 'WORKAREAS_SELECTOR.workitems_details_link' | translate:{workItemsCount: (workItemsCount - 2)} }}
                            </a>
                        </b>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div [ngClass]="{'step-second':true, 'hidden':!showSqmSelector && !showWorkAreasSelector && !showAddressSelector}">
    <div [ngClass]="{ 'block-project':true, 'block-active':showWorkAreasSelector }">
        <div class="title">
            <h5>
                <img src="assets/img/icons-number/third-dark.svg"
                     alt="3"> {{ 'WORKAREAS_SELECTOR.workareas_header3_p1' | translate }}
            </h5>
            <p>
                {{ 'WORKAREAS_SELECTOR.workareas_header3_p2' | translate }}
            </p>
        </div>
        <div class="active-block">
            <form id="work-details" class="work-details text-left">
                <div class="actions">
                    <div class="next-step">
                        <button class="btn btn-small btn-blue" role="button" (click)="doneWorkAreasClick(true)">
                            {{ 'WORKAREAS_SELECTOR.done' | translate }}
                        </button>
                        <div class="next-step_text">
                            {{ 'WORKAREAS_SELECTOR.update_prices_and_next' | translate }}
                            <div>
                                <img src="assets/img/icons-number/fourth-blue.svg"
                                     alt="4"><span>{{ 'WORKAREAS_SELECTOR.workitems_header7' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="back-search-result">
                        or <a
                            (click)="doneWorkAreasClick()">{{ 'WORKAREAS_SELECTOR.update_prices_and_close' | translate }}</a>
                    </div>
                </div>
                <div class="wrap-form-check" *ngFor="let mwa of masterWorkAreas">
                    <div [ngClass]="getMwaFormCheckClasses(mwa)">
                        <label class="form-check-label">
                            <input type="checkbox" class="form-check-input top-input" [checked]="mwa.selected"
                                   (change)="mwaSelectedChanged(mwa, $event)">
                            <i class="checkbox-icon"></i>
                            <img src="{{mwa.iconUrl}}" alt="" width="35">
                            {{mwa.name}}
                        </label>
                        <a *ngIf="mwa.selected" class="btn btn-bronze btn-small-extra btn-collapse"
                           (click)="onBtnCollapseClick(mwa)">{{mwa.collapseBtnText}}</a>
                    </div>
                    <div [ngClass]="getCollapseClasses(mwa)">
                        <div class="card card-block">
                            <p>{{selectWorkAreasText | translate}}</p>
                            <div *ngFor="let wa of mwa.workAreas" [ngClass]="getWaFormCheckClasses(wa)">
                                <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input"
                                           (change)="waSelectedChanged(wa, $event)" [checked]="wa.selected">
                                    <i class="checkbox-icon"></i>
                                    {{wa.name}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div [ngClass]="{'block-sqm':true, 'block-active':showSqmSelector }">
        <div class="title">
            <h5>
                <img src="assets/img/icons-number/second-dark.svg"
                     alt="2"><span>{{ 'WORKAREAS_SELECTOR.area_header3' | translate }}</span>
            </h5>
            <p>
                {{ 'WORKAREAS_SELECTOR.area_example' | translate }}
            </p>
        </div>
        <div class="active-block">
            <form action="" id="surface-form">
                <div class="actions">
                    <div class="next-step">
                        <button class="btn btn-small btn-blue" role="button" (click)="doneAreaClick(true)">
                            {{ 'WORKAREAS_SELECTOR.done' | translate }}
                        </button>
                        <div class="next-step_text">
                            {{ 'WORKAREAS_SELECTOR.update_prices_and_next' | translate }}
                            <div>
                                <img src="assets/img/icons-number/third-blue.svg"
                                     alt="3"><span>{{ 'WORKAREAS_SELECTOR.workareas_header4' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="back-search-result">
                        or <a
                            (click)="doneAreaClick()">{{ 'WORKAREAS_SELECTOR.update_prices_and_close' | translate }}</a>
                    </div>
                </div>
                <div class="sqm-block">
                    <div class="sqm-form">
                        <input type="text" id="sqm-input" [(ngModel)]="areaSqm"
                               [ngModelOptions]="{standalone: true}"
                               maxlength="8">
                        <span>{{ 'sqm' | translate }}</span>
                    </div>
                    <div class="sqm-values">
                        <div>
                            <a (click)="setAreaSqmToTextBox(15)">
                                15
                            </a>
                        </div>
                        <div>
                            <a (click)="setAreaSqmToTextBox(20)">
                                20
                            </a>
                        </div>
                        <div>
                            <a (click)="setAreaSqmToTextBox(30)">
                                30
                            </a>
                        </div>
                        <div>
                            <a (click)="setAreaSqmToTextBox(50)">
                                50
                            </a>
                        </div>
                        <div>
                            <a (click)="setAreaSqmToTextBox(70)">
                                70
                            </a>
                        </div>
                        <div>
                            <a (click)="setAreaSqmToTextBox(100)">
                                100
                            </a>
                        </div>
                        <div>
                            <a (click)="setAreaSqmToTextBox(150)">
                                150
                            </a>
                        </div>
                        <div>
                            <a (click)="setAreaSqmToTextBox(200)">
                                200
                            </a>
                        </div>
                        <div class="d-md-none d-lg-block">
                            <a (click)="setAreaSqmToTextBox(300)">
                                300
                            </a>
                        </div>
                        <div class="d-md-none d-lg-block">
                            <a (click)="setAreaSqmToTextBox(400)">
                                400
                            </a>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div [ngClass]="{'block-location':true, 'block-active':showAddressSelector }" class="block-active">
        <div class="title">
            <h5>
                <img src="assets/img/icons-number/first-dark.svg"
                     alt="1"><span>{{ 'WORKAREAS_SELECTOR.location_header2' | translate }}</span>
            </h5>
            <p>
                {{ 'WORKAREAS_SELECTOR.location_example' | translate }}
            </p>
        </div>
        <div class="active-block">
            <form action="">
                <div class="actions">
                    <div class="next-step">
                        <button class="btn btn-small btn-blue" role="button" (click)="doneAddressClick(true)">
                            {{ 'WORKAREAS_SELECTOR.done' | translate }}
                        </button>
                        <div class="next-step_text">
                            {{ 'WORKAREAS_SELECTOR.update_prices_and_next' | translate }}
                            <div>
                                <img src="assets/img/icons-number/second-blue.svg"
                                     alt="3"><span>{{ 'WORKAREAS_SELECTOR.area_header4' | translate }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="back-search-result">
                        or <a
                            (click)="doneAddressClick()">{{ 'WORKAREAS_SELECTOR.update_prices_and_close' | translate }}</a>
                    </div>
                </div>
                <div>
                    <div class="container" fxLayout="column" fxLayoutAlign="center">
                        <div fxFlex fxFlexAlign="center" class="autocomplete-container">
                            <mat-google-maps-autocomplete #addressSearchControl (onAutocompleteSelected)="onAutocompleteSelected($event)" placeholderText="{{'WORKAREAS_SELECTOR.address_input_placeholder' | translate}}" appearance="fill">
                            </mat-google-maps-autocomplete>
                        </div>
                    
                    </div>
                </div>
                <div class="map" *ngIf="showMap">
                    <google-map [options]="mapOptions" [center]="center" height="300px" width="300px">
                        <map-marker [position]="center"></map-marker>
                    </google-map>
                </div>
            </form>
        </div>
    </div>
</div>

