<div class="modal-header container">
    <h4 class="modal-title" id="exampleModalLabel">
        {{ providerName }} <i class="smaller-italic">{{ getHeaderAttrsMerged() }}</i>
    </h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body offices-docs container">
    <h5>
        {{ 'provider_docs_modal.offices' | translate }}
    </h5>
    <div class="content-block">
        <ng-container class="item" *ngFor="let office of docs.offices">
            <p class="address">
                <i>{{ office.officeTypeName }}:</i> {{ office.address }}
            </p>
            <!-- <ngx-gallery [options]="galleryOptions" [images]="office.galleryImages" *ngIf="isBrowser && office.galleryImages && office.galleryImages.length"></ngx-gallery> -->
        </ng-container>
        <p style="margin-left:2em;" *ngIf="!docs.offices || !docs.offices.length">
            {{ 'provider_docs_modal.no_offices_info' | translate }}
        </p>
    </div>
    <h5>
        {{ 'provider_docs_modal.key_docs' | translate }}
    </h5>
    <div class="content-block">
        <p *ngFor="let keyDoc of docs.keyDocs">
            <a href="{{keyDoc.url}}" target="_blank">
                <img src="assets/img/icon-pdf.svg" height="35" alt="">
                {{keyDoc.title}} <i *ngIf="keyDoc.refDate">{{ 'provider_docs_modal.doc_date' | translate }} {{ keyDoc.refDate | date }}</i>
            </a>
        </p>
        <p style="margin-left:2em;" *ngIf="!docs.keyDocs || !docs.keyDocs.length">
            {{ 'provider_docs_modal.no_keydocs_info' | translate }}
        </p>
    </div>
    <h5>
        {{ 'provider_docs_modal.services' | translate }}
    </h5>
    <div class="content-block services-row">
        <div class="service-row" *ngFor="let service of docs.additionalServices">
            <span class="free" *ngIf="service.headerText">
                {{ service.headerText }}
            </span>
            <div>
                {{ service.title }}
            </div>
            <div>
                {{ service.description }}
            </div>
        </div>
        <p style="margin-left:2em;" *ngIf="!docs.additionalServices || !docs.additionalServices.length">
            {{ 'provider_docs_modal.no_services_info' | translate }}
        </p>
    </div>
    <h5 *ngIf="docs.certs && docs.certs.length > 0">
        {{ 'provider_docs_modal.certifications' | translate }}
    </h5>
    <div class="content-block" *ngIf="docs.certs && docs.certs.length > 0">
        <p *ngFor="let doc of docs.certs">
            <a href="{{doc.url}}" target="_blank">
                <img src="assets/img/icon-pdf.svg" height="35" alt="">
                {{doc.title}} <i *ngIf="doc.refDate">{{ 'provider_docs_modal.doc_date' | translate }} {{ doc.refDate | date }}</i>
            </a>
        </p>
    </div>
    <h5 *ngIf="docs.awards && docs.awards.length > 0">
        {{ 'provider_docs_modal.awards' | translate }}
    </h5>
    <div class="content-block" *ngIf="docs.awards && docs.awards.length > 0">
        <p *ngFor="let doc of docs.awards">
            <a href="{{doc.url}}" target="_blank">
                <img src="assets/img/icon-pdf.svg" height="35" alt="">
                {{doc.title}} <i *ngIf="doc.refDate">{{ 'provider_docs_modal.doc_date' | translate }} {{ doc.refDate | date }}</i>
            </a>
        </p>
    </div>
    <h5 *ngIf="docs.mediaMentions && docs.mediaMentions.length > 0">
        {{ 'provider_docs_modal.media_mentions' | translate }}
    </h5>
    <div class="content-block" *ngIf="docs.mediaMentions && docs.mediaMentions.length > 0">
        <p *ngFor="let doc of docs.mediaMentions">
            <a href="{{doc.url}}" target="_blank">
                <img src="assets/img/icon-pdf.svg" height="35" alt="">
                {{doc.title}} <i *ngIf="doc.refDate">{{ 'provider_docs_modal.doc_date' | translate }} {{ doc.refDate | date }}</i>
            </a>
        </p>
    </div>
</div>
