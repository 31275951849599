import { Injectable, Inject } from '@angular/core';

import { LandingParamsDto } from './LandingParamsDto';
import { InitDataService } from '../init-data.service';

@Injectable()
export class LandingParamsService {
    private landingParams: LandingParamsDto;
    private landingParamsReturnedOnce: boolean = false;
    private hadLandingParams: boolean;

    constructor(initDataService: InitDataService) {
        let initData = initDataService.getInitialData();
        if (initData.landingParams) {
            this.landingParams = initData.landingParams
            this.hadLandingParams = true;
        } else {
            this.landingParams = { page: null, text1: null, text2: null, text3: null, text4: null, text5: null, text6: null, hidePrices: false };
            this.hadLandingParams = false;
        }
    }

    public getLandingParams(): LandingParamsDto {
        return this.landingParams;
    }

    public getHadLandingParams(): boolean {
        return this.hadLandingParams;
    }

    public markShowingLandingText(): void {
        if (this.landingParamsReturnedOnce) {
            this.landingParams.text1 = null;
            this.landingParams.text2 = null;
            this.landingParams.text3 = null;
            this.landingParams.text4 = null;
            this.landingParams.text5 = null;
            this.landingParams.text6 = null;
            this.landingParams.hidePrices = false;
        }
        this.landingParamsReturnedOnce = true;
    }
}
