import { Injectable } from '@angular/core';

import { FaqParagraph } from './faqParagraph';
import { DataService } from '../network/data.service';

@Injectable()
export class FaqService {

    constructor(private dataService: DataService) {
    }

    public getFaq(): Promise<FaqParagraph[]> {
        return this.dataService.get<FaqParagraph[]>('FaqData');
    }
}
