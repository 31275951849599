import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

import { GroupedMwaWithWorkItems } from '../../shared/projects/GroupedMwaWithWorkItems';
import { ProjectService } from '../../shared/projects/project.service';
import { EditProjectWorkItemOptionModalComponent } from './edit-projectworkitemoption-modal.component';
import { WorkItemDto } from '../../shared/projects/WorkItemDto';
import { CommentModalComponent } from './comment-modal.component';
import { AddProjectWorkItemOptionModalComponent } from './add-projectworkitemoption-modal.component';
import { AnalyticsService } from '../../shared/analytics.service';
import { AbTestsService } from '../../shared/abTesting/abTestsService';

@Component({
    selector: 'workitems-view',
    templateUrl: './workitems-view.component.html'
})
export class WorkItemsViewComponent implements OnInit {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public groupedMwasWithWorkItems: GroupedMwaWithWorkItems[] = [];
    editProjectWorkItemOptionModalRef!: BsModalRef;

    private commentModalRef!: BsModalRef;
    public comment: string = '';
    public addWorkItemMode: boolean = false;
    addProjectWorkItemOptionModalRef!: BsModalRef;
    private loadingWorkItemModel: boolean = false;
    totalWorkItemsCount: number = -1;
    manualWorkItemsCount: number = -1;

    constructor(public translate: TranslateService, private projectService: ProjectService,
        private modalService: BsModalService, private analyticsService: AnalyticsService, private abTestsService: AbTestsService) {
    }

    ngOnInit() {
        this.subscribeToPrices();
    }

    private subscribeToPrices(): void {
        this.projectService.pricesDto.pipe(takeUntil(this.ngUnsubscribe)).subscribe(dto => {
            this.groupedMwasWithWorkItems = dto.groupedMwasWithWorkItems;
            this.comment = dto.userComment;
            this.countWorkItems();
        });
    }

    private countWorkItems(): void {
        if (!this.groupedMwasWithWorkItems || !this.groupedMwasWithWorkItems.length) {
            return;
        }
        let total = 0;
        let manual = 0;
        for (let i = 0; i < this.groupedMwasWithWorkItems.length; ++i) {
            if (!this.groupedMwasWithWorkItems[i].workItems) {
                continue;
            }
            for (let j = 0; j < this.groupedMwasWithWorkItems[i].workItems.length; ++j) {
                ++total;
                if (this.groupedMwasWithWorkItems[i].workItems[j].locked) {
                    ++manual;
                }
            }
        }
        this.totalWorkItemsCount = total;
        this.manualWorkItemsCount = manual;
    }

    editProjectWorkItemOptionModal(workItemId: string, preselectedWorkItemOptionId: string | null = null) {
        this.projectService.getEditProjectWorkItemOptionModel(workItemId).then(model => {
            this.editProjectWorkItemOptionModalRef = this.modalService.show(EditProjectWorkItemOptionModalComponent);
            this.editProjectWorkItemOptionModalRef.content.quantity = model.quantity;
            this.editProjectWorkItemOptionModalRef.content.locked = model.locked || preselectedWorkItemOptionId != null;
            this.editProjectWorkItemOptionModalRef.content.initialQuantity = model.quantity;
            this.editProjectWorkItemOptionModalRef.content.workItemId = model.workItemId;
            this.editProjectWorkItemOptionModalRef.content.workItemName = model.workItemName;
            this.editProjectWorkItemOptionModalRef.content.workItemDescription = model.workItemDescription;
            this.editProjectWorkItemOptionModalRef.content.uom = model.uom;
            this.editProjectWorkItemOptionModalRef.content.workItemOptions = model.workItemOptions;
            if (preselectedWorkItemOptionId) {
                this.editProjectWorkItemOptionModalRef.content.selectedWorkItemOptionId = preselectedWorkItemOptionId;
            } else {
                this.editProjectWorkItemOptionModalRef.content.selectedWorkItemOptionId = model.selectedWorkItemOptionId;
            }
        });
    }

    removeWorkItem(workItemOptionId: string) {
        for (let i = 0; i < this.groupedMwasWithWorkItems.length; ++i) {
            let wis = this.groupedMwasWithWorkItems[i].workItems;
            if (wis) {
                this.groupedMwasWithWorkItems[i].workItems = wis.filter(wi => wi.workItemOptionId != workItemOptionId);
            }
        }
        this.projectService.removeWorkItemAndUpdatePrices(workItemOptionId);
        this.analyticsService.pageView();
    }

    public enableAddWorkItemMode(): void {
        this.addWorkItemMode = true;
        setTimeout(function () {
            document.getElementsByClassName('hide-add')[0].scrollIntoView({ behavior: "smooth", block: "start" });
        }, 1);
    }

    public disableAddWorkItemMode(): void {
        this.addWorkItemMode = false;
    }

    public openCommentModal() {
        this.commentModalRef = this.modalService.show(CommentModalComponent);
        this.commentModalRef.content.comment = this.comment;
    }

    public async addtWorkItemOptionModal(workItem: WorkItemDto) {
        if (this.loadingWorkItemModel) {
            return;
        }
        try {
            this.loadingWorkItemModel = true;
            let model = await this.projectService.getAddProjectWorkItemOptionModel(workItem.workItemId + "");
            this.addProjectWorkItemOptionModalRef = this.modalService.show(AddProjectWorkItemOptionModalComponent);
            this.addProjectWorkItemOptionModalRef.content.quantity = 1;
            this.addProjectWorkItemOptionModalRef.content.workItemId = workItem.workItemId;
            this.addProjectWorkItemOptionModalRef.content.workItemName = workItem.name;
            this.addProjectWorkItemOptionModalRef.content.workItemDescription = model.workItemDescription;
            this.addProjectWorkItemOptionModalRef.content.uom = model.uom;
            this.addProjectWorkItemOptionModalRef.content.workItemOptions = model.workItemOptions;
            this.addProjectWorkItemOptionModalRef.content.selectedWorkItemOptionId = model.workItemOptions[0].id;
        } finally {
            this.loadingWorkItemModel = false;
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
