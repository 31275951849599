import { Injectable, Inject, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
 
import { DataService } from '../network/data.service';
import { InitData } from '../initData';
import { UserInfo } from './UserInfo';
import { LoginResponse } from './LoginResponse';
import { ConfirmTokensResponse } from './ConfirmTokensResponse';
import { AbTestsService } from '../abTesting/abTestsService';
import { InitDataService } from '../init-data.service';

@Injectable()
export class AccountService {
    private isLoggedInSource: BehaviorSubject<boolean>;
    public isLoggedIn: Observable<boolean>;
    private userInfo: UserInfo;

    constructor(private injector: Injector, private dataService: DataService, initDataService: InitDataService) {
        let initData = initDataService.getInitialData();
        this.isLoggedInSource = new BehaviorSubject<boolean>(initData.isLoggedIn);
        this.isLoggedIn = this.isLoggedInSource.asObservable();
        this.userInfo = initData.userInfo;
    }

    public register(email: string, phoneNumber: string, password: string, firstName: string, lastName: string): Promise<string> {
        return this.dataService.post<string>('Account/Register', {
            email,
            phoneNumber,
            password,
            firstName,
            lastName
        }).then((error: any) => {
            if (!error) {
                this.isLoggedInSource.next(true);
                this.userInfo.email = email;
                this.userInfo.phoneNumber = phoneNumber
                this.userInfo.firstName = firstName;
                this.userInfo.lastName = lastName;
            }
            return Promise.resolve(error);
        });
    }

    public registerLight(email: string, password: string): Promise<string> {
        return this.dataService.post<string>('Account/RegisterLight', {
            email,
            password
        }).then((error: string) => {
            if (!error) {
                this.isLoggedInSource.next(true);
                this.userInfo.email = email;
            }
            return Promise.resolve(error);
        });
    }

    public async confirmTokens(emailToken: string, phoneToken: string) {
        let result = await this.dataService.post<ConfirmTokensResponse>('Account/ConfirmTokens', { emailToken, phoneToken });
        if (!result.emailError) {
            this.userInfo.emailConfirmed = true;
        }
        if (!result.phoneNumberError) {
            this.userInfo.phoneNumberConfirmed = true;
        }
        return result;
    }

    public async confirmEmailFromEmail(token1: string, token2: string): Promise<string> {
        let error = await this.dataService.post<string>('Account/ConfirmEmailFromEmail', { userToken: token1, validationToken: token2 });
        if (!error) {
            this.userInfo.emailConfirmed = true;
        }
        return error;
    }

    public login(email: string, password: string): Promise<string> {
        return this.dataService.post<LoginResponse>('Account/Login', { email, password }).then((response: LoginResponse) => {
            if (!response.error) {
                this.isLoggedInSource.next(true);
                this.userInfo = response.userInfo;
            }
            return Promise.resolve(response.error);
        });
    }

    public async logout(): Promise<any> {
        await this.dataService.post<LoginResponse>('Account/Logout', {});
        this.isLoggedInSource.next(false);
    }

    public async sendSmsWithCode(phoneNumber: string): Promise<string> {
        var result = await this.dataService.post<string>('Account/SendSmsWithCode', { phoneNumber });
        if (!result) {
            this.userInfo.phoneNumber = phoneNumber;
        }
        return result;
    }

    public getUserInfo(): UserInfo {
        return this.userInfo ? this.userInfo : new UserInfo();
    }

    public resendEmailCode(email: string) {
        return this.dataService.post<string>('Account/SendEmailConfirmation', { email });
    }

    public sendPasswordRecoveryEmail(email: string): Promise<string> {
        return this.dataService.post<string>('Account/SendPasswordRecoveryEmail', { email });
    }

    public resetPassword(token: string, email: string, password: string): Promise<string> {
        return this.dataService.post<string>('Account/ResetPassword', { token, email, password });
    }

    readonly cookiesAcceptedLSKey = 'cookiesAccepted';
    public getCookiesAccepted() {
        if (this.isLoggedInSource.getValue()) {
            return true;
        }
        if (!localStorage) {
            return false;
        }
        return localStorage.getItem(this.cookiesAcceptedLSKey) == 'true';
    }

    public acceptCookies() {
        if (localStorage) {
            localStorage.setItem(this.cookiesAcceptedLSKey, 'true');
        }
        this.dataService.post('Account/AcceptCookies', {});
    }

    validateEmail(email: string): boolean {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
}
