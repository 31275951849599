import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core'
import { Subject, takeUntil } from 'rxjs';

import { ProjectService } from '../../shared/projects/project.service';
import { NotificationService } from '../../shared/notificaton.service';
import { DisplayService } from '../../shared/display.service';
import { GroupedMwaWithWorkItems } from '../../shared/projects/GroupedMwaWithWorkItems';
import { ProviderWorkItemPriceDto } from '../../shared/projects/WorkItemPricesDto';
import { ProjectPriceItem } from '../../shared/projects/ProjectPriceItem';
import { AccountService } from '../../shared/account/account.service';
import { SeoService } from '../../shared/seo.service';

@Component({
    templateUrl: './offer-preview.page.html'
})
export class OfferPreviewPageComponent implements OnInit {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public groupedMwasWithWorkItems: GroupedMwaWithWorkItems[] = [];
    private providerId: string | undefined;
    seletedOffer: ProjectPriceItem | undefined;
    offers: ProjectPriceItem[] = [];

    nextProviderName: string | undefined;
    nextProviderId: string | undefined;

    currentDate: string | undefined;
    validUntilDate: string | undefined;
    shownMore: boolean = false;

    currencySign: string | undefined;
    totalPrice: number | undefined;

    constructor(public translate: TranslateService, private displayService: DisplayService,
        private router: Router, private projectService: ProjectService, private accountService: AccountService,
        private seoService: SeoService, private activatedRoute: ActivatedRoute) {
    }

    ngOnInit() {
        this.translate.get(['offer_preview.title', 'offer_preview.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['offer_preview.title'], res['offer_preview.desc']);
        });
        this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            this.providerId = params['id'];
            this.loadOfferPreview();
        });
        this.subscribeToPrices();
    }

    private loadOfferPreview(): void {
        if (!this.providerId) {
            return;
        }

        let selectedProvider = this.offers.find(p => p.providerId == this.providerId);
        if (this.providerId && !selectedProvider && this.offers.length) {    //no offer for this provider
            this.router.navigateByUrl('/search-results');
        }
        if (!selectedProvider) {
            return;
        }

        this.seletedOffer = selectedProvider;
        let nextOffer = this.getNextOffer();
        if (nextOffer) {
            this.nextProviderId = nextOffer.providerId;
            this.nextProviderName = nextOffer.providerName;
        } else {
            this.nextProviderId = undefined;
            this.nextProviderName = undefined;
        }
    }

    private subscribeToPrices(): void {
        this.projectService.pricesDto.pipe(takeUntil(this.ngUnsubscribe)).subscribe(dto => {
            if (dto.prices && dto.prices.length) {
                this.offers = dto.prices;
                this.groupedMwasWithWorkItems = dto.groupedMwasWithWorkItems.filter(gmwa => gmwa.workItems && gmwa.workItems.length);
                this.currentDate = dto.currentDate;
                this.validUntilDate = dto.offersValidUntil;
            }

            this.loadOfferPreview();
        });
    }

    private getNextOffer(): ProjectPriceItem | undefined {
        let selectedProviders = this.projectService.getSelectedProviders();
        if (!selectedProviders || !selectedProviders.length || !this.providerId) {
            return undefined;
        }

        let selectedProviderIndex = selectedProviders.findIndex(p => p.providerId == this.providerId);
        let nextIndex = selectedProviderIndex + 1;
        if (nextIndex >= selectedProviders.length) {
            nextIndex -= selectedProviders.length;
        }

        let nextProvider = selectedProviders[nextIndex];
        if (this.providerId == nextProvider.providerId) {
            return undefined;
        }

        return nextProvider;
    }
}
