import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import { SeoService } from '../../shared/seo.service';
import { FeedbackService } from '../../shared/feedback/feedback.service';
import { DisplayService } from '../../shared/display.service';
import { AccountService } from '../../shared/account/account.service';
import { ProviderScoreModel } from '../../shared/feedback/ProviderScoreModel';
import { ProviderService } from '../../shared/providers/ProviderService';

@Component({
    selector: 'feedback-provider-initiated',
    templateUrl: './feedback-provider-initiated.component.html'
})
export class FeedbackProviderInitiatedComponent implements OnInit {
    ngUnsubscribe: Subject<void> = new Subject<void>();
    public score: ProviderScoreModel;
    public step: number = 1;
    termsAccepted: boolean = false;
    public firstName: string | undefined;
    public lastName: string | undefined;
    public email: string | undefined;
    public price: number = 3500;
    public password: string | undefined;
    public providerId!: string;

    constructor(public translate: TranslateService, private seoService: SeoService, private feedbackService: FeedbackService,
        private providerService: ProviderService, private activatedRoute: ActivatedRoute, private router: Router,
        private displayService: DisplayService, private accountService: AccountService) {
        this.score = new ProviderScoreModel();
    }

    ngOnInit() {
        this.score = new ProviderScoreModel();
        this.translate.get(['feedback_provider_score.title', 'feedback_provider_score.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['feedback_provider_score.title'], res['feedback_provider_score.desc']);
        });
        this.activatedRoute.data.subscribe((data: any) => {
            this.score.providerName = data.dto;
        });
        this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            let id = params['id'];
            this.providerId = id;
        });
    }

    public setScore(type: string, value: number): void {
        (this.score as any)[type] = value;
    }

    public gotoStep(num: number): void {
        if (this.step == 1 && !this.score.allScoresSet()) {
            return;
        }
        this.step = num;
    }

    public allRegisterDataSet(): boolean {
        return this.termsAccepted && !!this.firstName && !!this.lastName && !!this.email && this.accountService.validateEmail(this.email);
    }

    setPrice(num: number) {
        this.price = num;
    }

    async submit() {
        if (!this.allRegisterDataSet()) {
            return;
        }
        let requiresPassword = await this.feedbackService.saveScoreProviderInitiated(this.providerId, this.firstName as string, this.lastName as string, this.email as string, this.password as string, this.price, this.score);
        if (requiresPassword && !this.password) {
            this.gotoStep(3);
        } else if (!requiresPassword && this.password) {
            this.gotoStep(5);
        } else {
            this.gotoStep(4);
        }
    }
}
