import { Component, OnInit, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SeoService } from '../../shared/seo.service';
import { DisplayService } from '../../shared/display.service';
import { ActivatedRoute } from '@angular/router';
import { ServiceUseDto } from '../../shared/service-use/ServiceUseDto';
import { DataService } from '../../shared/network/data.service';

@Component({
    selector: 'service-use.component',
    templateUrl: './service-use.component.html'
})
export class ServiceUseComponent implements OnInit {
    terms: string | undefined;
    privacy: string | undefined;
    cookies: string | undefined;

    constructor(public translate: TranslateService, private seoService: SeoService, private dataService: DataService,
        private displayService: DisplayService, private activatedRoute: ActivatedRoute, private ngZone: NgZone) {
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data: any) => {
            let dto = data.dto as ServiceUseDto
            this.terms = dto.terms;
            this.privacy = dto.privacy;
            this.cookies = dto.cookies;
        });
        this.activatedRoute.fragment.subscribe((fragment: any) => {
            this.scrollToFragment(fragment);
        })
        this.translate.get(['service_use.title', 'service_use.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['service_use.title'], res['service_use.desc']);
        });
    }

    scrollToFragment(fragment: string): void {
        this.displayService.runOutsideAngular(this.ngZone, () => {
            let elem = document.getElementById(fragment);
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }, 200);
    }
}
