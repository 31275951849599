<div class="modal-blue">
    <div class="modal-header">
        <h4 class="modal-title" id="editModalLabel">{{workItemName}}</h4>
        <p>
            {{workItemDescription}}
        </p>
    </div>
    <div class="modal-body">
        <form>
            <div class="quantity-block">
                <div class="row">
                    <div class="col-6 wrap-label">
                        <label for="quantity">
                            <b>
                                {{ 'edit_projectworkitemoption_modal.quantity' | translate }}
                            </b>
                        </label>
                    </div>
                    <div class="col-6">
                        <input id="quantity" type="number" min="1" [(ngModel)]="quantity" name="fakename">
                        <span>
                        {{uom}}
                    </span>
                    </div>
                </div>
            </div>
            <div class="type-block" *ngIf="workItemOptions && workItemOptions.length>1">
                <div>
                    <b>
                        {{ 'edit_projectworkitemoption_modal.type' | translate }}
                    </b>
                </div>
                <div class="radio-block">
                    <div class="radio-block-row row" *ngFor="let workItemOption of workItemOptions">
                        <div class="col-12">
                            <div class="form-check">
                                <input type="radio" id="rb{{workItemOption.id}}" name="radio-group"
                                       [value]="workItemOption.id" [(ngModel)]="selectedWorkItemOptionId"
                                       [checked]="selectedWorkItemOptionId===workItemOption.id">
                                <label for="rb{{workItemOption.id}}">{{workItemOption.name}}</label>
                            </div>
                            <div class="form-check">
                                <i>
                                    {{workItemOption.description}}
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <div class="actions" [ngClass]="{ 'loading':loading }">
            <button class="btn btn-transparent-blue btn-small" (click)="close()">{{
                'edit_projectworkitemoption_modal.cancel' | translate }}
            </button>
            <button class="btn btn-blue btn-small" (click)="save()">{{ 'edit_projectworkitemoption_modal.save' |
                translate }}
            </button>
        </div>
    </div>
</div>
