import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AccountService } from '../../shared/account/account.service';
import { NotificationService } from '../../shared/notificaton.service';
import { SeoService } from '../../shared/seo.service';
import { RenoRouter } from '../../shared/RenoRouter';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
    public loading: boolean = false;
    public email: string | null = null;
    public password: string | undefined;
    public passwordConfirm: string | undefined;
    private token: string | null = null;
    public error: string | null = null;
    public success: boolean = false;

    constructor(public translate: TranslateService, private accountService: AccountService,
        private route: ActivatedRoute, private notifier: NotificationService,
        private seoService: SeoService) {
    }

    ngOnInit() {
        this.translate.get(['reset_password.title', 'reset_password.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['reset_password.title'], res['reset_password.desc']);
        });
        this.token = this.route.snapshot.queryParamMap.get('token');
        this.email = this.route.snapshot.queryParamMap.get('email');
    }

    continueButtonEnabled(): boolean {
        return !!this.password && !!this.passwordConfirm && (this.password === this.passwordConfirm);
    }

    passwordIsGood(): boolean {
        return !!this.password && this.password.length > 6;
    }

    btnContinueClick(): void {
        if (this.loading) {
            return;
        }
        if (!this.validateForm()) {
            return;
        }
        this.loading = true;
        let self = this;
        this.accountService.resetPassword(this.token as string, this.email as string, this.password as string).then(error => {
            if (error) {
                self.showError(error);
                self.loading = false;
            } else {
                this.success = true;
                this.translate.get('reset_password.success').subscribe((res: string) => {
                    self.notifier.showSuccess(res);
                });
            }
        }).catch(error => {
            self.loading = false;
        });
    }

    public validateForm(): boolean {
        let self = this;
        if (!this.email) {
            this.translate.get('registration.invalid_email').subscribe((res: string) => {
                self.showError(res);
            });
            return false;
        }
        if (!this.password || this.password.length < 6) {
            this.translate.get('registration.password_too_short').subscribe((res: string) => {
                self.showError(res);
            });
            return false;
        }
        if (this.password != this.passwordConfirm) {
            this.translate.get('registration.password_and_confirm_are_different').subscribe((res: string) => {
                self.showError(res);
            });
            return false;
        }
        return true;
    }

    showError(text: string) {
        console.error(text);
        this.error = text;
    }

    removeError() {
        this.error = null;
    }

}
