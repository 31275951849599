import { Inject, inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

import { BaseResponse } from './BaseResponse';
import { environment } from '../../../environments/environment';
import { NotificationService } from '../notificaton.service';

@Injectable()
export class DataService {
    private headers: HttpHeaders;
    private baseUrl: string;
    private serverUrl: string;
    private throttledRequests = new Map<string, Promise<any>>();

    constructor(private http: HttpClient, private notifier: NotificationService, @Inject(PLATFORM_ID) private platformId: Object, @Optional() @Inject('ORIG_IP') public origIpAddress: any, @Optional() @Inject('FWDED_FOR') public forwardedFor: any) {
        this.baseUrl = environment.baseUrl /*+ l10nService.getCurrentLang()*/;
        this.serverUrl = environment.baseUrl.replace(/\/+$/, "");
        //console.log('base url = ' + this.baseUrl);
        let headers: any = { 'Content-Type': 'application/json' };
        if (isPlatformBrowser(platformId) && typeof(localStorage) !== 'undefined' && localStorage) {
            
        }
        //console.log('isPlatformServer = ' + isPlatformServer(platformId));
        //console.log('origIpAddress = ' + origIpAddress);
        //console.log('forwardedFor = ' + forwardedFor + ' (' + typeof(forwardedFor) + ')');
        //if (isPlatformServer(platformId)) {
        let xFwdedFor = forwardedFor ? forwardedFor : origIpAddress;
        if (xFwdedFor) {
            let cleanIP = xFwdedFor.replace(/[^a-zA-Z0-9\-_.]/g, '');
            if (cleanIP) {
                headers['X-Forwarded-For'] = cleanIP;
            }
        }
        //}
        this.headers = new HttpHeaders(headers);
    }

    public get<T>(url: string, throttle: boolean = false, queryParams: any = undefined): Promise<T> {
        let promise: Promise<any> | undefined;
        url = this.removeUndefinedFromUrl(url);
        if (throttle) {
            promise = this.throttledRequests.get(url);
            /*if (promise) {
                console.log('Throttling ' + url);
            }*/
        }
        if (!promise) {
            promise = this.http
                .get(this.baseUrl + '/' + url, { headers: this.headers, withCredentials: true, params: queryParams })
                .toPromise();
            if (throttle) {
                this.throttledRequests.set(url, promise);
            }
        }
        return promise.then(
            res => {
                this.throttledRequests.delete(url);
                let response = res as BaseResponse;
                if (!response) {
                    throw new Error("Error");
                }
                if (response.error) {
                    if (response.showAsWarning) {
                        this.notifier.showWarning(response.error);
                    } else {
                        throw new Error(response.error);
                    }
                }
                return Promise.resolve(response.data as T);
            }).catch(ex => {
                this.notifier.showError(`Error in GET ${url} : ` + this.extractErrorMessage(ex));
                throw ex;
            });
    }

    private removeUndefinedFromUrl(url: string): string {
        const urlParts = url.split('?');
        if (urlParts.length !== 2) {
          return url;
        }
      
        const queryString = urlParts[1];
        const params = new URLSearchParams(queryString);
      
        const validParams: [string, string][] = [];
      
        params.forEach((value, key) => {
          if (value !== 'undefined') {
            validParams.push([key, value]);
          }
        });
      
        const updatedQueryString = validParams.map(([key, value]) => `${key}=${value}`).join('&');
      
        if (updatedQueryString === '') {
          return urlParts[0];
        }
      
        return `${urlParts[0]}?${updatedQueryString}`;
      }
    

    public post<T>(url: string, body: any): Promise<T> {
        console.log('POST to ' + url);
        Object.keys(body).forEach(key => body[key] === undefined ? delete body[key] : {});
        return this.http
            .post(this.baseUrl + '/' + url, body, { headers: this.headers, withCredentials: true })
            .toPromise().then(
            res => {
                let response = res as BaseResponse;
                if (response.error) {
                    if (response.showAsWarning) {
                        this.notifier.showWarning(response.error);
                    } else {
                        throw new Error(response.error);
                    }
                }
                return Promise.resolve(response.data as T);
            }).catch(ex => {
                this.notifier.showError(this.extractErrorMessage(ex));
                throw ex;
            });
    }

    public delete<T>(url: string): Promise<T> {
        return this.http
            .delete(this.baseUrl + '/' + url, { headers: this.headers, withCredentials: true })
            .toPromise().then(
                res => {
                    let response = res as BaseResponse;
                    if (response.error) {
                        throw new Error(response.error);
                    }
                    return Promise.resolve(response.data as T);
                }).catch(ex => {
                    this.notifier.showError(this.extractErrorMessage(ex));
                    throw ex;
                });
    }

    public postFile<T>(url: string, fileToUpload: File, frmData: any = null): Promise<T> {
        const formData: FormData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        if(frmData) {
            for (let key in frmData) {
                formData.append(key, frmData[key]);
              }
        }
        return this.http
          .post(this.baseUrl + '/' + url, formData)
          .toPromise().then(
            res => {
                let response = res as BaseResponse;
                if (response.error) {
                    throw new Error(response.error);
                }
                return Promise.resolve(response.data as T);
            }).catch(ex => {
                this.notifier.showError(this.extractErrorMessage(ex));
                throw ex;
            });
    }

    public getCookieValue(a: any) {
        var b = document.cookie.match('(^|[^;]+)\\s*' + a + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }

    private extractErrorMessage(errorObj: any) {
        let possibleProperties = ['error', 'statusText', 'message'];
        for(let i = 0; i < possibleProperties.length; ++i) {
            let possibleMsg = errorObj[possibleProperties[i]];
            if (possibleMsg && (typeof possibleMsg === 'string' || possibleMsg instanceof String)) {
                return possibleMsg;
            }
        }
        return errorObj;
    }

    public getBaseUrl(): string {
        return this.baseUrl;
    }

    public getServerUrl(): string {
        return this.serverUrl;
    }
}
