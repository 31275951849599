import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { Location } from './Location';
import { LocationSource } from './LocationSource';
import { Market } from './Market';
import { CountryMarket } from './CountryMarket';
import { InitData } from '../initData';
import { DataService } from '../network/data.service';
import { ActionTracker } from '../projects/ActionTracker';
import { InitDataService } from '../init-data.service';

@Injectable()
export class LocationService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    private initLocation: Location;
    public currentLocation: Observable<Location>;
    private currentLocationSource: BehaviorSubject<Location>;
    public markets: Observable<Market[]>;
    private marketsSource: BehaviorSubject<Market[]>;
    public countryMarkets: Observable<CountryMarket[]>;
    private countryMarketsSource: BehaviorSubject<CountryMarket[]>;

    constructor(private http: HttpClient, initDataService: InitDataService, private dataService: DataService,
        private actionTracker: ActionTracker) {
        let initData = initDataService.getInitialData();
        this.initLocation = initData.location;
        this.initLocation.source = LocationSource.Server;
        this.currentLocationSource = new BehaviorSubject<Location>(initData.location);
        this.currentLocation = this.currentLocationSource.asObservable();
        this.countryMarketsSource = new BehaviorSubject<CountryMarket[]>(initData.countryMarkets);
        this.countryMarkets = this.countryMarketsSource.asObservable();
        this.marketsSource = new BehaviorSubject<Market[]>(this.selectMarkets(initData.countryMarkets));
        this.markets = this.marketsSource.asObservable();
    }

    private selectMarkets(countryMarkets: CountryMarket[]): Market[] {
        return countryMarkets
            .map(function (cm) { return cm.markets; })
            .reduce(function (a, b) { return a.concat(b); });
    }

    public getCurrentLocation(): Location {
        return this.currentLocationSource.getValue();
    }

    public async setCurrentLocation(newLocation: Location) {
        for (let market of this.marketsSource.getValue()) {
            market.isActive = false;
        }
        if (newLocation.source != LocationSource.Server) {
            let result = await this.dataService.get<boolean>(`Providers/haveEnoughProviders?latitude=${newLocation.latitude}&longitude=${newLocation.longitude}`);
            newLocation.serviceAvailable = result;
        }
        this.currentLocationSource.next(newLocation);
        this.actionTracker.incUnsavedChangesCount();
    }

    public isAddressSameAsOneOfMarkets(address: string) {
        var markets = this.marketsSource.getValue();
        return !!markets.find(m => m.localName == address);
    }

    public markActiveMarket(markets: Market[]) {
        let currentLocation = this.currentLocationSource.getValue();
        if (currentLocation.address && !this.isAddressSameAsOneOfMarkets(currentLocation.address)) {
            return;     //showing 'my location', don't mark closest market
        }
        let minDistance = Number.MAX_SAFE_INTEGER;
        let closestMarket: Market | null = null;
        for (let market of markets)
        {
            let distance = this.getDistanceFromLatLonInKm(currentLocation.latitude, currentLocation.longitude, market.latitude, market.longitude);
            if (distance < minDistance) {
                minDistance = distance;
                closestMarket = market;
            }
        }
        let maxDistanceToGetIntoMarket = 50.0;
        if (minDistance <= maxDistanceToGetIntoMarket && closestMarket != null) {
            closestMarket.isActive = true;
        }
    }

    public markActiveCountryMarket(countryMarkets: CountryMarket[]) {
        this.markActiveMarket(this.selectMarkets(countryMarkets));
    }

    private getDistanceFromLatLonInKm(lat1: number, lon1: number, lat2: number, lon2: number) {
        function deg2rad(deg: number) {
            return deg * (Math.PI / 180)
        }

        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2 - lat1);  // deg2rad below
        var dLon = deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
            ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d;
    }

}
