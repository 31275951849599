import { Injectable, Inject, Injector } from '@angular/core';
import { ToastrService } from "ngx-toastr";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class NotificationService {
    private errorHeader: string = 'Error';
    private warnHeader: string = 'Warning';
    private successHeader: string = 'Success';
    private infoHeader: string = 'Info';

    constructor(private notifier: ToastrService, private translate: TranslateService) {
        translate.get('error').subscribe((res: string) => {
            this.errorHeader = res;
        });       
         translate.get('warn').subscribe((res: string) => {
            this.warnHeader = res;
        });
        translate.get('success').subscribe((res: string) => {
            this.successHeader = res;
        });
        translate.get('info').subscribe((res: string) => {
            this.infoHeader = res;
        });
    }

    public showError(errorText: string): void {
        if (errorText && errorText.lastIndexOf('<!DOCTYPE html', 0) === 0) {
            this.translate.get('unknown_error').subscribe((res: string) => {
                this.notifier.error(res, this.errorHeader, {
                    timeOut: 8000,
                    extendedTimeOut: 6000,
                    progressBar: true,
                    tapToDismiss: true
                });
            });
        } else {
            this.notifier.error(errorText, this.errorHeader, {
                timeOut: 8000,
                extendedTimeOut: 6000,
                progressBar: true,
                tapToDismiss: true
            });
        }
    }

    public showWarning(text: string): void {
        this.notifier.warning(text, this.warnHeader, {
            timeOut: 12000,
            extendedTimeOut: 8000,
            progressBar: true,
            tapToDismiss: true
        });
    }

    public showSuccess(text: string, header: string = this.successHeader, timeout: number = 8000): void {
        this.notifier.success(text, header, {
            timeOut: timeout,
            extendedTimeOut: 6000,
            progressBar: true,
            tapToDismiss: true
        });
    }

    public showInfo(text: string): void {
        this.notifier.info(text, this.infoHeader, {
            timeOut: 12000,
            extendedTimeOut: 6000,
            tapToDismiss: true
        });
    }
}
