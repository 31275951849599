import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil} from 'rxjs/operators';

import { DisplayService } from '../../shared/display.service';
import { AccountService } from '../../shared/account/account.service';
import { ProjectService } from '../../shared/projects/project.service';
import { ActionTracker } from '../../shared/projects/ActionTracker';

@Component({
    selector: 'save-project-modal',
    templateUrl: './save-project-modal.component.html'
})
export class SaveProjectModalComponent implements OnInit {
    ngUnsubscribe: Subject<void> = new Subject<void>();
    loading: boolean = false;

    public projectName: string | undefined;

    public email: string = '';
    public password: string = '';
    public passwordConfirm: string = '';
    public termsAccepted: boolean = false;

    public isRegistered: boolean = false;

    constructor(public bsModalRef: BsModalRef, private displayService: DisplayService, private accountService: AccountService,
        private projectService: ProjectService, private actionTracker: ActionTracker) {
        
    }

    ngOnInit() {
        this.accountService.isLoggedIn.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: boolean) => {
            this.isRegistered = res;
        });
    }

    public async save() {
        if (this.loading || !this.isBtnSaveEnabled()) {
            return;
        }
        if (!this.isRegistered) {
            await this.accountService.registerLight(this.email, this.password);
        }
        await this.projectService.saveProject(this.projectName);
        this.close();
    }

    close() {
        this.actionTracker.hideSaveModal();
        this.bsModalRef.hide();
    }

    passwordIsGood(): boolean {
        return !!this.password && this.password.length >= 6;
    }

    isBtnSaveEnabled(): boolean {
        return !!this.projectName && (this.isRegistered || this.termsAccepted && !!this.password && this.passwordIsGood() && (this.password === this.passwordConfirm));
    }

}
