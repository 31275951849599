<ul>
    <li *ngFor="let offer of selectedOffers">
        <span class="price">{{offer.currencySign}}&nbsp;{{offer.totalPrice | number:'1.0-0'}} {{ 'search_results.offer' | translate }}</span>
        <span class="wrap">
            <span class="provider">{{ 'search_results.from' | translate }} {{offer.providerName}}</span>
            <span class="action">
                <a [routerLink]="'/offer-preview/' + offer.providerId">{{ 'provider_details.preview' | translate }}</a><a (click)="deselectProvider(offer.providerId)">{{ 'search_results.unselect_provider' | translate }}</a>
            </span>
        </span>
    </li>
    <ng-container *ngIf="showMissing">
        <li class="red" *ngIf="!selectedOffers.length">
            <span class="price">{{ 'selected_offers.not_selected' | translate }}</span>
            <span class="wrap">
                {{ 'selected_offers.1st_offer_not_selected' | translate }}
            </span>
        </li>
        <li class="red" *ngIf="selectedOffers.length <= 1">
            <span class="price">{{ 'selected_offers.not_selected' | translate }}</span>
            <span class="wrap">
                {{ 'selected_offers.2nd_offer_not_selected' | translate }}
            </span>
        </li>
        <li class="red" *ngIf="selectedOffers.length <= 2">
            <span class="price">{{ 'selected_offers.not_selected' | translate }}</span>
            <span class="wrap">
                {{ 'selected_offers.3rd_offer_not_selected' | translate }}
            </span>
        </li>
    </ng-container>
</ul>