<div class="edit-info" *ngIf="totalWorkItemsCount > 0">
    <h4>
        {{ 'WORKAREAS_SELECTOR.workitems_header8' | translate:{workItemsCount: totalWorkItemsCount} }}
    </h4>
    <div class="details">
        <div>
            <b class="yellow">{{totalWorkItemsCount-manualWorkItemsCount}}</b> {{'workitems_list.expert_system_workitems' | translate }}
        </div>
        <div>
            <b class="green">{{manualWorkItemsCount}}</b> {{'workitems_list.manual_workitems' | translate }}
        </div>
    </div>
    <div class="actions">
        <a class="btn btn-small btn-transparent-brown" (click)="enableAddWorkItemMode()">{{ 'WORKAREAS_SELECTOR.add_workitem' | translate }}</a>
        <a langRouterLink routerLink="/project-uploads" class="btn btn-small btn-transparent-brown">{{ 'WORKAREAS_SELECTOR.add_comment' | translate }}</a>
    </div>
</div>
<div class="list-block">
    <ng-container *ngFor="let mwag of groupedMwasWithWorkItems">
        <ng-container *ngIf="mwag.workItems && mwag.workItems.length || addWorkItemMode">
            <h6>
                {{ mwag.masterWorkAreasNames }} <span *ngIf="mwag.workItems && mwag.workItems.length">({{mwag.workItems.length}})</span>
            </h6>
            <ul>
                <li *ngFor="let wi of mwag.workItems">
                    <div class="top-part">
                        <b class="title cursor-pointer" (click)="editProjectWorkItemOptionModal(wi.workItemId)">{{ wi.workItemName }}:</b>
                        <div class="wrap-actions">
                            <b [ngClass]="{'orange':!wi.locked, 'green':wi.locked, 'text-badge':true}" (click)="editProjectWorkItemOptionModal(wi.workItemId)">{{ wi.qty }} {{ wi.uomName }}</b>
                            <a class="edit-popup-link"
                               (click)="editProjectWorkItemOptionModal(wi.workItemId)"> <i class="fa fa-pencil" aria-hidden="true"></i>
                            </a>
                            <a class="remove" (click)="removeWorkItem(wi.workItemOptionId)">{{'workitems_list.remove' | translate }}</a>
                        </div>
                    </div>
                    <div class="option-labels" *ngIf="wi.allWorkItemOptions && wi.allWorkItemOptions.length > 1">
                        <div class="label" *ngFor="let wio of wi.allWorkItemOptions">
                            <b *ngIf="wio.workItemOptionId == wi.workItemOptionId" (click)="editProjectWorkItemOptionModal(wi.workItemId)">
                                {{ wio.label }}
                            </b>
                            <a *ngIf="wio.workItemOptionId != wi.workItemOptionId"
                               (click)="editProjectWorkItemOptionModal(wi.workItemId, wio.workItemOptionId)">
                                {{ wio.label }}
                            </a>
                        </div>
                        <div class="label">
                            <a (click)="editProjectWorkItemOptionModal(wi.workItemId)">
                                {{ 'workitems_list.more_options' | translate }}
                            </a>
                        </div>
                    </div>
                    <p>
                        {{ wi.workItemOptionDescription ? wi.workItemOptionDescription : wi.workItemDescription }}
                    </p>
                    <span class="quantity">{{ wi.qty }} {{ wi.uomName }}</span>
                </li>
                <ng-container *ngIf="addWorkItemMode">
                    <li *ngFor="let wi of mwag.workItemsToAdd">
                        <div class="add-line">
                            <b>{{ wi.name }}:</b>&nbsp;<a class="text-badge blue" (click)="addtWorkItemOptionModal(wi)">{{'workitems_list.add' | translate }}</a> <a (click)="disableAddWorkItemMode()" class="hide-add">{{ 'workitems_list.hide' | translate }}</a>
                            <span>
                                {{ wi.description }}
                            </span>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </ng-container>
    </ng-container>
</div>
