import { Component } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { DisplayService } from '../../shared/display.service';
import { WorkAreasService } from '../../shared/workareas/work-areas.service';
import { AccountService } from '../../shared/account/account.service';
import { ProjectService } from '../../shared/projects/project.service';
import { ActionTracker } from '../../shared/projects/ActionTracker';

@Component({
    selector: 'top-menu',
    templateUrl: './top-menu.component.html'
})
export class TopMenuComponent {
    isLoggedIn: boolean = false;
    showMobileMenu: boolean = false;
    isModalMode: boolean = false;
    hasOffers: boolean = false;
    hasAskedForFinishedPrices: boolean = false;
    hasProject: boolean = false;
    private finishedPricesSub: Subscription | undefined;
    
    constructor(private workAreasService: WorkAreasService, private displayService: DisplayService,
        private accountService: AccountService, private projectService: ProjectService,
        private actionTracker: ActionTracker) {
        this.subscribeToIsLoggedIn();
        this.subscribeToModalMode();
        this.subscribeToFinishedPrices();
        this.subscribeToPrices();
    }
    
    private subscribeToIsLoggedIn() {
        this.accountService.isLoggedIn.subscribe(isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
        });
    }

    private subscribeToModalMode() {
        this.displayService.isModalMode.subscribe(mode => {
            this.isModalMode = mode;
        });
    }

    private subscribeToPrices() {
        if (this.displayService.isBrowser()) {
            this.projectService.pricesDto.subscribe(dto => {
                this.hasProject = !!dto.prices.length;
            });
        }
    }
    
    private subscribeToFinishedPrices(): void {
        if (this.displayService.isBrowser()) {
            this.finishedPricesSub = this.projectService.finishedPricesDto.subscribe(dto => {
                this.hasOffers = !!dto.prices.length;
                if (this.isLoggedIn && !this.hasAskedForFinishedPrices && !this.hasOffers && this.displayService.isBrowser()) {
                    this.hasAskedForFinishedPrices = true;
                    this.projectService.updatePricesForFinishedProject();
                }
            });
        }
    }

    public return() : void {
        this.workAreasService.setShowMobileWorkAreasSelect(false);
    }

    public async btnLogoutClick() {
        await this.accountService.logout();
        this.projectService.clearSavedSelectedProviders();
        this.actionTracker.resetUnsavedChangesCountSincePricesUpdate();
        this.actionTracker.resetUnsavedChangesCountSinceProjectSave();
        if (this.displayService.isBrowser()) {
            window.location.reload();
        }
    }

    public toggleMobileMenu() {
        this.showMobileMenu = !this.showMobileMenu;
    }

    public disableModalMode() {
        this.displayService.setModalMode(false);
    }

    ngOnDestroy() {
        if (this.finishedPricesSub) {
            this.finishedPricesSub.unsubscribe();
        }
    }
}
