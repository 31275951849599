import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ProjectService } from '../../shared/projects/project.service';
import { Router } from '@angular/router';

@Component({
    selector: 'features-set',
    templateUrl: './features-set.component.html'
})
export class FeaturesSetComponent {
    public loading: boolean = false;

    constructor(public translate: TranslateService, private projectService: ProjectService, private router: Router) {
        this.translate = translate;
    }

    async openResultsWithLocation() {
        await this.openResultsWithTab('location');
    }

    async openResultsWithWaSelector() {
        await this.openResultsWithTab('workareas');
    }

    private async openResultsWithTab(tabname: string) {
        if (this.loading) {
            return;
        }
        try {
            this.loading = true;
            await this.projectService.updatePricesForCurrentProject();
            this.router.navigateByUrl('/search-results?tab=' + tabname);
        } finally {
            this.loading = false;
        }
    }
}
