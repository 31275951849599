import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { InitDataService } from './init-data.service';
import { L10nInitData } from './languages/L10nInitData';
import { Language } from './languages/Language';

@Injectable({
    providedIn: 'root',
})
export class L10nService {
    public static supportedLangs = ['en', 'it'];
    private currentLang: string;
    private l10nInitData: L10nInitData;

    constructor(@Inject(DOCUMENT) document: any, initDataService: InitDataService) {
        this.l10nInitData = initDataService.getInitialData().l10n;
        L10nService.supportedLangs = this.l10nInitData.supportedLanguages.map(l => l.id);
        let langFromPath = this.extractLangFromPath(document.location.pathname);
        this.currentLang = langFromPath ? langFromPath : this.l10nInitData.language;
    }

    public getCurrentLang() {
        return this.currentLang;
    }

    getLanguages(): Language[] {
        return this.l10nInitData.supportedLanguages;
    }

    private extractLangFromPath(path: string): string | undefined {
        let regex = new RegExp(`^/(${L10nService.supportedLangs.join('|')})/`);
        let match = path.match(regex);
        if (match) {
            return match[1];
        } else {
            return undefined;
        }
    }
    
}
