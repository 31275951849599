import { Component, Inject, Injector, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
//import { REQUEST } from '@nguniversal/aspnetcore-engine/tokens';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Subject } from 'rxjs';
import { filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { LinkService } from './shared/link.service';

import { DisplayService } from './shared/display.service';
import { WorkAreasService } from './shared/workareas/work-areas.service';
import { SeoService } from './shared/seo.service';
import { GalleryService } from './shared/galleries/gallery.service';
//import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SaveProjectModalComponent } from './components/register/save-project-modal.component';
import { ActionTracker } from './shared/projects/ActionTracker';
import { ProjectService } from './shared/projects/project.service';
import { AnalyticsService } from './shared/analytics.service';
import { MetaTagsSet } from './shared/MetaTagsSet';
import { InitDataService } from './shared/init-data.service';
import { L10nService } from './shared/l10n.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    
    private endPageTitle: string = 'RenoBooking';
    private defaultPageTitle: string = 'RenoBooking';

    private routerSub$: Subscription | undefined;
    private request: any;

    showMain: boolean = true;
    showMobileWorkAreaSelect: boolean = false;
    isBrowser: boolean = false;

    //saveProjectModalRef: BsModalRef | undefined;  //TODO: fix

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private title: Title,
        private meta: Meta,
        private linkService: LinkService,
        public translate: TranslateService,
        private injector: Injector,
        private workAreasService: WorkAreasService,
        private displayService: DisplayService,
        private seoService: SeoService,
        private galleryService: GalleryService,
        //private modalService: BsModalService,
        private actionTracker: ActionTracker,
        private projectService: ProjectService,
        private analyticsService: AnalyticsService,
        l10nService: L10nService
    ) {
        this.isBrowser = displayService.isBrowser();
        // this language will be used as a fallback when a translation isn't found in the current language
        translate.setDefaultLang('en');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use(l10nService.getCurrentLang());

        //this.request = this.injector.get(REQUEST);    //TODO: fix
    }

    ngOnInit() {
        // Change "Title" on every navigationEnd event
        // Titles come from the data.title property on all Routes (see app.routes.ts)
        this._changeTitleOnNavigation();
        this.analyticsService.init();
        this.subscribeToShowMobileWorkAreasSelect();
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            if (this.displayService.isBrowser()) {
                window && window.scrollTo(0, 0);
                this.analyticsService.pageView();
            }
        });
        this.subscribeToMetaTags();
        if (this.displayService.isBrowser()) {
            this.galleryService.updatePictures();
        }

        this.actionTracker.initTimer();
        /*this.actionTracker.showSaveModal.pipe(takeUntil(this.ngUnsubscribe)).subscribe(showModal => {
            if (showModal) {
                let projectMeta = this.projectService.getCurrentProjectMeta();
                this.saveProjectModalRef = this.modalService.show(SaveProjectModalComponent, { class: 'modal-green' });
                if (projectMeta) {
                    this.saveProjectModalRef.content.projectId = projectMeta.id;
                    this.saveProjectModalRef.content.projectName = projectMeta.name;
                }
            }
        });
        this.modalService.onHide.pipe(takeUntil(this.ngUnsubscribe)).subscribe((reason: string) => {
            this.actionTracker.hideSaveModal();
        });*/
    }

    ngOnDestroy() {
        // Subscription clean-up
        if (this.routerSub$) {
            this.routerSub$.unsubscribe();
        }
    }

    private subscribeToMetaTags() {
        this.seoService.metaTags.subscribe((tagSet: MetaTagsSet) => {
            if (tagSet.title && tagSet.description) {
                this.title.setTitle(tagSet.title);
                this.meta.updateTag({ name: "description", content: tagSet.description });
            }
        });
    }

    private _changeTitleOnNavigation() {
        this.routerSub$ = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map(route => {
                    while (route.firstChild) route = route.firstChild;
                    return route;
                }),
                filter(route => route.outlet === 'primary'),
                mergeMap(route => route.data)
            )
            .subscribe(event => {
                this._setMetaAndLinks(event);
            });
    }

    private subscribeToShowMobileWorkAreasSelect() {
        this.workAreasService.showMobileWorkAreasSelect.subscribe(show => {
            if (this.displayService.isMobile()) {
                this.showMobileWorkAreaSelect = show;
                this.showMain = !show;
            }
        });
    }

    private _setMetaAndLinks(event: any) {
        // Set Title if available, otherwise leave the default Title
        const title = event['title']
            ? `${event['title']} - ${this.endPageTitle}`
            : `${this.defaultPageTitle} - ${this.endPageTitle}`;

        this.title.setTitle(title);

        const metaData = event['meta'] || [];
        const linksData = event['links'] || [];

        for (let i = 0; i < metaData.length; i++) {
            this.meta.updateTag(metaData[i]);
        }

        for (let i = 0; i < linksData.length; i++) {
            this.linkService.addTag(linksData[i]);
        }
    }
}
