import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DisplayService } from '../../shared/display.service';
import { SeoService } from '../../shared/seo.service';
import { AccountService } from '../../shared/account/account.service';
import { NotificationService } from '../../shared/notificaton.service';
import { DataService } from '../../shared/network/data.service';

@Component({
    selector: 'join',
    templateUrl: './join.component.html',
})
export class JoinComponent implements OnInit {
    showForm: boolean = false;
    isMobile: boolean = false;
    loading: boolean = false;

    termsAccepted: boolean = false;
    firstName: string | undefined;
    lastName: string | undefined;
    companyName: string | undefined;
    companyAddress: string | undefined;
    phoneNumber: string | undefined;
    email: string | undefined;

    constructor(public translate: TranslateService, private displayService: DisplayService, private seoService: SeoService,
        private accountService: AccountService, private notifier: NotificationService, private dataService: DataService) { }

    ngOnInit(): void {
        this.translate.get(['join.title', 'join.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['join.title'], res['join.desc']);
        });
        this.isMobile = this.displayService.isMobile();
    }

    openForm() {
        this.showForm = true;
    }

    hideForm() {
        this.showForm = false;
    }

    isFormValid(): boolean {
        return this.termsAccepted && this.accountService.validateEmail(this.email as string)
            && !!this.firstName && !!this.lastName && !!this.companyName && !!this.companyAddress && !!this.phoneNumber && !!this.email;
    }

    private validateForm(): boolean {
        let self = this;
        if (!this.firstName) {
            this.translate.get('registration.empty_firstname').subscribe((res: string) => {
                self.notifier.showError(res);
            });
            return false;
        }
        if (!this.lastName) {
            this.translate.get('registration.empty_lastname').subscribe((res: string) => {
                self.notifier.showError(res);
            });
            return false;
        }
        if (!this.phoneNumber) {
            this.translate.get('registration.empty_phone_number').subscribe((res: string) => {
                self.notifier.showError(res);
            });
            return false;
        }
        if (!this.companyName) {
            this.translate.get('join.empty_company_name').subscribe((res: string) => {
                self.notifier.showError(res);
            });
            return false;
        }
        if (!this.companyAddress) {
            this.translate.get('join.empty_company_address').subscribe((res: string) => {
                self.notifier.showError(res);
            });
            return false;
        }
        if (!this.accountService.validateEmail(this.email as string)) {
            this.translate.get('registration.invalid_email').subscribe((res: string) => {
                self.notifier.showError(res);
            });
            return false;
        }
        if (!this.termsAccepted) {
            this.translate.get('registration.terms_not_accepted').subscribe((res: string) => {
                self.notifier.showError(res);
            });
            return false;
        }
        return true;
    }

    async submit() {
        if (this.loading) {
            return;
        }
        if (!this.validateForm()) {
            return;
        }
        this.loading = true;
        try {
            let error = await this.dataService.post<string>('Providers/Join', {
                email: this.email,
                phoneNumber: this.phoneNumber,
                firstName: this.firstName,
                lastName: this.lastName,
                companyName: this.companyName,
                companyAddress: this.companyAddress
            });
            if (error) {
                return;
            }
            this.translate.get('join.request_sent').subscribe((res: string) => {
                this.notifier.showSuccess(res);
            });
        } catch (ex) {
            this.notifier.showError(ex as string);
        }
        finally {
            this.loading = false;
        }
    }
}
