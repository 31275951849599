import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import { AccountService } from '../../shared/account/account.service';
import { ProjectService } from '../../shared/projects/project.service';
import { NotificationService } from '../../shared/notificaton.service';
import { DisplayService } from '../../shared/display.service';
import { SeoService } from '../../shared/seo.service';
import { ProjectPriceItem } from '../../shared/projects/ProjectPriceItem';
import { AnalyticsService } from '../../shared/analytics.service';
import { AbTestsService } from '../../shared/abTesting/abTestsService';

@Component({
    selector: 'register',
    templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public loading: boolean = false;
    public firstName: string | undefined;
    public lastName: string | undefined;
    public email: string | undefined;
    public phoneNumber: string | undefined;
    public password: string | undefined;
    public passwordConfirm: string | undefined;
    public termsAccepted: boolean = false;
    public error: string | null = null;
    public workItemsCount: number = 0;
    public selectedProviders: ProjectPriceItem[] = [];
    
    public isLoggedIn: boolean = false;
    public emailInitiallyConfirmed: boolean = false;
    public phoneNumberInitiallyConfirmed: boolean = false;
    public showConfirmationsUI: boolean = false;
    public emailConfirmed: boolean = false;
    public phoneNumberConfirmed: boolean = false;
    public emailCode: string | undefined;
    public phoneNumberCode: string | undefined;
    public emailCodeIsWrong: boolean = false;
    public phoneNumberCodeIsWrong: boolean = false;
    public triedValidateCodes: boolean = false;
    public expandEmail: boolean = false;
    public expandPhoneNumber: boolean = false;
    public fullyRegistered: boolean = false;

    private emailConfirmationFromEmailRequested: boolean = false;
    
    constructor(public translate: TranslateService, private accountService: AccountService,
        private router: Router, private projectService: ProjectService, private notifier: NotificationService,
        private activatedRoute: ActivatedRoute, private displayService: DisplayService,
        private seoService: SeoService, private analyticsService: AnalyticsService, private abTestsService: AbTestsService) {
    }

    ngOnInit() {
        let self = this;
        this.translate.get(['registration.title', 'registration.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['registration.title'], res['registration.desc']);
        });
        this.accountService.isLoggedIn.pipe(takeUntil(this.ngUnsubscribe)).subscribe(loggedIn => {
            //a user may be already logged in, if he did a light registration to save his project
            this.isLoggedIn = loggedIn;
            if (loggedIn) {
                this.termsAccepted = true;
                this.password = this.passwordConfirm = '123456qazwsx[[';
            }
        });
        this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            let token1 = params['token1'];
            let token2 = params['token2'];
            let loadPricesFlag = params['loadPrices'];
            if (!this.emailConfirmationFromEmailRequested && token1 && token2) {
                this.emailConfirmationFromEmailRequested = true;
                this.emailCode = token2;
                this.accountService.confirmEmailFromEmail(token1, token2)
                    .then(() => {
                        this.translate.get('registration.email_confirmed_successfully').subscribe((res: string) => {
                            self.notifier.showSuccess(res);
                        });
                    });
            }
        });

        let userInfo = this.accountService.getUserInfo();
        this.firstName = userInfo.firstName;
        this.lastName = userInfo.lastName;
        if (!this.isFakeEmail(userInfo.email)) {
            this.email = userInfo.email;
        }
        this.phoneNumber = userInfo.phoneNumber;
        this.emailInitiallyConfirmed = this.emailConfirmed = userInfo.emailConfirmed;
        this.phoneNumberInitiallyConfirmed = this.phoneNumberConfirmed = userInfo.phoneNumberConfirmed;
        this.fullyRegistered = this.emailInitiallyConfirmed && this.phoneNumberInitiallyConfirmed;
        this.subscribeToSelectedOffers();
        this.subscribeToFinishedPrices();

        this.projectService.loadPricesIfNotLoaded();
    }

    private subscribeToSelectedOffers(): void {
        this.projectService.selectedOffers.pipe(takeUntil(this.ngUnsubscribe)).subscribe(offers => {
            this.selectedProviders = offers;
        });
    }

    private subscribeToFinishedPrices(): void {
        if (this.displayService.isBrowser()) {
            this.projectService.finishedPricesDto.pipe(takeUntil(this.ngUnsubscribe)).subscribe(dto => {
                if (!!dto.prices.length) {
                    this.router.navigateByUrl('/comparison');
                }
            });
        }
    }

    passwordIsGood(): boolean {
        return !!this.password && this.password.length >= 6;
    }

    isBtnGoToConfirmationsEnabled(): boolean {
        return this.termsAccepted && !!this.firstName && !!this.lastName
            && !!this.email && !!this.phoneNumber && this.accountService.validateEmail(this.email)
            && !!this.password && this.passwordIsGood() && (this.password === this.passwordConfirm);
    }

    async btnGoToConfirmationsClick() {
        if (this.loading) {
            return;
        }
        if (!this.validateForm()) {
            return;
        }
        this.loading = true;
        try {
            if (this.fullyRegistered) {
                this.gotoComparisonPage();
                return;
            }

            let error = await this.accountService.register(this.email as string, this.phoneNumber as string, this.password as string, this.firstName as string, this.lastName as string);
            if (error) {
                return;
            }
            this.showConfirmationsUI = true;    //TODO: if all is confirmed already, proceed
        } catch (ex) {
            this.notifier.showError(ex as string);
        }
        finally {
            this.loading = false;
        }
    }

    isBtnValidateCodesEnabled(): boolean {
        let userInfo = this.accountService.getUserInfo();
        return ((this.phoneNumberConfirmed || (!!this.phoneNumberCode && !!this.phoneNumber))
            && this.termsAccepted);
    }

    btnValidateCodesClick(): void {
        if (this.loading) {
            return;
        }
        this.loading = true;
        let self = this;
        if (!this.phoneNumberConfirmed && !this.phoneNumberCode) {
            this.translate.get('registration.empty_phoneCode').subscribe((res: string) => {
                self.showError(res);
            });
            this.loading = false;
            return;
        }
        if (!this.termsAccepted) {
            this.translate.get('registration.terms_not_accepted').subscribe((res: string) => {
                self.showError(res);
            });
            this.loading = false;
            return;
        }
        if (!this.emailConfirmed || !this.phoneNumberConfirmed) {
            this.emailCodeIsWrong = false;
            this.phoneNumberCodeIsWrong = false;
            this.accountService.confirmTokens(this.emailCode as string, this.phoneNumberCode as string).then(result => {
                if (result.emailError) {
                    this.emailCodeIsWrong = true;
                    this.notifier.showError(result.emailError);
                } else {
                    this.emailConfirmed = true;
                }
                if (result.phoneNumberError) {
                    this.phoneNumberCodeIsWrong = true;
                    this.notifier.showError(result.phoneNumberError);
                } else {
                    this.phoneNumberConfirmed = true;
                }
                if (!result.emailError && !result.phoneNumberError) {
                    this.onSuccessfullConfirmation();
                } else {
                    this.loading = false;
                }
            });
        }
        else if (this.phoneNumberConfirmed && this.emailConfirmed) {
            this.onSuccessfullConfirmation();   //shouldn't ever happen, but in case we get to a wrong state
        }
    }

    public validateForm(): boolean {
        let self = this;
        if (!this.firstName) {
            this.translate.get('registration.empty_firstname').subscribe((res: string) => {
                self.showError(res);
            });
            return false;
        }
        if (!this.lastName) {
            this.translate.get('registration.empty_lastname').subscribe((res: string) => {
                self.showError(res);
            });
            return false;
        }
        if (!this.phoneNumber) {
            this.translate.get('registration.empty_phone_number').subscribe((res: string) => {
                self.showError(res);
            });
            return false;
        }
        if (!this.accountService.validateEmail(this.email as string)) {
            this.translate.get('registration.invalid_email').subscribe((res: string) => {
                self.showError(res);
            });
            return false;
        }
        if (!this.passwordIsGood()) {
            this.translate.get('registration.password_too_short').subscribe((res: string) => {
                self.showError(res);
            });
            return false;
        }
        if (this.password != this.passwordConfirm) {
            this.translate.get('registration.password_and_confirm_are_different').subscribe((res: string) => {
                self.showError(res);
            });
            return false;
        }
        if (!this.termsAccepted) {
            this.translate.get('registration.terms_not_accepted').subscribe((res: string) => {
                self.showError(res);
            });
            return false;
        }
        return true;
    }

    showError(text: string) {
        this.error = text;
        this.notifier.showError(text);
    }

    removeError() {
        this.error = null;
    }

    private isFakeEmail(email: string): boolean {
        return !!email && email.endsWith('@fake.com');
    }

    public async resendEmailCode() {
        this.emailCodeIsWrong = false;
        let error = await this.accountService.resendEmailCode(this.email as string);
        if (!error) {
            this.translate.get('registration.emailCodeWasResent').subscribe((res: string) => {
                this.notifier.showSuccess(res);
            });
        }
    }

    public async resendSms() {
        this.phoneNumberCodeIsWrong = false;
        let error = await this.accountService.sendSmsWithCode(this.phoneNumber as string);
        if (!error) {
            this.translate.get('registration.phoneCodeWasResent').subscribe((res: string) => {
                this.notifier.showSuccess(res);
            });
        }
    }

    private onSuccessfullConfirmation() {
        if (this.selectedProviders && this.selectedProviders.length) {
            this.gotoComparisonPage();
        } else {
            this.router.navigateByUrl('/search');
        }
    }

    private gotoComparisonPage(): void {
        this.loading = true;
        this.projectService.createLeads().then(() => {
            this.analyticsService.fireEvent('checkoutFinished');
            this.router.navigateByUrl('/comparison');
        });
    }
    
    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
