import { Component, OnInit } from '@angular/core';
import { RenoRouter } from '../../shared/RenoRouter';

@Component({
    selector: 'search-results',
    template: '<div></div>'
})
export class SearchResultsRedirectComponent implements OnInit {
    constructor(private renoRouter: RenoRouter) {
    }
    
    ngOnInit() {
        this.renoRouter.navigateByUrl('/search-results');
    }
}
