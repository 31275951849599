<div class="wrap-bg">
    <div class="container">
        <div class="score-providers">
            <div class="title-green">
                <h3>
                    {{ 'feedback_provider_score.top_slogan' | translate:{firstName: firstName} }}
                </h3>
                <span class="subtitle">
                    {{ 'feedback_provider_score.top_slogan_desc' | translate }}
                </span>
            </div>
            <div>
                <ul class="nav nav-tabs company-tabs">
                    <li class="nav-item" *ngFor="let score of scores">
                        <a [ngClass]="{'active':score.providerId == selectedProviderId, 'nav-link':true}" (click)="selectProvider(score.providerId)">
                            {{score.providerName}}
                        </a>
                    </li>
                </ul>
                <div class="wrap-feedback-content">
                    <div class="content-block">
                        <div class="score-value">
                            <div class="title">
                                {{ 'feedback_provider_score.score_header' | translate:{providerName: selectedScore.providerName} }}
                            </div>
                            <div class="value not-ready" *ngIf="!selectedScore.showScore">
                                {{ 'feedback_provider_score.no_responses_no_score' | translate }}
                            </div>
                            <div class="value" *ngIf="selectedScore.showScore">
                                <span class="number">{{getCurrentScore()}}</span>, <span>{{getCurrentScoreDesc()}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="content-block count-progress">
                        <h5>
                            {{ 'feedback_provider_score.header' | translate:{providerName: selectedScore.providerName} }}
                        </h5>
                        <div class="cells">
                            <div [ngClass]="{'active':selectedScore.statusId==1, 'cell':true, 'cell-online_offer':true}" (click)="setScore('statusId', 1)">
                                <span>
                                    {{ 'feedback_provider_score.preliminary_offer_p1' | translate }}<br> {{ 'feedback_provider_score.preliminary_offer_p2' | translate }}
                                </span>
                                <div class="cell-image"></div>
                            </div>
                            <div [ngClass]="{'active':selectedScore.statusId==3, 'cell':true, 'cell-contact':true}" (click)="setScore('statusId', 3)">
                                <span>
                                    {{ 'feedback_provider_score.connected_by_phone_p1' | translate }}<br> {{ 'feedback_provider_score.connected_by_phone_p2' | translate }}
                                </span>
                                <div class="cell-image"></div>
                            </div>
                            <div [ngClass]="{'active':selectedScore.statusId==4, 'cell':true, 'cell-inspect_property':true}" (click)="setScore('statusId', 4)">
                                <span>
                                    {{ 'feedback_provider_score.property_inspected_p1' | translate }}<br> {{ 'feedback_provider_score.property_inspected_p2' | translate }}
                                </span>
                                <div class="cell-image"></div>
                            </div>
                            <div [ngClass]="{'active':selectedScore.statusId==5, 'cell':true, 'cell-final_offer':true}" (click)="setScore('statusId', 5)">
                                <span>
                                    {{ 'feedback_provider_score.final_offer_p1' | translate }}<br> {{ 'feedback_provider_score.final_offer_p2' | translate }}
                                </span>
                                <div class="cell-image"></div>
                            </div>
                            <div [ngClass]="{'active':selectedScore.statusId==7, 'cell':true, 'cell-contract_signed':true}" (click)="setScore('statusId', 7)">
                                <span>
                                    {{ 'feedback_provider_score.contract_signed_p1' | translate }}<br> {{ 'feedback_provider_score.contract_signed_p2' | translate }}
                                </span>
                                <div class="cell-image"></div>
                            </div>
                            <div [ngClass]="{'active':selectedScore.statusId==8, 'cell':true, 'cell-work_started':true}" (click)="setScore('statusId', 8)">
                                <span>
                                    {{ 'feedback_provider_score.work_started_p1' | translate }}<br> {{ 'feedback_provider_score.work_started_p2' | translate }}
                                </span>
                                <div class="cell-image"></div>
                            </div>
                            <div [ngClass]="{'active':selectedScore.statusId==9, 'cell':true, 'cell-work_completed':true}" (click)="setScore('statusId', 9)">
                                <span>
                                    {{ 'feedback_provider_score.project_completed_p1' | translate }}<br> {{ 'feedback_provider_score.project_completed_p2' | translate }}
                                </span>
                                <div class="cell-image"></div>
                            </div>
                        </div>
                    </div>
                    <div class="content-block score-company">
                        <h5>
                            {{ 'feedback_provider_score.questions_header' | translate:{providerName: selectedScore.providerName} }}
                        </h5>
                        <div class="Ftable Ftable-stripped score-company-table">
                            <div class="Ftable-row Ftable-header">
                                <div class="Ftable-cell"></div>
                                <div class="Ftable-cell">
                                    {{ 'feedback_provider_score.answers.answer1' | translate }}
                                </div>
                                <div class="Ftable-cell">
                                    {{ 'feedback_provider_score.answers.answer2' | translate }}
                                </div>
                                <div class="Ftable-cell">
                                    {{ 'feedback_provider_score.answers.answer3' | translate }}
                                </div>
                                <div class="Ftable-cell">
                                    {{ 'feedback_provider_score.answers.answer4' | translate }}
                                </div>
                                <div class="Ftable-cell"></div>
                            </div>
                            <div class="Ftable-row">
                                <div [ngClass]="{'active': selectedScore.interaction==-1, 'Ftable-cell':true}" (click)="setScore('interaction', -1)">
                                    "{{ 'feedback_provider_score.questions.interaction' | translate }}"
                                    <span class="feedback-value feedback-value_mobile">
                                        {{ 'feedback_provider_score.answers.early_to_say' | translate }}
                                    </span>
                                </div>
                                <div [ngClass]="{'active':selectedScore.interaction==1, 'gray':selectedScore.interaction, 'Ftable-cell':true, 'cell-feedback-sad':true, 'feedback-value':true}" (click)="setScore('interaction', 1)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer1' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.interaction==2, 'gray':selectedScore.interaction, 'Ftable-cell':true, 'cell-feedback-neutral':true, 'feedback-value':true}" (click)="setScore('interaction', 2)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer2' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.interaction==3, 'gray':selectedScore.interaction, 'Ftable-cell':true, 'cell-feedback-smile':true, 'feedback-value':true}" (click)="setScore('interaction', 3)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer3' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.interaction==4, 'gray':selectedScore.interaction, 'Ftable-cell':true, 'cell-feedback-happy':true, 'feedback-value':true}" (click)="setScore('interaction', 4)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer4' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.interaction==-1, 'gray':selectedScore.interaction, 'Ftable-cell':true, 'feedback-value':true}" (click)="setScore('interaction', -1)">
                                    <span class="text">
                                        {{ 'feedback_provider_score.answers.early_to_say' | translate }}
                                    </span>
                                </div>
                            </div>
                            <div class="Ftable-row">
                                <div [ngClass]="{'active': selectedScore.competence==-1, 'Ftable-cell':true}" (click)="setScore('competence', -1)">
                                    <span class="text">
                                        "{{ 'feedback_provider_score.questions.competence_p1' | translate }}<span class="d-sm-inline d-none"> {{ 'feedback_provider_score.questions.competence_p2' | translate }}</span>"
                                    </span>
                                    <span class="feedback-value feedback-value_mobile">
                                        {{ 'feedback_provider_score.answers.early_to_say' | translate }}
                                    </span>
                                </div>
                                <div [ngClass]="{'active':selectedScore.competence==1, 'gray':selectedScore.competence, 'Ftable-cell':true, 'cell-feedback-sad':true, 'feedback-value':true}" (click)="setScore('competence', 1)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer1' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.competence==2, 'gray':selectedScore.competence, 'Ftable-cell':true, 'cell-feedback-neutral':true, 'feedback-value':true}" (click)="setScore('competence', 2)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer2' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.competence==3, 'gray':selectedScore.competence, 'Ftable-cell':true, 'cell-feedback-smile':true, 'feedback-value':true}" (click)="setScore('competence', 3)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer3' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.competence==4, 'gray':selectedScore.competence, 'Ftable-cell':true, 'cell-feedback-happy':true, 'feedback-value':true}" (click)="setScore('competence', 4)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer4' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.competence==-1, 'gray':selectedScore.competence, 'Ftable-cell':true, 'feedback-value':true}" (click)="setScore('competence', -1)">
                                    <span class="text">
                                        {{ 'feedback_provider_score.answers.early_to_say' | translate }}
                                    </span>
                                </div>
                            </div>
                            <div class="Ftable-row">
                                <div [ngClass]="{'active': selectedScore.prices==-1, 'Ftable-cell':true}" (click)="setScore('prices', -1)">
                                    <span class="text">
                                        "{{ 'feedback_provider_score.questions.prices' | translate }}"
                                    </span>
                                    <span class="feedback-value feedback-value_mobile">
                                        {{ 'feedback_provider_score.answers.early_to_say' | translate }}
                                    </span>
                                </div>
                                <div [ngClass]="{'active':selectedScore.prices==1, 'gray':selectedScore.prices, 'Ftable-cell':true, 'cell-feedback-sad':true, 'feedback-value':true}" (click)="setScore('prices', 1)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer1' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.prices==2, 'gray':selectedScore.prices, 'Ftable-cell':true, 'cell-feedback-neutral':true, 'feedback-value':true}" (click)="setScore('prices', 2)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer2' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.prices==3, 'gray':selectedScore.prices, 'Ftable-cell':true, 'cell-feedback-smile':true, 'feedback-value':true}" (click)="setScore('prices', 3)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer3' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.prices==4, 'gray':selectedScore.prices, 'Ftable-cell':true, 'cell-feedback-happy':true, 'feedback-value':true}" (click)="setScore('prices', 4)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer4' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.prices==-1, 'gray':selectedScore.prices, 'Ftable-cell':true, 'feedback-value':true}" (click)="setScore('prices', -1)">
                                    <span class="text">
                                        {{ 'feedback_provider_score.answers.early_to_say' | translate }}
                                    </span>
                                </div>
                            </div>
                            <div class="Ftable-row">
                                <div [ngClass]="{'active': selectedScore.quality==-1, 'Ftable-cell':true}" (click)="setScore('quality', -1)">
                                    <span class="text">
                                        "{{ 'feedback_provider_score.questions.quality' | translate }}"
                                    </span>
                                    <span class="feedback-value feedback-value_mobile">
                                        {{ 'feedback_provider_score.answers.early_to_say' | translate }}
                                    </span>
                                </div>
                                <div [ngClass]="{'active':selectedScore.quality==1, 'gray':selectedScore.quality, 'Ftable-cell':true, 'cell-feedback-sad':true, 'feedback-value':true}" (click)="setScore('quality', 1)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer1' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.quality==2, 'gray':selectedScore.quality, 'Ftable-cell':true, 'cell-feedback-neutral':true, 'feedback-value':true}" (click)="setScore('quality', 2)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer2' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.quality==3, 'gray':selectedScore.quality, 'Ftable-cell':true, 'cell-feedback-smile':true, 'feedback-value':true}" (click)="setScore('quality', 3)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer3' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.quality==4, 'gray':selectedScore.quality, 'Ftable-cell':true, 'cell-feedback-happy':true, 'feedback-value':true}" (click)="setScore('quality', 4)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer4' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.quality==-1, 'gray':selectedScore.quality, 'Ftable-cell':true, 'feedback-value':true}" (click)="setScore('quality', -1)">
                                    <span class="text">
                                        {{ 'feedback_provider_score.answers.early_to_say' | translate }}
                                    </span>
                                </div>
                            </div>
                            <div class="Ftable-row">
                                <div [ngClass]="{'Ftable-cell':true, 'active':selectedScore.nps==-1}" (click)="setScore('nps', -1)">
                                    <span class="text">
                                        "{{ 'feedback_provider_score.questions.nps' | translate }}"
                                    </span>
                                    <span class="feedback-value feedback-value_mobile">
                                        {{ 'feedback_provider_score.answers.early_to_say' | translate }}
                                    </span>
                                </div>
                                <div [ngClass]="{'active':selectedScore.nps==1, 'gray':selectedScore.nps, 'Ftable-cell':true, 'cell-feedback-sad':true, 'feedback-value':true}" (click)="setScore('nps', 1)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer1' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.nps==2, 'gray':selectedScore.nps, 'Ftable-cell':true, 'cell-feedback-neutral':true, 'feedback-value':true}" (click)="setScore('nps', 2)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer2' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.nps==3, 'gray':selectedScore.nps, 'Ftable-cell':true, 'cell-feedback-smile':true, 'feedback-value':true}" (click)="setScore('nps', 3)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer3' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.nps==4, 'gray':selectedScore.nps, 'Ftable-cell':true, 'cell-feedback-happy':true, 'feedback-value':true}" (click)="setScore('nps', 4)">
                                    <span class="mobile-header">
                                        {{ 'feedback_provider_score.answers.answer4' | translate }}
                                    </span>
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.nps==-1, 'gray':selectedScore.nps, 'Ftable-cell':true, 'feedback-value':true}" (click)="setScore('nps', -1)">
                                    <span class="text">
                                        {{ 'feedback_provider_score.answers.early_to_say' | translate }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-block more-feedback">
                        <h5>
                            {{ 'feedback_provider_score.part2_header' | translate:{providerName: selectedScore.providerName} }}
                        </h5>
                        <div class="form-group">
                            <label for="exampleFormControlTextarea1">
                                {{ 'feedback_provider_score.positive_comment_p1' | translate }}<b>{{ 'feedback_provider_score.positive_comment_p2' | translate }}</b>
                            </label>
                            <textarea [(ngModel)]="positiveComment" (blur)="saveComment(true)" class="form-control" id="exampleFormControlTextarea1"
                                      rows="3" placeholder="{{'feedback_provider_score.positive_comment_placeholder' | translate}}"></textarea>
                        </div>
                        <div class="form-group">
                            <label for="exampleFormControlTextarea2">
                                {{ 'feedback_provider_score.negative_comment_p1' | translate }}<b>{{ 'feedback_provider_score.negative_comment_p2' | translate }}</b>
                            </label>
                            <textarea [(ngModel)]="negativeComment" (blur)="saveComment(false)" class="form-control" id="exampleFormControlTextarea2"
                                      rows="3" placeholder="{{'feedback_provider_score.negative_comment_placeholder' | translate}}"></textarea>
                        </div>
                    </div>
                    <div class="content-block continue-working">
                        <h5>
                            {{ 'feedback_provider_score.part2_header' | translate:{providerName: selectedScore.providerName} }}
                        </h5>
                        <div class="Ftable Ftable-stripped score-company-table">
                            <div class="Ftable-row Ftable-header">
                                <div class="Ftable-cell"></div>
                                <div [ngClass]="{'active':selectedScore.continue==1, 'Ftable-cell':true}" (click)="setScore('continue', 1)">
                                    {{ 'feedback_provider_score.answers.answer1' | translate }}
                                </div>
                                <div [ngClass]="{'active':selectedScore.continue==2, 'Ftable-cell':true}" (click)="setScore('continue', 2)">
                                    {{ 'feedback_provider_score.answers.answer2' | translate }}
                                </div>
                                <div [ngClass]="{'active':selectedScore.continue==3, 'Ftable-cell':true}" (click)="setScore('continue', 3)">
                                    {{ 'feedback_provider_score.answers.answer3' | translate }}
                                </div>
                                <div [ngClass]="{'active':selectedScore.continue==4, 'Ftable-cell':true}" (click)="setScore('continue', 4)">
                                    {{ 'feedback_provider_score.answers.answer4' | translate }}
                                </div>
                                <div class="Ftable-cell"></div>
                            </div>
                            <div class="Ftable-row">
                                <div class="Ftable-cell">
                                </div>
                                <div [ngClass]="{'active':selectedScore.continue==1, 'Ftable-cell':true, 'cell-feedback-sad':true, 'feedback-value':true}" (click)="setScore('continue', 1)">
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.continue==2, 'Ftable-cell':true, 'cell-feedback-neutral':true, 'feedback-value':true}" (click)="setScore('continue', 2)">
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.continue==3, 'Ftable-cell':true, 'cell-feedback-smile':true, 'feedback-value':true}" (click)="setScore('continue', 3)">
                                    <div class="cell-image"></div>
                                </div>
                                <div [ngClass]="{'active':selectedScore.continue==4, 'Ftable-cell':true, 'cell-feedback-happy':true, 'feedback-value':true}" (click)="setScore('continue', 4)">
                                    <div class="cell-image"></div>
                                </div>
                                <div class="Ftable-cell">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="action">
                        <a [ngClass]="{'disabled':!selectedScore.allScoresSet(), 'btn':true, 'btn-blue':true, 'btn-middle':true, 'w-100':true}" (click)="calcScore()">
                            {{ 'feedback_provider_score.btn_submit' | translate }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
