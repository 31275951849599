import { WorkItemPriceDto } from './WorkItemPriceDto'
import { ProviderAttr, FeaturedServiceDto } from '../providers/ProviderAttr';
import { ProviderStats } from '../providers/ProviderStats';

export class ProjectPriceItem {
    providerId!: string;
    providerName!: string;
    providerDescription!: string;
    totalPrice!: number;
    workItems!: WorkItemPriceDto[];
    currencySign!: string;
    headerAttrs!: ProviderAttr[];
    featuredServices!: FeaturedServiceDto[];
    showDetails: boolean = false;
    verifiedThings: string[] = [];
    stats!: ProviderStats;
    numOfPricesBelowAverage!: number;
    numOfPricesAboveAverage!: number;
    guarantee!: ProjectGuarantee;
    selected: boolean = false;
    hasOfficeInfo: boolean = false;
    numOfDocs: number = 0;
    leadingPictureUrl!: string;
}

export enum ProjectGuaranteeDatesInContract {
    none,
    bigProject,
    always,
}

export class ProjectGuarantee {
    public datesInContract!: ProjectGuaranteeDatesInContract;
    public projectGuaranteeDatesInContractPriceThreshold!: number;
    public weeklyPenalty!: number;
    public maxPenalty!: number;
}
