<div class="wrap-bg">
    <div class="faq-content service-use">
        <div class="container">
            <h1>
                {{ 'service_use.header1' | translate}}
            </h1>
            <h5>
                {{ 'service_use.header2_1' | translate}}
            </h5>
            <h5 (click)="scrollToFragment('privacy')">
                {{ 'service_use.header2_2' | translate}}
            </h5>
            <h5 (click)="scrollToFragment('cookies')">
                {{ 'service_use.header2_3' | translate}}
            </h5>
            
            <h2 id="terms">
                {{ 'service_use.terms_header' | translate}}
            </h2>
            <div class="faq-content_block">
                <div class="block">
                    <div [innerHTML]="terms"></div>
                </div>
            </div>

            <br/>
            <h2 id="privacy">
                {{ 'service_use.privacy_header' | translate}}
            </h2>
            <div class="faq-content_block">
                <div class="block">
                    <div [innerHTML]="privacy"></div>
                </div>
            </div>

            <br/>
            <h2 id="cookies">
                {{ 'service_use.cookies_header' | translate}}
            </h2>
            <div class="faq-content_block">
                <div class="block">
                    <div [innerHTML]="cookies"></div>
                </div>
            </div>
        </div>
    </div>
</div>
