import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProviderDocsDto } from '../../shared/providers/ProviderDocsDto';
import { ProviderAttr } from '../../shared/providers/ProviderAttr';
import { DisplayService } from '../../shared/display.service';
//import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';

@Component({
    selector: 'offer-details-modal',
    templateUrl: './offer-details-modal.component.html'
})
export class OfferDetailsModalComponent {
    public headerAttrs: ProviderAttr[] = [];
    public providerName: string | undefined;
    public docs: ProviderDocsDto;

    //galleryOptions: NgxGalleryOptions[];

    isBrowser: boolean = false;

    constructor(public bsModalRef: BsModalRef, displayService: DisplayService) {
        this.docs = {
            offices: [],
            keyDocs: [],
            additionalServices:[],
            certs: [],
            awards: [],
            mediaMentions:[]
        };
        this.isBrowser = displayService.isBrowser();

      /*  this.galleryOptions = [
            {
                image: false,
                imageArrowsAutoHide: true,
                width: '100%',
                height: displayService.isMobile() ? '120px': '240px',
                thumbnailsColumns: 3,
                thumbnailsRows: 1,
                thumbnailsPercent: 100,
                imagePercent: 0,
                thumbnailMargin: 2,
                thumbnailsMargin: 2,
                imageAnimation: NgxGalleryAnimation.Slide,
                previewSwipe: true,
                imageInfinityMove: true,
                preview: true,
                previewFullscreen: true,
                previewCloseOnEsc: true,
                previewAnimation: true,
            }
        ];*/
    }

    close() {
        this.bsModalRef.hide();
    }

    getHeaderAttrsMerged() {
        return this.headerAttrs ? this.headerAttrs.map(a => a.text).join(', ') : null;
    }

    public setDocs(dto: ProviderDocsDto) {
        this.loadGalleryImages(dto);
        this.docs = dto;
    }

    private loadGalleryImages(dto: ProviderDocsDto) {
      /*  if (!dto.offices) {
            return;
        }
        for (let i = 0; i < dto.offices.length; ++i) {
            dto.offices[i].galleryImages = [];
            for (let j = 0; j < dto.offices[i].picUrls.length; ++j) {
                dto.offices[i].galleryImages.push({
                    small: dto.offices[i].picUrls[j],
                    medium: dto.offices[i].picUrls[j],
                    big: dto.offices[i].picUrls[j]
                });
            }
        }*/
    }
}
