import { Component, OnInit, AfterViewInit, Inject, ElementRef, NgZone, PLATFORM_ID, HostListener, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { VerticalGalleryComponent } from '../../components/gallery/vertical-gallery.component';
import { GalleryService } from '../../shared/galleries/gallery.service';
import { LocationService } from '../../shared/locations/location.service';
import { Market } from '../../shared/locations/Market';
import { DisplayService } from '../../shared/display.service';
import { WorkAreasService } from '../../shared/workareas/work-areas.service';
import { ProjectService } from '../../shared/projects/project.service';
import { SeoService } from '../../shared/seo.service';
import { GalleryComponent } from '../gallery/gallery.component';

@Component({
    selector: 'search',
    templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit, AfterViewInit {
    title: string = 'RenoBooking';
    currentLocationName: string = '';
    markets: Market[] = [];
    showAreYouNotInButton: boolean = true;
    @ViewChildren(VerticalGalleryComponent) galleries!: QueryList<VerticalGalleryComponent>;
    loading: boolean = false;
    showMap: boolean = false;
    
    constructor(public translate: TranslateService, private locationService: LocationService,
        private galleryService: GalleryService, private displayService: DisplayService,
        private workAreasService: WorkAreasService, private projectService: ProjectService, private router: Router,
        private seoService: SeoService) {
    }

    ngOnInit() {
        if (!this.displayService.isMobile()) {
            this.showMap = true;
        }
        this.subscribeToCurrentLocation();
        this.translate.get(['search.title', 'search.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['search.title'], res['search.desc']);
        });
    }

    ngAfterViewInit() {
        if (this.displayService.isBrowser()) {
            let galleries: GalleryComponent[] = [];
            this.galleries.forEach(g => galleries.push(g));
            this.galleryService.setActiveGalleries(galleries);
        }
    }

    public async openCustomProject() {
        if (!this.loading) {
            this.loading = true;
            try {
                this.workAreasService.createCustomProject();
                if (await this.projectService.updateCurrentProjectAndPrices()) {
                    this.router.navigateByUrl('/search-results');
                } else {
                    this.router.navigateByUrl('/comparison');
                }
            } finally {
                this.loading = false;
            };
            return;
        }
    }

    private subscribeToCurrentLocation(): void {
        this.locationService.currentLocation.subscribe(newLocation => {
            this.currentLocationName = newLocation.locationName || this.currentLocationName;
            if (newLocation.address) {
                this.showAreYouNotInButton = false;
            }
        });
    }
}
