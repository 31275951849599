<div class="modal-blue">
    <div class="modal-header">
        <h4 class="modal-title">{{ 'comments_modal.header' | translate }}</h4>
        <p>
            {{ 'comments_modal.header_desc' | translate }}
        </p>
    </div>
    <div class="modal-body">
        <form>
            <div>
                <b>
                    {{ 'comments_modal.textarea_header' | translate }}
                </b>
            </div>
            <textarea name="" rows="10" [(ngModel)]="comment" [ngModelOptions]="{standalone: true}"></textarea>
        </form>
    </div>
    <div class="modal-footer">
        <div [ngClass]="{ 'actions':true, 'loading':loading }">
            <button class="btn btn-transparent-blue btn-middle" (click)="close()">{{ 'comments_modal.cancel' | translate }}</button>
            <button class="btn btn-blue btn-middle" (click)="save()">{{ 'comments_modal.save' | translate }}</button>
        </div>
    </div>
</div>
