import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import { ProjectService } from '../../shared/projects/project.service';
import { DisplayService } from '../../shared/display.service';
import { ProjectPriceItem } from '../../shared/projects/ProjectPriceItem';

@Component({
    selector: 'selected-offers-search-results',
    templateUrl: './selected-offers-search-results.component.html'
})
export class SelectedOffersSearchResultsComponent implements OnInit {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    selectedOffers: ProjectPriceItem[] = [];
    @Input() showMissing: boolean = false;
    
    constructor(public translate: TranslateService, private projectService: ProjectService) {
    }

    ngOnInit() {
        this.subscribeToSelectedOffers();
    }

    private subscribeToSelectedOffers(): void {
        this.projectService.selectedOffers.pipe(takeUntil(this.ngUnsubscribe)).subscribe(offers => {
            this.selectedOffers = offers;
        });
    }

    public deselectProvider(providerId: string): void {
        this.projectService.deSelectProvider(providerId);
    }

}
