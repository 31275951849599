import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { DisplayService } from '../display.service';
import { FaqParagraph } from './faqParagraph';
import { FaqService } from './faq.service';

@Injectable()
export class FaqResolve implements Resolve<FaqParagraph[]> {
    constructor(private faqService: FaqService, private displayService: DisplayService) {
    }

    resolve(route: ActivatedRouteSnapshot): Promise<FaqParagraph[]> {
        return this.faqService.getFaq().then(faq => {
            return faq;
        });
    }
}
