import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs';

import { WorkAreasService } from '../../shared/workareas/work-areas.service';
import { ProjectPlug } from '../../shared/workareas/ProjectPlug';
import { ProjectService } from '../../shared/projects/project.service';
import { RenoRouter } from '../../shared/RenoRouter';

@Component({
    selector: 'bottom-menu',
    templateUrl: './bottom-menu.component.html'
})
export class BottomMenuComponent implements OnInit {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    projectPlugs: ProjectPlug[] = [];

    constructor(translate: TranslateService, private workAreasService: WorkAreasService,
        private projectService: ProjectService, private renoRouter: RenoRouter) {
    }

    ngOnInit(): void {
        this.subscribeToProjectPlugs();
    }

    private subscribeToProjectPlugs() {
        this.workAreasService.projectPlugs.pipe(takeUntil(this.ngUnsubscribe)).subscribe(newProjectPlugs => {
            this.projectPlugs = newProjectPlugs.filter(pp => pp.id > 0).slice(0, 2);
        });
    }

    public async loadProjectPlug(pp: ProjectPlug) {
        if (await this.projectService.projectExists()) {
            this.projectService.loadPricesIfNotLoaded();
            this.renoRouter.navigateByUrl('/search-results');
        } else {
            await this.workAreasService.updateWorkAreasToSelect(pp);
            let projectCanBeUpdated = await this.projectService.updateCurrentProjectAndPrices()
            if (projectCanBeUpdated) {
                this.renoRouter.navigateByUrl('/search-results');
            } else {
                this.renoRouter.navigateByUrl('/comparison');
            }
        }
    }
}
