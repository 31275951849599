import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GoogleSearchSettings } from "./GoogleSearchSettings";
import { DisplayService } from '../display.service';
import { InitDataService } from '../init-data.service';

@Injectable()
export class SearchService {
    private settings: GoogleSearchSettings;

    constructor(private http: HttpClient, initdataService: InitDataService, private displayService: DisplayService) {
        this.settings = initdataService.getInitialData().googleSearchSettings;
    }

    public async search(query: string): Promise<string[]> {
        if (!query) {
            return [];
        }
        try {
            let result: any = await this.http
                .get(`https://www.googleapis.com/customsearch/v1?key=${this.settings.apiKey}&cx=${this.settings.cseId}&q=${query}`)
                .toPromise();
            if (!result || !result.items || !result.items.length) {
                return [];
            }
            let self = this;
            return result.items.map(function (i: any) {
                return self.convertUrlToSpa(i.link);
            });
        } catch {
            return [];
        }
    }

    private convertUrlToSpa(url: string): string {
        if (!this.displayService.isBrowser()) {
            return url;
        }
        let domain = window.location.hostname;
        return url.replace('https://', '').replace('http://', '').replace(domain, '').replace('dev.renobooking.com', '').replace('staging.renobooking.com', '').replace('renobooking.com', '');
    }

}
