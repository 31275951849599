<div class="wrap-bg">
    <div class="container">
        <div class="compare-providers">
            <div class="title-green">
                <h3>
                    {{ 'feedback_project_status.top_slogan' | translate:{firstName: firstName} }}
                </h3>
            </div>
            <div class="title">
                <h4>
                    {{ 'feedback_project_status.header' | translate }}
                </h4>
                <p>
                    {{ 'feedback_project_status.header_desc' | translate }}
                </p>
            </div>
            <div class="wrap_compare-prov-table">
                <div class="Ftable Ftable-stripped compare-prov-table">
                    <div class="Ftable-row Ftable-header">
                        <div class="Ftable-cell cell-company_name"></div>
                        <div class="Ftable-cell cell-online_offer">
                            <span>{{ 'feedback_project_status.preliminary_offer_p1_bold' | translate }}</span>{{ 'feedback_project_status.preliminary_offer_p2' | translate }}
                        </div>
                        <div class="Ftable-cell cell-contact">
                            {{ 'feedback_project_status.in_contact_p1' | translate }}<span>{{ 'feedback_project_status.in_contact_p2_bold' | translate }}</span>{{ 'feedback_project_status.in_contact_p3' | translate }}
                        </div>
                        <div class="Ftable-cell cell-inspect_property">
                            {{ 'feedback_project_status.inspected_p1' | translate }}<span>{{ 'feedback_project_status.inspected_p2_bold' | translate }}</span>
                        </div>
                        <div class="Ftable-cell cell-final_offer">
                            {{ 'feedback_project_status.final_offer_p1' | translate }}<span>{{ 'feedback_project_status.final_offer_p2_bold' | translate }}</span>{{ 'feedback_project_status.final_offer_p3' | translate }}
                        </div>
                        <div class="Ftable-cell cell-tips"></div>
                    </div>
                    <div [ngClass]="{'active':i%2===1, 'Ftable-row':true}" *ngFor="let feedbackItem of feedbackUserStatus?.items; let i = index">
                        <div class="Ftable-cell cell-company_name">
                            <a [href]="'/offer/'+feedbackItem.providerId" target="_blank">{{ feedbackItem.providerName }}</a>
                        </div>
                        <div [ngClass]="{'active': feedbackItem.leadStatusId == 1, 'Ftable-cell':true, 'cell-online_offer':true}" (click)="updateStatus(feedbackItem.providerId, 1)">
                            <div class="wrap-img">
                                <div class="cell-image"></div>
                            </div>
                            <span class="price">
                                {{feedbackItem.currencySign}}&nbsp;{{feedbackItem.totalPrice | number:'1.0-0'}}
                            </span>
                        </div>
                        <div [ngClass]="{'active': feedbackItem.leadStatusId == 3, 'Ftable-cell':true, 'cell-contact':true}" (click)="updateStatus(feedbackItem.providerId, 3)">
                            <div class="cell-image"></div>
                        </div>
                        <div [ngClass]="{'active': feedbackItem.leadStatusId == 4, 'Ftable-cell':true, 'cell-inspect_property':true}" (click)="updateStatus(feedbackItem.providerId, 4)">
                            <div class="cell-image"></div>
                        </div>
                        <div [ngClass]="{'active': feedbackItem.leadStatusId == 5, 'Ftable-cell':true, 'cell-final_offer':true}" (click)="updateStatus(feedbackItem.providerId, 5)">
                            <div class="cell-image"></div>
                        </div>
                        <div class="Ftable-cell cell-tips" *ngIf="feedbackItem.hint && feedbackItem.hint.hintType == 0">
                            {{feedbackItem.hint.contactTextBeforePhone}} {{feedbackItem.hint.contactPhone}} {{feedbackItem.hint.contactTextBeforeEmail}} {{feedbackItem.hint.contactEmail}}
                        </div>
                        <div class="Ftable-cell cell-tips" *ngIf="feedbackItem.hint && feedbackItem.hint.hintType == 1">
                            {{feedbackItem.hint.articleDescription}} <a [href]="'/article?id='+feedbackItem.hint.articleId" target="_blank">{{feedbackItem.hint.articleLinkText}}</a>
                        </div>
                        <div class="Ftable-cell cell-tips" *ngIf="!feedbackItem.hint">
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrap_table-comparison">
                <div class="title-flesh">
                    <h3>
                        {{ 'feedback_project_status.comparison_table_header' | translate:{date: feedbackUserStatus?.date } }}
                    </h3>
                    <span class="subtitle">
                        {{ 'feedback_project_status.comparison_table_header_desc' | translate }}
                    </span>
                </div>
                <comparison-table></comparison-table>
            </div>
        </div>
    </div>
</div>
