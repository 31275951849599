export class ProviderScoreModel {
    public providerId!: string;
    public providerName!: string;
    public statusId!: number;
    public interaction!: number;
    public competence!: number;
    public prices!: number;
    public quality!: number;
    public nps!: number;
    public continue!: number;
    public positiveComment!: string;
    public negativeComment!: string;

    public showScore!: boolean;

    public getScore() {
        let result = 0;
        let eligible = 0;
        if (this.interaction > 0) {
            result += this.interaction;
            eligible += 4;
        }
        if (this.competence > 0) {
            result += this.competence;
            eligible += 4;
        }
        if (this.prices > 0) {
            result += this.prices;
            eligible += 4;
        }
        if (this.quality > 0) {
            result += this.quality;
            eligible += 4;
        }
        if (this.nps > 0) {
            result += this.nps * 3;
            eligible += 4 * 3;
        }
        if (this.continue > 0) {
            result += this.continue * 2;
            eligible += 4 * 2;
        }
        if (result <= 0) {
            return undefined;
        }
        return Math.round(10.0 * result / eligible * 2) / 2;
    }

    public allScoresSet() {
        return this.interaction && this.competence && this.prices && this.quality && this.nps && this.continue;
    }
}
