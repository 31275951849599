<ul class="mobile-tabs">
    <li [ngClass]="{'active': activeTabId === 0}">
        <a (click)="selectTab(0)"></a>
    </li>
    <li [ngClass]="{'active': activeTabId === 1}">
        <a (click)="selectTab(1)"></a>
    </li>
    <li [ngClass]="{'active': activeTabId === 2}">
        <a (click)="selectTab(2)"></a>
    </li>
</ul>
<!-- add class "two-lines" to widget-block, when there are 3-4 tiles -->
<div [ngClass]="{'widget-block':true, 'two-lines':true, 'col-xl-6':true, 'active':activeTabId === 0}"
     (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
    <div [ngClass]="{'wrap':true, 'loading-small': loading}" *ngIf="serviceAvailable && initialLoadingDone">
        <h4>
            {{ 'WORKAREAS_SELECTOR.select_project_plug_p1' | translate }}
        </h4>
        <div class="tiles">
            <div class="tiles-block">
                <h6>
                    {{ 'WORKAREAS_SELECTOR.select_project_plug_p2' | translate }}
                </h6>
                <div class="tiles-wrap">
                    <div *ngFor="let plug of projectPlugs" [ngClass]="{'tile': true, 'active': plug.isActive}" (click)="selectProjectPlug(plug)">
                        {{ plug.name }}
                    </div>
                </div>
            </div>
            <div class="tiles-block" *ngIf="!hasExistingProject">
                <h6>
                    {{ 'WORKAREAS_SELECTOR.from_scratch_desc' | translate }}
                </h6>
                <div class="tile tile-full_width" (click)="openCustomProject()">
                    <span>
                        {{ 'WORKAREAS_SELECTOR.from_scratch' | translate }}
                    </span>
                </div>
            </div>
            <div class="tiles-block" *ngIf="hasExistingProject">
                <h6>
                    {{ 'WORKAREAS_SELECTOR.existing_project_desc' | translate }}
                </h6>
                <div [ngClass]="{ 'active':existingProjectSelected, 'tile':true, 'tile-full_width':true }" (click)="existingProjectClick()">
                    <span>
                        {{ 'WORKAREAS_SELECTOR.existing_project' | translate }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="{'wrap':true, 'loading-small': loading}" *ngIf="!serviceAvailable && initialLoadingDone">
        <h3>{{ 'WORKAREAS_SELECTOR.service_not_available' | translate:{locationName: currentLocationName} }}</h3>
    </div>
    <div [ngClass]="{'wrap':true, 'loading-small': true}" *ngIf="!initialLoadingDone">
        <h3>&nbsp;</h3>
    </div>
</div>
<div [ngClass]="{'widget-block':true, 'col-xl-2':true, 'active':activeTabId === 1}"
     (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
    <div [ngClass]="{'wrap':true, 'loading-small': loading}">
        <h4>
            {{ 'WORKAREAS_SELECTOR.surface_question_p1' | translate }}
            <span class="wrap-icon-info">
                <span class="icon-info d-none d-xl-inline-block" tooltip="{{ 'WORKAREAS_SELECTOR.area_tooltip' | translate }}"></span>
            </span>
            {{ 'WORKAREAS_SELECTOR.surface_question_p2' | translate }}
        </h4>
        <p class="d-xl-none">
            {{ 'WORKAREAS_SELECTOR.area_example' | translate }}
        </p>
        <div class="dropdown" dropdown>
            <button dropdownToggle class="btn btn-white-green btn-small-extra dropdown-toggle" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {{areaSqm}} {{ 'sqm' | translate }}
            </button>
            <ul *dropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <li class="dropdown-item" *ngFor="let area of [5, 10, 15, 20, 30, 50, 70, 100, 150, 200, 300, 400]" (click)="setAreaSqm(area)">
                {{ area }}
              </li>
            </ul>
        </div>
    </div>
</div>
<div [ngClass]="{'widget-block':true, 'col-xl-2':true, 'active':activeTabId === 2}"
     (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
    <div [ngClass]="{'wrap':true, 'loading-small': loading}">
        <h4>
            {{ 'WORKAREAS_SELECTOR.location_question' | translate }}
        </h4>
        <p class="d-xl-none"></p>
        <div class="dropdown" dropdown>
            <button dropdownToggle class="btn btn-white-green btn-small-extra dropdown-toggle" type="button" id="dropdownMenuButton2"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" tooltip="{{ currentLocationName }}">
                {{ currentLocationName }}
            </button>
            <div *dropdownMenu class="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                <ul class="list-countries">
                    <li *ngFor="let cm of countryMarkets">
                        <strong>
                            {{cm.countryName}}
                        </strong>
                        <ul class="list-cities">
                            <a *ngFor="let market of cm.markets" class="dropdown-item"
                               (click)="setLocationFromMarket(market)">
                                {{ market.localName }}
                            </a>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="widget-button widget-block col-xl-2">
    <div class="wrap">
        <button [ngClass]="{'btn':true, 'btn-green':true, 'disabled':!isContinueButtonEnabled(), 'loading-small':(loading || continueButtonLoading) }"
                (click)="continueBtnClick()">
            {{ getContinueButtonText() | translate }}
        </button>
    </div>
</div>
