import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInComponent } from './components/register/signin.component';
import { TestComponent } from './test/test.component';
import { MyProjectsComponent } from './components/projects/my-projects.component';
import { JoinComponent } from './components/join/join.component';
import { FaqComponent } from './components/faq/faq.page';
import { FaqResolve } from './shared/faq/FaqResolve';
import { HowItWorksComponent } from './components/how-it-works/how-it-works.page';
import { ServiceUseComponent } from './components/service-use/service-use.component';
import { ServiceUseResolve } from './shared/service-use/ServiceUseResolve';
import { ArticlesComponent } from './components/articles/articles-search.component';
import { ArticleBodyComponent } from './components/articles/article-body.component';
import { ArticleResolve } from './shared/content/ArticleResolve';
import { FeedbackResolve } from './shared/feedback/FeedbackResolve';
import { FeedbackProjectStatusComponent } from './components/feedback/feedback-project-status.component';
import { FeedbackProviderScoreComponent } from './components/feedback/feedback-provider-score.component';
import { FeedbackUnsubscribeComponent } from './components/feedback/feedback-unsubscribe.component';
import { FeedbackProviderInitiatedComponent } from './components/feedback/feedback-provider-initiated.component';
import { FeedbackProviderInitiatedResolve } from './shared/feedback/FeedbackProviderInitiatedResolve';
import { FeedbackProviderInitiatedDoneComponent } from './components/feedback/feedback-provider-initiated-done.component';
import { OfferPreviewPageComponent } from './components/comparison/offer-preview.page';
import { ProviderDetailsResolve } from './shared/providers/ProviderDetailsResolve';
import { RegisterComponent } from './components/register/register.component';
import { RegisterConfirmationComponent } from './components/register/register-confirmation.component';
import { ForgotPasswordComponent } from './components/register/forgot-password.component';
import { ResetPasswordComponent } from './components/register/reset-password.component';
import { ComparisonComponent } from './components/comparison/comparison.component';
import { ProviderDetailsPageComponent } from './components/providers/provider-details.page';
import { ProjectEditComponent } from './components/project-edit/project-edit.component';
import { ProjectUploadsComponent } from './components/project-edit/project-uploads.component';
import { SearchComponent } from './components/search/search.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { SearchResultsRedirectComponent } from './components/search-results/searchResults-redirect.component';
import { OfferDetailsComponent } from './components/search-results/offer-details.component';
import { L10nService } from './shared/l10n.service';

let baseRoutes: Routes = [
    {
        path: '',
        redirectTo: 'search',
        pathMatch: 'full'
    },
    {
        path: 'search', component: SearchComponent,
    },
    {
        path: 'search-results', component: SearchResultsComponent
    },
    {
        path: 'searchResults', component: SearchResultsRedirectComponent,
    },
    {
        path: 'offer/:id', component: OfferDetailsComponent
    },
    {
        path: 'register', component: RegisterComponent
    },
    {
        path: 'registerConfirmation', component: RegisterConfirmationComponent
    },
    {
        path: 'signin', component: SignInComponent
    },
    {
        path: 'article', component: ArticleBodyComponent,
        resolve: {
            article: ArticleResolve
        }
    },
    {
        path: 'forgotPassword', component: ForgotPasswordComponent
    },
    {
        path: 'resetPassword', component: ResetPasswordComponent
    },
    {
        path: 'comparison', component: ComparisonComponent
    },
    {
        path: 'how-it-works', component: HowItWorksComponent
    },
    {
        path: 'articles-search', component: ArticlesComponent
    },
    {
        path: 'feedback-project-status', component: FeedbackProjectStatusComponent,
        resolve: {
            feedback: FeedbackResolve
        }
    },
    {
        path: 'feedback-provider-score', component: FeedbackProviderScoreComponent
    },
    {
        path: 'unsubscribe', component: FeedbackUnsubscribeComponent
    },
    {
        path: 'projects', component: MyProjectsComponent
    },
    {
        path: 'faq', component: FaqComponent,
        resolve: {
            faq: FaqResolve
        }
    },
    {
        path: 'join', component: JoinComponent
    },
    {
        path: 'service-use', component: ServiceUseComponent,
        resolve: {
            dto: ServiceUseResolve
        }
    },
    {
        path: 'provider-initiated-feedback/:id', component: FeedbackProviderInitiatedComponent,
        resolve: {
            dto: FeedbackProviderInitiatedResolve
        }
    },
    {
        path: 'provider/:id', component: ProviderDetailsPageComponent,
        resolve: {
            dto: ProviderDetailsResolve
        }
    },
    {
        path: 'provider-initiated-feedback-done', component: FeedbackProviderInitiatedDoneComponent,
    },
    {
        path: 'edit-project', component: ProjectEditComponent,
    },
    {
        path: 'project-uploads', component: ProjectUploadsComponent,
    },
    {
        path: 'offer-preview/:id', component: OfferPreviewPageComponent,
    },
];

function createLocalizedRoutes(route: any, langs: string[]) {
    return langs.map(lang => {
        return {
            ...route,
            path: `${lang}/${route.path}`
        };
    });
}

let langs = L10nService.supportedLangs;
let routes = [...baseRoutes];
for (let i = 0; i < baseRoutes.length; ++i) {
    routes =  [...routes, ...createLocalizedRoutes(baseRoutes[i], langs)];
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
