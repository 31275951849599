import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AccountService } from '../../shared/account/account.service';
import { SeoService } from '../../shared/seo.service';

@Component({
    selector: 'signin',
    templateUrl: './signin.component.html'
})
export class SignInComponent implements OnInit {
    public loading: boolean = false;
    public email?: string;
    public password?: string;
    public error: string | null = null;
    private returnUrl: string | null = null;

    constructor(public translate: TranslateService, private accountService: AccountService,
        private router: Router, private route: ActivatedRoute, private seoService: SeoService) {
    }

    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        this.translate.get(['login.title', 'login.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['login.title'], res['login.desc']);
        });
    }

    signInButtonEnabled(): boolean {
        return !!this.email && !!this.password;
    }

    btnSignInClick(): void {
        if (this.loading || !this.signInButtonEnabled()) {
            return;
        }

        this.error = null;
        let self = this;
        this.accountService.login(this.email as string, this.password as string).then(error => {
            if (error) {
                self.loading = false;
                this.error = error;
            } else {
                if (this.returnUrl) {
                    self.router.navigateByUrl(this.returnUrl);
                } else {
                    self.router.navigateByUrl('/');
                }
            }
        }).catch(error => {
            self.loading = false;
            this.error = error.message;
        });
    }

    removeError() {
        this.error = null;
    }
}
