<div class="bottom-part">
    <div class="container">
        <div class="bottom-nav">
            <div class="bottom-nav-column">
                <ul class="bottom-nav-column-list">
                    <li *ngFor="let mwa of masterWorkAreas1">
                        <a (click)="selectMwaAndSearch(mwa)">
                            {{mwa.name}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="bottom-nav-column">
                <ul class="bottom-nav-column-list">
                    <li *ngFor="let mwa of masterWorkAreas2">
                        <a (click)="selectMwaAndSearch(mwa)">
                            {{mwa.name}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="bottom-nav-column">
                <ul class="bottom-nav-column-list">
                    <li *ngFor="let market of markets">
                        <a (click)="setMarketAndSearch(market)">
                            {{market.localName}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="bottom-nav-column">
                <ul class="bottom-nav-column-list">
                    <li>
                        <a routerLink="/how-it-works">
                            {{ 'how_it_works_menu' | translate}}
                        </a>
                    </li>
                    <li>
                        <a routerLink="/faq">
                            {{ 'footer.bottom.faq' | translate}}
                        </a>
                    </li>
                    <li>
                        <a routerLink="/join">
                            {{ 'join_as_pro' | translate}}
                        </a>
                    </li>
                    <li>
                        <a (click)="searchWithSortBy(ProvidersSorting.Price)">
                            {{ 'footer.bottom.search_results_sorted_by_price' | translate}}
                        </a>
                    </li>
                    <li>
                        <a (click)="searchWithSortBy(ProvidersSorting.Reviews)">
                            {{ 'footer.bottom.search_results_sorted_by_reviews' | translate}}
                        </a>
                    </li>
                </ul>
            </div>
            <div class="bottom-nav-column">
                <ul class="bottom-nav-column-list">
                    <li>
                        <a routerLink="/how-it-works">
                            {{ 'footer.bottom.about' | translate}}
                        </a>
                    </li>
                    <li>
                        <a routerLink="/service-use">
                            {{ 'footer.bottom.terms' | translate}}
                        </a>
                    </li>
                    <li>
                        <a routerLink="/service-use" fragment="privacy">
                            {{ 'footer.bottom.privacy' | translate}}
                        </a>
                    </li>
                    <li>
                        <a routerLink="/service-use" fragment="cookies">
                            {{ 'footer.bottom.cookies' | translate}}
                        </a>
                    </li>
                    <li>
                        <a routerLink="/search">
                            {{ 'footer.bottom.contact_us' | translate}}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="title">
            {{ 'footer.bottom.bottom_slogan' | translate}}
        </div>
    </div>
</div>
