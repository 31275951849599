<div class="top-banner top-banner_search">
    <h1>
        {{'search.top_slogan' | translate}}
    </h1>
    <div class="container">
        <!-- @defer (on viewport) { -->
            <initial-workareas-select class="top-banner_search-widget row text-center">
            </initial-workareas-select>
        <!-- } @placeholder {
            <h3>Loading...</h3>
        } -->
    </div>
</div>
<div class="features">
    <div class="container">
        <features-set></features-set>
    </div>
    <div class="features-points d-none d-lg-block">
        <div class="container">
            <div class="row">
                <div class="col-4">
                    <ul>
                        <li>
                            {{'search.bullet_points.p1' | translate}}
                        </li>
                        <li>
                            {{'search.bullet_points.p2' | translate}}
                        </li>
                        <li>
                            {{'search.bullet_points.p3' | translate}}
                        </li>
                    </ul>
                </div>
                <div class="col-4">
                    <ul>
                        <li>
                            {{'search.bullet_points.p4' | translate}}
                        </li>
                        <li>
                            {{'search.bullet_points.p5' | translate}}
                        </li>
                        <li>
                            {{'search.bullet_points.p6' | translate}}
                        </li>
                    </ul>
                </div>
                <div class="col-4">
                    <ul>
                        <li>
                            {{'search.bullet_points.p7' | translate}}
                        </li>
                        <li>
                            {{'search.bullet_points.p8' | translate}}
                        </li>
                        <li>
                            {{'search.bullet_points.p9' | translate}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="container" *ngIf="false && showMap">
    <div class="wrap-providers">
        <div class="title">
            <h2>
                {{ 'providers_map.providers_in' | translate }} {{currentLocationName}}
            </h2>
        </div>
        <providers-map class="providers">
        </providers-map>
    </div>
</div>

<div class="container">
    <div class="projects">
        <h2>
            {{ 'search.galleries_header' | translate }}
        </h2>
        <div class="general-slider row">
            <vertical-gallery class="col-xl-4 col-md-6 col-12">
            </vertical-gallery>
            <vertical-gallery class="col-xl-4 col-md-6 col-12">
            </vertical-gallery>
            <vertical-gallery class="col-xl-4 col-md-6 col-12">
            </vertical-gallery>
        </div>
    </div>
</div>

