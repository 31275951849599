import { Component, OnInit, Input } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from '@kolkov/ngx-gallery';

import { GalleryPic } from '../../shared/galleries/GalleryPic';
import { GalleryService } from '../../shared/galleries/gallery.service';

@Component({
    selector: 'gallery-light',
    templateUrl: './gallery-light.component.html'
})
export class GalleryLightComponent implements OnInit {
    id: string;
    galleryOptions: NgxGalleryOptions[] | undefined;
    galleryImages: NgxGalleryImage[] = [];
    public showGallery: boolean = true;
    @Input() providerId!: string;
    @Input() mode!: string;
    @Input() height!: string;
    @Input() showImage: boolean = true;
    public picsNotLoadedYet: boolean = true;

    constructor(private galleryService: GalleryService) {
        this.id = this.generateGuid();
    }

    ngOnInit() {
        this.galleryService.registerGalleryWithProviderId(this);
        this.initGalleryOptions();
        this.setGalleryOptions();
    }

    public setPics(pics: GalleryPic[]): void {
        this.initGalleryOptions();

        pics = pics || [];
        if (!pics.length) {
            const placeholderPicUrl = 'assets/img/provider_pic_placeholder.png';
            this.galleryImages = [{ small: placeholderPicUrl, medium: placeholderPicUrl, big: placeholderPicUrl }];
        } else {
            if (this.mode == 'details') {
                this.galleryImages = pics.map(p => { return { small: p.medResUrl, medium: p.medResUrl, big: p.hiResUrl, description: p.providerName } });
            } else {
                this.galleryImages = pics.map(p => { return { small: p.loResUrl, medium: p.medResUrl, big: p.hiResUrl, description: p.providerName } });
            }
        }

        this.picsNotLoadedYet = false;
        this.setGalleryOptions();
        this.showGallery = true;
    }

    private initGalleryOptions() {
        if (this.galleryOptions) {
            return;
        }
        if (this.mode == 'details') {
            this.galleryOptions = [
                {
                    image: false,
                    imageArrowsAutoHide: true,
                    width: '100%',
                    height: this.height ? this.height : '240px',
                    thumbnailsColumns: 3,
                    thumbnailsRows: 1,
                    thumbnailsPercent: 100,
                    imagePercent: 0,
                    thumbnailMargin: 20,
                    thumbnailsMargin: 2,
                    imageAnimation: NgxGalleryAnimation.Slide,
                    previewSwipe: true,
                    imageInfinityMove: true,
                    preview: true,
                    previewFullscreen: true,
                    previewCloseOnEsc: true,
                    previewAnimation: true,
                }
            ]
        } else {
            this.galleryOptions = [
                {
                    image: !!this.showImage,
                    width: '100%',
                    height: this.height ? this.height : '210px',
                    thumbnails: true,
                    imageAnimation: NgxGalleryAnimation.Slide,
                    imageSwipe: true,
                    previewSwipe: true,
                    imageInfinityMove: true,
                    imageAutoPlay: false,
                    imageAutoPlayPauseOnHover: true,
                    preview: true,
                    previewFullscreen: true,
                    previewCloseOnEsc: true,
                    previewInfinityMove: true,
                    previewAnimation: true,
                    thumbnailsMargin: 0,
                    thumbnailMargin: 0,
                    thumbnailsColumns: 3,
                    arrowPrevIcon: "fa fa-chevron-left",
                    arrowNextIcon: "fa fa-chevron-right",
                    thumbnailsRemainingCount: true
                }
            ];
        }
    }

    private setGalleryOptions() {
        if (this.picsNotLoadedYet) {
            return;
        }
        if (this.galleryOptions) {
            if (this.galleryImages.length == 1) {
                this.galleryOptions[0].thumbnails = false;
                this.galleryOptions[0].preview = false;
                this.galleryOptions[0].imageArrows = false;
            } else if (this.galleryImages.length > 1) {
                this.galleryOptions[0].thumbnails = true;
                this.galleryOptions[0].preview = true;
                this.galleryOptions[0].imageArrows = true;
            }
        }
    }

    public hasPics(): boolean {
        return this.galleryImages.length > 0;
    }

    private generateGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    private areSamePics(pics1: GalleryPic[], pics2: NgxGalleryImage[]): boolean {
        if (pics1.length != pics2.length) {
            return false;
        }
        for (let i = 0; i < pics1.length; ++i) {
            if (pics1[i].hiResUrl != pics2[i].url) {
                return false;
            }
        }
        return true;
    }
}
