<div class="container">
    <nav class="go-back">
        <a langRouterLink routerLink="/search-results" class="btn btn-green">
            {{ 'project_edit.link_back' | translate }}
        </a>
        <span>
            {{ 'project_edit.to_n_offers' | translate: {selectedOffersNum: offersNum } }}
        </span>
    </nav>
    <div class="benefits">
        <div class="wrap">
            <img src="assets/img/arrow-orange-bottom-double.svg" width="21">
            <b>
                <span class="d-sm-none">{{ 'project_edit.slogan_1_mobile' | translate }}</span>
                <span class="d-none d-sm-inline">{{ 'project_edit.slogan_1' | translate }}</span>
            </b>
        </div>
        <div class="wrap">
            <img src="assets/img/arrow-green-right.svg" width="25">
            <b>
                <span class="d-sm-none">{{ 'project_edit.slogan_2_mobile' | translate }}</span>
                <span class="d-none d-sm-inline">{{ 'project_edit.slogan_2' | translate }}</span>
            </b>
        </div>
    </div>
</div>