import { Component, OnInit, NgZone } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { SeoService } from '../../shared/seo.service';
import { DisplayService } from '../../shared/display.service';
import { ActivatedRoute } from '@angular/router';
import { FaqParagraph } from '../../shared/faq/faqParagraph';

@Component({
    selector: 'faq.component',
    templateUrl: './faq.page.html'
})
export class FaqComponent implements OnInit {
    faq: FaqParagraph[][] = [];

    constructor(public translate: TranslateService, private seoService: SeoService,
        private displayService: DisplayService, private activatedRoute: ActivatedRoute, private ngZone: NgZone) {
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((data: any) => {
            this.loadFaq(data.faq);
        });
        this.activatedRoute.fragment.subscribe((fragment: any) => {
            this.scrollToFragment(fragment);
        })
        this.translate.get(['faq.title', 'faq.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['faq.title'], res['faq.desc']);
        });
    }

    scrollToChapter(chapterNum: number): void {
        let tagId = 'faq-chapter' + chapterNum;
        this.scrollToFragment(tagId);
    }
    
    scrollToFragment(fragment: string): void {
        this.displayService.runOutsideAngular(this.ngZone, () => {
            let elem = document.getElementById(fragment);
            if (elem) {
                elem.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }, 200);
    }

    private loadFaq(faq: FaqParagraph[]): void {
        this.faq = [];
        for (let i = 1; i <= 5; ++i) {
            this.faq.push(faq.filter(p => p.chapterId == i).sort((p1, p2) => p1.sortOrder - p2.sortOrder));
        }
    }
}
