<div class="wrap-bg">
<div class="title-green top font-small feedback-title">
    <h3 *ngIf="step===1">
        {{ 'feedback_provider_initiated.header1' | translate:{providerName: score.providerName} }}
    </h3>
    <h3 *ngIf="step===2">
        {{ 'feedback_provider_initiated.header2' | translate:{providerName: score.providerName} }}
    </h3>
    <h3 *ngIf="step===3">
        {{ 'feedback_provider_initiated.header3' | translate }}
    </h3>
    <h3 *ngIf="step===4">
        {{ 'feedback_provider_initiated.header4' | translate }}
    </h3>
    <h3 *ngIf="step===5">
        {{ 'feedback_provider_initiated.header5' | translate }}
    </h3>
</div>
<div class="container">
<div class="score-providers review" *ngIf="step===1">
<div>
<div class="wrap-feedback-content">
<div class="content-block count-progress">
    <h5>
        {{ 'feedback_provider_score.header' | translate:{providerName: score.providerName} }}
    </h5>
    <div class="cells">
        <div [ngClass]="{'active':score.statusId==1, 'cell':true, 'cell-online_offer':true}" (click)="setScore('statusId', 1)">
            <span>
                {{ 'feedback_provider_score.preliminary_offer_p1' | translate }}<br> {{ 'feedback_provider_score.preliminary_offer_p2' | translate }}
            </span>
            <div class="cell-image"></div>
        </div>
        <div [ngClass]="{'active':score.statusId==3, 'cell':true, 'cell-contact':true}" (click)="setScore('statusId', 3)">
            <span>
                {{ 'feedback_provider_score.connected_by_phone_p1' | translate }}<br> {{ 'feedback_provider_score.connected_by_phone_p2' | translate }}
            </span>
            <div class="cell-image"></div>
        </div>
        <div [ngClass]="{'active':score.statusId==4, 'cell':true, 'cell-inspect_property':true}" (click)="setScore('statusId', 4)">
            <span>
                {{ 'feedback_provider_score.property_inspected_p1' | translate }}<br> {{ 'feedback_provider_score.property_inspected_p2' | translate }}
            </span>
            <div class="cell-image"></div>
        </div>
        <div [ngClass]="{'active':score.statusId==5, 'cell':true, 'cell-final_offer':true}" (click)="setScore('statusId', 5)">
            <span>
                {{ 'feedback_provider_score.final_offer_p1' | translate }}<br> {{ 'feedback_provider_score.final_offer_p2' | translate }}
            </span>
            <div class="cell-image"></div>
        </div>
        <div [ngClass]="{'active':score.statusId==7, 'cell':true, 'cell-contract_signed':true}" (click)="setScore('statusId', 7)">
            <span>
                {{ 'feedback_provider_score.contract_signed_p1' | translate }}<br> {{ 'feedback_provider_score.contract_signed_p2' | translate }}
            </span>
            <div class="cell-image"></div>
        </div>
        <div [ngClass]="{'active':score.statusId==8, 'cell':true, 'cell-work_started':true}" (click)="setScore('statusId', 8)">
            <span>
                {{ 'feedback_provider_score.work_started_p1' | translate }}<br> {{ 'feedback_provider_score.work_started_p2' | translate }}
            </span>
            <div class="cell-image"></div>
        </div>
        <div [ngClass]="{'active':score.statusId==9, 'cell':true, 'cell-work_completed':true}" (click)="setScore('statusId', 9)">
            <span>
                {{ 'feedback_provider_score.project_completed_p1' | translate }}<br> {{ 'feedback_provider_score.project_completed_p2' | translate }}
            </span>
            <div class="cell-image"></div>
        </div>
    </div>
</div>
<div class="content-block score-company">
<h5>
    {{ 'feedback_provider_score.questions_header' | translate:{providerName: score.providerName} }}
</h5>
<div class="Ftable Ftable-stripped score-company-table">
<div class="Ftable-row Ftable-header">
    <div class="Ftable-cell"></div>
    <div class="Ftable-cell">
        {{ 'feedback_provider_score.answers.answer1' | translate }}
    </div>
    <div class="Ftable-cell">
        {{ 'feedback_provider_score.answers.answer2' | translate }}
    </div>
    <div class="Ftable-cell">
        {{ 'feedback_provider_score.answers.answer3' | translate }}
    </div>
    <div class="Ftable-cell">
        {{ 'feedback_provider_score.answers.answer4' | translate }}
    </div>
    <div class="Ftable-cell"></div>
</div>
<div class="Ftable-row">
    <div [ngClass]="{'active': score.interaction==-1, 'Ftable-cell':true}" (click)="setScore('interaction', -1)">
        "{{ 'feedback_provider_score.questions.interaction' | translate }}"
        <span class="feedback-value feedback-value_mobile">
            {{ 'feedback_provider_score.answers.early_to_say' | translate }}
        </span>
    </div>
    <div [ngClass]="{'active':score.interaction==1, 'gray':score.interaction, 'Ftable-cell':true, 'cell-feedback-sad':true, 'feedback-value':true}" (click)="setScore('interaction', 1)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer1' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.interaction==2, 'gray':score.interaction, 'Ftable-cell':true, 'cell-feedback-neutral':true, 'feedback-value':true}" (click)="setScore('interaction', 2)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer2' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.interaction==3, 'gray':score.interaction, 'Ftable-cell':true, 'cell-feedback-smile':true, 'feedback-value':true}" (click)="setScore('interaction', 3)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer3' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.interaction==4, 'gray':score.interaction, 'Ftable-cell':true, 'cell-feedback-happy':true, 'feedback-value':true}" (click)="setScore('interaction', 4)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer4' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.interaction==-1, 'gray':score.interaction, 'Ftable-cell':true, 'feedback-value':true}" (click)="setScore('interaction', -1)">
        <span class="text">
            {{ 'feedback_provider_score.answers.early_to_say' | translate }}
        </span>
    </div>
</div>
<div class="Ftable-row">
    <div [ngClass]="{'active': score.competence==-1, 'Ftable-cell':true}" (click)="setScore('competence', -1)">
        <span class="text">
            "{{ 'feedback_provider_score.questions.competence_p1' | translate }}<span class="d-sm-inline d-none"> {{ 'feedback_provider_score.questions.competence_p2' | translate }}</span>"
        </span>
        <span class="feedback-value feedback-value_mobile">
            {{ 'feedback_provider_score.answers.early_to_say' | translate }}
        </span>
    </div>
    <div [ngClass]="{'active':score.competence==1, 'gray':score.competence, 'Ftable-cell':true, 'cell-feedback-sad':true, 'feedback-value':true}" (click)="setScore('competence', 1)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer1' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.competence==2, 'gray':score.competence, 'Ftable-cell':true, 'cell-feedback-neutral':true, 'feedback-value':true}" (click)="setScore('competence', 2)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer2' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.competence==3, 'gray':score.competence, 'Ftable-cell':true, 'cell-feedback-smile':true, 'feedback-value':true}" (click)="setScore('competence', 3)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer3' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.competence==4, 'gray':score.competence, 'Ftable-cell':true, 'cell-feedback-happy':true, 'feedback-value':true}" (click)="setScore('competence', 4)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer4' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.competence==-1, 'gray':score.competence, 'Ftable-cell':true, 'feedback-value':true}" (click)="setScore('competence', -1)">
        <span class="text">
            {{ 'feedback_provider_score.answers.early_to_say' | translate }}
        </span>
    </div>
</div>
<div class="Ftable-row">
    <div [ngClass]="{'active': score.prices==-1, 'Ftable-cell':true}" (click)="setScore('prices', -1)">
        <span class="text">
            "{{ 'feedback_provider_score.questions.prices' | translate }}"
        </span>
        <span class="feedback-value feedback-value_mobile">
            {{ 'feedback_provider_score.answers.early_to_say' | translate }}
        </span>
    </div>
    <div [ngClass]="{'active':score.prices==1, 'gray':score.prices, 'Ftable-cell':true, 'cell-feedback-sad':true, 'feedback-value':true}" (click)="setScore('prices', 1)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer1' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.prices==2, 'gray':score.prices, 'Ftable-cell':true, 'cell-feedback-neutral':true, 'feedback-value':true}" (click)="setScore('prices', 2)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer2' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.prices==3, 'gray':score.prices, 'Ftable-cell':true, 'cell-feedback-smile':true, 'feedback-value':true}" (click)="setScore('prices', 3)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer3' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.prices==4, 'gray':score.prices, 'Ftable-cell':true, 'cell-feedback-happy':true, 'feedback-value':true}" (click)="setScore('prices', 4)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer4' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.prices==-1, 'gray':score.prices, 'Ftable-cell':true, 'feedback-value':true}" (click)="setScore('prices', -1)">
        <span class="text">
            {{ 'feedback_provider_score.answers.early_to_say' | translate }}
        </span>
    </div>
</div>
<div class="Ftable-row">
    <div [ngClass]="{'active': score.quality==-1, 'Ftable-cell':true}" (click)="setScore('quality', -1)">
        <span class="text">
            "{{ 'feedback_provider_score.questions.quality' | translate }}"
        </span>
        <span class="feedback-value feedback-value_mobile">
            {{ 'feedback_provider_score.answers.early_to_say' | translate }}
        </span>
    </div>
    <div [ngClass]="{'active':score.quality==1, 'gray':score.quality, 'Ftable-cell':true, 'cell-feedback-sad':true, 'feedback-value':true}" (click)="setScore('quality', 1)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer1' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.quality==2, 'gray':score.quality, 'Ftable-cell':true, 'cell-feedback-neutral':true, 'feedback-value':true}" (click)="setScore('quality', 2)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer2' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.quality==3, 'gray':score.quality, 'Ftable-cell':true, 'cell-feedback-smile':true, 'feedback-value':true}" (click)="setScore('quality', 3)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer3' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.quality==4, 'gray':score.quality, 'Ftable-cell':true, 'cell-feedback-happy':true, 'feedback-value':true}" (click)="setScore('quality', 4)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer4' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.quality==-1, 'gray':score.quality, 'Ftable-cell':true, 'feedback-value':true}" (click)="setScore('quality', -1)">
        <span class="text">
            {{ 'feedback_provider_score.answers.early_to_say' | translate }}
        </span>
    </div>
</div>
<div class="Ftable-row">
    <div [ngClass]="{'Ftable-cell':true, 'active':score.nps==-1}" (click)="setScore('nps', -1)">
        <span class="text">
            "{{ 'feedback_provider_score.questions.nps' | translate }}"
        </span>
        <span class="feedback-value feedback-value_mobile">
            {{ 'feedback_provider_score.answers.early_to_say' | translate }}
        </span>
    </div>
    <div [ngClass]="{'active':score.nps==1, 'gray':score.nps, 'Ftable-cell':true, 'cell-feedback-sad':true, 'feedback-value':true}" (click)="setScore('nps', 1)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer1' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.nps==2, 'gray':score.nps, 'Ftable-cell':true, 'cell-feedback-neutral':true, 'feedback-value':true}" (click)="setScore('nps', 2)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer2' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.nps==3, 'gray':score.nps, 'Ftable-cell':true, 'cell-feedback-smile':true, 'feedback-value':true}" (click)="setScore('nps', 3)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer3' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.nps==4, 'gray':score.nps, 'Ftable-cell':true, 'cell-feedback-happy':true, 'feedback-value':true}" (click)="setScore('nps', 4)">
        <span class="mobile-header">
            {{ 'feedback_provider_score.answers.answer4' | translate }}
        </span>
        <div class="cell-image"></div>
    </div>
    <div [ngClass]="{'active':score.nps==-1, 'gray':score.nps, 'Ftable-cell':true, 'feedback-value':true}" (click)="setScore('nps', -1)">
        <span class="text">
            {{ 'feedback_provider_score.answers.early_to_say' | translate }}
        </span>
    </div>
</div>
</div>
</div>
<div class="content-block more-feedback">
    <h5>
        {{ 'feedback_provider_score.part2_header' | translate:{providerName: score.providerName} }}
    </h5>
    <div class="form-group">
        <label for="exampleFormControlTextarea1">
            {{ 'feedback_provider_score.positive_comment_p1' | translate }}<b>{{ 'feedback_provider_score.positive_comment_p2' | translate }}</b>
        </label>
        <textarea [(ngModel)]="score.positiveComment" class="form-control" id="exampleFormControlTextarea1"
                                      rows="3" placeholder="{{'feedback_provider_score.positive_comment_placeholder' | translate}}"></textarea>
    </div>
    <div class="form-group">
        <label for="exampleFormControlTextarea2">
            {{ 'feedback_provider_score.negative_comment_p1' | translate }}<b>{{ 'feedback_provider_score.negative_comment_p2' | translate }}</b>
        </label>
        <textarea [(ngModel)]="score.negativeComment" class="form-control" id="exampleFormControlTextarea2"
                                      rows="3" placeholder="{{'feedback_provider_score.negative_comment_placeholder' | translate}}"></textarea>
    </div>
</div>
<div class="content-block continue-working">
    <h5>
        {{ 'feedback_provider_score.part2_header' | translate:{providerName: score.providerName} }}
    </h5>
    <div class="Ftable Ftable-stripped score-company-table">
        <div class="Ftable-row Ftable-header">
            <div class="Ftable-cell"></div>
            <div [ngClass]="{'active':score.continue==1, 'Ftable-cell':true}" (click)="setScore('continue', 1)">
                {{ 'feedback_provider_score.answers.answer1' | translate }}
            </div>
            <div [ngClass]="{'active':score.continue==2, 'Ftable-cell':true}" (click)="setScore('continue', 2)">
                {{ 'feedback_provider_score.answers.answer2' | translate }}
            </div>
            <div [ngClass]="{'active':score.continue==3, 'Ftable-cell':true}" (click)="setScore('continue', 3)">
                {{ 'feedback_provider_score.answers.answer3' | translate }}
            </div>
            <div [ngClass]="{'active':score.continue==4, 'Ftable-cell':true}" (click)="setScore('continue', 4)">
                {{ 'feedback_provider_score.answers.answer4' | translate }}
            </div>
            <div class="Ftable-cell"></div>
        </div>
        <div class="Ftable-row">
            <div class="Ftable-cell">
            </div>
            <div [ngClass]="{'active':score.continue==1, 'Ftable-cell':true, 'cell-feedback-sad':true, 'feedback-value':true}" (click)="setScore('continue', 1)">
                <div class="cell-image"></div>
            </div>
            <div [ngClass]="{'active':score.continue==2, 'Ftable-cell':true, 'cell-feedback-neutral':true, 'feedback-value':true}" (click)="setScore('continue', 2)">
                <div class="cell-image"></div>
            </div>
            <div [ngClass]="{'active':score.continue==3, 'Ftable-cell':true, 'cell-feedback-smile':true, 'feedback-value':true}" (click)="setScore('continue', 3)">
                <div class="cell-image"></div>
            </div>
            <div [ngClass]="{'active':score.continue==4, 'Ftable-cell':true, 'cell-feedback-happy':true, 'feedback-value':true}" (click)="setScore('continue', 4)">
                <div class="cell-image"></div>
            </div>
            <div class="Ftable-cell">
            </div>
        </div>
    </div>
</div>
<div class="action">
    <a [ngClass]="{'disabled':!score.allScoresSet(), 'btn':true, 'btn-blue':true, 'btn-middle':true, 'w-100':true}" (click)="gotoStep(2)">
        {{ 'feedback_provider_initiated.btn_submit' | translate }}
    </a>
</div>
</div>
</div>
</div>
<div class="score-providers review green" *ngIf="step===2">
    <div class="wrap-feedback-content">
        <div class="wrap-back-link">
            <a (click)="gotoStep(1)">
                {{ 'feedback_provider_initiated.btn_back' | translate }}
            </a>
        </div>
        <form action="">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{'registration.firstname_header' | translate}}</label>
                        <input [(ngModel)]="firstName" [ngModelOptions]="{standalone: true}"  type="text" class="form-control" placeholder="{{'registration.firstname_placeholder' | translate}}">
                    </div>
                    <div class="form-group">
                        <label>{{'registration.surname_header2' | translate}}</label>
                        <input [(ngModel)]="lastName" [ngModelOptions]="{standalone: true}"  type="text" class="form-control" placeholder="{{'registration.lastname_placeholder' | translate}}">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{'registration.email' | translate}}</label>
                        <input [(ngModel)]="email" [ngModelOptions]="{standalone: true}" type="email" placeholder="{{ 'registration.email_placeholder' | translate }}" class="form-control">
                    </div>
                    <div class="form-group">
                        <label>{{ 'feedback_provider_initiated.renovation_value_header' | translate }}</label>
                        <div class="wrap-select">
                            <select class="select-custom form-control" required>
                                <option hidden="">2’000-5’000 €</option>
                                <option (click)="setPrice(1000)">0-2’000 €</option>
                                <option (click)="setPrice(3500)">2’000-5’000 €</option>
                                <option (click)="setPrice(7500)">5’000-10’000 €</option>
                                <option (click)="setPrice(15000)">10’000-20’000 €</option>
                                <option (click)="setPrice(35000)">20’000-50’000 €</option>
                                <option (click)="setPrice(75000)">50’000-100’000 €</option>
                                <option (click)="setPrice(120000)">> 100’000 €</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-check">
                <label>
                    <input [ngClass]="{ 'form-check-input':true }"type="checkbox" [checked]="termsAccepted" (change)="termsAccepted = !termsAccepted;" required>
                    <i class="checkbox-icon"></i>
                    {{ 'registration.I_read' | translate }}<a routerLink="/service-use" target="_blank">{{ 'registration.terms_conditions' | translate }}</a>
                </label>
            </div>
            <button class="btn btn-green btn-large w-100" [ngClass]="{'disabled':!allRegisterDataSet(), 'btn':true, 'btn-green':true, 'btn-large':true, 'w-100':true}" (click)="submit()">
                {{ 'feedback_provider_initiated.btn_submit' | translate }}
            </button>
        </form>
    </div>
</div>
<div class="score-providers review green" *ngIf="step===3">
    <div class="wrap-feedback-content">
        <div class="wrap-back-link">
            <a (click)="gotoStep(2)">
                {{ 'feedback_provider_initiated.btn_back' | translate }}
            </a>
        </div>
        <div class="wrap-text">
            <p>
                {{ 'feedback_provider_initiated.email_already_registered' | translate }}
            </p>
        </div>
        <form action="">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ 'registration.password_header' | translate }}</label>
                        <input [(ngModel)]="password" [ngModelOptions]="{standalone: true}" [attr.disabled]="false ? '' : null" type="password" class="form-control" placeholder="{{ 'registration.password_placeholder' | translate }}">
                        <a class="forgot-password-link" target="_blank" routerLink="/forgotPassword">{{ 'registration.forgot_password' | translate }}</a>
                    </div>
                </div>
            </div>
            <button class="btn btn-green btn-large w-100" [ngClass]="{'disabled':!password, 'btn':true, 'btn-green':true, 'btn-large':true, 'w-100':true}" (click)="submit()">
                {{ 'feedback_provider_initiated.btn_submit' | translate }}
            </button>
        </form>
    </div>
</div>
<div class="score-providers review green" *ngIf="step===4">
    <div class="wrap-feedback-content">
        <div class="wrap-text">
            <p>
                {{ 'feedback_provider_initiated.success1' | translate }}
            </p>
            <p>
                {{ 'feedback_provider_initiated.success2' | translate }}
            </p>
            <p>
                {{ 'feedback_provider_initiated.success3' | translate }}
            </p>
        </div>
        <div>
            <a class="btn btn-green btn-large w-100" routerLink="/">
                {{ 'feedback_provider_initiated.goto_home_link' | translate }}
            </a>
        </div>
    </div>
</div>
<div class="score-providers review green" *ngIf="step===5">
    <div class="wrap-feedback-content">
        <div class="wrap-text">
            <p>
                {{ 'feedback_provider_initiated.success1_1' | translate }}
            </p>
        </div>
    </div>
</div>
</div>
</div>
