<div class="modal-header">
    <h4 class="modal-title" id="editModalLabel">{{workItemName}}</h4>
    <p>
        {{workItemDescription}}
    </p>
</div>
<div class="modal-body">
    <form>
        <div class="quantity-block">
            <div class="row">
                <div class="col-sm-6">
                    <label for="quantity">
                        <b>
                            {{ 'edit_projectworkitemoption_modal.quantity' | translate }}
                        </b>
                    </label>
                </div>
                <div class="col-sm-6">
                    <input id="quantity" type="number" min="1" [(ngModel)]="quantity" (change)="qtyChanged()" name="fakename">
                    <span>
                        {{uom}}
                    </span>
                </div>
            </div>
        </div>
        <div class="type-block" *ngIf="workItemOptions && workItemOptions.length>1">
            <div>
                <b>
                    {{ 'edit_projectworkitemoption_modal.type' | translate }}
                </b>
            </div>
            <div class="radio-block">
                <div class="radio-block-row row" *ngFor="let workItemOption of workItemOptions">
                    <div class="col-12">
                        <div class="form-check">
                            <input type="radio" id="rb{{workItemOption.id}}" name="radio-group"
                                   [value]="workItemOption.id" [(ngModel)]="selectedWorkItemOptionId"
                                   [checked]="selectedWorkItemOptionId===workItemOption.id"
                                   (change)="wioChanged()">
                            <label for="rb{{workItemOption.id}}">{{workItemOption.name}}</label>
                        </div>
                        <div class="form-check">
                            <i>
                                {{workItemOption.description}}
                            </i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="actions" [ngClass]="{ 'loading':loading }">
        <button class="btn btn-transparent-blue btn-middle"
                (click)="close()">{{ 'edit_projectworkitemoption_modal.cancel' | translate }}</button>
        <button class="btn btn-blue btn-middle"
                (click)="save()">{{ 'edit_projectworkitemoption_modal.save' | translate }}</button>
        <button class="btn btn-transparent-red btn-middle" (click)="removeWorkItemOption()">{{ 'edit_projectworkitemoption_modal.remove' | translate }}</button>
    </div>
</div>
<div class="modal-footer" *ngIf="!locked" (click)="locked=true">
    <div class="locked-field unlocked">
        <b>
            {{ 'edit_projectworkitemoption_modal.unlocked' | translate }}
        </b>
        <div class="wrap-switch">
            <img src="assets/img/unlock-orange.svg" width="12" alt="">
            <div class="switch">
                <div class="slider"></div>
            </div>
            <img src="assets/img/lock-blue.svg" width="12" alt="">
        </div>
    </div>
    <p>
        {{ 'edit_projectworkitemoption_modal.unlocked_desc' | translate }}
    </p>
</div>
<div class="modal-footer" *ngIf="locked" (click)="locked=false">
    <div class="locked-field">
        <b>
            {{ 'edit_projectworkitemoption_modal.locked' | translate }}
        </b>
        <div class="wrap-switch">
            <img src="assets/img/unlock-blue.svg" width="12" alt="">
            <div class="switch green">
                <div class="slider pull-right"></div>
            </div>
            <img src="assets/img/lock-green.svg" width="12" alt="">
        </div>
    </div>
    <p>
        {{ 'edit_projectworkitemoption_modal.locked_desc' | translate }}
    </p>
</div>
