import { Component, OnInit, Inject, ElementRef, NgZone, ViewChild, PLATFORM_ID } from '@angular/core';
import { FormControl } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
//import { MapsAPILoader } from '@agm/core';    //TODO: fix
//import { } from 'googlemaps';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { LocationService } from '../../shared/locations/location.service';
import { ProviderMarker } from '../../shared/providers/ProviderMarker';
import { ProviderService } from '../../shared/providers/ProviderService';

declare var google: any;

@Component({
    selector: 'providers-map',
    templateUrl: './providers-map.component.html'
})
export class ProvidersMapComponent implements OnInit {
    currentLocationName: string = '';
    latitude: number = 0;
    longitude: number = 0;
    zoom: number;
    addressSearchControl!: FormControl;

    //map: google.maps.Map;
    showMap: boolean;
    providerMarkers: ProviderMarker[];
    private scrollCounter: number;
    private mapWest: number;
    private mapEast: number;
    private mapNorth: number;
    private mapSouth: number;
    private loadProviderMarkersFunc: () => void;

    bsModalRef!: BsModalRef;

    constructor(public translate: TranslateService, private locationService: LocationService,
        /*private mapsAPILoader: MapsAPILoader,*/ private ngZone: NgZone,
        private providerService: ProviderService, @Inject(PLATFORM_ID) private platformId: Object,
        private modalService: BsModalService) {
        this.scrollCounter = 10;
        this.zoom = 11;
        this.mapNorth = 0;
        this.mapEast = 0;
        this.mapSouth = 0;
        this.mapWest = 0;
        this.loadProviderMarkersFunc = this.debounce(() => this.loadProviderMarkers());
        this.providerMarkers = [];
        this.showMap = false || isPlatformBrowser(platformId);

        this.subscribeToCurrentLocation();
    }

    ngOnInit() {
        let location = this.locationService.getCurrentLocation();
        this.currentLocationName = location.locationName;
        this.latitude = location.latitude;
        this.longitude = location.longitude;
    }

    subscribeToCurrentLocation(): void {
        this.locationService.currentLocation.subscribe(newLocation => {
            this.latitude = newLocation.latitude;
            this.longitude = newLocation.longitude;
            this.currentLocationName = newLocation.locationName;
            this.zoom = 12;
        });
    }
    
    debounce(func: () => void, wait = 100) {
        let h: any;
        return () => {
            clearTimeout(h);
            h = setTimeout(() => func(), wait);
        };
    }

    mapBoundsChanged(bounds: any) {
        if (this.isCoordDifferent(this.mapNorth, bounds.getNorthEast().lat())
            || this.isCoordDifferent(this.mapEast, bounds.getNorthEast().lng())
            || this.isCoordDifferent(this.mapSouth, bounds.getSouthWest().lat())
            || this.isCoordDifferent(this.mapWest, bounds.getSouthWest().lng())) {
            this.mapNorth = bounds.getNorthEast().lat();
            this.mapEast = bounds.getNorthEast().lng();
            this.mapSouth = bounds.getSouthWest().lat();
            this.mapWest = bounds.getSouthWest().lng();

            this.loadProviderMarkersFunc();
        }
    }

    loadProviderMarkers() {  //should be called with debounce only
        this.providerService.getProvidersInArea(this.mapNorth, this.mapSouth, this.mapWest, this.mapEast).then(providers => {
            for (let provider of providers) {
                if (!this.providerMarkers.some(p => p.latitude === provider.latitude
                    && p.longitude === provider.longitude
                    && p.name === provider.name)) {
                    this.providerMarkers.push(provider);
                }
            }
        });
    }
    
    private isCoordDifferent(a: number, b: number) {
        return Math.abs(a - b) > 0.005;
    }

    onWindowScroll(event: any) {
        --this.scrollCounter;
        /*if (this.scrollCounter > 0 && this.map && google ) {
            google.maps.event.trigger(this.map, 'resize');
            this.map.setCenter({ lat: this.latitude, lng: this.longitude });
        }*/
    }

    mapIdle() {
    }

    /*(mapReady(map: google.maps.Map) {
        this.map = map;
    }*/
    
}

