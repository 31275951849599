import { Component, OnInit, AfterViewInit, PLATFORM_ID, Inject, ViewChildren, QueryList } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';

import { ArticleViewDto } from '../../shared/content/ArticleViewDto';
import { ArticleService } from '../../shared/content/article.service';
import { SeoService } from '../../shared/seo.service';
import { GalleryService } from '../../shared/galleries/gallery.service';
import { ArticlePieceDto } from '../../shared/content/ArticlePieceDto';
import { GalleryComponent } from '../../components/gallery/gallery.component';

@Component({
    selector: 'article-body',
    templateUrl: './article-body.component.html'
})
export class ArticleBodyComponent implements OnInit, AfterViewInit {
    slideConfig = { "slidesToShow": 1, "slidesToScroll": 1, "infinite": false, centerMode: true, centerPadding: '0px', };
    isBrowser: boolean = false;
    resolverHandled: boolean = false;
    title: string = '';

    @ViewChildren(GalleryComponent) galleries!: QueryList<GalleryComponent>;
    public pieces: ArticlePieceDto[] = [];

    constructor(private activatedRoute: ActivatedRoute, private articleService: ArticleService, private seoService: SeoService,
        @Inject(PLATFORM_ID) private platformId: Object, private galleryService: GalleryService) {
        this.isBrowser = isPlatformBrowser(platformId);
    }

    ngOnInit() {
        this.activatedRoute.data.subscribe((dat: any) => {
            let data: { article: ArticleViewDto } = dat; 
            if (!data.article) {
                this.galleryService.setArticleId(null);
                //TODO: 404
            }
            this.resolverHandled = true;
            this.loadArticle(data.article);
        });
        this.activatedRoute.queryParams.subscribe((queryParams: any) => {
            if (this.resolverHandled) {
                this.resolverHandled = false;
                return;
            }
            this.articleService.getArticle(queryParams.id).then((articleDto: ArticleViewDto) => {
                this.loadArticle(articleDto);
            });
        });
    }

    private loadArticle(articleDto: ArticleViewDto) {
        this.galleryService.setArticleId(articleDto.id);
        this.seoService.setMetaTags(articleDto.title, articleDto.description);
        this.title = articleDto.title;
        this.pieces = articleDto.pieces;
    }

    ngAfterViewInit() {
        if (this.isBrowser) {
            let galleries: GalleryComponent[] = [];
            this.galleries.forEach(g => galleries.push(g));
            this.galleryService.setActiveGalleries(galleries);
        }
    }

}
