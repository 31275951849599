import { Component, Inject, OnInit } from '@angular/core';

import { ActionTracker } from '../../shared/projects/ActionTracker';
import { AccountService } from '../../shared/account/account.service';
import { DisplayService } from '../../shared/display.service';

@Component({
    selector: 'gdpr-popup',
    templateUrl: './gdpr-popup.component.html'
})
export class GdprPopupComponent implements OnInit {
    show: boolean = false;

    constructor(private accountService: AccountService, private displayService: DisplayService) {
    }

    ngOnInit() {
        this.show = this.displayService.isBrowser() && !this.accountService.getCookiesAccepted();
    }

    close() {
        this.show = false;
    }

    accept() {
        this.close();
        this.accountService.acceptCookies();
    }
}
