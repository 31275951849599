<div class="download-links download-links-mobile d-md-none">
    <a class="download-link download-link_red" href="/Project/Pdfs" target="_blank">
        <img src="assets/img/pdf-icon.png" width="40" alt="">
        <span>
            {{ 'comparison.download' | translate }}<br class="d-sm-none"> {{ 'comparison.pdf_file' | translate }}
        </span>
    </a>
    <br class="d-none d-lg-block d-xl-none">
    <a class="download-link download-link_green" href="/Project/SendXlsx" target="_blank">
        <img src="assets/img/Excel_logo.png" width="40" alt="">
        <span>
            {{ 'comparison.download' | translate }}<br class="d-sm-none"> {{ 'comparison.xlsx_file' | translate }}
        </span>
    </a>
</div>
<div class="table-comparison table-comparison-mobile d-block d-md-none">
    <ul class="company-names">
        <li *ngFor="let projectPrice of projectPriceItems; let i = index">
            <b class="number-in-circle">
                {{i}}
            </b>
            <a>
                {{projectPrice.providerName}}
            </a>
        </li>
    </ul>
    <div class="table-flex">
        <div class="table-row">
            <div class="table-cell empty-first-cell"></div>
            <div class="table-cell" *ngFor="let projectPrice of projectPriceItems; let i = index">
                <b class="number-in-circle">{{i}}</b>
                <br>
                <b class="price">
                    {{projectPrice.currencySign}}&nbsp;{{projectPrice.totalPrice | number:'1.0-0'}}
                </b>
            </div>
        </div>
        <div class="item-block" *ngFor="let mwa of groupedMwasWithWorkItems">
            <div class="table-row flesh-row">
                <h6>
                    {{mwa.masterWorkAreasNames}}
                </h6>
            </div>
            <div class="item" *ngFor="let workItem of mwa.workItems">
                <div class="table-row row-detailed-prices">
                    <div class="table-cell type">
                        <h6>
                            {{workItem.workItemName}}
                        </h6>
                        <span class="text-badge">{{workItem.qty}} {{workItem.uomName}}</span>
                    </div>
                    <div class="table-cell" *ngFor="let projectPrice of projectPriceItems">
                        <b>{{projectPrice.currencySign}}&nbsp;{{workItem.prices[projectPrice.providerId].price | number:'1.0-0'}}</b>
                    </div>
                </div>
                <div class="table-row flex-column description">
                    <span>
                        {{ workItem.workItemOptionDescription ? workItem.workItemOptionDescription : workItem.workItemDescription }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
<table [ngClass]="{'loading-small':!dataWasLoaded, 'table':true, 'table-responsive':true, 'table-comparison':true, 'd-none':true, 'd-md-block':true}">
    <thead>
    <tr>
        <th class="download-links" scope="col">
            <a class="download-link download-link_red" href="/Project/Pdfs" target="_blank">
                <img src="assets/img/pdf-icon.png" width="40" alt="">
                <span>
                    {{ 'comparison.download' | translate }} {{ 'comparison.pdf_file' | translate }}
                </span>
            </a>
            <br class="d-none d-lg-block d-xl-none">
            <a class="download-link download-link_green" href="/Project/SendXlsx" target="_blank">
                <img src="assets/img/Excel_logo.png" width="40" alt="">
                <span>
                    {{ 'comparison.download' | translate }} {{ 'comparison.xlsx_file' | translate }}
                </span>
            </a>
        </th>
        <th scope="col"></th>
        <th scope="col" *ngFor="let projectPrice of projectPriceItems">
            <a>
                {{projectPrice.providerName}}
            </a>
            <br>
            <span class="price">
                {{projectPrice.currencySign}}&nbsp;{{projectPrice.totalPrice | number:'1.0-0'}}
            </span>
        </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let mwa of groupedMwasWithWorkItems">
        <tr class="flesh-row">
            <td>
                <h5>
                    {{mwa.masterWorkAreasNames}}
                </h5>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
        </tr>
        <tr class="bg-none" *ngFor="let workItem of mwa.workItems">
            <td class="description">
                <h5>{{workItem.workItemName}}</h5>
                <span class="text-badge">{{workItem.qty}} {{workItem.uomName}}</span>&nbsp;
                <i>
                    {{workItem.workItemOptionName}}
                </i>
                <br>
                <span>
                    {{workItem.workItemDescription}}
                </span>
            </td>
            <td class="size">{{workItem.qty}} {{workItem.uomName}}</td>
            <td *ngFor="let projectPrice of projectPriceItems">
                <b>{{projectPrice.currencySign}}&nbsp;{{workItem.prices[projectPrice.providerId].price | number:'1.0-0'}}</b>
            </td>
        </tr>
    </ng-container>
    </tbody>
</table>