import { Inject, Injectable, Optional } from '@angular/core';
import { DataService } from './network/data.service';
import { InitData } from './initData';

@Injectable({
    providedIn: 'root',
})
export class InitDataService {
    private initData: InitData | undefined;

    constructor(private dataService: DataService, @Optional() @Inject('ORIG_QUERY') public origQueryParams: any) {}

    loadInitialData(): Promise<InitData> {
        //console.log('origQueryParams:');
        //console.log(this.origQueryParams);
        return this.dataService.get<InitData>('initData', false, this.origQueryParams)
          .then(data => {
            this.initData = data;
            return data;
          });
      }

    getInitialData(): InitData {
        if (this.initData === undefined) {
            throw new Error('InitData was not initialized.');
        }
        return this.initData;
    }
}

export function initializeApp(initDataService: InitDataService): () => Promise<any> {
    return () => initDataService.loadInitialData();
}