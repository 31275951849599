import { Inject, Injectable, Optional, PLATFORM_ID } from "@angular/core";
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from "@angular/common";
import acceptLanguage from 'accept-language';

import { InitDataService } from "../init-data.service";

@Injectable()
export class LangDetector {
    private initDataLang: string;
    constructor(@Inject(DOCUMENT) private document: any, initDataService: InitDataService, @Inject(PLATFORM_ID) private platformId: Object,
                @Optional() @Inject('ACCEPT_LANG') public acceptedLangs: string) {
        this.initDataLang = initDataService.getInitialData().l10n.language;
}

    public determineCurrentLang(supportedLangs: string[]): string {
        let langFromPath = this.extractLangFromPath(this.document.location.pathname, supportedLangs);
        if (langFromPath) {
            return langFromPath;
        }
        if (isPlatformBrowser(this.platformId)) {
            let langFromBrowser = this.getPreferableLangFromBrowser(supportedLangs);
            if (langFromBrowser) {
                return langFromBrowser;
            }
        } else if (isPlatformServer(this.platformId)) {
            let langFromHeaders = this.getPreferableLangFromHeaders(supportedLangs);
            if (langFromHeaders) {
                return langFromHeaders;
            }
        }
        return this.initDataLang;
    }

    private getPreferableLangFromBrowser(supportedLangs: string[]): string | undefined{
        let langFromBrowser = this.findPreferable(window.navigator.languages, supportedLangs);
        if (langFromBrowser) {
            return langFromBrowser;
        }
        return window.navigator.language || (window.navigator as any).userLanguage;
    }

    private getPreferableLangFromHeaders(supportedLangs: string[]): string | undefined {
        //console.log('accepted-language header = ' + this.acceptedLangs);
        if (this.acceptedLangs) {
            acceptLanguage.languages(supportedLangs);
            let matchedLang = acceptLanguage.get(this.acceptedLangs);
            console.log('matchedLang = ' + matchedLang);
            if (matchedLang) {
                return matchedLang;
            }
        }
        return undefined;
    }
    private findPreferable(usrLangs: readonly string[], supportedLangs: readonly string[]): string | undefined {
        for (const lang of usrLangs) {
            if (supportedLangs.includes(lang)) {
                return lang;
            }
        }
        return undefined;
    }

    private extractLangFromPath(path: string, supportedLangs: string[]): string | undefined {
        let regex = new RegExp(`^/(${supportedLangs.join('|')})/`);
        let match = path.match(regex);
        if (match) {
            return match[1];
        } else {
            return undefined;
        }
    }
    
}
