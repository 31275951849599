import { Component, AfterViewInit, AfterViewChecked, OnInit, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ProjectService } from '../../shared/projects/project.service';
import { ProjectPriceItem } from '../../shared/projects/ProjectPriceItem';
import { ProvidersSorting } from '../../shared/projects/ProvidersSorting';
import { DisplayService } from '../../shared/display.service';
import { GalleryService } from '../../shared/galleries/gallery.service';
import { GalleryLightComponent } from '../../components/gallery/gallery-light.component';
import { WorkAreasService } from '../../shared/workareas/work-areas.service';
import { ProviderService } from '../../shared/providers/ProviderService';
import { OfferDetailsModalComponent } from './offer-details-modal.component';
import { LocationService } from '../../shared/locations/location.service';
import { LandingParamsService } from '../../shared/landingParams/landing-params.service';
import { AnalyticsService, AnalyticsProjectFlags } from '../../shared/analytics.service';
import { AbTestsService } from '../../shared/abTesting/abTestsService';
import { RenoRouter } from '../../shared/RenoRouter';

@Component({
    selector: 'search-results-view',
    templateUrl: './search-results-view.component.html'
})
export class SearchResultsViewComponent implements OnInit, OnDestroy {
    ProvidersSorting: typeof ProvidersSorting = ProvidersSorting;   //hack for enum to be available in the template
    priceItems: ProjectPriceItem[] = [];
    workItemsCount: number = 0;
    sorting: ProvidersSorting = ProvidersSorting.Overall;
    loading: boolean = true;
    ngUnsubscribe: Subject<void> = new Subject<void>();
    workAreasSelectionName: string = '';
    providerDetailsModalRef!: BsModalRef;
    private loadingModal: boolean = false;
    public loadingPrices = false;
    currentLocationName!: string;
    private initPricesLoadDone: boolean = false;

    hidePrices: boolean = false;
    haveLandingText: boolean = false;
    isMobile: boolean = false;
    editsSinceCreation: number = 0;
    itemLandingHeader: string | null = null;

    projectPendingText1: string | null = null;
    projectPendingText2: string | null = null;

    showHalfEnvelope: boolean = false;
    showGalleryImage: boolean = false;
    showReviewsSort: boolean = false;

    selectedOffers: ProjectPriceItem[] = [];

    @ViewChildren(GalleryLightComponent) galleries!: QueryList<GalleryLightComponent>;

    constructor(public translate: TranslateService,
        private projectService: ProjectService, private router: Router, private displayService: DisplayService,
        private galleryService: GalleryService, private workAreasService: WorkAreasService,
        private providerService: ProviderService, private modalService: BsModalService,
        private locationService: LocationService, private landingParamsService: LandingParamsService,
        private analyticsService: AnalyticsService, private abTestsService: AbTestsService,
        private renoRouter: RenoRouter) {
    }
    
    ngOnInit() {
        this.subscribeToPrices();
        this.subscribeToProviderSorting();
        this.subscribeToPricesBeingLoaded();
        this.subscribeToCurrentLocation();
        this.subscribeToSelectedOffers();
        let landingParams = this.landingParamsService.getLandingParams();
        this.haveLandingText = !!landingParams.text1;
        if (this.haveLandingText) {
            this.projectPendingText1 = landingParams.text1;
            this.projectPendingText2 = landingParams.text2;
        }
        this.itemLandingHeader = landingParams.text2;
        this.isMobile = this.displayService.isMobile();
    }

    private subscribeToPrices(): void {
        this.projectService.pricesDto.pipe(takeUntil(this.ngUnsubscribe)).subscribe(dto => {
            this.priceItems = dto.prices;
            this.workItemsCount = dto.prices && dto.prices.length && dto.prices[0].workItems ? dto.prices[0].workItems.length : 0;
            if (this.initPricesLoadDone || dto.prices.length) {
                this.loading = false;
            }
            if (this.initPricesLoadDone && (!dto.prices || !dto.prices.length)) {
                this.router.navigateByUrl('/search');
            }
            this.initPricesLoadDone = true;
            this.workAreasSelectionName = this.workAreasService.getWorkAreasSelectionName();
            if (this.displayService.isBrowser()) {
                let providerIds = this.priceItems.map(pi => pi.providerId);
                this.galleryService.loadGalleryPicsByProviderIds(providerIds);
            }
            this.hidePrices = this.landingParamsService.getLandingParams().hidePrices && (!dto.projectMeta || !dto.projectMeta.editsSinceCreation);
            this.editsSinceCreation = dto.projectMeta ? dto.projectMeta.editsSinceCreation : 0;
            this.showHalfEnvelope = this.isMobile && this.editsSinceCreation >= 5;
            this.showGalleryImage = this.editsSinceCreation >= 5;

            this.showReviewsSort = false;
            for (let i = 0; i < this.priceItems.length; ++i)
            {
                if (this.priceItems[i].stats.numOf12Reviews + this.priceItems[i].stats.numOf3Reviews + this.priceItems[i].stats.numOf45Reviews > 0) {
                    this.showReviewsSort = true;
                    break;
                }
            }
        });
    }

    private subscribeToProviderSorting(): void {
        this.projectService.currentSorting.pipe(takeUntil(this.ngUnsubscribe)).subscribe(sorting => {
            this.sorting = sorting;
        });
    }

    private subscribeToPricesBeingLoaded(): void {
        this.projectService.pricesBeingUpdated.subscribe(loadingPrices => {
            this.loadingPrices = loadingPrices;
        });
    }

    private subscribeToCurrentLocation(): void {
        this.locationService.currentLocation.subscribe(newLocation => {
            this.currentLocationName = newLocation.locationName || this.currentLocationName;
        });
    }

    private subscribeToSelectedOffers(): void {
        this.projectService.selectedOffers.pipe(takeUntil(this.ngUnsubscribe)).subscribe(offers => {
            this.selectedOffers = offers;
        });
    }

    public sortProviders(sorting: ProvidersSorting): void {
        this.projectService.setProvidersSorting(sorting);
    }

    private getPriceItem(providerId: string): ProjectPriceItem {
        return this.priceItems.find(pi => pi.providerId == providerId) as ProjectPriceItem;
    }

    ngOnDestroy() {
        this.galleryService.unregisterGalleriesWithProviderId();
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    async showDocsModal(providerId: string) {
        if (this.loadingModal) {
            return;
        }
        this.loadingModal = true;

        let docsDto = await this.providerService.getProviderDocs(providerId);
        this.providerDetailsModalRef = this.modalService.show(OfferDetailsModalComponent, { class: 'provider-docs' });
        this.providerDetailsModalRef.content.setDocs(docsDto);
        let priceItem = this.getPriceItem(providerId);
        this.providerDetailsModalRef.content.headerAttrs = priceItem.headerAttrs;
        this.providerDetailsModalRef.content.providerName = priceItem.providerName;

        this.loadingModal = false;
    }

    public selectProvider(providerId: string): void {
        this.projectService.selectProvider(providerId);
    }

    public openOffer(providerId: string): void {
        if (!this.selectedOffers || !this.selectedOffers.length) {
            this.selectProvider(providerId);
        }
        this.renoRouter.gotoOfferDetails(providerId);
    }

    public deselectProvider(providerId: string): void {
        this.projectService.deSelectProvider(providerId);
    }

    public scrollToProjectEdit(): void {
        this.displayService.scrollToClass('edit-block');
    }

}
