<div class="wrap-bg">
<div class="top-banner top-banner_faq">
    <div class="top-part">
        <div class="wrap">
            <div class="title-strip-yellow">
                <div class="container">
                    <h3>
                        {{ 'faq.top_header1' | translate}}
                    </h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="faq-content">
<div class="container">
    <h6>
        {{ 'faq.top_header2' | translate}}
    </h6>

<div class="steps-line" id="faq-chapter1">
    <div class="step step-active">
        <span class="number">1.</span>&nbsp;<span>{{ 'faq.chapter1' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(2)">
        <span class="number">2.</span>&nbsp;<span>{{ 'faq.chapter2' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(3)">
        <span class="number">3.</span>&nbsp;<span>{{ 'faq.chapter3' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(4)">
        <span class="number">4.</span>&nbsp;<span>{{ 'faq.chapter4' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(5)">
        <span class="number">5.</span>&nbsp;<span>{{ 'faq.chapter5' | translate}}</span>
    </div>
</div>

<h3>
    {{ 'faq.chapter1_header' | translate}}
</h3>

<div class="faq-content_block">
    <div class="block" *ngFor="let p of faq[0]">
        <h5>
            {{ p.header }}
        </h5>
        <ul>
            <li [innerHTML]="p.body">
            </li>
            <li *ngIf="p.tip">
                <div class="clearfix">
                    <span class="green"><b>{{ 'faq.tip' | translate}}</b>:&nbsp;</span>
                    <div [innerHTML]="p.tip"></div>
                </div>
            </li>
        </ul>
    </div>
</div>

<div class="steps-line" id="faq-chapter2">
    <div class="step" (click)="scrollToChapter(1)">
        <span class="number">1.</span>&nbsp;<span>{{ 'faq.chapter1' | translate}}</span>
    </div>
    <div class="step step-active">
        <span class="number">2.</span>&nbsp;<span>{{ 'faq.chapter2' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(3)">
        <span class="number">3.</span>&nbsp;<span>{{ 'faq.chapter3' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(4)">
        <span class="number">4.</span>&nbsp;<span>{{ 'faq.chapter4' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(5)">
        <span class="number">5.</span>&nbsp;<span>{{ 'faq.chapter5' | translate}}</span>
    </div>
</div>

<h3>
    {{ 'faq.chapter2_header' | translate}}
</h3>

<div class="faq-content_block">
    <div class="block" *ngFor="let p of faq[1]">
        <h5>
            {{ p.header }}
        </h5>
        <ul>
            <li [innerHTML]="p.body">
            </li>
            <li *ngIf="p.tip">
                <div class="clearfix">
                    <span class="green"><b>{{ 'faq.tip' | translate}}</b>:&nbsp;</span>
                    <div [innerHTML]="p.tip"></div>
                </div>
            </li>
        </ul>
    </div>
</div>

<div class="steps-line" id="faq-chapter3">
    <div class="step" (click)="scrollToChapter(1)">
        <span class="number">1.</span>&nbsp;<span>{{ 'faq.chapter1' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(2)">
        <span class="number">2.</span>&nbsp;<span>{{ 'faq.chapter2' | translate}}</span>
    </div>
    <div class="step step-active">
        <span class="number">3.</span>&nbsp;<span>{{ 'faq.chapter3' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(4)">
        <span class="number">4.</span>&nbsp;<span>{{ 'faq.chapter4' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(5)">
        <span class="number">5.</span>&nbsp;<span>{{ 'faq.chapter5' | translate}}</span>
    </div>
</div>

<h3>
    {{ 'faq.chapter3_header' | translate}}
</h3>

<div class="faq-content_block">
    <div class="block" *ngFor="let p of faq[2]">
        <h5>
            {{ p.header }}
        </h5>
        <ul>
            <li [innerHTML]="p.body">
            </li>
            <li *ngIf="p.tip">
                <div class="clearfix">
                    <span class="green"><b>{{ 'faq.tip' | translate}}</b>:&nbsp;</span>
                    <div [innerHTML]="p.tip"></div>
                </div>
            </li>
        </ul>
    </div>
</div>

<div class="steps-line" id="faq-chapter4">
    <div class="step" (click)="scrollToChapter(1)">
        <span class="number">1.</span>&nbsp;<span>{{ 'faq.chapter1' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(2)">
        <span class="number">2.</span>&nbsp;<span>{{ 'faq.chapter2' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(3)">
        <span class="number">3.</span>&nbsp;<span>{{ 'faq.chapter3' | translate}}</span>
    </div>
    <div class="step step-active">
        <span class="number">4.</span>&nbsp;<span>{{ 'faq.chapter4' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(5)">
        <span class="number">5.</span>&nbsp;<span>{{ 'faq.chapter5' | translate}}</span>
    </div>
</div>

<h3>
    {{ 'faq.chapter4_header' | translate}}
</h3>

<div class="faq-content_block">
    <div class="block" *ngFor="let p of faq[3]">
        <h5>
            {{ p.header }}
        </h5>
        <ul>
            <li [innerHTML]="p.body">
            </li>
            <li *ngIf="p.tip">
                <div class="clearfix">
                    <span class="green"><b>{{ 'faq.tip' | translate}}</b>:&nbsp;</span>
                    <div [innerHTML]="p.tip"></div>
                </div>
            </li>
        </ul>
    </div>
</div>

<div class="steps-line" id="faq-chapter5">
    <div class="step" (click)="scrollToChapter(1)">
        <span class="number">1.</span>&nbsp;<span>{{ 'faq.chapter1' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(2)">
        <span class="number">2.</span>&nbsp;<span>{{ 'faq.chapter2' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(3)">
        <span class="number">3.</span>&nbsp;<span>{{ 'faq.chapter3' | translate}}</span>
    </div>
    <div class="step" (click)="scrollToChapter(4)">
        <span class="number">4.</span>&nbsp;<span>{{ 'faq.chapter4' | translate}}</span>
    </div>
    <div class="step step-active">
        <span class="number">5.</span>&nbsp;<span>{{ 'faq.chapter5' | translate}}</span>
    </div>
</div>

<h3>
    {{ 'faq.chapter5_header' | translate}}
</h3>

<div class="faq-content_block">
    <div class="block" *ngFor="let p of faq[4]">
        <h5>
            {{ p.header }}
        </h5>
        <ul>
            <li [innerHTML]="p.body">
            </li>
            <li *ngIf="p.tip">
                <div class="clearfix">
                    <span class="green"><b>{{ 'faq.tip' | translate}}</b>:&nbsp;</span>
                    <div [innerHTML]="p.tip"></div>
                </div>
            </li>
        </ul>
    </div>
</div>

</div>

</div>
</div>
