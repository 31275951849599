<div class="wrap-bg">
    <div class="title-green top font-small feedback-title">
        <h3>
            {{ 'feedback_provider_initiated.header5' | translate }}
        </h3>
    </div>
    <div class="container">
        <div class="score-providers review green">
            <div class="wrap-feedback-content">
                <div class="wrap-text">
                    <p>
                        {{ 'feedback_provider_initiated.success1_1' | translate }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
