import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'register-confirmation',
    templateUrl: './register-confirmation.component.html'
})
export class RegisterConfirmationComponent implements OnInit {
    public loading: boolean = false;
    public email: string | undefined;
    public password: string | undefined;
    public passwordConfirm: string | undefined;
    public termsAccepted: boolean = false;

    constructor(public translate: TranslateService, private router: Router) {
    }

    ngOnInit() {
    }

}
