import { Component, OnInit, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ProjectService } from '../../shared/projects/project.service';
import { ProjectPriceItem, ProjectGuaranteeDatesInContract } from '../../shared/projects/ProjectPriceItem';
import { DisplayService } from '../../shared/display.service';
import { ProviderService } from '../../shared/providers/ProviderService';
import { OfferDetailsModalComponent } from './offer-details-modal.component';
import { GalleryService } from '../../shared/galleries/gallery.service';
import { NotificationService } from '../../shared/notificaton.service';
import { FeaturedServiceDto } from '../../shared/providers/ProviderAttr';
import { GroupedMwaWithWorkItems } from '../../shared/projects/GroupedMwaWithWorkItems';
import { AccountService } from '../../shared/account/account.service';
import { SeoService } from '../../shared/seo.service';
import { WorkItemDto } from '../../shared/projects/WorkItemDto';
import { LeadFeedbackViewDto } from '../../shared/providers/LeadFeedbackViewDto';
import { AnalyticsService } from '../../shared/analytics.service';
import { WorkAreasService } from '../../shared/workareas/work-areas.service';
import { AbTestsService } from '../../shared/abTesting/abTestsService';
import { CommentModalComponent } from '../../components/workitems/comment-modal.component';
import { AddProjectWorkItemOptionModalComponent } from '../../components/workitems/add-projectworkitemoption-modal.component';

@Component({
    selector: 'offer-details',
    templateUrl: './offer-details.component.html'
})
export class OfferDetailsComponent implements OnInit {
    ProjectGuaranteeDatesInContract: typeof ProjectGuaranteeDatesInContract = ProjectGuaranteeDatesInContract;   //hack for enum to be available in the template
    private routeParamsSub: any;
    private providerId: string | undefined;
    public priceItem!: ProjectPriceItem;
    public searchResultsCount: number = 0;
    ngUnsubscribe: Subject<void> = new Subject<void>();
    providerDetailsModalRef!: BsModalRef;
    private loadingModal: boolean = false;
    public belowAveragePricesBarClasses: any = { indicator_bar: true, 'length-5': true };
    public aboutAveragePricesBarClasses: any = { indicator_bar: true, 'length-5': true };
    public aboveAveragePricesBarClasses: any = { indicator_bar: true, 'length-5': true };
    public numOf12StarReviewsBarClasses: any = { indicator_bar: true, 'length-5': true };
    public numOf3StarReviewsBarClasses: any = { indicator_bar: true, 'length-5': true };
    public numOf45StarReviewsBarClasses: any = { indicator_bar: true, 'length-5': true };
    public hasServices: FeaturedServiceDto[] = [];
    public hasNotServices: FeaturedServiceDto[] = [];
    public providerDescription: string | undefined;
    public providerDecsriptionShortened: boolean = false;
    public selectedProviders: ProjectPriceItem[] = [];
    public loading: boolean = false;
    public editsSinceCreation: number = 99;
    public mainRatingStars: any[] = [];
    public showWorkItems: boolean = false;
    public isMobile: boolean = false;
    public displayGuaranteeDesc: boolean = false;
    public displayReviews: boolean = false;
    public displayOfficeDetailsButton: boolean = false;
    public numOfDocs: number = 0;
    private initPricesLoadDone: boolean = false;
    public masterWorkAreasNum: number | undefined = undefined;
    projectPending: boolean = false;
    activeTab: string = '';

    private commentModalRef!: BsModalRef;
    private comment: string = '';
    public addWorkItemMode: boolean = false;
    public workItemsToAdd: WorkItemDto[] = [];
    addProjectWorkItemOptionModalRef!: BsModalRef;
    private loadingWorkItemModel: boolean = false;
    reviews: LeadFeedbackViewDto[] = [];

    workItem1Name: string = '';
    workItem1Desc: string = '';
    workItem2Name: string = '';
    workItem2Desc: string = '';

    constructor(private activatedRoute: ActivatedRoute, private projectService: ProjectService,
        private router: Router, private providerService: ProviderService, private modalService: BsModalService,
        private galleryService: GalleryService, private notifier: NotificationService, private translate: TranslateService,
        private accountService: AccountService, private seoService: SeoService, private displayService: DisplayService,
        private analyticsService: AnalyticsService, private workAreasService: WorkAreasService,
        private abTestsService: AbTestsService) { }

    ngOnInit(): void {
        this.translate.get(['price_details.title', 'price_details.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['price_details.title'], res['price_details.desc']);
        });
        this.routeParamsSub = this.activatedRoute.params.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
            let id = params['id'];
            this.providerId = id;
        });
        this.subscribeToPrices();
        this.isMobile = this.displayService.isMobile();
    }

    private subscribeToPrices(): void {
        this.projectService.pricesDto.pipe(takeUntil(this.ngUnsubscribe)).subscribe(dto => {
            this.searchResultsCount = dto.prices.length;
            this.editsSinceCreation = dto.projectMeta ? dto.projectMeta.editsSinceCreation : 0;
            if (this.providerId) {
                this.loadProvider(this.providerId);
            }
            this.selectedProviders = this.projectService.getSelectedProviders();
            this.masterWorkAreasNum = dto.masterWorkAreasNum;
            this.projectPending = this.projectService.isProjectPending();
            this.activeTab = this.projectPending ? 'details' : '';

            if (dto.topWorkItemsByPrices && dto.topWorkItemsByPrices.length) {  //TODO: refactor it, it's copypasted in WorkAreasSelectSideComponent
                const wisSorted = dto.prices[0].workItems.sort((a, b) => {
                    return b.price - a.price;
                });

                let wi = dto.topWorkItemsByPrices[0];
                this.workItem1Name = `${wi.workItemName}: ${wi.qty} ${wi.uomName}`;
                this.workItem1Desc = wi.workItemDescription;
                if (dto.topWorkItemsByPrices.length > 1) {
                    wi = dto.topWorkItemsByPrices[1];
                    this.workItem2Name = `${wi.workItemName}: ${wi.qty} ${wi.uomName}`;
                    this.workItem2Desc = wi.workItemDescription;
                }
            }
        });
    }

    private loadProvider(id: string): void {
        let priceItem = this.projectService.getPriceItem(id);
        if (!priceItem) {
            if (this.initPricesLoadDone) {
                this.returnToSearch();
                return;
            }
            this.projectService.updatePricesForCurrentOrCreateForProvider(id);
            this.initPricesLoadDone = true;
            return;
        } else {
            this.initPricesLoadDone = true;
        }
        this.priceItem = priceItem;
        this.galleryService.loadGalleryPicsByProviderIds([id]);

        this.mainRatingStars = [];
        if (this.priceItem) {
            for (var i = 0; i < priceItem.stats.mainRating.starsNum; i++) {
                this.mainRatingStars.push(i);
            }
        }

        this.belowAveragePricesBarClasses = { indicator_bar: true };
        let num1 = Math.max(1, Math.min(3, Math.round(3 * priceItem.numOfPricesBelowAverage / priceItem.workItems.length)));
        this.belowAveragePricesBarClasses['length-' + num1] = true;

        this.aboutAveragePricesBarClasses = { indicator_bar: true };
        let num2 = Math.max(1, Math.min(3, Math.round(3 * (priceItem.workItems.length - priceItem.numOfPricesBelowAverage - priceItem.numOfPricesAboveAverage) / priceItem.workItems.length)));
        this.aboutAveragePricesBarClasses['length-' + num2] = true;

        this.aboveAveragePricesBarClasses = { indicator_bar: true};
        let num3 = Math.max(1, Math.min(3, Math.round(3 * priceItem.numOfPricesAboveAverage / priceItem.workItems.length)));
        this.aboveAveragePricesBarClasses['length-' + num3] = true;

        let totalReviews = priceItem.stats.numOf12Reviews + priceItem.stats.numOf3Reviews + priceItem.stats.numOf45Reviews;
        this.numOf12StarReviewsBarClasses = { indicator_bar: true };
        let num12OfReviews = Math.max(0, Math.min(3, Math.round(3 * priceItem.stats.numOf12Reviews / totalReviews)));
        this.numOf12StarReviewsBarClasses['length-' + num12OfReviews] = true;

        this.numOf3StarReviewsBarClasses = { indicator_bar: true };
        let numOf3Reviews = Math.max(0, Math.min(3, Math.round(3 * priceItem.stats.numOf3Reviews / totalReviews)));
        this.numOf3StarReviewsBarClasses['length-' + numOf3Reviews] = true;
        
        this.numOf45StarReviewsBarClasses = { indicator_bar: true };
        let numOf45Reviews = Math.max(0, Math.min(3, Math.round(3 * priceItem.stats.numOf45Reviews / totalReviews)));
        this.numOf45StarReviewsBarClasses['length-' + numOf45Reviews] = true;


        this.hasServices = priceItem.featuredServices.filter(s => s.has);
        this.hasNotServices = priceItem.featuredServices.filter(s => !s.has);

        let descLength = this.displayService.isMobile() ? 220: 380;
        if (priceItem.providerDescription && priceItem.providerDescription.length > descLength) {
            this.providerDecsriptionShortened = true;
            this.providerDescription = priceItem.providerDescription.substring(0, descLength - 3) + '...';
        } else {
            this.providerDescription = priceItem.providerDescription;
        }
        this.loadReviews(id);
        this.displayOfficeDetailsButton = priceItem.hasOfficeInfo;
        this.numOfDocs = priceItem.numOfDocs;
    }

    private returnToSearch(): void {
        this.router.navigateByUrl('/search');
    }

    async showDocsModal(providerId: string) {
        if (this.loadingModal || !this.priceItem) {
            return;
        }
        this.loadingModal = true;

        let docsDto = await this.providerService.getProviderDocs(providerId);
        this.providerDetailsModalRef = this.modalService.show(OfferDetailsModalComponent, { class: 'provider-docs' });
        this.providerDetailsModalRef.content.setDocs(docsDto);
        this.providerDetailsModalRef.content.headerAttrs = this.priceItem.headerAttrs;
        this.providerDetailsModalRef.content.providerName = this.priceItem.providerName;

        this.loadingModal = false;
    }

    public selectProvider() {
        this.projectService.selectProvider(this.priceItem!.providerId);
        this.selectedProviders = this.projectService.getSelectedProviders();
    }

    public unselectProvider() {
        this.projectService.deSelectProvider(this.priceItem!.providerId);
        this.selectedProviders = this.selectedProviders.filter(p => p.providerId != this.priceItem!.providerId);
    }

    public compareClick(): void {
        if (this.selectedProviders.length < 3) {
            this.translate.get('provider_details.must_select_3_providers_error').subscribe((res: string) => {
                this.notifier.showError(res);
            });
        } else {
            let user = this.accountService.getUserInfo();
            this.loading = true;
            try {
                if (user.emailConfirmed && user.phoneNumberConfirmed) {
                    this.projectService.createLeads().then(() => {
                        this.router.navigateByUrl('/comparison');
                    });
                } else {
                    this.analyticsService.fireEvent('checkoutStarted');
                    this.router.navigateByUrl('/register');
                }
            } finally {
                this.loading = false;
            }
        }
    }

    public showAllDesc() {
        this.providerDecsriptionShortened = false;
        this.providerDescription = this.priceItem!.providerDescription;
    }

    public enableAddWorkItemMode(): void {
        this.addWorkItemMode = true;
    }

    public openCommentModal() {
        this.commentModalRef = this.modalService.show(CommentModalComponent);
        this.commentModalRef.content.comment = this.comment;
    }

    public async addtWorkItemOptionModal(workItemId: number) {
        if (this.loadingWorkItemModel) {
            return;
        }
        try {
            this.loadingWorkItemModel = true;
            let model = await this.projectService.getAddProjectWorkItemOptionModel(workItemId + "");
            let workItem = this.workItemsToAdd.find(wi => wi.workItemId == workItemId) as WorkItemDto;
            this.addProjectWorkItemOptionModalRef = this.modalService.show(AddProjectWorkItemOptionModalComponent);
            this.addProjectWorkItemOptionModalRef.content.quantity = 1;
            this.addProjectWorkItemOptionModalRef.content.workItemId = workItemId;
            this.addProjectWorkItemOptionModalRef.content.workItemName = workItem.name;
            this.addProjectWorkItemOptionModalRef.content.workItemDescription = model.workItemDescription;
            this.addProjectWorkItemOptionModalRef.content.uom = model.uom;
            this.addProjectWorkItemOptionModalRef.content.workItemOptions = model.workItemOptions;
            this.addProjectWorkItemOptionModalRef.content.selectedWorkItemOptionId = model.workItemOptions[0].id;
        } finally {
            this.loadingWorkItemModel = false;
        }
    }

    public numIconUrl(i: number) {
        return `/assets/img/number-${i}.svg`;
    }

    public scrollToWorkItems(): void {
        this.showWorkItems = true;
        this.displayService.scrollToClass('edit-block');
    }

    public showGuaranteeDesc(): void {
        this.displayReviews = false;
        this.displayGuaranteeDesc = true;
        //this.analyticsService.pageView('/guarantee');
    }

    public showReviews(): void {
        this.displayGuaranteeDesc = false;
        this.displayReviews = true;
        //this.analyticsService.pageView('/reviews');
    }

    private async loadReviews(providerId: string) {
        this.reviews = await this.providerService.getReviews(providerId);
    }
}
