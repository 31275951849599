import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { WorkAreasResponse } from './WorkAreasResponse';
import { ProjectPlug } from './ProjectPlug';
import { MasterWorkAreaSelectDto } from './MasterWorkAreaSelectDto';
import { SelectedMasterWorkAreaDto } from './SelectedMasterWorkAreaDto';
import { DataService } from '../network/data.service';
import { LocationService } from '../locations/location.service';
import { InitData } from '../initData';
import { ActionTracker } from '../projects/ActionTracker';
import { Location } from '../locations/Location';
import { InitDataService } from '../init-data.service';

@Injectable()
export class WorkAreasService {
    private workAreasSource = new BehaviorSubject<MasterWorkAreaSelectDto[]>([]);
    workAreas = this.workAreasSource.asObservable();
    private renovationAreaSource = new BehaviorSubject<number>(70);
    renovationArea = this.renovationAreaSource.asObservable();
    private currentCountryId: string;
    private showMobileWorkAreasSelectSource = new BehaviorSubject<boolean>(false);
    public showMobileWorkAreasSelect = this.showMobileWorkAreasSelectSource.asObservable();
    public workAreasLoadedFromSavedProject: boolean = false;
    private projectPlugsSource = new BehaviorSubject<ProjectPlug[]>([]);
    public projectPlugs = this.projectPlugsSource.asObservable();
    private currentProjectPlug: ProjectPlug | null;
    private workAreasSelectionChangedByUserSource = new BehaviorSubject<boolean>(false);
    public workAreasSelectionChangedByUser = this.workAreasSelectionChangedByUserSource.asObservable();
    private entireRenovationName: string;
    protected noMwasSelectedText: string = '...';

    constructor(private dataService: DataService, private locationService: LocationService,
        initDataService: InitDataService, private translate: TranslateService, private actionTracker: ActionTracker) {
        let initData = initDataService.getInitialData();
        this.currentProjectPlug = initData.currentProjectPlug;
        this.currentCountryId = initData.location.country;
        this.subscribeToCurrentLocation();
        this.entireRenovationName = 'Entire';   //fallback
        let l10nToken = 'WORKAREAS_SELECTOR.no_masterworkareas_selected';
        this.translate.get(l10nToken).subscribe((res: string) => {
            if (res != l10nToken) {
                this.noMwasSelectedText = res;
            }
        });
    }

    updateWorkAreasToSelect(projectPlug: ProjectPlug | null = null): Promise<void> {
        this.currentCountryId = this.locationService.getCurrentLocation().country;
        this.currentProjectPlug = projectPlug || this.currentProjectPlug;
        let plugId = this.currentProjectPlug ? this.currentProjectPlug.id : null;
        if (projectPlug) {
            this.workAreasSelectionChangedByUserSource.next(false);
        }
        return this.dataService.get<WorkAreasResponse>(`WorkAreas?countryId=${this.currentCountryId}&pp=${plugId}`, true).then(response => {
            this.workAreasLoadedFromSavedProject = response.isSavedProject;
            if (response.isSavedProject) {
                this.workAreasSelectionChangedByUserSource.next(true);
            }
            let plugs = response.projectPlugs;
            this.markActiveProjectPlug(plugs);
            this.projectPlugsSource.next(plugs);
            this.workAreasSource.next(response.workAreas);
            this.renovationAreaSource.next(response.area);
        });
    }

    subscribeToCurrentLocation(): void {
        this.locationService.currentLocation.subscribe((newLocation: Location) => {
            let countryWasChanged = this.currentCountryId !== newLocation.country;
            if (countryWasChanged) {
                this.updateWorkAreasToSelect();
            }
        });
    }

    setRenovationArea(area: number) {
        this.actionTracker.incUnsavedChangesCount();
        this.renovationAreaSource.next(area);
    }

    getRenovationArea(): number {
        return this.renovationAreaSource.getValue();
    }

    public getSelectedWorkAreas(): SelectedMasterWorkAreaDto[] {
        let result = new Array<SelectedMasterWorkAreaDto>();
        let masterWorkAreas = this.workAreasSource.getValue();
        for (let mwa of masterWorkAreas) {
            if (mwa.selected) {
                let selectedMwa = new SelectedMasterWorkAreaDto();
                selectedMwa.MasterWorkAreaId = mwa.id;
                selectedMwa.refArea = mwa.refArea;
                selectedMwa.refAreaIsLocked = mwa.refAreaIsLocked;
                for (let wa of mwa.workAreas) {
                    if (wa.selected) {
                        selectedMwa.WorkAreaIds.push(wa.id);
                    }
                }
                if (selectedMwa.WorkAreaIds.length > 0) {
                    result.push(selectedMwa);
                }
            }
        }
        return result;
    }

    public setShowMobileWorkAreasSelect(show: boolean): void {
        this.showMobileWorkAreasSelectSource.next(show);
    }

    public setWorkAreasSelectionChangedByUser() {
        this.currentProjectPlug = null;
        this.workAreasSelectionChangedByUserSource.next(true);
        let projectPlugs = this.projectPlugsSource.getValue();
        this.markActiveProjectPlug(projectPlugs);
        this.projectPlugsSource.next(projectPlugs);
    }

    public getCurrentProjectPlug(): ProjectPlug | null {
        return this.currentProjectPlug;
    }

    public getWorkAreasSelectionName(): string {
        if (this.currentProjectPlug && this.currentProjectPlug.name) {
            return this.currentProjectPlug.name;
        }
        return this.entireRenovationName;
    }

    private markActiveProjectPlug(projectPlugs: ProjectPlug[]) {
        for (let projectPlug of projectPlugs) {
            projectPlug.isActive = this.currentProjectPlug && projectPlug.id === this.currentProjectPlug.id
                || (this.workAreasLoadedFromSavedProject && projectPlug.id == -1);  //custom project
        }
    }

    public createCustomProject(): void {
        var workAreas = this.workAreasSource.getValue();
        if (!workAreas || !workAreas.length) {
            return;
        }
        workAreas[0].selected = true;
        for (let i = 1; i < workAreas.length; ++i) {
            workAreas[i].selected = false;
        }
    }

    public getMasterWorkAreasDisplayList(maxLength: number): string {
        let selectedMasterWorkAreas = this.workAreasSource.getValue().filter(mwa => mwa.selected);
        if (!selectedMasterWorkAreas || !selectedMasterWorkAreas.length) {
            return this.noMwasSelectedText;
        }
        let text: string;
        if (selectedMasterWorkAreas.length === 1) {
            text = selectedMasterWorkAreas[0].name;
        } else if (selectedMasterWorkAreas.length === 2) {
            text = `${selectedMasterWorkAreas[0].name}, ${selectedMasterWorkAreas[1].name}`;
        } else {
            text = `${selectedMasterWorkAreas[0].name}, ${selectedMasterWorkAreas[1].name}...`;
        }
        if (text.length > maxLength) {
            text = text.substring(0, maxLength - 3) + '...';
        }
        return text;
    }
    
    public async setMwaRefArea(mwaId: string, newArea: number, isLocked: boolean): Promise<void> {
        let masterWorkAreas = this.workAreasSource.getValue();
        let mwa = masterWorkAreas.find(mwa => mwa.id == mwaId);
        if (!mwa) {
            return;
        }
        mwa.refArea = newArea;
        mwa.refAreaIsLocked = isLocked;
        this.workAreasSource.next(masterWorkAreas);
/*
        await this.dataService.post('project/SetMwaRefArea', {
            mwaId,
            newArea,
            isLocked
        });*/
    }

}
