import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, takeUntil, timeout  } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { } from 'rxjs/operators';

import { DataService } from '../network/data.service';
import { WorkAreasService } from '../workareas/work-areas.service';
import { LocationService } from '../locations/location.service';
import { ProjectPriceItem } from './ProjectPriceItem';
import { ProjectPricesDto, ProjectPricesResponse } from './ProjectPricesDto';
import { ProvidersSorting } from './ProvidersSorting';
import { EditProjectWorkItemOptionModel } from './EditProjectWorkItemOptionModel';
import { AddProjectWorkItemOptionModel } from './AddProjectWorkItemOptionModel';
import { NotificationService } from '../notificaton.service';
import { UserProjectDto } from './UserProjectDto';
import { ActionTracker } from './ActionTracker';
import { ProjectMeta } from '../projects/ProjectMeta';
import { AnalyticsService, AnalyticsProjectFlags } from '../analytics.service';
import { LocationSource } from '../locations/LocationSource';
import { AbTestsService } from '../abTesting/abTestsService';

@Injectable()
export class ProjectService {
    private readonly selectedProvidersKey = 'selectedProviders';
    private pricesDtoSource = new BehaviorSubject<ProjectPricesDto>({
        prices: [],
        groupedMwasWithWorkItems: [],
        userComment: '',
        projectMeta: null,
        topWorkItemsByPrices: [],
        topMwaIconsByPrices: [],
        masterWorkAreasNum: undefined,
        pics: [],
        currentDate: '',
        offersValidUntil: ''
    });
    public pricesDto = this.pricesDtoSource.asObservable();
    private finishedPricesDtoSource = new BehaviorSubject<ProjectPricesDto>({
        prices: [],
        groupedMwasWithWorkItems: [],
        userComment: '',
        projectMeta: null,
        topWorkItemsByPrices: [],
        topMwaIconsByPrices: [],
        masterWorkAreasNum: undefined,
        pics: [],
        currentDate: '',
        offersValidUntil: ''
    });
    public finishedPricesDto = this.finishedPricesDtoSource.asObservable();

    private numOfProjectPicsSource = new BehaviorSubject<number>(0);
    public numOfProjectPics = this.numOfProjectPicsSource.asObservable();

    private selectedProjectPriceItemSource = new BehaviorSubject<ProjectPriceItem>(new ProjectPriceItem());
    public selectedProjectPriceItem = this.selectedProjectPriceItemSource.asObservable();
    private currentSortingSource = new BehaviorSubject<ProvidersSorting>(ProvidersSorting.Overall);
    public currentSorting = this.currentSortingSource.asObservable();
    public isSaved: boolean = false; //saved at any point, not necessary all latest changes
    private pricesBeingUpdatedSource = new BehaviorSubject<boolean>(false);
    public pricesBeingUpdated = this.pricesBeingUpdatedSource.asObservable();
    private averagePrice: number = 0;

    private selectedOffersSource = new BehaviorSubject<ProjectPriceItem[]>([]);
    public selectedOffers = this.selectedOffersSource.asObservable();

    constructor(private dataService: DataService, private workAreasService: WorkAreasService,
        private locationService: LocationService, private notifier: NotificationService,
        private translate: TranslateService, private actionTracker: ActionTracker, private analyticsService: AnalyticsService,
        private abTestsService: AbTestsService) {
        this.pricesDto.subscribe(dto => {
            let priceItem = dto.prices.find(p => p.providerId == this.selectedProjectPriceItemSource.getValue().providerId);
            if (priceItem) {
                this.selectedProjectPriceItemSource.next(priceItem);
            } else {
                if (dto.prices.length > 0) {
                    this.selectedProjectPriceItemSource.next(dto.prices[0]);
                } else {
                    this.selectedProjectPriceItemSource.next(new ProjectPriceItem());
                }
            }
            this.analyticsService.setProjectMeta(dto.projectMeta as ProjectMeta);
            if (!this.averagePrice) {
                this.averagePrice = this.calcAveragePrice(dto.prices);
            }
            this.loadSelectedOffers();
            this.numOfProjectPicsSource.next((dto && dto.pics) ? dto.pics.length: 0);
        });
        this.workAreasService.workAreas.subscribe(workAreas => {
            this.isSaved = this.isSaved || this.workAreasService.workAreasLoadedFromSavedProject;
        });
    }

    public async updateCurrentProject(): Promise<boolean> {
        this.isSaved = true;
        var response: any = await this.dataService.post('project/update', this.getProjectCreateInput());
        if (!response.projectCanBeUpdated) {
            this.translate.get('WORKAREAS_SELECTOR.no_project_edit_alert').subscribe((res: string) => {
                this.notifier.showInfo(res);
            })
            return response.projectCanBeUpdated == true;
        }
        return false;
    }

    public async updatePricesForCurrentProject(): Promise<boolean> {
        this.pricesBeingUpdatedSource.next(true);
        try {
            let response = await this.dataService.get<ProjectPricesResponse>('project/prices');
            if (!response) {
                return false;
            }
            let pricesDto = response.pricesDto;
            this.sortProjectPrices(pricesDto.prices);
            this.loadProviderSelection(pricesDto.prices);
            this.pricesDtoSource.next(pricesDto);
        } finally {
            this.pricesBeingUpdatedSource.next(false);
        }
        return true;
    };

    public async updatePricesForCurrentOrCreateForProvider(providerId: string): Promise<boolean> {
        this.pricesBeingUpdatedSource.next(true);
        try {
            let response = await this.dataService.get<ProjectPricesResponse>('project/PricesForCurrentOrCreateForProvider?providerId=' + providerId);
            if (!response) {
                return false;
            }
            let pricesDto = response.pricesDto;
            this.sortProjectPrices(pricesDto.prices);
            this.pricesDtoSource.next(pricesDto);
            let location = response.location;
            location.source = LocationSource.Server;
            this.locationService.setCurrentLocation(location);
        } finally {
            this.pricesBeingUpdatedSource.next(false);
        }
        return true;
    };


    public async updatePricesForFinishedProject(): Promise<void> {
        this.pricesBeingUpdatedSource.next(true);
        try {
            let pricesDto = await this.dataService.get<ProjectPricesDto>('project/pricesForFinished');
            this.sortProjectPrices(pricesDto.prices);
            this.loadProviderSelection(pricesDto.prices);
            this.finishedPricesDtoSource.next(pricesDto);
        } finally {
            this.pricesBeingUpdatedSource.next(false);
        }
    };

    public async updateCurrentProjectAndPrices(): Promise<boolean> {
        this.isSaved = true;
        this.pricesBeingUpdatedSource.next(true);
        try {
            let response = await this.dataService.post<ProjectPricesResponse>('Project/UpdateAndGetPrices', this.getProjectCreateInput());
            if (!response.projectCanBeUpdated) {
                this.translate.get('WORKAREAS_SELECTOR.no_project_edit_alert').subscribe((res: string) => {
                    this.notifier.showInfo(res);
                })
                return false;
            }
            let pricesDto = response.pricesDto;
            this.sortProjectPrices(pricesDto.prices);
            this.loadProviderSelection(pricesDto.prices);
            this.pricesDtoSource.next(pricesDto);
            this.showAveragePriceChange(pricesDto.prices);
        } finally {
            this.pricesBeingUpdatedSource.next(false);
        }
        return true;
    }

    public async updateAreaAndPrices(areaSqm: number) {
        this.isSaved = true;
        this.pricesBeingUpdatedSource.next(true);
        try {
            let response = await this.dataService.post<ProjectPricesResponse>('project/UpdateAreaAndGetPrices', areaSqm);
            
            let pricesDto = response.pricesDto;
            this.sortProjectPrices(pricesDto.prices);
            this.loadProviderSelection(pricesDto.prices);
            this.pricesDtoSource.next(pricesDto);
            this.showAveragePriceChange(pricesDto.prices);
        } finally {
            this.pricesBeingUpdatedSource.next(false);
        }
    }

    public selectProjectPriceItem(providerId: string): boolean {
        let projPriceItem = this.pricesDtoSource.getValue().prices.find(p => p.providerId == providerId);
        if (projPriceItem) {
            this.selectedProjectPriceItemSource.next(projPriceItem);
            return true;
        }
        return false;
    }

    private sortProjectPrices(prices: ProjectPriceItem[]): void {
        if (!prices) {
            return;
        }
        prices.sort((a, b) => {
            switch (this.currentSortingSource.getValue()) {
                case ProvidersSorting.Overall:
                    return b.stats.mainRating.num - a.stats.mainRating.num;
                case ProvidersSorting.Reviews:
                    let reviewsDiff = b.stats.reviews.starsNum - a.stats.reviews.starsNum;
                    if (reviewsDiff) {
                        return reviewsDiff;
                    }
                    let reviewsNumDiff = b.stats.reviews.num - a.stats.reviews.num;
                    if (reviewsNumDiff) {
                        return reviewsNumDiff;
                    }
                    return b.stats.mainRating.num - a.stats.mainRating.num;
                case ProvidersSorting.Price:
                    return a.totalPrice - b.totalPrice;
                default:
                    return 0;
            }
        });
    }

    private loadProviderSelection(prices: ProjectPriceItem[]) {
        if (!prices) {
            return;
        }
        let selectedProvs = this.getSavedSelectedProviders();
        if (selectedProvs && selectedProvs.length) {
            for (let i = 0; i < prices.length; ++i) {
                if (selectedProvs.some(p => p == prices[i].providerId)) {
                    prices[i].selected = true;
                }
            }
        }
    }

    public setProvidersSorting(sorting: ProvidersSorting) {
        this.currentSortingSource.next(sorting);
        let dto = this.pricesDtoSource.getValue();
        var arr = dto.prices.slice(0);
        this.sortProjectPrices(arr);
        dto.prices = arr;
        this.pricesDtoSource.next(dto);
    }

    public getEditProjectWorkItemOptionModel(workItemId: string): Promise<EditProjectWorkItemOptionModel> {
        return this.dataService.get<EditProjectWorkItemOptionModel>('Project/EditProjectWorkItemOptionModel?workItemId=' + workItemId);
    }

    public getAddProjectWorkItemOptionModel(workItemId: string): Promise<AddProjectWorkItemOptionModel> {
        return this.dataService.get<AddProjectWorkItemOptionModel>('Project/AddProjectWorkItemOptionModel?workItemId=' + workItemId);
    }

    public async changeProjectWorkItemOptionAndUpdatePrices(newWorkItemOptionId: string, workItemId: string, quantity: number, initialQuantity: number, locked: boolean): Promise<void> {
        this.isSaved = true;
        this.pricesBeingUpdatedSource.next(true);
        try {
            let dto = await this.dataService.post<ProjectPricesDto>('project/ChangeWorkItemOptionAndGetPrices', {
                newWorkItemOptionId,
                workItemId,
                quantity,
                locked
            });
            this.sortProjectPrices(dto.prices);
            this.loadProviderSelection(dto.prices);
            this.pricesDtoSource.next(dto);
            this.showAveragePriceChange(dto.prices);
        } finally {
            this.pricesBeingUpdatedSource.next(false);
        }
        if (newWorkItemOptionId != workItemId) {
            this.actionTracker.incUnsavedChangesCount();
        }
        if (quantity != initialQuantity) {
            this.actionTracker.incUnsavedChangesCount();
        }
    }

    public async addProjectWorkItemOptionAndUpdatePrices(newWorkItemOptionId: string, workItemId: string, quantity: number): Promise<void> {
        this.isSaved = true;
        this.pricesBeingUpdatedSource.next(true);
        try {
            let dto = await this.dataService.post<ProjectPricesDto>('project/AddWorkItemOptionAndGetPrices', {
                newWorkItemOptionId,
                workItemId,
                quantity
            });
            this.sortProjectPrices(dto.prices);
            this.loadProviderSelection(dto.prices);
            this.pricesDtoSource.next(dto);
            this.showAveragePriceChange(dto.prices);
        } finally {
            this.pricesBeingUpdatedSource.next(false);
        }
        this.actionTracker.incUnsavedChangesCount();
    }

    public async removeWorkItemAndUpdatePrices(workItemOptionId: string) {
        this.isSaved = true; this.pricesBeingUpdatedSource.next(true);
        try {
            let dto = await this.dataService.post<ProjectPricesDto>('project/RemoveWorkItemOptionAndGetPrices', {
                workItemOptionId
            });
            this.sortProjectPrices(dto.prices);
            this.loadProviderSelection(dto.prices);
            this.pricesDtoSource.next(dto);
            this.showAveragePriceChange(dto.prices);
        } finally {
            this.pricesBeingUpdatedSource.next(false);
        }
        this.actionTracker.incUnsavedChangesCount();
    }

    public async createLeads(): Promise<any> {
        this.pricesBeingUpdatedSource.next(true);
        try {
            let dto = await this.dataService.post<ProjectPricesDto>('project/CreateLeads', {
                providerIds: this.getSelectedProviderIds(),
            });
            this.pricesDtoSource.next(dto);
        } finally {
            this.pricesBeingUpdatedSource.next(false);
        }
    }

    public sendAdditionalDetails(interestType: number, propertyOccupancyStatus: number, accessEase: number): Promise<any> {
        this.actionTracker.incUnsavedChangesCount();
        return this.dataService.post('project/AdditionalDetails', {
            interestType,
            propertyOccupancyStatus,
            accessEase,
        });
    }

    public getPriceItem(providerId: string): ProjectPriceItem {
        return this.pricesDtoSource.getValue().prices.find(p => p.providerId == providerId) as ProjectPriceItem;
    }

    private getProjectCreateInput(): any {
        let selectedWorkAreas = this.workAreasService.getSelectedWorkAreas();
        let area = this.workAreasService.getRenovationArea();
        let currentLocation = this.locationService.getCurrentLocation();
        let unsavedActionsCount = this.actionTracker.getUnsavedActionsCountSincePricesUpdate();
        this.actionTracker.resetUnsavedChangesCountSincePricesUpdate();
        return {
            selectedWorkAreasAreaInputs: selectedWorkAreas,
            area: area,
            locationName: currentLocation.locationName,
            address: currentLocation.address || currentLocation.locationName,
            countryId: currentLocation.country,
            latitude: currentLocation.latitude,
            longitude: currentLocation.longitude,
            changesSincePriceUpdate: unsavedActionsCount,
        };
    }

    public requestXlsxFileEmail(): Promise<any> {
        return this.dataService.post('project/SendXlsx', {});
    }

    public selectProvider(providerId: string): void {
        let priceItem = this.getPriceItem(providerId);
        if (this.getSelectedProviders().some(p => p.providerId == providerId)) {    //already selected
            return;
        }
        priceItem.selected = true;
        this.saveSelectedProviders();
        this.loadSelectedOffers();
        let numProvidersSelected = this.getSelectedProviders().length;
        if (numProvidersSelected >= 2) {
            this.analyticsService.fireEvent('visited2Pdp', AnalyticsProjectFlags.visited2Pdp);
        }
        if (numProvidersSelected >= 3) {
            this.analyticsService.fireEvent('selected3providers', AnalyticsProjectFlags.selected3Providers);
        }
    }

    public deSelectProvider(providerId: string): void {
        let priceItem = this.getPriceItem(providerId);
        priceItem.selected = false;
        this.saveSelectedProviders();
        this.loadSelectedOffers();
    }

    public getSelectedProviders(): ProjectPriceItem[] {
        //note, there is also obervable 'selectedOffers' in this class
        return this.pricesDtoSource.getValue().prices.filter(p => p.selected);
    }

    public getSelectedProviderIds(): string[] {
        let selectedProviders = this.getSelectedProviders();
        if (selectedProviders && selectedProviders.length) {
            return selectedProviders.map(p => p.providerId);
        }
        return this.getSavedSelectedProviders();
    }

    private loadSelectedOffers(): void {
        this.selectedOffersSource.next(this.getSelectedProviders());
    }

    public clearSavedSelectedProviders(): void {
        if (typeof localStorage === 'undefined' || !localStorage) {
            return;
        }
        localStorage.removeItem(this.selectedProvidersKey);
        this.loadSelectedOffers();
    }

    async updateComment(comment: string) {
        await this.dataService.post('project/UpdateComment', { comment });
        let pricesDto = this.pricesDtoSource.getValue();
        pricesDto.userComment = comment;
        this.pricesDtoSource.next(pricesDto);
    }

    private getSavedSelectedProviders(): string[] {
        if (typeof localStorage === 'undefined' || !localStorage) {
            return [];
        }

        let saved = localStorage.getItem(this.selectedProvidersKey);
        if (!saved) {
            return [];
        }
        return JSON.parse(saved);
    }

    private saveSelectedProviders(): void {
        if (typeof localStorage === 'undefined' || !localStorage) {
            return;
        }
        let selectedProvs = this.getSelectedProviders().map(p => p.providerId);
        if (selectedProvs) {
            localStorage.setItem(this.selectedProvidersKey, JSON.stringify(selectedProvs));
        }
    }

    public getUserProjects(): Promise<UserProjectDto[]> {
        return this.dataService.get<UserProjectDto[]>('Project/My');
    }

    public setCurrentProject(projectId: number): Promise<ProjectMeta> {
        return this.dataService.post<ProjectMeta>(`Project/SetCurrent/${projectId}`, {});
    }

    public async saveProject(name: string | null = null) {
        let result = await this.dataService.post<any>(`Project/Save`, { name: name });
        this.actionTracker.resetUnsavedChangesCountSinceProjectSave();
        this.analyticsService.fireEvent('savedProject', AnalyticsProjectFlags.savedProject);
        return result;
    }

    public async renameProject(projectId: number, name: string) {
        let result = await this.dataService.post<any>(`Project/Rename/${projectId}`, { name: name });
        return result;
    }

    public deleteProject(projectId: number): any {
        return this.dataService.post<any>(`Project/Delete/${projectId}`, {});
    }

    public projectExists(): Promise<boolean> {
        return this.dataService.get<boolean>('Project/Exists');
    }

    public haveLoadedPrices(): boolean {
        let pricesDto = this.pricesDtoSource.getValue();
        return pricesDto && pricesDto.prices && pricesDto.prices.length > 0;
    }

    public loadPricesIfNotLoaded(): void {
        if (!this.haveLoadedPrices()) {
            this.updatePricesForCurrentProject();
        }
    }

    public getCurrentProjectMeta() {
        return this.pricesDtoSource.getValue().projectMeta;
    }

    public markStepCompleted(stepNum: number) {
        this.dataService.post('Project/MarkStepCompleted/' + stepNum, {});
    }

    public isProjectPending(): boolean {
        let meta = this.getCurrentProjectMeta();
        if (!meta) {
            return false;
        }
        return meta.editsSinceCreation < 5;
    }

    public removePic(url: string) {
        var picsNum = this.numOfProjectPicsSource.getValue();
        if (picsNum > 0) {
            this.numOfProjectPicsSource.next(picsNum - 1);
        }
        this.dataService.delete(url);
    }

    public picWasAdded(): void {
        var picsNum = this.numOfProjectPicsSource.getValue();
        this.numOfProjectPicsSource.next(picsNum + 1);
    }

    private calcAveragePrice(prices: ProjectPriceItem[]): number {
        if (!prices || !prices.length) {
            return 0;
        }

        let sum = 0;
        for (let i = 0; i < prices.length; ++i) {
            sum += prices[i].totalPrice;
        }
        return sum / prices.length;
    }

    private showAveragePriceChange(prices: ProjectPriceItem[]): void {
        let newAveragePrice = this.calcAveragePrice(prices);
        if (this.averagePrice > 0 && newAveragePrice != this.averagePrice) {
            let percent = Math.abs(Math.round((this.averagePrice - newAveragePrice) / this.averagePrice * 100));
            if (!percent) {
                return;
            }
            
            if (this.averagePrice < newAveragePrice) {
                this.translate.get(['workitems_list.prices_up_msg', 'workitems_list.prices_up_header'], { providersCount: prices.length, percent: percent }).subscribe((res: any) => {
                    this.notifier.showSuccess(res['workitems_list.prices_up_msg'], res['workitems_list.prices_up_header'], 4000);
                });
            } else {
                this.translate.get(['workitems_list.prices_down_msg', 'workitems_list.prices_down_header'], { providersCount: prices.length, percent: percent }).subscribe((res: any) => {
                    this.notifier.showSuccess(res['workitems_list.prices_down_msg'], res['workitems_list.prices_down_header'], 4000);
                });
            }
        }
        this.averagePrice = newAveragePrice;
    }

}
