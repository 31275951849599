import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { ArticleViewDto } from '../../shared/content/ArticleViewDto';
import { ArticleService } from './article.service';
import { RenoRouter } from '../RenoRouter';

@Injectable()
export class ArticleResolve implements Resolve<ArticleViewDto> {
    constructor(private renoRouter: RenoRouter, private articleService: ArticleService) {
    }

    resolve(route: ActivatedRouteSnapshot): Promise<ArticleViewDto> {
        let id = route.queryParams['id'];
        return this.articleService.getArticle(id).then(article => {
            if (article) {
                return article;
            } else {
                //this.renorouter.navigate(['/']);    //redirect to 404
                return new ArticleViewDto();
            }
        });
    }

}
