import { Component, OnInit, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ProjectService } from '../../shared/projects/project.service';
import { SeoService } from '../../shared/seo.service';

@Component({
    templateUrl: './project-uploads.component.html'
})
export class ProjectUploadsComponent implements OnInit {
    ngUnsubscribe: Subject<void> = new Subject<void>();
    comment: string | undefined;
    savedComment: string | undefined;
    pics: string[] = [];
    loading: boolean = false;
    selectedProvidersNum: number = 0;

    uploadBtnCaption: string | undefined;
    dropBoxMsg: string | undefined;
    fileTooLargeMsg: string | undefined;

    constructor(private projectService: ProjectService, private translate: TranslateService, private seoService: SeoService) { }

    ngOnInit(): void {
        this.translate.get(['project_edit.upload_title', 'project_edit.upload_desc', 'project_edit.upload_btn_caption', 'project_edit.upload_dropBox_msg', 'project_edit.upload_file_too_large']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['project_edit.upload_title'], res['project_edit.upload_desc']);
            this.uploadBtnCaption = res['project_edit.upload_btn_caption'];
            this.dropBoxMsg = res['project_edit.upload_dropBox_msg'];
            this.fileTooLargeMsg = res['project_edit.upload_file_too_large'];
        });
        this.projectService.loadPricesIfNotLoaded();
        this.subscribeToPrices();
    }

    private subscribeToPrices(): void {
        this.projectService.pricesDto.pipe(takeUntil(this.ngUnsubscribe)).subscribe(dto => {
            this.comment = this.savedComment = dto.userComment;
            if (!this.pics || !this.pics.length) {
                this.pics = dto.pics.map(p => `/ProjectPic/${p}`);
            }
            this.selectedProvidersNum = this.projectService.getSelectedProviders().length;
        });
    }

    async save() {
        if (this.loading || this.comment === this.savedComment) {
            return;
        }
        this.loading = true;
        await this.projectService.updateComment(this.comment as string);
        this.loading = false;
    }

    cancel() {
        this.comment = this.savedComment;
    }

    onPicRemoved($event: any) {
        this.projectService.removePic($event.src);
    }

    onUploadFinished($event: any) {
        let gg = $event;
        this.projectService.picWasAdded();
        this.projectService.updatePricesForCurrentProject();
    }
}
