<div class="wrap-bg">
    <div class="container">
        <div class="my-projects">
            <div class="title-green">
                <h3>
                    {{ 'my_projects.header' | translate }}
                </h3>
            </div>
            <div [ngClass]="{'loading-small':loading, 'wrap_projects-table':true}">
                <div *ngIf="!(projects && projects.length)" class="Ftable projects-table project-active">
                    <div class="Ftable-row Ftable-header">
                        <div class="Ftable-cell">
                            {{ 'my_projects.active_project' | translate }}
                        </div>
                    </div>
                    <div class="Ftable-row">
                        <div class="Ftable-cell">
                            <b>
                                {{ 'my_projects.not_saved' | translate }}
                            </b>
                            <a class="btn btn-green btn-no_vert_paddings" (click)="saveCurrentProject()">
                                {{ 'my_projects.save' | translate }}
                            </a>
                            <br /><br />
                        </div>
                    </div>
                </div>
                <div *ngIf="unsavedChangesCount && projects && projects.length" class="Ftable projects-table project-active">
                    <div class="Ftable-row Ftable-header">
                        <div class="Ftable-cell">
                            {{ 'my_projects.active_project' | translate }}
                        </div>
                    </div>
                    <div class="Ftable-row">
                        <div class="Ftable-cell">
                            <b>
                                {{ 'my_projects.changes_since_last' | translate:{changesNum:unsavedChangesCount} }}
                            </b>
                            <a class="btn btn-green btn-no_vert_paddings" (click)="saveCurrentProject()">
                                {{ 'my_projects.save' | translate }}
                            </a>
                            <br /><br />
                        </div>
                    </div>
                </div>

                <div *ngIf="projects && projects.length" class="Ftable projects-table project-saved">
                    <div class="Ftable-row Ftable-header">
                        <div class="Ftable-cell">
                            {{ 'my_projects.saved_projects' | translate }}
                        </div>
                        <div class="Ftable-cell">
                            {{ 'my_projects.created' | translate }}
                        </div>
                        <div class="Ftable-cell">
                            {{ 'my_projects.items' | translate }}
                        </div>
                        <div class="Ftable-cell">
                            {{ 'my_projects.offers' | translate }}
                        </div>
                        <div class="Ftable-cell">
                            {{ 'my_projects.last_saved' | translate }}
                        </div>
                    </div>
                    <div class="Ftable-row" *ngFor="let proj of projects">
                        <div class="Ftable-cell cell-object">
                            <b>
                                {{proj.name}}
                            </b>
                            <div>
                                <a *ngIf="unsavedChangesCount<10" class="text-badge green" (click)="loadProject(proj)">{{ 'my_projects.load' | translate }}</a>
                                <a *ngIf="unsavedChangesCount>=10" class="text-badge green" mwlConfirmationPopover
                                   [popoverTitle]="unsavedChangesPopoverTitle"
                                   [popoverMessage]="unsavedChangesPopoverMessage"
                                   [cancelText]="unsavedChangesPopoverCancelText"
                                   [confirmText]="unsavedChangesPopoverConfirmText"
                                   placement="right" (confirm)="saveCurrentProject()">{{ 'my_projects.load' | translate }}</a>
                                <a class="blue" (click)="renameProject(proj)">{{ 'my_projects.rename' | translate }}</a>
                                <a class="red" *ngIf="!proj.leadsCount" mwlConfirmationPopover
                                   [popoverTitle]="deletePopoverTitle"
                                   [popoverMessage]="deletePopoverMessage"
                                   [cancelText]="deletePopoverCancelText"
                                   [confirmText]="deletePopoverConfirmText"
                                   placement="left" (confirm)="deleteProject(proj)">{{ 'my_projects.delete' | translate }}</a>
                            </div>
                        </div>
                        <div class="Ftable-cell cell-created">
                            <span class="d-md-none">{{ 'my_projects.created' | translate }}:&nbsp;</span><span>{{proj.creationDate}}</span>
                        </div>
                        <div class="Ftable-cell cell-items">
                            <span>{{proj.workItemsCount}}</span><span class="d-md-none">&nbsp;{{ 'my_projects.items' | translate }}</span>
                        </div>
                        <div class="Ftable-cell cell-offers">
                            <span>{{proj.leadsCount}}</span><span class="d-md-none">&nbsp;{{ 'my_projects.offers' | translate }}</span>
                        </div>
                        <div class="Ftable-cell cell-last_saved">
                            <span class="d-md-none">{{ 'my_projects.last_modified' | translate }}:&nbsp;</span><span>{{proj.lastEditDate}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
