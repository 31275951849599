import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { DisplayService } from '../display.service';

@Injectable()
export class ActionTracker {
    private readonly unsavedChangesCountSinceProjectSaveKey: string = 'unsavedChangesCountSinceProjectSave';
    private readonly shownSavePopupOnce: string = 'shownSavePopupOnce';
    private unsavedActionsCountSincePricesUpdate: number = 0;    //if local storage doesn't work

    private showSaveModalSource = new BehaviorSubject<boolean>(false);
    public showSaveModal = this.showSaveModalSource.asObservable();

    private doNotShowSaveModalAt: string[] = ['/signin', '/register', '/forgotPassword', '/resetPassword', '/unsubscribe', '/projects'];
    private tickCounter: number = 0;

    constructor(private displayService: DisplayService, private ngZone: NgZone, private router: Router) {
    }

    public initTimer() {
        let self = this;
        let shortInterval = 5 * 60 * 1000;
        if (this.displayService.isBrowser()) {
            this.ngZone.runOutsideAngular(function () {
                setInterval(function () {
                    let route = self.router.url;
                    let shownOnce = localStorage && 'true' == localStorage.getItem('shownSavePopupOnce');
                    if (!self.showSaveModalSource.getValue()
                        && self.getUnsavedChangesCountSinceProjectSave() > 3
                        && self.doNotShowSaveModalAt.every(s => route.indexOf(s) === -1)
                        && (!shownOnce || self.tickCounter % 3 === 0) ) {
                        localStorage && localStorage.setItem('shownSavePopupOnce', 'true')
                        self.ngZone.run(function () {
                            self.showSaveModalSource.next(true);
                        });
                    }
                    ++self.tickCounter;
                }, shortInterval);
            });
        }
    }

    public incUnsavedChangesCount() {
        ++this.unsavedActionsCountSincePricesUpdate;

        let num = this.getUnsavedActionsCountSinceProjectSaveFromLocalStorage();
        if (!num) {
            num = 0;
        }
        ++num;
        this.setUnsavedActionsCountSinceProjectSaveToLocalStorage(num);
    }

    public getUnsavedActionsCountSincePricesUpdate() {
        return this.unsavedActionsCountSincePricesUpdate;
    }

    public getUnsavedChangesCountSinceProjectSave(): number {
        return this.getUnsavedActionsCountSinceProjectSaveFromLocalStorage() ?? 0;
    }

    public resetUnsavedChangesCountSincePricesUpdate() {
        this.unsavedActionsCountSincePricesUpdate = 0;
    }

    public resetUnsavedChangesCountSinceProjectSave() {
        this.setUnsavedActionsCountSinceProjectSaveToLocalStorage(0);
        this.showSaveModalSource.next(false);
    }
    
    private getUnsavedActionsCountSinceProjectSaveFromLocalStorage(): number | undefined {
        let str = localStorage.getItem(this.unsavedChangesCountSinceProjectSaveKey);
        if (!str) {
            return undefined;
        }
        return +str;
    }

    private setUnsavedActionsCountSinceProjectSaveToLocalStorage(num: number): void {
        localStorage.setItem(this.unsavedChangesCountSinceProjectSaveKey, '' + num);
    }

    public doShowSaveModal() {
        this.showSaveModalSource.next(true);
    }

    public hideSaveModal() {
        this.showSaveModalSource.next(false);
    }
}
