<div class="top-part">
    <div class="container">
        <nav [ngClass]="{'navbar':true, 'navbar-expand-lg':true, 'navbar-inverse':true, 'open':showMobileMenu}">
            <a routerLink="/search" class="navbar-brand" *ngIf="!showMobileMenu && !isModalMode">
                <img src="assets/img/logo.svg" alt="Renobooking" height="36">
            </a>
            <div class="back-arrow" *ngIf="isModalMode" (click)="disableModalMode()">
                <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </div>
            <b class="mobile-menu-title">
                {{ 'menu' | translate }}
            </b>
            <div class="page_links d-none d-sm-flex d-lg-none" *ngIf="!isModalMode">
                <a class="nav-link page_link" routerLink="/search">
                    {{ 'search_menu_item' | translate }}
                </a>
                <a class="nav-link page_link d-none d-md-inline" routerLink="/how-it-works">
                    {{ 'how_it_works_menu' | translate }}
                </a>
                <a href="" class="nav-link page_link d-none d-md-inline" routerLink="/articles-search">
                    {{ 'RENOVATION_TIPS' | translate }}
                </a>
            </div>
            <button class="navbar-toggler navbar-toggler-right collapsed" type="button" (click)="toggleMobileMenu()">
                <i class="fa fa-bars" aria-hidden="true"></i>
            </button>
            <div [ngClass]="{'collapse':true, 'navbar-collapse':true, 'show':showMobileMenu}" id="navbarNav">
                <h6 class="d-lg-none">
                    {{ 'main_menu_header' | translate }}
                </h6>
                <ul class="navbar-nav links">
                    <li class="nav-item d-inline d-sm-none d-lg-inline">
                        <a class="nav-link" routerLink="/search">
                            {{ 'search_menu_item' | translate }}
                        </a>
                    </li>
                    <li class="nav-item d-inline d-md-none d-lg-inline">
                        <a class="nav-link" routerLink="/how-it-works">
                            {{ 'how_it_works_menu' | translate }}
                        </a>
                    </li>
                    <li class="nav-item d-inline d-md-none d-lg-inline">
                        <a href="" class="nav-link" routerLink="/articles-search">
                            {{ 'RENOVATION_TIPS' | translate }}
                        </a>
                    </li>
                </ul>
                <ul class="navbar-nav profile">
                    <li class="nav-item join">
                        <a class="btn btn-white btn-small" routerLink="/join">{{ 'join_as_pro' | translate }}</a>
                    </li>
                    <li class="d-lg-none">
                        <h6>
                            {{ 'language' | translate }}
                        </h6>
                    </li>
                    <li class="nav-item language">
                        <lang-selector class="dropdown" dropdown></lang-selector>
                    </li>
                    <li class="d-lg-none">
                        <h6>
                            {{ 'account_menu_header' | translate }}
                        </h6>
                    </li>
                    <li class="nav-item account">
                        <div class="dropdown" dropdown>
                            <a dropdownToggle class="dropdown-toggle" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-bars" aria-hidden="true"></i>
                            </a>
                            <div *dropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" routerLink="/signin" *ngIf="!isLoggedIn">{{ 'user_menu.signin' | translate }}</a>
                                <a class="dropdown-item" routerLink="/register" *ngIf="!isLoggedIn">{{ 'user_menu.register' | translate }}</a>
                                <a class="dropdown-item" routerLink="/signin" *ngIf="!isLoggedIn">{{ 'user_menu.my_offers' | translate }}</a>
                                <a class="dropdown-item" routerLink="/projects" *ngIf="hasProject">{{ 'user_menu.my_projects' | translate }}</a>
                                <a class="dropdown-item" routerLink="/comparison" *ngIf="hasOffers">{{ 'user_menu.my_offers' | translate }}</a>
                                <a class="dropdown-item" (click)="btnLogoutClick()" *ngIf="isLoggedIn">{{ 'user_menu.logout' | translate }}</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>
