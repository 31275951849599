import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'feature-ad',
    templateUrl: './feature-ad.component.html'
})
export class FeatureAdComponent {
    title: string | undefined;
    imgPath: string | undefined;
    translate: TranslateService;

    @Input() link: string | undefined;

    constructor(translate: TranslateService) {
        this.translate = translate;
    }

    @Input()
    set id(id: string) {
        switch (id) {
            case "1": {
                this.imgPath = 'assets/img/icon-supplier.svg';
                break;
            }
            case "2": {
                this.imgPath = 'assets/img/icon-piggybank.svg';
                break;
            }
            case "3": {
                this.imgPath = 'assets/img/icon-ideas.svg';
                break;
            }
        }
        this.translate.get('search.features.feature_' + id + '_title').subscribe((res: string) => {
            this.title = res;
        });
    }

}
