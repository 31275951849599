import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

import { SeoService } from '../../shared/seo.service';
import { FeedbackService } from '../../shared/feedback/feedback.service';
import { ProjectService } from '../../shared/projects/project.service';
import { ProjectPricesDto } from '../../shared/projects/ProjectPricesDto';
import { LeadFeedbackUserStatusDto, LeadFeedbackUserStatusItemDto } from '../../shared/feedback/LeadFeedbackUserStatusDto';
import { DisplayService } from '../../shared/display.service';
import { AccountService } from '../../shared/account/account.service';

@Component({
    selector: 'feedback-project-status',
    templateUrl: './feedback-project-status.component.html'
})
export class FeedbackProjectStatusComponent implements OnInit {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    private pricesDto: ProjectPricesDto | undefined;
    public feedbackUserStatus: LeadFeedbackUserStatusDto | undefined;
    public firstName: string = '';

    constructor(public translate: TranslateService, private seoService: SeoService, private feedbackService: FeedbackService,
        private projectService: ProjectService, private activatedRoute: ActivatedRoute,
        private displayService: DisplayService, private accountService: AccountService) {
    }

    ngOnInit() {
        if (this.displayService.isBrowser()) {
            this.activatedRoute.data.subscribe((dat: any) => {
                let data: { feedback: LeadFeedbackUserStatusDto } = dat;
                this.feedbackUserStatus = data.feedback;
                for (let i = 0; i < this.feedbackUserStatus.items.length; ++i) {
                    this.loadHint(this.feedbackUserStatus.items[i]);
                }
            });
            this.projectService.pricesDto.pipe(takeUntil(this.ngUnsubscribe)).subscribe(pricesDto => {
                this.pricesDto = pricesDto;
                if (this.feedbackUserStatus) {
                    for (let i = 0; i < this.feedbackUserStatus.items.length; ++i) {
                        let item = this.feedbackUserStatus.items[i];
                        let priceItem = this.pricesDto.prices.find(p => p.providerId == item.providerId);
                        if (!priceItem) {
                            continue;
                        }
                        item.providerName = priceItem.providerName;
                        item.totalPrice = priceItem.totalPrice;
                        item.currencySign = priceItem.currencySign;
                    }
                }
            });
        }
        this.translate.get(['feedback_project_status.title', 'feedback_project_status.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['feedback_project_status.title'], res['feedback_project_status.desc']);
        });
        this.firstName = this.accountService.getUserInfo().firstName;
    }

    public updateStatus(providerId: string, statusId: number) {
        if (!this.feedbackUserStatus) {
            return;
        }
        let feedbackItem = this.feedbackUserStatus.items.find(f => f.providerId == providerId);
        if (feedbackItem) {//should always happen
            feedbackItem.leadStatusId = statusId;
            this.loadHint(feedbackItem);
        }
        this.feedbackService.updateStatus(providerId, statusId);
    }

    private loadHint(feedbackItem: LeadFeedbackUserStatusItemDto) {
        if (!this.feedbackUserStatus) {
            return;
        }
        let providerHints = this.feedbackUserStatus.providerHints.find(ph => ph.providerId == feedbackItem.providerId);
        if (!providerHints) {
            return; //should never happen
        }
        feedbackItem.hint = providerHints.statusHints.find(h => h.leadStatusId == feedbackItem.leadStatusId);
    }
}
