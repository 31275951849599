<div class="top-banner top-banner_renoidea">
    <div class="top-part">
        <div class="wrap">
            <div class="title-strip-yellow">
                <div class="container">
                    <h3>
                        {{ 'articles.top_slogan' | translate }}
                    </h3>
                </div>
            </div>
            <div class="container">
                <form action="">
                    <div class="search-field">
                        <div class="form-group">
                            <label for="search_articles">
                                {{ 'articles.search_header' | translate }}
                            </label>
                            <div class="wrap-input-with-button">
                                <input [(ngModel)]="searchQuery"[ngModelOptions]="{standalone: true}" type="text" class="form-control" id="search_articles"
                                       aria-describedby="searchArticles"
                                       placeholder="{{ 'articles.search_textbox_placeholder' | translate }}">
                                <button [ngClass]="{'btn':true, 'btn-yellow':true, 'loading-small':loading}" (click)="doSearch()">{{ 'articles.do_search' | translate }}</button>
                            </div>
                            <i>
                                <b>{{ 'articles.its_free' | translate }}</b>
                            </i>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="bottom-part">
        <ul class="tile-search_tips">
            <li>
                <a (click)="searchPredefined(1)" [ngClass]="{'loading-small':loading}">
                    {{ 'articles.predef_search_query1_caption' | translate }}
                </a>
            </li>
            <li>
                <a (click)="searchPredefined(2)" [ngClass]="{'loading-small':loading}">
                    {{ 'articles.predef_search_query2_caption' | translate }}
                </a>
            </li>
            <li>
                <a (click)="searchPredefined(3)" [ngClass]="{'loading-small':loading}">
                    {{ 'articles.predef_search_query3_caption' | translate }}
                </a>
            </li>
            <li>
                <a (click)="searchPredefined(4)" [ngClass]="{'loading-small':loading}">
                    {{ 'articles.predef_search_query4_caption' | translate }}
                </a>
            </li>
            <li>
                <a (click)="searchPredefined(5)" [ngClass]="{'loading-small':loading}">
                    {{ 'articles.predef_search_query5_caption' | translate }}
                </a>
            </li>
            <li>
                <a (click)="searchPredefined(6)" [ngClass]="{'loading-small':loading}">
                    {{ 'articles.predef_search_query6_caption' | translate }}
                </a>
            </li>
            <li>
                <a (click)="searchPredefined(7)" [ngClass]="{'loading-small':loading}">
                    {{ 'articles.predef_search_query7_caption' | translate }}
                </a>
            </li>
            <li>
                <a (click)="searchPredefined(8)" [ngClass]="{'loading-small':loading}">
                    {{ 'articles.predef_search_query8_caption' | translate }}
                </a>
            </li>
            <li>
                <a (click)="searchPredefined(9)" [ngClass]="{'loading-small':loading}">
                    {{ 'articles.predef_search_query9_caption' | translate }}
                </a>
            </li>
        </ul>
    </div>
</div>
<div class="articles">
    <div class="container">
        <div class="row" *ngFor="let row of searchResults;">
            <article-search-result *ngFor="let item of row" [article]="item" class="col-12 col-md-6">
            </article-search-result>
        </div>
    </div>
</div>
