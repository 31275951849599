<div class="prov-details pep">
    <div class="top-block">
        <project-edit-top-banner></project-edit-top-banner>
    </div>
    <project-tabs activeTab="details">
    </project-tabs>
    <div>
        <div class="info info-new">
            <div class="project-details-part">
                <div class="container">
                    <div class="edit-block">
                        <workitems-view></workitems-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>