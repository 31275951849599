<!-- <div [ngClass]="{'wrap': true, 'pointable':!!articleId}" (click)="galleryClick()"> -->
    <!-- <div class="top-part over-content" *ngIf="galleryImages.length > 0 && !pictureId">
        <div class="company-name">
            <a [routerLink]="providerLink">
                {{providerName}}
            </a>
        </div>
        <span class="price" *ngIf="price > 0">
            {{price | number:'1.0-0'}}&nbsp;{{currencySign}}
        </span>
    </div> -->
    <ngx-gallery [options]="galleryOptions" [images]="galleryImages" (change)="onPicChange($event)"></ngx-gallery>
<!-- </div> -->
