<div class="top-part">
    <div class="container">
        <ul class="nav">
            <li class="nav-item" *ngFor="let pp of projectPlugs">
                <a class="nav-link" (click)="loadProjectPlug(pp)">{{ 'footer.top.projectplug_renovation' | translate:{pp: pp.name} }}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" langRouterLink routerLink="/search-results">{{ 'footer.top.local_providers' | translate}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" langRouterLink routerLink="/search">{{ 'footer.top.articles_search' | translate}}</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" langRouterLink routerLink="/search">{{ 'footer.top.search' | translate}}</a>
            </li>
        </ul>
    </div>
</div>
