import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';

import { InitDataService } from '../init-data.service';
import { Language } from './Language';
import { LangDetector } from './lang-detector';
import { L10nInitData } from './L10nInitData';

@Injectable({
    providedIn: 'root',
})
export class L10nService {
    public static supportedLangs = ['en', 'it'];
    private currentLang: string;
    private l10nInitData: L10nInitData;

    constructor(initDataService: InitDataService, @Inject(PLATFORM_ID) private platformId: Object,
                @Optional() @Inject('ACCEPT_LANG') public acceptedLangs: string, langDetector: LangDetector) {
        this.l10nInitData = initDataService.getInitialData().l10n;
        L10nService.supportedLangs = this.l10nInitData.supportedLanguages.map(l => l.id);
        this.currentLang = langDetector.determineCurrentLang(L10nService.supportedLangs);
    }

    public getCurrentLang() {
        return this.currentLang;
    }

    getLanguages(): Language[] {
        return this.l10nInitData.supportedLanguages;
    }

}
