import { Component, OnInit, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ProjectService } from '../../shared/projects/project.service';
import { SeoService } from '../../shared/seo.service';

@Component({
    templateUrl: './project-edit.component.html'
})
export class ProjectEditComponent implements OnInit {
    ngUnsubscribe: Subject<void> = new Subject<void>();

    constructor(private projectService: ProjectService, private translate: TranslateService, private seoService: SeoService) { }

    ngOnInit(): void {
        this.translate.get(['project_edit.title', 'project_edit.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['project_edit.title'], res['project_edit.desc']);
        });
        this.projectService.loadPricesIfNotLoaded();
    }



}
