import { Injectable } from '@angular/core';

import { GalleryPic } from './GalleryPic';
import { GalleryWithPics } from './GalleryWithPics';
import { GalleryPicsResponse } from './GalleryPicsResponse';
import { GalleriesWithPicsResponse } from './GalleriesWithPicsResponse';
import { DataService } from '../network/data.service';
import { WorkAreasService } from '../workareas/work-areas.service';
import { LocationService } from '../locations/location.service';
import { DisplayService } from '../display.service';
import { GalleryLightComponent } from '../../components/gallery/gallery-light.component';
import { GalleryComponent } from '../../components/gallery/gallery.component';

@Injectable()
export class GalleryService {
    private freeGalleries: GalleryComponent[] = [];
    private galleriesWithId: GalleryComponent[] = [];
    private galleriesWithProviderId: GalleryLightComponent[] = [];
    private freePics: GalleryPic[] = [];
    private articleId: string | null = null;
    private picsForProviders: GalleryWithPics[] = [];

    constructor(private dataService: DataService, private workAreasService: WorkAreasService,
        private locationService: LocationService, private displayService: DisplayService) {
    }
    
    public setActiveGalleries(newGalleries: GalleryComponent[]): void {
        this.freeGalleries.length = 0;
        this.galleriesWithId.length = 0;
        for (let i = 0; i < newGalleries.length; ++i) {
            if (newGalleries[i].galleryId) {
                this.addGallery(newGalleries[i], this.galleriesWithId);
            } else if (!newGalleries[i].pictureId) {    //TODO: maybe there should be more filtering before addding to freeGalleries
                this.addGallery(newGalleries[i], this.freeGalleries);
            }
        }
        if (this.galleriesWithId.length > 0) {
            let galleries = [];
            for (var i = 0; i < this.galleriesWithId.length; i++) {
                galleries.push({
                    gid: this.galleriesWithId[i].galleryId,
                    pid: this.galleriesWithId[i].projectId
                });
            }
            this.loadGalleryPicsByGalleryIds(galleries);
        }
        this.redistributeFreePictures();
    }

    private addGallery(gallery: any, galleries: any[]): boolean {
        if (!galleries.find(g => g.id === gallery.id)) {
            galleries.push(gallery);
            return true;
        }
        return false;
    }

    public updatePictures(): void {
        if (this.displayService.isBrowser()) {
            this.loadGalleryPicsByProviderIds(null);
            this.getPicsForCurrentProject().then(picsResponse => {
                if (picsResponse && picsResponse.pics) {
                    this.freePics.length = 0;
                    this.freePics = picsResponse.pics;
                    this.redistributeFreePictures();
                }
            });
        }
    }
    
    public redistributeFreePictures(): void {
        let galleriesWithNoFilters = this.getGalleriesWithNoFilters();
        let picsToDistribute = this.getFirstPicsForGalleries(this.freePics, galleriesWithNoFilters.length);
        picsToDistribute = picsToDistribute.concat(this.freePics.filter(p => !picsToDistribute.some(ep => ep.hiResUrl === p.hiResUrl)));
        let picsForGalleries = new Array<GalleryPic[]>(galleriesWithNoFilters.length);
        for (let j = 0; j < picsToDistribute.length; ++j) {
            let g = j % galleriesWithNoFilters.length;
            picsForGalleries[g] = picsForGalleries[g] || [];
            picsForGalleries[g].push(picsToDistribute[j]);
        }
        for (let i = 0; i < galleriesWithNoFilters.length; ++i) {
            galleriesWithNoFilters[i].setPics(picsForGalleries[i]);
        }
    }

    private getFirstPicsForGalleries(pics: GalleryPic[], numOfGalleries: number): GalleryPic[] {
        let result: GalleryPic[] = [];
        if (pics.length <= numOfGalleries) {
            return result;
        }
        for (let i = 0; i < pics.length; ++i) {
            if (!result.some(p => p.providerId == pics[i].providerId)) {
                result.push(pics[i]);
            }
            if (result.length >= numOfGalleries) {
                break;
            }
        }
        return result;
    }

    private getGalleriesWithNoFilters(): GalleryComponent[] {
        return this.freeGalleries.filter(g => g.galleryId === undefined);
    }
    
    private getPicsForCurrentProject(): Promise<GalleryPicsResponse> {
        let currentProjectPlug = this.workAreasService.getCurrentProjectPlug();
        var currentLocation = this.locationService.getCurrentLocation();
        let projectPlugId = currentProjectPlug && currentProjectPlug.id;
        return this.dataService.get<GalleryPicsResponse>(`Gallery/Pics?pp=${projectPlugId}&latitude=${currentLocation.latitude}&longitude=${currentLocation.longitude}&countryId=${currentLocation.country}`);
    }

    public loadGalleryPicsByGalleryIds(galleries: Array<any>) {
        let currentLocation = this.locationService.getCurrentLocation();
        let galleryIdsQuery = '';
        for (var i = 0; i < galleries.length; i++) {
            galleryIdsQuery += `&gs[${i}].gid=${galleries[i].gid}`;
            if (galleries[i].pid) {
                galleryIdsQuery += `&gs[${i}].pid=${galleries[i].pid}`;
            }
        }
        return this.dataService.get<GalleriesWithPicsResponse>(`Gallery/PicsForGalleries?latitude=${currentLocation.latitude}&longitude=${currentLocation.longitude}&countryId=${currentLocation.country}&articleId=${this.articleId}${galleryIdsQuery}`)
            .then(picsResponse => {
                if (picsResponse.galleries) {
                    for (var i = 0; i < picsResponse.galleries.length; i++) {
                        var gallery = this.galleriesWithId.find(g => g.galleryId == picsResponse.galleries[i].galleryId);
                        if (!gallery) {
                            continue;
                        }
                        gallery.setPics(picsResponse.galleries[i].pictures);
                    }
                }
            });
    }

    public loadGalleryPicsByProviderIds(providerIds: Array<string> | null) {
        if (!providerIds || !providerIds.length) {
            providerIds = this.galleriesWithProviderId.map(g => g.providerId + '');
        }
        if (!providerIds || !providerIds.length) {
            return;
        }
        let providerIdsQuery = `?p=${providerIds[0]}`;
        for (var i = 1; i < providerIds.length; i++) {
            providerIdsQuery += `&p=${providerIds[i]}`;
        }
        return this.dataService.get<GalleriesWithPicsResponse>(`Gallery/PicsForProvider${providerIdsQuery}`, true)
            .then(picsResponse => {
                if (picsResponse.galleries) {
                    this.picsForProviders = picsResponse.galleries;
                    this.setPicsToGalleriesWithProviderId();
                }
            });
    }

    private setPicsToGalleriesWithProviderId(): void {
        for (var i = 0; i < this.picsForProviders.length; i++) {
            var galleries = this.galleriesWithProviderId.filter(g => g.providerId == this.picsForProviders[i].galleryId);
            galleries.forEach(g => {
                g.setPics(this.picsForProviders[i].pictures);
            });
        }
    }

    public registerGalleryWithProviderId(gallery: GalleryLightComponent) {
        this.addGallery(gallery, this.galleriesWithProviderId);
        var existingSetOfPics = this.picsForProviders.find(g => g.galleryId == gallery.providerId);
        if (existingSetOfPics) {
            gallery.setPics(existingSetOfPics.pictures);
        }
    }

    public unregisterGalleriesWithProviderId() {
        //This manual register/unregister is potentially dangerous. Can cause bugs and/or memory leak
        this.galleriesWithProviderId.length = 0;
    }

    public getGalleryPicsByPictureId(pictureId: number): Promise<GalleryPic[]>  {
        var currentLocation = this.locationService.getCurrentLocation();
        return this.dataService.get<GalleryPicsResponse>(`Gallery/StandalonePic?pictureId=${pictureId}&articleId=${this.articleId}`)
            .then(picsResponse => {
                return picsResponse.pics;
            });
    }
    
    public setArticleId(articleId: string | null) {
        this.articleId = articleId;
    }
}
