<div class="wrap-bg">
<div class="container">
<div class="title-green">
    <h3>
        {{ 'comparison.header_p1' | translate }}<br class="d-md-none"> {{ 'comparison.header_p2' | translate }}<span class="d-none d-md-inline">{{ 'comparison.header_p3' | translate }}</span>!
    </h3>
    <span class="subtitle">
        {{ 'comparison.header_p4' | translate }}
    </span>
</div>
<comparison-table></comparison-table>
</div>
</div>
