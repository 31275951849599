<div class="wrap-bg">
    <div class="container">
        <div class="navigation-links d-none d-md-flex">
            <a backBtn defaultRoute="/search-results">
                {{ 'back' | translate }}
            </a>
            <a *ngIf="nextProviderId"
               [routerLink]="'/offer-preview/' + nextProviderId"> {{'offer_preview.providers_offer' | translate:{providerName: nextProviderName} }}
                >></a>
        </div>
        <div class="offer-preview">
            <div class="">
                <div class="top-part">
                    <div class="block-green">
                        <ul class="advantages-check advantages-check_inline">
                            <li>
                                <span class="check">✓</span>
                                <span><b>{{ 'registration.slogan_1_1' | translate }}</b>{{ 'registration.slogan_1_2' | translate }}</span>
                            </li>
                            <li>
                                <span class="check">✓</span>
                                <span><b>{{ 'registration.slogan_2_1' | translate }}</b>{{ 'registration.slogan_2_2' | translate }}</span>
                            </li>
                            <li>
                                <span class="check">✓</span>
                                <span><b>{{ 'registration.slogan_3_1' | translate }}</b>{{ 'registration.slogan_3_2' | translate }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="top-title">
                        <h5>
                            {{'offer_preview.providers_offer' | translate:{providerName: seletedOffer?.providerName} }}
                        </h5>
                        <span>
                    <a routerLink="/register">{{'offer_preview.submit_request_v1_p1' | translate }}</a> {{'offer_preview.submit_request_v1_p2' | translate }}
                </span>
                    </div>
                </div>
                <div class="mail-part">
                    <b class="alert-message">
                        {{ 'offer_preview.preview' | translate }}
                    </b>
                    <h4>
                        {{seletedOffer?.providerName}}
                    </h4>
                    <span>
                {{ 'offer_preview.via' | translate }}
            </span>
                    <br><br>

                    <span>
                {{ currentDate }}
            </span>
                    <br>

                    <span>
                {{ 'offer_preview.to' | translate }}
            </span>
                    <br>

                    <b>
                        {{ 'offer_preview.subj' | translate }}
                    </b>
                    <br><br>

                    <span>
                {{ 'offer_preview.dear' | translate }}
            </span>
                    <br><br>

                    <p>
                        {{ 'offer_preview.total_price_header' | translate }}{{seletedOffer?.currencySign}}&nbsp;{{seletedOffer?.totalPrice | number:'1.0-0'}}
                        <span *ngIf="!shownMore">...</span> <a *ngIf="!shownMore"
                                                               (click)="shownMore = true">{{'offer_preview.show_more' | translate}}</a>
                    </p>
                    <div *ngIf="shownMore">
                        <p>
                            {{ 'offer_preview.valid_until' | translate }}{{validUntilDate}}.
                        </p>

                        <span>
                    {{ 'offer_preview.text_p1' | translate }}
                </span>
                        <ul>
                            <li>
                                {{ 'offer_preview.text_p2' | translate }}
                            </li>
                            <li>
                                {{ 'offer_preview.text_p3' | translate }}
                            </li>
                            <li>
                                {{ 'offer_preview.text_p4' | translate }}
                            </li>
                        </ul>
                        <br>
                        <span>
                    {{ 'offer_preview.thx' | translate }}
                </span>
                        <br><br>
                        <span>
                    {{ 'offer_preview.regards' | translate }}
                </span>
                        <br><br>
                        <span>
                    {{seletedOffer?.providerName}}
                </span>
                    </div>
                </div>
                <div *ngIf="seletedOffer" class="table-part">
                    <h4>
                        {{ 'offer_preview.pdf_link_header' | translate }} <a target="_blank" href="/Project/OfferPreview?providerId={{seletedOffer.providerId}}"><img
                            src="assets/img/icon-pdf.svg" height="40" alt=""></a>
                    </h4>

                    <div *ngIf="groupedMwasWithWorkItems" class="Ftable table-offer">
                        <div class="Ftable-row Ftable-header">
                            <div class="Ftable-cell"></div>
                            <div class="Ftable-cell">
                                {{ 'offer_preview.total' | translate }}
                            </div>
                            <div class="Ftable-cell price">
                                <span>{{ 'offer_preview.total' | translate }}</span>
                                {{seletedOffer.currencySign}}&nbsp;{{seletedOffer.totalPrice | number:'1.0-0'}}
                            </div>
                        </div>
                        <ng-container *ngFor="let mwa of groupedMwasWithWorkItems">
                            <div class="Ftable-row title-row">
                                <h6>
                                    {{mwa.masterWorkAreasNames}}
                                </h6>
                            </div>
                            <div *ngFor="let workItem of mwa.workItems" class="Ftable-row">
                                <div class="Ftable-cell description">
                                    <h6>{{workItem.workItemName}}</h6>
                                    <span class="text-badge">{{workItem.qty}} {{workItem.uomName}}</span>
                                    <span>{{ workItem.workItemOptionDescription ? workItem.workItemOptionDescription : workItem.workItemDescription }}</span>
                                </div>
                                <div class="Ftable-cell size">
                                    {{workItem.qty}} {{workItem.uomName}}
                                </div>
                                <div class="Ftable-cell price">
                                    <b>???.0 {{seletedOffer.currencySign}}</b>
                                    <br>
                                    <b class="red"><a
                                            routerLink="/register">{{'offer_preview.submit_request_v2_p1' | translate }}</a> {{'offer_preview.submit_request_v2_p2' | translate }}
                                    </b>
                                </div>
                                <div class="Ftable-cell description-mobile">
                                    <p>
                                        <i>{{ workItem.workItemOptionDescription }}</i>
                                    </p>
                                    <span>{{ workItem.workItemDescription }}</span>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
