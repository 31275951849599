import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import { SeoService } from '../../shared/seo.service';
import { FeedbackService } from '../../shared/feedback/feedback.service';
import { ProjectService } from '../../shared/projects/project.service';
import { DisplayService } from '../../shared/display.service';
import { AccountService } from '../../shared/account/account.service';
import { ProjectPriceItem } from '../../shared/projects/ProjectPriceItem';
import { ProviderScoreModel } from '../../shared/feedback/ProviderScoreModel';

@Component({
    selector: 'feedback-provider-score',
    templateUrl: './feedback-provider-score.component.html'
})
export class FeedbackProviderScoreComponent implements OnInit {
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    public selectedProviderId!: string;
    public firstName!: string;
    public scores: ProviderScoreModel[];
    public selectedScore: ProviderScoreModel;
    public positiveComment: string = '';
    public negativeComment: string = '';
    public scoreDescs: any;

    constructor(public translate: TranslateService, private seoService: SeoService, private feedbackService: FeedbackService,
        private projectService: ProjectService, private activatedRoute: ActivatedRoute, private router: Router,
        private displayService: DisplayService, private accountService: AccountService) {
        this.scores = [
            new ProviderScoreModel(),
            new ProviderScoreModel(),
            new ProviderScoreModel(),
        ];
        this.selectedScore = this.scores[0];
    }

    ngOnInit() {
        if (this.displayService.isBrowser()) {
            this.feedbackService.getProviderScores().then(dto => {
                let scores = dto.scores;
                for (let i = 0; i < 3 && i < scores.length; ++i) {
                    this.scores[i].providerId = scores[i].providerId;
                    this.scores[i].providerName = scores[i].providerName;
                    this.scores[i].competence = scores[i].competence;
                }
                this.selectedProviderId = scores[0].providerId;
            });
        }
        this.translate.get(['feedback_provider_score.title', 'feedback_provider_score.desc']).subscribe((res: any) => {
            this.seoService.setMetaTags(res['feedback_provider_score.title'], res['feedback_provider_score.desc']);
        });
        this.translate.get('feedback_provider_score.score_descs').subscribe((res: any) => {
            this.scoreDescs = res;
        });
        this.firstName = this.accountService.getUserInfo().firstName;
    }

    public selectProvider(providerId: string) {
        this.selectedProviderId = providerId;
        let index = this.scores.findIndex(p => p.providerId == this.selectedProviderId);
        this.selectedScore = this.scores[index];
    }

    public getCurrentScore(): number | undefined {
        if (!this.selectedScore) {
            return undefined;
        }
        return this.selectedScore.getScore();
    }

    public getCurrentScoreDesc(): string {
        let score = this.getCurrentScore();
        if (score === undefined || score < 3) {
            return this.scoreDescs.score1;
        }
        if (score < 4) {
            return this.scoreDescs.score2;
        }
        if (score < 5) {
            return this.scoreDescs.score3;
        }
        if (score < 6.5) {
            return this.scoreDescs.score4;
        }
        if (score < 8) {
            return this.scoreDescs.score5;
        }
        return this.scoreDescs.score6;
        
    }

    public setScore(type: string, value: number): void {
        (this.selectedScore as any)[type] = value;
        this.feedbackService.saveScore(this.selectedProviderId, type, value);
    }

    public saveComment(isPositive: boolean): void {
        this.feedbackService.saveComment(this.selectedProviderId, isPositive, isPositive ? this.positiveComment : this.negativeComment);
    }

    public calcScore() {
        if (this.selectedScore.allScoresSet()) {
            this.selectedScore.showScore = true;
            window && window.scrollTo(0, 0);
        }
    }
}
