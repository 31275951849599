import { Injectable, Inject } from '@angular/core';

import { ProviderMarker } from './ProviderMarker';
import { DataService } from '../network/data.service';
import { ProviderDocsDto } from './ProviderDocsDto';
import { LeadFeedbackViewDto } from './LeadFeedbackViewDto';
import { ProviderDetailsDto } from './ProviderDetailsDto';

@Injectable()
export class ProviderService {
    private providersDocsCache: any = {};
    private providersReviewsCache: any = {};

    constructor(private dataService: DataService) {
    }

    public getProvidersInArea(north: number, south: number, west: number, east: number): Promise<ProviderMarker[]> {
        return this.dataService.get<ProviderMarker[]>(`Providers?north=${north}&south=${south}&west=${west}&east=${east}`);
    }

    public async getProviderDocs(providerId: any): Promise<ProviderDocsDto>{
        let docs = this.providersDocsCache[providerId];
        if (docs) {
            return docs;
        }
        docs = await this.dataService.get<ProviderDocsDto>(`Providers/Docs?providerId=${providerId}`);
        this.providersDocsCache[providerId] = docs;
        return docs;
    }

    public getProviderDetails(providerId: any): Promise<ProviderDetailsDto> {
        return this.dataService.get<ProviderDetailsDto>(`Providers/Details?providerId=${providerId}`);
    }

    public async getReviews(providerId: string): Promise<LeadFeedbackViewDto[]> {
        let reviews = this.providersReviewsCache[providerId];
        if (reviews) {
            return reviews;
        }
        reviews = await this.dataService.get<any>(`Providers/Reviews/${providerId}`);
        this.providersReviewsCache[providerId] = reviews;
        return reviews;
    }

    public async getProviderName(providerId: string): Promise<string> {
        let response = await this.dataService.get<any>(`Providers/Name?id=${encodeURIComponent(providerId)}`);
        return response.providerName;
    }
}
