<div class="wrap-bg">
    <div class="top-banner top-banner_how-it-works" *ngIf="!(showExample1 || showExample2 || showExample3)">
        <div class="top-part">
            <div class="wrap">
                <div class="title-strip-yellow">
                    <div class="container">
                        <h3>
                            {{ 'how_it_works.top_slogan' | translate }}
                        </h3>
                    </div>
                </div>
                <div class="container">
                    <div class="row">
                        <div class="feedback col-10 col-md-6 col-lg-5 col-xl-4">
                            <p>
                                <img src="assets/img/quotes-gray.png" alt="">
                                {{ 'how_it_works.top_quote' | translate }}
                            </p>
                            <span class="author">
                            {{ 'how_it_works.top_quote_author' | translate }}
                        </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="how-it-works how-it-works_general" *ngIf="!(showExample1 || showExample2 || showExample3)">
        <div class="container">
            <div id="step1" class="steps-line">
                <div class="step step-active">
                    <b>
                        {{ 'how_it_works.step1' | translate }}
                    </b>
                </div>
                <div class="step" (click)="scrollToFragment('step2')">
                    <b>
                        {{ 'how_it_works.step2' | translate }}
                    </b>
                </div>
                <div class="step" (click)="scrollToFragment('step3')">
                    <b>
                        {{ 'how_it_works.step3' | translate }}
                    </b>
                </div>
                <div>
                    <a class="btn btn-middle btn-yellow" routerLink="/search">
                        {{ 'how_it_works.ready_to_start' | translate }}<br>
                        <i>{{ 'how_it_works.its_free' | translate }}</i>
                    </a>
                </div>
            </div>
            <p class="wrap-link d-lg-none">
                <a routerLink="/search">{{ 'how_it_works.ready_to_start' | translate }}</a>&nbsp;<i>{{'how_it_works.its_free' | translate }}</i>
            </p>
            <div class="step-block step-define">
                <h3>
                    {{ 'how_it_works.step1_header' | translate }}
                </h3>
                <div class="gray-block">
                    <ul class="list">
                        <li>
                            {{ 'how_it_works.step1_desc1' | translate }}
                        </li>
                        <li>
                            {{ 'how_it_works.step1_desc2' | translate }}
                        </li>
                        <li>
                            {{ 'how_it_works.step1_desc3' | translate }}
                        </li>
                    </ul>
                    <div class="content">
                        <div class="step step-first">
                            <h6>
                                {{ 'how_it_works.step1_1' | translate }}
                            </h6>
                            <div class="wrap">
        <span class="btn btn-like-dropdown btn-transparent-bronze">
            <span class="label">
                {{ 'how_it_works.step1_1_area_header' | translate }}
            </span>
            <span class="value">
                {{ 'how_it_works.step1_1_area_value' | translate }}
            </span>
        </span>
                                <form class="work-details text-left">
                                    <div class="wrap-form-check">
                                        <div class="form-check main">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input">
                                                <i class="checkbox-icon"></i>
                                                <img alt=""
                                                     src="assets/img/icon-kitchen.svg"
                                                     width="35">
                                                {{ 'how_it_works.step1_1_mwa1' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="wrap-form-check">
                                        <div class="form-check main">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input">
                                                <i class="checkbox-icon"></i>
                                                <img alt=""
                                                     src="assets/img/icon-default.svg"
                                                     width="35">
                                                {{ 'how_it_works.step1_1_mwa2' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="wrap-form-check">
                                        <div class="form-check main">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input">
                                                <i class="checkbox-icon"></i>
                                                <img alt=""
                                                     src="assets/img/icon-flooring.svg"
                                                     width="35">
                                                {{ 'how_it_works.step1_1_mwa3' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="wrap-form-check">
                                        <div class="form-check main active">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" checked>
                                                <i class="checkbox-icon"></i>
                                                <img alt=""
                                                     src="assets/img/icon-walls.svg"
                                                     width="35">
                                                {{ 'how_it_works.step1_1_mwa4' | translate }}
                                            </label>
                                            <a class="btn btn-bronze btn-small-extra btn-collapse collapsed"
                                               role="button" data-toggle="collapse"
                                               aria-expanded="false" aria-controls="collapseDemolitions">
                                                {{ 'how_it_works.step1_1_edit' | translate }}
                                            </a>
                                        </div>
                                    </div>
                                    <div class="wrap-form-check">
                                        <div class="form-check main">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input">
                                                <i class="checkbox-icon"></i>
                                                <img alt=""
                                                     src="assets/img/icon-bathroom.svg"
                                                     width="35">
                                                {{ 'how_it_works.step1_1_mwa5' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="step cond">
                            <h6>
                                {{ 'how_it_works.step1_2' | translate }}
                            </h6>
                            <div class="edit-block">
                                <div class="list-block">
                                    <h6>
                                        {{ 'how_it_works.step1_1_mwa4' | translate }}
                                    </h6>
                                    <ul>
                                        <li>
                                            <div class="top-part">
                                                <b class="title">{{ 'how_it_works.step1_2_wi1_name' | translate }}:</b>
                                                <div class="wrap-actions">
                                                    <b class="text-badge">{{ 'how_it_works.step1_2_wi1_qty' | translate }}</b>
                                                    <a class="edit-popup-link">
                                                        <i aria-hidden="true" class="fa fa-pencil"></i>
                                                    </a>
                                                    <a href=""
                                                       class="remove">{{ 'workitems_list.remove' | translate }}</a>
                                                </div>
                                            </div>
                                            <div class="option-labels">
                                                <div class="label">
                                                    <a>
                                                        {{ 'how_it_works.step1_2_wi1_wio_short_1' | translate }}
                                                    </a>
                                                </div>
                                                <div class="label">
                                                    <b>
                                                        {{ 'how_it_works.step1_2_wi1_wio_short_2' | translate }}
                                                    </b>
                                                </div>
                                                <div class="label">
                                                    <a>
                                                        {{ 'how_it_works.step1_wio_short_more' | translate }}
                                                    </a>
                                                </div>
                                            </div>
                                            <span>
                        {{ 'how_it_works.step1_2_wi1_desc' | translate }}
                    </span>
                                        </li>
                                        <li>
                                            <div class="top-part">
                                                <b class="title">{{ 'how_it_works.step1_2_wi2_name' | translate }}:</b>
                                                <div class="wrap-actions">
                                                    <img src="assets/img/unlock-orange.svg">
                                                    <b class="text-badge orange">{{ 'how_it_works.step1_2_wi2_qty' | translate }}</b>
                                                    <a class="edit-popup-link">
                                                        <i aria-hidden="true" class="fa fa-pencil"></i>
                                                    </a>
                                                    <a href=""
                                                       class="remove">{{ 'workitems_list.remove' | translate }}</a>
                                                </div>
                                            </div>
                                            <div class="option-labels">
                                                <div class="label">
                                                    <b>
                                                        {{ 'how_it_works.step1_2_wi2_wio_short_1' | translate }}
                                                    </b>
                                                </div>
                                                <div class="label">
                                                    <a>
                                                        {{ 'how_it_works.step1_2_wi2_wio_short_2' | translate }}
                                                    </a>
                                                </div>
                                                <div class="label">
                                                    <a>
                                                        {{ 'how_it_works.step1_wio_short_more' | translate }}
                                                    </a>
                                                </div>
                                            </div>
                                            <span>
                        {{ 'how_it_works.step1_2_wi2_desc' | translate }}
                    </span>
                                        </li>
                                        <li>
                                            <div class="top-part">
                                                <b class="title">{{ 'how_it_works.step1_2_wi3_name' | translate }}:</b>
                                                <div class="wrap-actions">
                                                    <b class="text-badge">{{ 'how_it_works.step1_2_wi3_qty' | translate }}</b>
                                                    <a class="edit-popup-link">
                                                        <i aria-hidden="true" class="fa fa-pencil"></i>
                                                    </a>
                                                    <a href=""
                                                       class="remove">{{ 'workitems_list.remove' | translate }}</a>
                                                </div>
                                            </div>
                                            <div class="option-labels">
                                                <div class="label">
                                                    <a>
                                                        {{ 'how_it_works.step1_2_wi3_wio_short_1' | translate }}
                                                    </a>
                                                </div>
                                                <div class="label">
                                                    <b>
                                                        {{ 'how_it_works.step1_2_wi3_wio_short_1' | translate }}
                                                    </b>
                                                </div>
                                                <div class="label">
                                                    <a>
                                                        {{ 'how_it_works.step1_wio_short_more' | translate }}
                                                    </a>
                                                </div>
                                            </div>
                                            <span>
                        {{ 'how_it_works.step1_2_wi3_desc' | translate }}
                    </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="step step-third">
                            <h6>
                                {{ 'how_it_works.step1_3' | translate }}
                            </h6>
                            <div class="edit-block">
                                <div class="list-block">
                                    <h6>
                                        {{ 'how_it_works.step1_2_wi1_name' | translate }}
                                    </h6>
                                    <ul>
                                        <li>
                                            <div>
                                                <input id="radio" type="radio" name="radio" checked>
                                                <label for="radio" class="radiobtn"></label>
                                            </div>
                                            <div>
                                                <b>{{ 'how_it_works.step1_3_wio1_name' | translate }}</b>
                                                <br>
                                                <span>
                            {{ 'how_it_works.step1_3_wio1_desc' | translate }}
                        </span>
                                                <br>
                                                <span class="quantity"><span>{{ 'how_it_works.step1_3_wio1_qty' | translate }}</span> {{ 'how_it_works.step1_3_wio1_uom' | translate }}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <input id="radio1" type="radio" name="radio">
                                                <label for="radio1" class="radiobtn"></label>
                                            </div>
                                            <div>
                                                <b>{{ 'how_it_works.step1_3_wio2_name' | translate }}</b>
                                                <br>
                                                <span>
                            {{ 'how_it_works.step1_3_wio2_desc' | translate }}
                        </span>
                                                <br>
                                                <span class="quantity"><span>{{ 'how_it_works.step1_3_wio2_qty' | translate }}</span> {{ 'how_it_works.step1_3_wio2_uom' | translate }}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mobile-content">
                        <div class="see-examples">
                            <div class="wrap-img">
                                <img src="assets/img/see-example.png" alt="">
                            </div>
                            <div class="wrap-btn">
                                <button class="btn btn-white-black btn-small" (click)="showExample(1)">
                                    <b>{{ 'how_it_works.see_example' | translate }}</b>
                                </button>
                            </div>
                        </div>
                        <div class="faq-block">
                            <div class="questions">
                                <div class="wrap-links">
                                    <div class="wrap-img">
                                        <img src="assets/img/questions.png" alt="">
                                    </div>
                                    <div class="wrap-list-btn">
                                        <ul>
                                            <li>
                                                <a routerLink="/faq" fragment="faq-chapter3">
                                                    {{ 'how_it_works.faq7' | translate }}
                                                </a>
                                            </li>
                                            <li>
                                                <a routerLink="/faq" fragment="faq-chapter3">
                                                    {{ 'how_it_works.faq8' | translate }}
                                                </a>
                                            </li>
                                            <li>
                                                <a routerLink="/faq" fragment="faq-chapter3">
                                                    {{ 'how_it_works.faq9' | translate }}
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="wrap-btn">
                                            <a class="btn btn-white-black btn-small" routerLink="/faq">
                                                {{ 'how_it_works.faq_link_p1' | translate }}<br
                                                    class="d-none d-md-block">
                                                <b class="d-none d-md-inline">
                                                    {{ 'how_it_works.faq_link_p2' | translate }}
                                                </b>&nbsp;<span
                                                    class="d-md-none">{{ 'how_it_works.faq_link_p2_v2' | translate }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="feedback-line">
                                <div class="wrap-img">
                                    <img src="assets/img/quotes-gray_big.png" alt="">
                                </div>
                                <p>
                                    {{ 'how_it_works.quote1' | translate }}
                                    <small>{{ 'how_it_works.quote1_author' | translate }}</small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="faq-block d-none d-sm-block">
                <div class="questions">
                    <div class="wrap-links">
                        <div class="wrap-img">
                            <img src="assets/img/questions.png" alt="">
                        </div>
                        <ul>
                            <li>
                                <a routerLink="/faq" fragment="faq-chapter1">
                                    {{ 'how_it_works.faq1' | translate }}
                                </a>
                            </li>
                            <li>
                                <a routerLink="/faq" fragment="faq-chapter1">
                                    {{ 'how_it_works.faq2' | translate }}
                                </a>
                            </li>
                            <li>
                                <a routerLink="/faq" fragment="faq-chapter1">
                                    {{ 'how_it_works.faq3' | translate }}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="wrap-btn">
                        <a class="btn btn-white-black btn-small" routerLink="/faq">
                            {{ 'how_it_works.faq_link_p1' | translate }}<br class="d-none d-md-block">
                            <b class="d-none d-md-inline">
                                {{ 'how_it_works.faq_link_p2' | translate }}
                            </b>&nbsp;<span class="d-md-none">{{ 'how_it_works.faq_link_p2_v2' | translate }}</span>
                        </a>
                    </div>
                </div>
                <div class="feedback-line">
                    <div class="wrap-img">
                        <img src="assets/img/quotes-gray_big.png" alt="">
                    </div>
                    <p>
                        {{ 'how_it_works.quote1' | translate }}
                        <small>{{ 'how_it_works.quote1_author' | translate }}</small>
                    </p>
                </div>
            </div>
            <div id="step2" class="steps-line">
                <div class="step" (click)="scrollToFragment('step1')">
                    <b>
                        {{ 'how_it_works.step1' | translate }}
                    </b>
                </div>
                <div class="step step-active">
                    <b>
                        {{ 'how_it_works.step2' | translate }}
                    </b>
                </div>
                <div class="step" (click)="scrollToFragment('step3')">
                    <b>
                        {{ 'how_it_works.step3' | translate }}
                    </b>
                </div>
                <div>
                    <a class="btn btn-middle btn-yellow" routerLink="/search">
                        {{ 'how_it_works.ready_to_start' | translate }}<br>
                        <i>{{ 'how_it_works.its_free' | translate }}</i>
                    </a>
                </div>
            </div>
            <p class="wrap-link d-lg-none">
                <a routerLink="/search"> {{ 'how_it_works.ready_to_start' | translate }}</a>&nbsp;<i>{{ 'how_it_works.its_free' | translate }}</i>
            </p>
            <div class="step-block step-compare">
                <h3>
                    {{ 'how_it_works.step2_header' | translate }}
                </h3>
                <div class="gray-block">
                    <ul class="list">
                        <li>
                            {{ 'how_it_works.step2_desc1' | translate }}
                        </li>
                        <li>
                            {{ 'how_it_works.step2_desc2' | translate }}
                        </li>
                        <li>
                            {{ 'how_it_works.step2_desc3' | translate }}
                        </li>
                    </ul>
                    <div class="content prov-details odp">
                        <div class="odp-tabs container">
                            <div class="part-left"><a class="btn btn-yellow with-icon" href="/en/offer-preview/7102">
                                <img src="assets/img/icon-heart-house-white.svg" alt=""><span>Preview offer</span></a>
                                <div class="price"> €&nbsp;2,609</div>
                            </div>
                            <div class="part-right">
                                    <span class="preview-text">
                                        for your personalized project
                                    </span>
                                    <div class="tabs">
                                        <div class="container">
                                            <div class="tabs-menu">
                                                <a class="tab tab-sqm" href="/en/search-results?tab=area">
                                                <div class="wrap-img">
                                                    <img src="assets/img/icon-area.svg">
                                                </div>
                                                <div class="wrap-content">
                                                    <b class="tab-title"><span>20</span> {{ 'sqm' | translate }}</b>
                                                    <span class="tab-action"> {{ 'provider_details.refine' | translate }} {{ 'provider_details.area' | translate }} </span>
                                                </div>
                                            </a><a class="tab tab-areas"
                                                   href="/en/search-results?tab=workareas">
                                                <div class="wrap-img"><img src="assets/img/icon-area.svg"></div>
                                                <div class="wrap-content">
                                                    <b class="tab-title"><span>2</span> {{ 'WORKAREAS_SELECTOR.workareas_header5' | translate }} </b>
                                                    <span class="tab-action">{{ 'provider_details.refine' | translate }} {{ 'provider_details.workareas' | translate }}</span>
                                                </div>
                                            </a><a class="tab-active tab tab-details"
                                                   href="/en/edit-project">
                                                <div class="wrap-img"><img src="assets/img/icon-file-search.svg" alt="">
                                                </div>
                                                <div class="wrap-content"><b class="tab-title"> {{ 'provider_details.edit_project_details_tab' | translate:{workItemsCount: 14} }}</b><span
                                                        class="tab-action">{{ 'provider_details.edit_workitems' | translate }}</span></div>
                                            </a><a class="tab tab-comments"
                                                   href="/en/project-uploads">
                                                <div class="wrap-img"><img src="assets/img/icon-area-empty.svg" alt="">
                                                    <img src="assets/img/icon-comment.svg" alt="">
                                                    <img src="assets/img/icon-picture.svg" alt=""></div>
                                                <div class="wrap-content">
                                                    <b class="tab-title"><span>0</span>{{ 'provider_details.add_comments_and_uploads' | translate }}</b>
                                                    <span class="tab-action">{{ 'provider_details.add_comments_p1' | translate }} {{ 'provider_details.add_comments_p2' | translate }}</span>
                                                </div>
                                            </a></div>
                                        </div>
                                    </div>
                                <div>
                                    <div class="project-details-list">
                                        <div class="container">
                                            <ul class="list-with-disc">
                                                <li><b>{{ 'how_it_works.step1_2_wi1_name' | translate }}: 1 {{ 'how_it_works.lumpsum' | translate }}</b>
                                                    <p>{{ 'how_it_works.step1_2_wi1_desc' | translate }}</p></li>
                                                <li><b>{{ 'how_it_works.step1_2_wi2_name' | translate }}: 1 {{ 'how_it_works.lumpsum' | translate }}</b>
                                                    <p>{{ 'how_it_works.step1_2_wi2_desc' | translate }}</p></li>

                                                <li><b><a href="/en/edit-project"> {{ 'WORKAREAS_SELECTOR.workitems_details_link' | translate:{workItemsCount: 12} }}</a></b></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="provider-block container">
                            <div>
                                <div class="info info-new">
                                    <div class="info-part">
                                        <div class="provider-line">
                                            <div class="info-verified"><img src="assets/img/icon-reno.svg" alt=""><span><b>{{ 'provider_details.review_provider' | translate }}</b><span
                                                    class="d-md-inline d-none">{{ 'provider_details.provider_pics' | translate }}</span></span>
                                            </div>
                                            <div class="info-provider"><a class="provider-name" href="">
                                                <span>{{ 'search_results.provider' | translate }} </span>Milano Ristrutturazioni </a>
                                                <div class="stars">
                                                    <div class="star star-orange star-small"></div>
                                                    <div class="star star-orange star-small"></div>
                                                    <div class="star star-orange star-small"></div>
                                                    <div class="star star-orange star-small"></div>
                                                </div>
                                            </div>
                                            <div class="advantages adv-column adv-row small-extra">
                                                <div class="advantage"><i
                                                        class="fa fa-check-square-o"></i><span><a>{{ 'search_results.offices' | translate }} {{ 'search_results.verified' | translate }}</a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="description">Milano Ristrutturazioni offre ai propri clienti uno studio approfondito dell’ambiente...
                                            <div><a class="text-badge gray">{{ 'provider_details.read_more' | translate }}</a><a
                                                    class="text-badge">{{ 'provider_details.office_details' | translate }}</a><a
                                                    class="text-badge">{{ 'provider_details.docs_num' | translate:{numOfDocs: 8} }}</a></div>
                                        </div>
                                        <div class="characteristic">
                                            <div class="tile tile-reviews">
                                                <div class="tile-title">
                                                    <div class="wrap-line"><h5>{{ 'provider_details.reviews' | translate }}</h5></div>
                                                    <div class="wrap-link"></div>
                                                </div>
                                                <div class="tile-content tile-indicator_bar">
                                                    <ul class="check-list">
                                                        <li><span class="icon red">&#x2718;</span>
                                                            {{ 'provider_details.no_reviews' | translate }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="tile tile-prices">
                                                <div class="tile-title">
                                                    <div class="wrap-line"><h5>{{ 'provider_details.prices_header' | translate }}</h5>
                                                        <div class="stars">
                                                            <div class="star star-small star-blue-dark"></div>
                                                            <div class="star star-small star-blue-dark"></div>
                                                            <div class="star star-small star-blue-dark"></div>
                                                            <div class="star star-small star-blue-dark"></div>
                                                            <div class="star star-small star-blue-transparent"></div>
                                                        </div>
                                                    </div>
                                                    <div class="wrap-link">
                                                        <a>{{ 'provider_details.price_stars_desc_p' | translate }}</a>
                                                    </div>
                                                </div>
                                                <div class="tile-content tile-indicator_bar">
                                                    <div class="wrap-indicator"><span>{{ 'provider_details.below_average' | translate }}</span>
                                                        <div class="indicator_bar length-1"></div>
                                                        <b>3</b></div>
                                                    <div class="wrap-indicator"><span>{{ 'provider_details.about_average' | translate }}</span>
                                                        <div class="indicator_bar length-1"></div>
                                                        <b>5</b></div>
                                                    <div class="wrap-indicator"><span>{{ 'provider_details.above_average' | translate }}</span>
                                                        <div class="indicator_bar length-1"></div>
                                                        <b>6</b></div>
                                                </div>
                                            </div>
                                            <div class="tile tile-on_time">
                                                <div class="tile-title">
                                                    <div class="wrap-line">
                                                        <h5>
                                                            {{ 'provider_details.guarantee_header_p1' | translate }}
                                                            {{ 'provider_details.guarantee_header_p2' | translate }}
                                                        </h5>
                                                    </div>
                                                    <div class="wrap-link"><a>{{ 'provider_details.guarantee.show_details_link' | translate }}</a></div>
                                                </div>
                                                <div class="tile-content tile-indicator_bar">
                                                    <ul class="check-list">
                                                        <li>
                                                            <span class="icon green">✓</span> {{ 'provider_details.guarantee.dates_in_contract.always' | translate }}
                                                        </li>
                                                        <li><span class="icon red">&#x2718;</span>
                                                            {{ 'provider_details.guarantee.dates_in_contract.none' | translate }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="tile tile-services">
                                                <div class="tile-title">
                                                    <div class="wrap-line">
                                                        <h5>
                                                            {{ 'provider_details.services' | translate }}
                                                        </h5>
                                                        <div class="stars">
                                                            <div class="star star-small star-blue-dark"></div>
                                                            <div class="star star-small star-blue-dark"></div>
                                                            <div class="star star-small star-blue-dark"></div>
                                                            <div class="star star-small star-blue-dark"></div>
                                                            <div class="star star-small star-blue-dark"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tile-content tile-indicator_bar">
                                                    <ul class="check-list">
                                                        <li><span class="icon green">✓</span><span>{{ 'how_it_works.free' | translate }}</span>
                                                            {{ 'how_it_works.step2_service1' | translate }}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mobile-content">
                        <div class="see-examples">
                            <div class="wrap-img">
                                <img src="assets/img/see-example.png" alt="">
                            </div>
                            <div class="wrap-btn">
                                <button id="first-block" class="btn btn-white-black btn-small" (click)="showExample(2)">
                                    <b>{{ 'how_it_works.see_example' | translate }}</b>
                                </button>
                            </div>
                        </div>
                        <div class="faq-block">
                            <div class="questions">
                                <div class="wrap-links">
                                    <div class="wrap-img">
                                        <img src="assets/img/questions.png" alt="">
                                    </div>
                                    <div class="wrap-list-btn">
                                        <ul>
                                            <li>
                                                <a routerLink="/faq" fragment="faq-chapter1">
                                                    {{ 'how_it_works.faq1' | translate }}
                                                </a>
                                            </li>
                                            <li>
                                                <a routerLink="/faq" fragment="faq-chapter1">
                                                    {{ 'how_it_works.faq2' | translate }}
                                                </a>
                                            </li>
                                            <li>
                                                <a routerLink="/faq" fragment="faq-chapter1">
                                                    {{ 'how_it_works.faq3' | translate }}
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="wrap-btn">
                                            <a class="btn btn-white-black btn-small" routerLink="/faq">
                                                {{ 'how_it_works.faq_link_p1' | translate }}<br
                                                    class="d-none d-md-block">
                                                <b class="d-none d-md-inline">
                                                    {{ 'how_it_works.faq_link_p2' | translate }}
                                                </b>&nbsp;<span
                                                    class="d-md-none">{{ 'how_it_works.faq_link_p2_v2' | translate }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="feedback-line">
                                <div class="wrap-img">
                                    <img src="assets/img/quotes-gray_big.png" alt="">
                                </div>
                                <p>
                                    {{ 'how_it_works.quote2' | translate }}
                                    <small>{{ 'how_it_works.quote2_author' | translate }}</small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="faq-block d-none d-sm-block">
                <div class="questions">
                    <div class="wrap-links">
                        <div class="wrap-img">
                            <img src="assets/img/questions.png" alt="">
                        </div>
                        <ul>
                            <li>
                                <a routerLink="/faq" fragment="faq-chapter2">
                                    {{ 'how_it_works.faq4' | translate }}
                                </a>
                            </li>
                            <li>
                                <a routerLink="/faq" fragment="faq-chapter2">
                                    {{ 'how_it_works.faq5' | translate }}
                                </a>
                            </li>
                            <li>
                                <a routerLink="/faq" fragment="faq-chapter2">
                                    {{ 'how_it_works.faq6' | translate }}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="wrap-btn">
                        <a class="btn btn-white-black btn-small" routerLink="/faq">
                            {{ 'how_it_works.faq_link_p1' | translate }}<br class="d-none d-md-block">
                            <b class="d-none d-md-inline">
                                {{ 'how_it_works.faq_link_p2' | translate }}
                            </b>&nbsp;<span class="d-md-none">{{ 'how_it_works.faq_link_p2_v2' | translate }}</span>
                        </a>
                    </div>
                </div>
                <div class="feedback-line">
                    <div class="wrap-img">
                        <img src="assets/img/quotes-gray_big.png" alt="">
                    </div>
                    <p>
                        {{ 'how_it_works.quote2' | translate }}
                        <small>{{ 'how_it_works.quote2_author' | translate }}</small>
                    </p>
                </div>
            </div>
            <div id="step3" class="steps-line">
                <div class="step" (click)="scrollToFragment('step1')">
                    <b>
                        {{ 'how_it_works.step1' | translate }}
                    </b>
                </div>
                <div class="step" (click)="scrollToFragment('step2')">
                    <b>
                        {{ 'how_it_works.step2' | translate }}
                    </b>
                </div>
                <div class="step step-active">
                    <b>
                        {{ 'how_it_works.step3' | translate }}
                    </b>
                </div>
                <div>
                    <a class="btn btn-middle btn-yellow" routerLink="/search">
                        {{ 'how_it_works.ready_to_start' | translate }}<br>
                        <i>{{ 'how_it_works.its_free' | translate }}</i>
                    </a>
                </div>
            </div>
            <p class="wrap-link d-lg-none">
                <a routerLink="/search">{{ 'how_it_works.ready_to_start' | translate }}</a>&nbsp;<i>{{ 'how_it_works.its_free' | translate }}</i>
            </p>
            <div class="step-block step-offers">
                <h3>
                    {{ 'how_it_works.step3_header' | translate }}
                </h3>
                <div class="gray-block">
                    <ul class="list">
                        <li>
                            {{ 'how_it_works.step3_desc1' | translate }}
                        </li>
                        <li>
                            {{ 'how_it_works.step3_desc2' | translate }}
                        </li>
                        <li>
                            {{ 'how_it_works.step3_desc3' | translate }}
                        </li>
                    </ul>
                    <div class="content prov-details">
                        <div class="download-links download-links-mobile d-lg-none">
                            <a class="download-link download-link_red">
                                <img src="assets/img/pdf-icon_1.png" width="40" alt="">
                                <span>
            {{ 'comparison.download' | translate }}<br class="d-sm-none"> {{ 'comparison.pdf_file' | translate }}
        </span>
                            </a>
                            <br class="d-none d-lg-block d-xl-none">
                            <a class="download-link download-link_green">
                                <img src="assets/img/excel-icon.png" width="40" alt="">
                                <span>
            {{ 'comparison.download' | translate }}<br class="d-sm-none"> {{ 'comparison.xlsx_file' | translate }}
        </span>
                            </a>
                        </div>
                        <div class="table-comparison table-comparison-mobile d-block d-lg-none">
                            <ul class="company-names">
                                <li>
                                    <b class="number-in-circle">
                                        1
                                    </b>
                                    <a>
                                        {{ 'how_it_works.provider1_name' | translate }}
                                    </a>
                                </li>
                                <li>
                                    <b class="number-in-circle">
                                        2
                                    </b>
                                    <a>
                                        {{ 'how_it_works.provider2_name' | translate }}
                                    </a>
                                </li>
                                <li>
                                    <b class="number-in-circle">
                                        3
                                    </b>
                                    <a>
                                        {{ 'how_it_works.provider3_name' | translate }}
                                    </a>
                                </li>
                            </ul>
                            <div class="table-flex">
                                <div class="table-row">
                                    <div class="table-cell empty-first-cell"></div>
                                    <div class="table-cell">
                                        <b class="number-in-circle">1</b>
                                        <br>
                                        <b class="price">
                                            {{ 'how_it_works.provider1_total_price' | translate }}
                                        </b>
                                    </div>
                                    <div class="table-cell">
                                        <b class="number-in-circle">2</b>
                                        <br>
                                        <b class="price">
                                            {{ 'how_it_works.provider2_total_price' | translate }}
                                        </b>
                                    </div>
                                    <div class="table-cell">
                                        <b class="number-in-circle">3</b>
                                        <br>
                                        <b class="price">
                                            {{ 'how_it_works.provider3_total_price' | translate }}
                                        </b>
                                    </div>
                                </div>
                                <div class="item-block">
                                    <div class="table-row flesh-row">
                                        <h6>
                                            {{ 'how_it_works.step1_1_mwa4' | translate }}
                                        </h6>
                                    </div>
                                    <div class="item">
                                        <div class="table-row row-detailed-prices">
                                            <div class="table-cell type">
                                                <h6>
                                                    {{ 'how_it_works.step1_2_wi1_name' | translate }}
                                                </h6>
                                                <span class="text-badge">{{ 'how_it_works.step1_2_wi1_qty' | translate }}</span>
                                            </div>
                                            <div class="table-cell">
                                                <b>{{ 'how_it_works.step3_wi1_price1' | translate }}</b>
                                            </div>
                                            <div class="table-cell">
                                                <b>{{ 'how_it_works.step3_wi1_price2' | translate }}</b>
                                            </div>
                                            <div class="table-cell">
                                                <b>{{ 'how_it_works.step3_wi1_price3' | translate }}</b>
                                            </div>
                                        </div>
                                        <div class="table-row flex-column description">
                    <span>
                        {{ 'how_it_works.step1_2_wi1_desc' | translate }}
                    </span>
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="table-row row-detailed-prices">
                                            <div class="table-cell type">
                                                <h6>
                                                    {{ 'how_it_works.step1_2_wi2_name' | translate }}
                                                </h6>
                                                <span class="text-badge">{{ 'how_it_works.step1_2_wi2_qty' | translate }}</span>
                                            </div>
                                            <div class="table-cell">
                                                <b>{{ 'how_it_works.step3_wi2_price1' | translate }}</b>
                                            </div>
                                            <div class="table-cell">
                                                <b>{{ 'how_it_works.step3_wi2_price2' | translate }}</b>
                                            </div>
                                            <div class="table-cell">
                                                <b>{{ 'how_it_works.step3_wi2_price3' | translate }}</b>
                                            </div>
                                        </div>
                                        <div class="table-row flex-column description">
                    <span>
                        {{ 'how_it_works.step1_2_wi1_desc' | translate }}
                    </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <table class="table table-responsive table-comparison d-none d-lg-block">
                            <thead>
                            <tr>
                                <th class="download-links" scope="col">
                                    <div class="wrap-links">
                                        <a class="download-link download-link_red">
                                            <img src="assets/img/pdf-icon_1.png" width="40" alt="">
                                            <span>
                        {{ 'comparison.download' | translate }} {{ 'comparison.pdf_file' | translate }}
                    </span>
                                        </a>
                                        <br class="d-none d-lg-block d-xl-none">
                                        <a class="download-link download-link_green">
                                            <img src="assets/img/excel-icon.png" width="40" alt="">
                                            <span>
                        {{ 'comparison.download' | translate }} {{ 'comparison.xlsx_file' | translate }}
                    </span>
                                        </a>
                                    </div>
                                </th>
                                <th scope="col"></th>
                                <th scope="col">
                                    <a>
                                        {{ 'how_it_works.provider1_name' | translate }}
                                    </a>
                                    <br>
                                    <span class="price">
                {{ 'how_it_works.provider1_total_price' | translate }}
            </span>
                                </th>
                                <th scope="col">
                                    <a>
                                        {{ 'how_it_works.provider2_name' | translate }}
                                    </a>
                                    <br>
                                    <span class="price">
                {{ 'how_it_works.provider2_total_price' | translate }}
            </span>
                                </th>
                                <th scope="col">
                                    <a>
                                        {{ 'how_it_works.provider3_name' | translate }}
                                    </a>
                                    <br>
                                    <span class="price">
                {{ 'how_it_works.provider3_total_price' | translate }}
            </span>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="flesh-row">
                                <td>
                                    <h5>
                                        {{ 'how_it_works.step1_1_mwa4' | translate }}
                                    </h5>
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td class="description">
                                    <h5>{{ 'how_it_works.step1_2_wi1_name' | translate }}</h5>
                                    <span>
                {{ 'how_it_works.step1_2_wi1_desc' | translate }}
            </span>
                                </td>
                                <td class="size">{{ 'how_it_works.step1_2_wi1_qty' | translate }}</td>
                                <td>
                                    <b>{{ 'how_it_works.step3_wi1_price1' | translate }}</b>
                                </td>
                                <td>
                                    <b>{{ 'how_it_works.step3_wi1_price2' | translate }}</b>
                                </td>
                                <td>
                                    <b>{{ 'how_it_works.step3_wi1_price3' | translate }}</b>
                                </td>
                            </tr>
                            <tr class="bg-none">
                                <td class="description">
                                    <h5>{{ 'how_it_works.step1_2_wi2_name' | translate }}</h5>
                                    <span>
                {{ 'how_it_works.step1_2_wi2_desc' | translate }}
            </span>
                                </td>
                                <td class="size">{{ 'how_it_works.step1_2_wi2_qty' | translate }}</td>
                                <td>
                                    <b>{{ 'how_it_works.step3_wi2_price1' | translate }}</b>
                                </td>
                                <td>
                                    <b>{{ 'how_it_works.step3_wi2_price2' | translate }}</b>
                                </td>
                                <td>
                                    <b>{{ 'how_it_works.step3_wi2_price3' | translate }}</b>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="mobile-content">
                        <div class="see-examples">
                            <div class="wrap-img">
                                <img src="assets/img/see-example.png" alt="">
                            </div>
                            <div class="wrap-btn">
                                <button id="first-block" class="btn btn-white-black btn-small" (click)="showExample(3)">
                                    <b>{{ 'how_it_works.see_example' | translate }}</b>
                                </button>
                            </div>
                        </div>
                        <div class="faq-block">
                            <div class="questions">
                                <div class="wrap-links">
                                    <div class="wrap-img">
                                        <img src="assets/img/questions.png" alt="">
                                    </div>
                                    <div class="wrap-list-btn">
                                        <ul>
                                            <li>
                                                <a routerLink="/faq" fragment="faq-chapter2">
                                                    {{ 'how_it_works.faq4' | translate }}
                                                </a>
                                            </li>
                                            <li>
                                                <a routerLink="/faq" fragment="faq-chapter2">
                                                    {{ 'how_it_works.faq5' | translate }}
                                                </a>
                                            </li>
                                            <li>
                                                <a routerLink="/faq" fragment="faq-chapter2">
                                                    {{ 'how_it_works.faq6' | translate }}
                                                </a>
                                            </li>
                                        </ul>
                                        <div class="wrap-btn">
                                            <a class="btn btn-white-black btn-small" routerLink="/faq">
                                                {{ 'how_it_works.faq_link_p1' | translate }}<br
                                                    class="d-none d-md-block">
                                                <b class="d-none d-md-inline">
                                                    {{ 'how_it_works.faq_link_p2' | translate }}
                                                </b>&nbsp;<span
                                                    class="d-md-none">{{ 'how_it_works.faq_link_p2_v2' | translate }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="feedback-line">
                                <div class="wrap-img">
                                    <img src="assets/img/quotes-gray_big.png" alt="">
                                </div>
                                <p>
                                    {{ 'how_it_works.quote3' | translate }}
                                    <small>{{ 'how_it_works.quote3_author' | translate }}</small>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="faq-block d-none d-sm-block">
                <div class="questions">
                    <div class="wrap-links">
                        <div class="wrap-img">
                            <img src="assets/img/questions.png" alt="">
                        </div>
                        <ul>
                            <li>
                                <a routerLink="/faq" fragment="faq-chapter3">
                                    {{ 'how_it_works.faq7' | translate }}
                                </a>
                            </li>
                            <li>
                                <a routerLink="/faq" fragment="faq-chapter3">
                                    {{ 'how_it_works.faq8' | translate }}
                                </a>
                            </li>
                            <li>
                                <a routerLink="/faq" fragment="faq-chapter3">
                                    {{ 'how_it_works.faq9' | translate }}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="wrap-btn">
                        <a class="btn btn-white-black btn-small" routerLink="/faq">
                            {{ 'how_it_works.faq_link_p1' | translate }}<br class="d-none d-md-block">
                            <b class="d-none d-md-inline">
                                {{ 'how_it_works.faq_link_p2' | translate }}
                            </b>&nbsp;<span class="d-md-none">{{ 'how_it_works.faq_link_p2_v2' | translate }}</span>
                        </a>
                    </div>
                </div>
                <div class="feedback-line">
                    <div class="wrap-img">
                        <img src="assets/img/quotes-gray_big.png" alt="">
                    </div>
                    <p>
                        {{ 'how_it_works.quote3' | translate }}
                        <small>{{ 'how_it_works.quote3_author' | translate }}</small>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div [ngClass]="{'how-it-works': true, 'how-it-works_mobile-blocks':true, 'd-block': showExample1 || showExample2 || showExample3 }">
        <div [ngClass]="{'mobile-block': true, 'first-block': true, 'd-block': showExample1 }">
            <div class="container">
                <div class="step-block step-define">
                    <div class="content">
                        <div class="step step-first">
                            <h6>
                                {{ 'how_it_works.step1_1' | translate }}
                            </h6>
                            <div class="wrap">
        <span class="btn btn-like-dropdown btn-transparent-bronze">
            <span class="label">
                {{ 'how_it_works.step1_1_area_header' | translate }}
            </span>
            <span class="value">
                {{ 'how_it_works.step1_1_area_value' | translate }}
            </span>
        </span>
                                <form id="work-details" class="work-details text-left">
                                    <div class="wrap-form-check">
                                        <div class="form-check main">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input">
                                                <i class="checkbox-icon"></i>
                                                <img alt=""
                                                     src="assets/img/icon-kitchen.svg"
                                                     width="35">
                                                {{ 'how_it_works.step1_1_mwa1' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="wrap-form-check">
                                        <div class="form-check main">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input">
                                                <i class="checkbox-icon"></i>
                                                <img alt=""
                                                     src="assets/img/icon-kitchen.svg"
                                                     width="35">
                                                {{ 'how_it_works.step1_1_mwa2' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="wrap-form-check">
                                        <div class="form-check main">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input">
                                                <i class="checkbox-icon"></i>
                                                <img alt=""
                                                     src="assets/img/icon-flooring.svg"
                                                     width="35">
                                                {{ 'how_it_works.step1_1_mwa3' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="wrap-form-check">
                                        <div class="form-check main active">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input" checked>
                                                <i class="checkbox-icon"></i>
                                                <img alt=""
                                                     src="assets/img/icon-walls.svg"
                                                     width="35">
                                                {{ 'how_it_works.step1_1_mwa4' | translate }}
                                            </label>
                                            <a class="btn btn-bronze btn-small-extra btn-collapse collapsed"
                                               role="button"
                                               aria-expanded="false" aria-controls="collapseDemolitions">
                                                {{ 'how_it_works.step1_1_edit' | translate }}
                                            </a>
                                        </div>
                                    </div>
                                    <div class="wrap-form-check">
                                        <div class="form-check main">
                                            <label class="form-check-label">
                                                <input type="checkbox" class="form-check-input">
                                                <i class="checkbox-icon"></i>
                                                <img alt=""
                                                     src="assets/img/icon-bathroom.svg"
                                                     width="35">
                                                {{ 'how_it_works.step1_1_mwa5' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="step step-second">
                            <h6>
                                {{ 'how_it_works.step1_2' | translate }}
                            </h6>
                            <div class="edit-block">
                                <div class="list-block">
                                    <h6>
                                        {{ 'how_it_works.step1_1_mwa4' | translate }}
                                    </h6>
                                    <ul>
                                        <li>
                                            <div class="top-part">
                                                <b class="title">{{ 'how_it_works.step1_2_wi1_name' | translate }}:</b>
                                                <div class="wrap-actions">
                                                    <b class="text-badge">{{ 'how_it_works.step1_2_wi1_qty' | translate }}</b>
                                                    <a class="edit-popup-link">
                                                        <i aria-hidden="true" class="fa fa-pencil"></i>
                                                    </a>
                                                    <a href=""
                                                       class="remove">{{ 'workitems_list.remove' | translate }}</a>
                                                </div>
                                            </div>
                                            <div class="option-labels">
                                                <div class="label">
                                                    <a>
                                                        {{ 'how_it_works.step1_2_wi1_wio_short_1' | translate }}
                                                    </a>
                                                </div>
                                                <div class="label">
                                                    <b>
                                                        {{ 'how_it_works.step1_2_wi1_wio_short_2' | translate }}
                                                    </b>
                                                </div>
                                                <div class="label">
                                                    <a>
                                                        {{ 'how_it_works.step1_wio_short_more' | translate }}
                                                    </a>
                                                </div>
                                            </div>
                                            <span>
                        {{ 'how_it_works.step1_2_wi1_desc' | translate }}
                    </span>

                                        </li>
                                        <li>
                                            <div class="top-part">
                                                <b class="title">{{ 'how_it_works.step1_2_wi2_name' | translate }}:</b>
                                                <div class="wrap-actions">
                                                    <img src="assets/img/unlock-orange.svg">
                                                    <b class="text-badge orange">{{ 'how_it_works.step1_2_wi2_qty' | translate }}</b>
                                                    <a class="edit-popup-link">
                                                        <i aria-hidden="true" class="fa fa-pencil"></i>
                                                    </a>
                                                    <a href=""
                                                       class="remove">{{ 'workitems_list.remove' | translate }}</a>
                                                </div>
                                            </div>
                                            <div class="option-labels">
                                                <div class="label">
                                                    <a>
                                                        {{ 'how_it_works.step1_2_wi1_wio_short_1' | translate }}
                                                    </a>
                                                </div>
                                                <div class="label">
                                                    <b>
                                                        {{ 'how_it_works.step1_2_wi1_wio_short_2' | translate }}
                                                    </b>
                                                </div>
                                                <div class="label">
                                                    <a>
                                                        {{ 'how_it_works.step1_wio_short_more' | translate }}
                                                    </a>
                                                </div>
                                            </div>
                                            <span>
                        {{ 'how_it_works.step1_2_wi2_desc' | translate }}
                    </span>

                                        </li>
                                        <li>
                                            <div class="top-part">
                                                <b class="title">{{ 'how_it_works.step1_2_wi3_name' | translate }}:</b>
                                                <div class="wrap-actions">
                                                    <b class="text-badge">{{ 'how_it_works.step1_2_wi3_qty' | translate }}</b>
                                                    <a class="edit-popup-link">
                                                        <i aria-hidden="true" class="fa fa-pencil"></i>
                                                    </a>
                                                    <a href=""
                                                       class="remove">{{ 'workitems_list.remove' | translate }}</a>
                                                </div>
                                            </div>
                                            <div class="option-labels">
                                                <div class="label">
                                                    <a>
                                                        {{ 'how_it_works.step1_2_wi1_wio_short_1' | translate }}
                                                    </a>
                                                </div>
                                                <div class="label">
                                                    <b>
                                                        {{ 'how_it_works.step1_2_wi1_wio_short_2' | translate }}
                                                    </b>
                                                </div>
                                                <div class="label">
                                                    <a>
                                                        {{ 'how_it_works.step1_wio_short_more' | translate }}
                                                    </a>
                                                </div>
                                            </div>
                                            <span>
                        {{ 'how_it_works.step1_2_wi3_desc' | translate }}
                    </span>

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="step step-third">
                            <h6>
                                {{ 'how_it_works.step1_3' | translate }}
                            </h6>
                            <div class="edit-block">
                                <div class="list-block">
                                    <h6>
                                        {{ 'how_it_works.step1_2_wi1_name' | translate }}
                                    </h6>
                                    <ul>
                                        <li>
                                            <div>
                                                <input id="radio2" type="radio" name="radio1" checked="checked">
                                                <label for="radio2" class="radiobtn"></label>
                                            </div>
                                            <div>
                                                <b>{{ 'how_it_works.step1_3_wio1_name' | translate }}</b>
                                                <br>
                                                <span>
                            {{ 'how_it_works.step1_3_wio1_desc' | translate }}
                        </span>
                                                <br>
                                                <span class="quantity"><span>{{ 'how_it_works.step1_3_wio1_qty' | translate }}</span> {{ 'how_it_works.step1_3_wio1_uom' | translate }}</span>
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <input id="radio3" type="radio" name="radio">
                                                <label for="radio3" class="radiobtn"></label>
                                            </div>
                                            <div>
                                                <b>{{ 'how_it_works.step1_3_wio2_name' | translate }}</b>
                                                <br>
                                                <span>
                            {{ 'how_it_works.step1_3_wio2_desc' | translate }}
                        </span>
                                                <br>
                                                <span class="quantity"><span>{{ 'how_it_works.step1_3_wio2_qty' | translate }}</span> {{ 'how_it_works.step1_3_wio2_uom' | translate }}</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [ngClass]="{'mobile-block': true, 'second-block': true, 'd-block': showExample2}">
            <div class="prov-details">
                <div class="block-green selected-providers">
                    <div class="container">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="wrap-border side-left">
                                    <a class="d-none d-md-inline">
                                        <b>
                                            {{ 'provider_details.select_more_providers_short1' | translate:{numProvidersToSelectMore: 2} }}
                                        </b>
                                    </a>
                                    <small>
                                        <span class="d-md-none">{{ 'provider_details.' | translate }}{{ 'provider_details.select_more_providers_short2_p1' | translate:{numProvidersToSelectMore: 2} }}</span>{{ 'provider_details.select_more_providers_short2_p2' | translate }}
                                        {{ 'provider_details.select_more_providers_short2_p2' | translate }}
                                        <a>{{ 'provider_details.select_more_providers_short2_p3' | translate }}</a>
                                    </small>
                                </div>
                            </div>
                            <div class="col-md-7 side-right">
                                <p class="d-none d-md-block">
                                    {{ 'provider_details.slogan2' | translate }}
                                </p>
                                <ul>
                                    <li>
                                        <img src="assets/img/number-1.svg" width="30px" alt="">
                                        <a class="company">
                                            {{ 'how_it_works.provider1_name' | translate }}
                                        </a>
                                        <a class="icon-link white">
                                            <img src="assets/img/offer-preview-white.svg" height="22px" alt="">
                                            <span>{{ 'how_it_works.provider1_total_price' | translate }}</span>
                                        </a>
                                        <small>
                                            <a>{{ 'provider_details.remove' | translate }}</a>
                                        </small>
                                    </li>
                                </ul>
                            </div>
                            <div class="action d-md-none col-12">
                                <a class="btn btn-transparent-white">
                                    {{ 'provider_details.select_more_providers_short1' | translate }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="prov-details odp">
                    <div class="odp-tabs container">
                        <div class="part-left"><a class="btn btn-yellow with-icon" href="/en/offer-preview/7102">
                            <img src="assets/img/icon-heart-house-white.svg" alt=""><span>Preview offer</span></a>
                            <div class="price"> €&nbsp;2,609</div>
                        </div>
                        <div class="part-right">
                                    <span class="preview-text">
                                        for your personalized project
                                    </span>
                            <div class="tabs">
                                <div class="container">
                                    <div class="tabs-menu">
                                        <a class="tab tab-sqm" href="/en/search-results?tab=area">
                                            <div class="wrap-img">
                                                <img src="assets/img/icon-area.svg">
                                            </div>
                                            <div class="wrap-content">
                                                <b class="tab-title"><span>20</span> {{ 'sqm' | translate }}</b>
                                                <span class="tab-action"> {{ 'provider_details.refine' | translate }} {{ 'provider_details.area' | translate }} </span>
                                            </div>
                                        </a><a class="tab tab-areas"
                                               href="/en/search-results?tab=workareas">
                                        <div class="wrap-img"><img src="assets/img/icon-area.svg"></div>
                                        <div class="wrap-content">
                                            <b class="tab-title"><span>2</span> {{ 'WORKAREAS_SELECTOR.workareas_header5' | translate }} </b>
                                            <span class="tab-action">{{ 'provider_details.refine' | translate }} {{ 'provider_details.workareas' | translate }}</span>
                                        </div>
                                    </a><a class="tab-active tab tab-details"
                                           href="/en/edit-project">
                                        <div class="wrap-img"><img src="assets/img/icon-file-search.svg" alt="">
                                        </div>
                                        <div class="wrap-content"><b class="tab-title"> {{ 'provider_details.edit_project_details_tab' | translate:{workItemsCount: 14} }}</b><span
                                                class="tab-action">{{ 'provider_details.edit_workitems' | translate }}</span></div>
                                    </a><a class="tab tab-comments"
                                           href="/en/project-uploads">
                                        <div class="wrap-img"><img src="assets/img/icon-area-empty.svg" alt="">
                                            <img src="assets/img/icon-comment.svg" alt="">
                                            <img src="assets/img/icon-picture.svg" alt=""></div>
                                        <div class="wrap-content">
                                            <b class="tab-title"><span>0</span>{{ 'provider_details.add_comments_and_uploads' | translate }}</b>
                                            <span class="tab-action">{{ 'provider_details.add_comments_p1' | translate }} {{ 'provider_details.add_comments_p2' | translate }}</span>
                                        </div>
                                    </a></div>
                                </div>
                            </div>
                            <div>
                                <div class="project-details-list">
                                    <div class="container">
                                        <ul class="list-with-disc">
                                            <li><b>Shower supply: 1 {{ 'how_it_works.lumpsum' | translate }}</b>
                                                <p>Supply of shower box and tray, including removal and disposal
                                                    of existing materials and landfill transport</p></li>
                                            <li><b>Work area opening: 1 {{ 'how_it_works.lumpsum' | translate }}</b>
                                                <p>Work area preparation and administrative procedures</p></li>

                                            <li><b><a href="/en/edit-project"> {{ 'WORKAREAS_SELECTOR.workitems_details_link' | translate:{workItemsCount: 12} }}</a></b></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="provider-block container">
                        <div>
                            <div class="info info-new">
                                <div class="info-part">
                                    <div class="provider-line">
                                        <div class="info-verified"><img src="assets/img/icon-reno.svg" alt=""><span><b>{{ 'provider_details.review_provider' | translate }}</b><span
                                                class="d-md-inline d-none">{{ 'provider_details.provider_pics' | translate }}</span></span>
                                        </div>
                                        <div class="info-provider"><a class="provider-name" href="">
                                            <span>{{ 'search_results.provider' | translate }} </span>Roma Ristrutturazioni </a>
                                            <div class="stars">
                                                <div class="star star-orange star-small"></div>
                                                <div class="star star-orange star-small"></div>
                                                <div class="star star-orange star-small"></div>
                                                <div class="star star-orange star-small"></div>
                                            </div>
                                        </div>
                                        <div class="advantages adv-column adv-row small-extra">
                                            <div class="advantage"><i
                                                    class="fa fa-check-square-o"></i><span><a>{{ 'search_results.offices' | translate }} {{ 'search_results.verified' | translate }}</a></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="description">Roma Ristrutturazioni offre ai propri clienti uno studio approfondito dell’ambiente...
                                        <div><a class="text-badge gray">{{ 'provider_details.read_more' | translate }}</a><a
                                                class="text-badge">{{ 'provider_details.office_details' | translate }}</a><a
                                                class="text-badge">{{ 'provider_details.docs_num' | translate:{numOfDocs: 2} }}</a></div>
                                    </div>
                                    <div class="characteristic">
                                        <div class="tile tile-reviews">
                                            <div class="tile-title">
                                                <div class="wrap-line"><h5>{{ 'provider_details.reviews' | translate }}</h5></div>
                                                <div class="wrap-link"></div>
                                            </div>
                                            <div class="tile-content tile-indicator_bar">
                                                <ul class="check-list">
                                                    <li><span class="icon red">&#x2718;</span>
                                                        {{ 'provider_details.no_reviews' | translate }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="tile tile-prices">
                                            <div class="tile-title">
                                                <div class="wrap-line"><h5>{{ 'provider_details.prices_header' | translate }}</h5>
                                                    <div class="stars">
                                                        <div class="star star-small star-blue-dark"></div>
                                                        <div class="star star-small star-blue-dark"></div>
                                                        <div class="star star-small star-blue-dark"></div>
                                                        <div class="star star-small star-blue-dark"></div>
                                                        <div class="star star-small star-blue-transparent"></div>
                                                    </div>
                                                </div>
                                                <div class="wrap-link">
                                                    <a>{{ 'provider_details.price_stars_desc_p' | translate }}</a>
                                                </div>
                                            </div>
                                            <div class="tile-content tile-indicator_bar">
                                                <div class="wrap-indicator"><span>{{ 'provider_details.below_average' | translate }}</span>
                                                    <div class="indicator_bar length-1"></div>
                                                    <b>3</b></div>
                                                <div class="wrap-indicator"><span>{{ 'provider_details.about_average' | translate }}</span>
                                                    <div class="indicator_bar length-1"></div>
                                                    <b>5</b></div>
                                                <div class="wrap-indicator"><span>{{ 'provider_details.above_average' | translate }}</span>
                                                    <div class="indicator_bar length-1"></div>
                                                    <b>6</b></div>
                                            </div>
                                        </div>
                                        <div class="tile tile-on_time">
                                            <div class="tile-title">
                                                <div class="wrap-line">
                                                    <h5>
                                                        {{ 'provider_details.guarantee_header_p1' | translate }}
                                                        {{ 'provider_details.guarantee_header_p2' | translate }}
                                                    </h5>
                                                </div>
                                                <div class="wrap-link"><a>{{ 'provider_details.guarantee.show_details_link' | translate }}</a></div>
                                            </div>
                                            <div class="tile-content tile-indicator_bar">
                                                <ul class="check-list">
                                                    <li>
                                                        <span class="icon green">✓</span> {{ 'provider_details.guarantee.dates_in_contract.always' | translate }}
                                                    </li>
                                                    <li><span class="icon red">&#x2718;</span>
                                                        {{ 'provider_details.guarantee.dates_in_contract.none' | translate }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="tile tile-services">
                                            <div class="tile-title">
                                                <div class="wrap-line">
                                                    <h5>
                                                        {{ 'provider_details.services' | translate }}
                                                    </h5>
                                                    <div class="stars">
                                                        <div class="star star-small star-blue-dark"></div>
                                                        <div class="star star-small star-blue-dark"></div>
                                                        <div class="star star-small star-blue-dark"></div>
                                                        <div class="star star-small star-blue-dark"></div>
                                                        <div class="star star-small star-blue-dark"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tile-content tile-indicator_bar">
                                                <ul class="check-list">
                                                    <li><span class="icon green">✓</span><span>{{ 'how_it_works.free' | translate }}</span>
                                                        {{ 'how_it_works.step2_service1' | translate }}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [ngClass]="{'mobile-block': true, 'd-block': showExample3}">
            <div class="prov-details">
                <div class="title-green">
                    <h3>
                        {{ 'comparison.header_p1' | translate }}<br class="d-md-none"> {{ 'comparison.header_p2' |
                        translate }}<span class="d-none d-md-inline">{{ 'comparison.header_p3' | translate }}</span>!
                    </h3>
                    <span class="subtitle">
                {{ 'comparison.header_p4' | translate }}
            </span>
                </div>
                <div class="download-links download-links-mobile d-lg-none">
                    <a class="download-link download-link_red">
                        <img src="assets/img/pdf-icon_1.png" width="40" alt="">
                        <span>
                    {{ 'comparison.download' | translate }}<br
                                class="d-sm-none"> {{ 'comparison.pdf_file' | translate }}
                </span>
                    </a>
                    <br class="d-none d-lg-block d-xl-none">
                    <a class="download-link download-link_green">
                        <img src="assets/img/excel-icon.png" width="40" alt="">
                        <span>
                    {{ 'comparison.download' | translate }}<br
                                class="d-sm-none"> {{ 'comparison.xlsx_file' | translate }}
                </span>
                    </a>
                </div>
                <div class="table-comparison table-comparison-mobile d-block d-lg-none">
                    <ul class="company-names">
                        <li>
                            <b class="number-in-circle">
                                1
                            </b>
                            <a>
                                {{ 'how_it_works.provider1_name' | translate }}
                            </a>
                        </li>
                        <li>
                            <b class="number-in-circle">
                                2
                            </b>
                            <a>
                                {{ 'how_it_works.provider2_name' | translate }}
                            </a>
                        </li>
                        <li>
                            <b class="number-in-circle">
                                3
                            </b>
                            <a>
                                {{ 'how_it_works.provider3_name' | translate }}
                            </a>
                        </li>
                    </ul>
                    <div class="table-flex">
                        <div class="table-row">
                            <div class="table-cell empty-first-cell"></div>
                            <div class="table-cell">
                                <b class="number-in-circle">1</b>
                                <br>
                                <b class="price">
                                    {{ 'how_it_works.provider1_total_price' | translate }}
                                </b>
                            </div>
                            <div class="table-cell">
                                <b class="number-in-circle">2</b>
                                <br>
                                <b class="price">
                                    {{ 'how_it_works.provider2_total_price' | translate }}
                                </b>
                            </div>
                            <div class="table-cell">
                                <b class="number-in-circle">3</b>
                                <br>
                                <b class="price">
                                    {{ 'how_it_works.provider3_total_price' | translate }}
                                </b>
                            </div>
                        </div>
                        <div class="item-block">
                            <div class="table-row flesh-row">
                                <h6>
                                    {{ 'how_it_works.step1_1_mwa4' | translate }}
                                </h6>
                            </div>
                            <div class="item">
                                <div class="table-row row-detailed-prices">
                                    <div class="table-cell type">
                                        <h6>
                                            {{ 'how_it_works.step1_2_wi1_name' | translate }}
                                        </h6>
                                        <span class="text-badge">{{ 'how_it_works.step1_2_wi1_qty' | translate }}</span>
                                    </div>
                                    <div class="table-cell">
                                        <b>{{ 'how_it_works.step3_wi1_price1' | translate }}</b>
                                    </div>
                                    <div class="table-cell">
                                        <b>{{ 'how_it_works.step3_wi1_price2' | translate }}</b>
                                    </div>
                                    <div class="table-cell">
                                        <b>{{ 'how_it_works.step3_wi1_price3' | translate }}</b>
                                    </div>
                                </div>
                                <div class="table-row flex-column description">
                            <span>
                                {{ 'how_it_works.step1_2_wi1_desc' | translate }}
                            </span>
                                </div>
                            </div>
                            <div class="item">
                                <div class="table-row row-detailed-prices">
                                    <div class="table-cell type">
                                        <h6>
                                            {{ 'how_it_works.step1_2_wi2_name' | translate }}
                                        </h6>
                                        <span class="text-badge">{{ 'how_it_works.step1_2_wi2_qty' | translate }}</span>
                                    </div>
                                    <div class="table-cell">
                                        <b>{{ 'how_it_works.step3_wi2_price1' | translate }}</b>
                                    </div>
                                    <div class="table-cell">
                                        <b>{{ 'how_it_works.step3_wi2_price2' | translate }}</b>
                                    </div>
                                    <div class="table-cell">
                                        <b>{{ 'how_it_works.step3_wi2_price3' | translate }}</b>
                                    </div>
                                </div>
                                <div class="table-row flex-column description">
                            <span>
                                {{ 'how_it_works.step1_2_wi1_desc' | translate }}
                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
