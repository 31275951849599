<header>
    <top-menu></top-menu>
</header>
<main *ngIf="showMain">
    <router-outlet></router-outlet>
    <gdpr-popup>
    </gdpr-popup>
    <ng-container *ngIf="isBrowser">
        <!--<simple-notifications></simple-notifications>-->
    </ng-container>
</main>
<footer>
    <bottom-menu></bottom-menu>
    <bottom-links></bottom-links>
</footer>
