import { Component, OnInit, Inject, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { ProjectService } from '../../shared/projects/project.service';
import { SeoService } from '../../shared/seo.service';
import { WorkAreasService } from '../../shared/workareas/work-areas.service';

@Component({
    selector: 'project-tabs',
    templateUrl: './tabs.component.html'
})
export class ProjectTabsComponent implements OnInit {
    ngUnsubscribe: Subject<void> = new Subject<void>();

    areaSqm: number | undefined = undefined;
    public masterWorkAreasNum: number | undefined = undefined;
    public selectedMwaIcons: string[] = [];
    workItemsCount: number | undefined = undefined;
    numOfUploads: number = 0;
    numOfCommentsAndUploads: number = 0;
    commentIsNotEmpty: boolean = false;

    @Input() activeTab!: string;

    constructor(private projectService: ProjectService, private translate: TranslateService,
        private seoService: SeoService, private workAreasService: WorkAreasService) { }

    ngOnInit(): void {
        this.subscribeToRenovationArea();
        this.subscribeToPrices();
        this.subscribeToNumOfPics();
    }

    private subscribeToRenovationArea(): void {
        this.workAreasService.renovationArea.subscribe(area => {
            this.areaSqm = area;
        });
    }

    private subscribeToPrices(): void {
        this.projectService.pricesDto.pipe(takeUntil(this.ngUnsubscribe)).subscribe(dto => {
            this.masterWorkAreasNum = dto.masterWorkAreasNum;
            this.selectedMwaIcons = dto.topMwaIconsByPrices;
            if (dto.prices && dto.prices.length) {
                this.workItemsCount = dto.prices[0].workItems.length;
                this.commentIsNotEmpty = !!dto.userComment;
                this.calcNumOfCommentsAndUploads();
            }
            
        });
    }

    private subscribeToNumOfPics() {
        this.projectService.numOfProjectPics.pipe(takeUntil(this.ngUnsubscribe)).subscribe(n => {
            this.numOfUploads = n;
            this.calcNumOfCommentsAndUploads();
        });
    }

    private calcNumOfCommentsAndUploads(): void {
        this.numOfCommentsAndUploads = (this.commentIsNotEmpty ? 1 : 0) + this.numOfUploads;
    }
}
